import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';

export const OnlineTransferOfFundLetter = (transaction: any, amtWords: any, transMode: any, transType: any,  transNetAmount: any, transGrossAmount: any) => {
    let date = new Date();
    let dateFormat = moment(date).format('DD-MM-YYYY');
    let q="qrcodeonlineTF";
    let id="www.digitalcustodian.co/v/"+transaction.txn_id;  
    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 80,
             height : 80
         });
           qrcode.makeCode(id);
     }
    
    } , 500)

    return `<html>
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
                <tr>
                    <td>
                        <img src="${DCClogo}" alt="DCC" height="100" />
                    </td>
                    <td valign="bottom">
                    </td>
                    <td valign="bottom">
                    </td>
                    <td align="right" valign="bottom">
                  
                        <div style='text-align: right; height:100%; float: right;' id="${q}">
                     
                        </div>
    
                    </td>
                </tr>
            </table>
        </header>
        <hr />
        <section>
        <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br/>
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${transaction.branch},</div>
            <br />  
            <div> Sub. <b>Online Transfer of Fund. </b></div>     
            <br />
            <div> You are requested to online transfer the funds as per below details.</div>
            <br />  
            <table style="width: 100%;" > 
            <tr><td style="font-weight: bold;" colspan="3">Debit Account Details: </td> </tr>
                <tr>
                    <td> Account Title</td> 
                    <td ><b>${transaction.account_title} </b></td> 
                </tr>
                <tr>
                    <td>Account No.</td> 
                    <td><b>${transaction.account_number} (${transaction.bank}, ${transaction.branch}) </b></td> 
                </tr>
                <tr>
                    <td style="width: 100px;">Instrument No. </td> 
                    <td><b> ${transaction.instrument_no} </b></td> 
                </tr>
                </table>

                <table>
                <br/>
                <tr> <td style="font-weight: bold;" colspan="3">Credit Account Details: </td> </tr>
                <tr>
                    <td style="width: 100px"> Account Title</td> 
                    <td><b>${transaction.counter_account_title}  </b></td> 
                </tr>
                <tr>
                    <td>Account No.</td> 
                    <td><b>${transaction.counter_account_number} (${transaction.counter_bank}, ${transaction.counter_branch}) </b></td> 
                </tr> 
                <tr>
                    <td> Value Date</td> 
                    <td><b>  ${moment(transaction.execution_date).format('DD-MM-YYYY')}  </b></td> 
                </tr>
                <tr>
                    <td> Amount</td> 
                    <td ><b>PKR. ${transNetAmount} (Rupees: ${amtWords})   </b></td> 
                </tr> 
             </table>
    
           
            <br />
            
            <div>Please acknowledge receipt of the said letter.</div>
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `

}