import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';

export const MoneyMarketSaleLetter = (transaction: any, securities: any, amtWords: any,   transNetAmount: any, transGrossAmount: any) => {
    let date = new Date();
    let dateFormat = moment(date).format('DD-MM-YYYY');
    let q="qrcodemmSale";
    let id="www.digitalcustodian.co/v/"+transaction.txn_id;  
    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 80,
             height : 80
         });
           qrcode.makeCode(id);
     }
    
    } , 500)
    const myFunc=()=>{
        let securityData =""
        securities.map((items:any, index:any)=>{    
       
        securityData = securityData+ `
        <tr><td> <b> Instrument Detail </b></td><td colspan="3"><hr /></td></tr> 
        <tr>
            <td> Instrument Type</td><td> <b> ${items.security_type || ''} </b> </td> 
        </tr>
        <tr>
            <td> Issue Date</td><td> <b> ${moment(items.issue_date || '').format('DD-MM-YYYY')}  </b> </td>
        </tr>
        <tr>            
            <td> Maturity Date</td> <td> <b> ${moment(items.maturity_date || '').format('DD-MM-YYYY')} </b> </td>
        </tr>
        <tr>
            <td> Face Value</td><td> <b> ${items.money_market_face_value || ''}</b>  </td>
        </tr>
        <tr>
            <td> Coupon / Yield</td> <td> <b>  ${items.coupon_rate || ''} / ${items.yield || ''} </b></td>
        </tr>
        <tr>
            <td> Price</td> <td> <b>  ${items.price || ''} </b></td>
        </tr>
         
        `
    });
    return securityData;
    } 

    

    const securityType = securities.map(u => u.security_type).join(', ');

    // String.prototype.removeDuplicate = function() { 
    // return Array.from(new Set(this.split(', '))).toString();
    // }

    function removeDuplicate(secType) {
        return Array.from(new Set(secType.split('|'))).toString().replace(',', '');
    }

   // add counter party ips account
   const counterIPSAcc = securities.map(u => u.counter_party_ips_account).join(', '); 
   // Counter Bank
   const counterBank = securities.map(u => u.counter_bank).join(', ');
   const counterPartyName = securities.map(u => u.counter_party_name).join(', ');



    return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
                <tr>
                    <td>
                        <img src="${DCClogo}" alt="Trustee" height="100" />
                    </td>
                    <td valign="bottom">
                    </td>
                    <td valign="bottom">
                    </td>
                    <td align="right" valign="bottom">
                    <div style='text-align: right; height:100%; float: right;' id="${q}">
                     
                    </div>
    
                    </td>
                </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br/>
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${transaction.branch},</div>
                <br/>
                    <div> <b> Sub: Sale of ${removeDuplicate(securityType)} with ${counterPartyName}.</b></div> 
                <br/>
            <table style="width: 100%;" > 
                 <tr>
                    <td> Settlement Date</td> 
                    <td ><b> ${moment(transaction.settlement_date).format('DD-MM-YYYY')}  </b></td> 
                </tr>
                 <tr>
                    <td>Fund   Account Title</td> 
                    <td ><b>${transaction.account_title}   </b></td> 
                </tr>
                 <tr>
                    <td> Fund   Account No.</td> 
                    <td colspan="3" ><b>${transaction.account_number || ''} (${transaction.bank}, ${transaction.branch}) </b></td> 
                </tr>
                 <tr>
                    <td> Broker</td> 
                    <td ><b>${transaction.broker} </b></td> 
                </tr>
                 <tr>
                    <td> Mode of Payment </td> 
                    <td ><b>${transaction.mode_of_payment} </b></td> 
                </tr>
                 <tr>
                    <td style="width: 170px;"> Settlement Amount</td> 
                    <td ><b>PKR: ${transNetAmount} (Rupees: ${amtWords}) </b></td> 
                </tr> 

                ${myFunc()}
                
                <tr><td> <b> Counter Party Detail:</b></td> <td colspan="3"><hr /></td></tr>

                <tr>
                    <td> Counter Party Name</td> 
                    <td><b>${counterPartyName}  </b></td> 
                </tr>
                <tr>
                    <td> Bank Account Detail </td> 
                     <td><b>${transaction.counter_bank} </b></td> 
                </tr> 
                <tr>
                    <td> SGL / IPS Account Detail </td> 
                    <td><b> ${counterIPSAcc} </b></td> 
                </tr> 
            </table>
            <br />
            <div> 
                Kindly receive the above mentioned settlement amount on value dated today through  ${transaction.mode_of_payment}  transfer from
                Counter party for further credit to our above mention account maintained with your branch and simultaneously
                transfer the above mentioned instrument from our IPS account maintained with you to counter party.
             </div>

            <br/>
            <div>Kindly note that transaction will be made on DVP basis</div> 
            <br/>
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `
}
