import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import MultiSelect from 'react-multi-select-component';
import 'react-toastify/dist/ReactToastify.css';
import {
  getReport,
  fetchSearchData,
} from './../../stores/services/report.service';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { BsTable } from 'react-icons/bs';
import { getFunds } from './../../stores/services/funds.service';
import { getDateType, checkFieldDisplay } from '../transactions/reportFields';
import { getAllBanks } from './../../stores/services/bank.service';
import { getBranchByBankName } from './../../stores/services/branch.service';
import { txnDropDown, getReuiredFieldForDropDown } from './fields';
import html2pdf from 'html2pdf.js';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import DCClogo from '../../assets/img/DCC-Logo.png';

// interface For report Object which we need to send in Api
interface FieldValues {
  fund_code: string;
  account_no: string;
  folio_no: string;
  from_date: string;
  to_date: string;
  txn_id: string;
  date_type: string;
  txn_type: string;
  bank_code: string;
  branch_code: string;
  issue_date: string;
  maturity_date: string;
  settlement_date: string;
  security_type: string;
  to_bank_code: string;
  payment_type: string;
  status: string;
}

const ReportScreen = (props: any) => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  const [headLabel, setHeadLabel] = useState('');
  const [counterType, setCounterType] = useState('');
  const [data, setData] = useState<any>([]);
  const [temp, setTemp] = useState('');
  let date = new Date();
  let getUrlPath = (window.location.pathname.split('/').pop() || '').toString();

  const [txn_id, setTxnId] = useState('');
  const [fund_code, setFundCode] = useState('');
  const [account_no, setAccount_No] = useState('');
  const [date_type, setDateType] = useState('');
  const [folio_no, setFolioNo] = useState('');
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [txn_type, setTxnType] = useState('');
  const [status, setStatus] = useState('');

  const [reportTxn, setReportTxn] = useState<any>([]);
  let [fields, setFields] = useState<FieldValues>({
    fund_code: '',
    txn_id: '',
    account_no: '',
    date_type: '',
    folio_no: '',
    from_date: moment(date).format('YYYY-MM-DD'),
    to_date: moment(date).format('YYYY-MM-DD'),
    txn_type: '',
    bank_code: '',
    branch_code: '',
    // issue_date: moment(date).format('YYYY-MM-DD'),
    // maturity_date: moment(date).format('YYYY-MM-DD'),
    // settlement_date: moment(date).format('YYYY-MM-DD'),
    issue_date: '',
    maturity_date: '',
    settlement_date: '',
    security_type: '',
    to_bank_code: '',
    payment_type: '',
    status: '',
  });
  const dateType = getDateType(headLabel, fields['txn_type']); // Fetch Report required fields for Validation
  const allFields = checkFieldDisplay(
    headLabel,
    fields['txn_type'],
    'fund_code'
  ); // Fetch Report required fields for Validation

  const formatData = (val) => {
    let words = val.split('_');
    return `${words[0]} ${words[1] ? words[1] : ''}`;
  };

  const setDefaultValue = (type) => { 
    // eslint-disable-next-line 
    (fields['fund_code'] = ''),
      (fields['txn_id'] = ''),
      (fields['account_no'] = ''),
      (fields['date_type'] = ''),
      (fields['folio_no'] = ''),
      // (fields['from_date'] = moment(date).format('DD-MM-YYYY')),
      // (fields['to_date'] = moment(date).format('DD-MM-YYYY')),
      (fields['from_date'] = ''),
      (fields['to_date'] = ''),
      (fields['txn_type'] = type),
      (fields['bank_code'] = ''),
      (fields['status'] = ''),
      (fields['branch_code'] = ''),
      // (fields['issue_date'] = moment(date).format('DD-MM-YYYY')),
      // (fields['maturity_date'] = moment(date).format('DD-MM-YYYY')),
      // (fields['settlement_date'] = moment(date).format('DD-MM-YYYY')),
      (fields['issue_date'] = ''),
      (fields['maturity_date'] = ''),
      (fields['settlement_date'] = ''),
      (fields['security_type'] = ''),
      (fields['to_bank_code'] = ''),
      (fields['payment_type'] = ''),
      setFields(fields);
    setChangesCheck(type);
  };

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allDropdownColumns, setAllDropdownColumns] = useState<any>();

  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [branchLoading, setBranchLoading] = useState<boolean>(false);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);

  React.useEffect(() => {
    // setHeadLabel(props.match.params.type.toLowerCase());
    setHeadLabel(getUrlPath.split("-")[0].toLowerCase());
    const UploadTxn = async () => {
      try {
        //const response = await getReport(email, props.match.params.type);
        const response = await getReport(email, getUrlPath.split("-")[0].charAt(0).toUpperCase() + getUrlPath.split("-")[0].slice(1));
        const arrayOfObj = Object.entries(response.data).map((e) => ({
          [e[0]]: e[1],
        }));
        setData(arrayOfObj);
      } catch (err:any) {
        // toast.error(error.response.data.message);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      try {
        const response = await getFunds(email);
        setAllFunds(response.data.data);
        if (response.data.data.length > 0) {
          setFundLoading(false);
        } else {
          setFundLoading(true);
        }
      } catch (error) {}
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
      } catch (error) {}
    };
    UploadTxn();
  }, [getUrlPath]);
  // }, [props.match.params.type]);

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  const getBranchByName = async (name: string) => {
    setBranchLoading(true);
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
    setBranchLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.symbol_code}-{item.fund_name}
        </option>
      );
    });
  };

  //render dropdown for datetype data
  const renderDateTypeDropdown = () => {
    return dateType.map((item: any, index: string) => {
      return (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      );
    });
  };

  const [dropdownList, setDropdownList] = useState([]);

  const calCulateDays = (d1) => {
    var date1 = new Date(d1);
    var date2 = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)).toFixed(0);
    return Difference_In_Days;
  };


  const renderDropdownValues = (selectedCol, transaction) => {
    let res = transaction[selectedCol.value];
    if (
      selectedCol.label.includes('date') ||
      selectedCol.label.includes('Date')
    ) {
      res = moment(transaction[selectedCol.value]).format('DD-MMM-YYYY');
    }
    if (selectedCol.value === 'txnr_type') {
      res = CapTxnType(transaction[selectedCol.value]);
    }
    if(selectedCol.value === 'gross_amount' || selectedCol.value === 'net_amount' || selectedCol.value === 'sale_load' || selectedCol.value === 'units' 
    || selectedCol.value==='current_holding' || selectedCol.value==='total_holding' || selectedCol.value==='remain_holding' || selectedCol.value === 'balance' || selectedCol.value==='face_value' ||
     selectedCol.value==='applicable_offer_price' || selectedCol.value==='offer_price' || selectedCol.value === 'redemption_load' || selectedCol === 'tax_cgt' ){
      res = numberWithCommas(transaction[selectedCol.value] || '0')
    }
    if (selectedCol.value === 'day_to_maturity') {
      res = calCulateDays(transaction['maturity_date']);
    }
    return res;
  };

  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code}>
          {item.bank_code}-{item.bank_name}
        </option>
      );
    });
  };

  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };

  //render dropdown for broker data
  const RenderData = () => {
    return data.map((items: any, index: string) => (
      <div
        key={index}
        style={{ background: 'gray', color: 'black', cursor: 'pointer' }}
        className="border rounded p-4 m-3"
        onClick={() => {
          setFields({
            ...fields,
            txn_type: items[index].name,
          });
          setTemp(CapTxnType(items[index].name));
        }}
      >
        <h3>
          {items[index].name !== 'Payment' && items[index].name !== 'Receipt'
            ? CapTxnType(items[index].name)
            : items[index].name}
        </h3>
      </div>
    ));
  };

  const renderReportTxnDropdown = () => {
    return data.map((item: any, index: string) => {
      return (
        <option key={index} value={item[index].type}>
          {item[index].name === 'Payment' && item[index].name === 'Receipt'
            ? CapTxnType(item[index].name)
            : item[index].name}
        </option>
      );
    });
  };

  const [changesCheck, setChangesCheck] = useState('');
  //code for search data for Reports
  const searchData = async () => {
    if (fields.txn_type === 'Payment' || fields.txn_type === 'Receipt') {
      fields['payment_type'] = fields.txn_type;
    }
    try {
      const searchResponse = await fetchSearchData(email, fields);
      setReportTxn(searchResponse.data.data);
      let temp = Object.keys(searchResponse.data.data[0]).map((val) => {
        return { label: formatData(val), value: val };
      });
      // setAllDropdownColumns(temp);

          setTxnId(fields['txn_id']);
          setFundCode(fields['fund_code']);
          setAccount_No(fields['account_no']);
          setDateType(fields['date_type']);
          setFolioNo(fields['folio_no']);
          setFromDate(fields['from_date']);
          setToDate(fields['to_date']);
          setTxnType(fields['txn_type']);
          setStatus(fields['status']);
    } catch (error) {
      setReportTxn([]);
    }
  };

  //Print Report
  const printReport = () => {
    var node = document.getElementById('printDiv') as HTMLElement;
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
        const imgProps = doc.getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width; // set its Height
        doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight); // add image to Pdf
        doc.save('Report.pdf'); // Save the cheque
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  return (
    <>
    
<div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> <span className="text-primary">  {headLabel.toUpperCase()}</span> Report</h4>
            </div> 
            <div className="card-body">
            {/* Here to show Api response About Report Catagory start */}
            {/* <RenderData /> */}

            <div className="form-group ">
              <label>Transaction Type</label>
              <select
                className="form-control"
                value={fields['txn_type']}
                onChange={(e) => {
                  setDefaultValue(e.target.value);
                  reportTxn.length = 0;
                  setReportTxn(reportTxn);
                  let fieldData = getReuiredFieldForDropDown(e.target.value);
                  setDropdownList(fieldData);
                  setAllDropdownColumns(fieldData);
                  if (
                    e.target.value !== 'Payment' &&
                    e.target.value !== 'Receipt'
                  ) {
                    setTemp(CapTxnType(e.target.value));
                    setFields({
                      ...fields,
                      txn_type: e.target.value,
                      payment_type: '',
                    });
                  } else {
                    setTemp(e.target.value);
                    setFields({
                      ...fields,
                      txn_type: e.target.value,
                    });
                  }
                }}
              >
                <option value="" defaultChecked hidden>
                  Select Type
                </option>
                {/* <option value="saleofunit">Sale Of Unit</option>
                <option value="conversionin">Conversion In</option>
                <option value="conversionout">Conversion Out</option> */}
                {renderReportTxnDropdown()}
              </select>
            </div>
            {/* End */}
       
          {/* Response On Select Report Catagory For Search start */}
          {temp && (
            <div className=""> 
              <Row>
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'fund_code'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Fund Name</label>
                      <select
                        className="form-control"
                        value={fields['fund_code']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            fund_code: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Fund
                        </option>
                        {renderFundsDropdown()}
                      </select>
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(headLabel, fields['txn_type'], 'status') && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Transaction Status</label>
                      <select
                        className="form-control"
                        value={fields['status']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            status: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>Select type</option>
                        <option value="COMPLIANCE">Compliance</option>
                        <option value="INREVIEW">In Review</option>
                        <option value="SIGNATORY-A">Signatory A</option>
                        <option value="SIGNATORY-B">Signatory B</option>
                        <option value="COMPLETED">Completed</option>
                        <option value="REJECTED">Rejected</option>
                      </select>
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(headLabel, fields['txn_type'], 'txn_id') && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Trans ID</label>
                      <input
                        type="text"
                        className="form-control"
                        value={fields['txn_id']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            txn_id: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'bank_code'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>
                        {fields['txn_type'] === 'fundtransfer'
                          ? 'From Bank'
                          : 'Bank'}
                      </label>
                      <select
                        className="form-control"
                        value={fields['bank_code']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            bank_code: e.target.value,
                          });
                          getBranchByName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Bank
                        </option>
                        {renderBankNameDropdown()}
                      </select>
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'to_bank_code'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>To Bank</label>
                      <select
                        className="form-control"
                        value={fields['to_bank_code']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            to_bank_code: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Bank
                        </option>
                        {renderBankNameDropdown()}
                      </select>
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'branch_code'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Branch</label>
                      {branchLoading ? ( 
                         <div className="form-group">
                          <div className="form-control" > Branch Loading  
                              <i className="fa fa-spinner fa-spin fa-1x ml-2" ></i> 
                          </div> 
                       </div>

                      ) : (
                        <select
                          className="form-control"
                          value={fields['branch_code']}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              branch_code: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {branchCheckFlag
                              ? 'No Branch Found'
                              : 'Select Branch'}
                          </option>
                          {renderBranchsDropdown()}
                        </select>
                      )}
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'account_no'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Fund Bank A/c No</label>
                      <input
                        type="text"
                        className="form-control"
                        value={fields['account_no']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            account_no: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'date_type'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Date Type</label>
                      <select
                        className="form-control"
                        value={fields['date_type']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            date_type: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Type
                        </option>
                        {renderDateTypeDropdown()}
                      </select>
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'folio_no'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Unit Holder Folio</label>
                      <input
                        type="text"
                        className="form-control"
                        value={fields['folio_no']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            folio_no: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'issue_date'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Issue Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={fields['issue_date']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            issue_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'maturity_date'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Maturity Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={fields['maturity_date']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            maturity_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                )}
                {checkFieldDisplay(
                  headLabel,
                  fields['txn_type'],
                  'settlement_date'
                ) && (
                  <Col md="4">
                    <div className="form-group  ">
                      <label>Settlement Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={fields['settlement_date']}
                        onChange={(e) => {
                          setFields({
                            ...fields,
                            settlement_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              {checkFieldDisplay(headLabel, fields['txn_type'], 'from_date') &&
                checkFieldDisplay(headLabel, fields['txn_type'], 'to_date') && (
                  <div className="row">
                      <Col md="4"> 
                          <label>From</label> 
                          <div className="form-group">
                            <input
                              type="date"
                              value={fields['from_date']}
                              className="form-control"
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  from_date: e.target.value,
                                });
                              }}
                            />  
                      </div>  
                      </Col>
                      <Col md="4"> 
                          <label>To</label> 
                            <div className="form-group">
                              <input
                                type="date"
                                value={fields['to_date']}
                                className="form-control"
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    to_date: e.target.value,
                                  });
                                }}
                              />  
                        </div> 
                    </Col>
                    </div>
                )}


              <div className="mt-3">
                {/* here Search Button */}
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    searchData();
                  }}
                  disabled={Boolean(loading)}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Search</span>
                  )}
                </button>
              </div>
              {/* End */}
            {/* </div> */}
            </div>
  
          )}
           </div>
           </div>

           {reportTxn.length > 0 && (
           <Row>
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className="mt-3 mr-3 mb-4">
                        {reportTxn.length > 0 ? (
                          <MultiSelect
                            options={allDropdownColumns}
                            // selectAllLabel={allDropdownColumns}
                            value={dropdownList}
                            onChange={setDropdownList}
                            labelledBy={'Select Columns'}
                            className="multi-select"
                            overrideStrings={{
                              selectAll: 'Select all Columns',
                              selectSomeItems: 'Select Columns',
                            }}
                            // hasSelectAll={true}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Col>
                  </Row>
           )}

          {/* here Search results for report Data */}
          <div className="card">  
            <div className="card-body">
            <div
              id="printDiv"
              className="p-2 "
              style={{ background: 'white', color: 'black' }}
            >
              <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Report-"+date+".pdf"} ref={pdfExportComponent}>
              {reportTxn.length > 0 ? (
                <>
                <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">
                      {headLabel.toUpperCase()} Wise {temp} Report
                    </h3>
                  </div>

                  <Row>
                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span style={{ fontWeight: 'bold' }}>
                            Report Date:
                          </span>
                        </Col>
                        <Col md="6">
                          <span>{moment(date).format('DD-MM-YYYY')}</span>
                        </Col>
                      </Row>
                    </Col>
                    {fund_code !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              Fund Name:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{fund_code}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {folio_no!== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              Unit Holder Folio:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{folio_no}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {from_date !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              From Date:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{moment(from_date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {to_date !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>To Date:</span>
                          </Col>
                          <Col md="6">
                            <span>{moment(to_date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {txn_id !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              DCC Trans ID:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{txn_id}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {fields['account_no'] !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              Fund Bank A/c No:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{account_no}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {date_type !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              Date Type:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{date_type}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {txn_type !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              Transaction Type:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{CapTxnType(txn_type)}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {status !== '' && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span style={{ fontWeight: 'bold' }}>
                              Status:
                            </span>
                          </Col>
                          <Col md="6">
                            <span>{CapTxnType(status)}</span>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <div
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                          {dropdownList.map((item:any, index:number) => {
                            return (
                              <th key={index}>
                                <span
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {item.label.toString()}
                                </span>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody className="report-table-body" style={{ color: 'black' }}>
                        {reportTxn.map((transaction: any, index: number) => {
                          return (
                            <tr key={index}>
                              {dropdownList.map((item, i) => {
                                return (
                                  <td key={i}>
                                    {renderDropdownValues(item, transaction)}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
               </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
              Print
            </button>
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportScreen;
