declare global {
    interface Window {
      env: any
    }
  }
  
  // change with your own variables
  type EnvType = {
      // for PTCL
    REACT_APP_TITLE: "MACS Core Alpha",
    REACT_APP_VERSION: "v2.2.5",
    REACT_APP_BASE_URL: "http://182.191.93.242:3000/api",
    REACT_APP_SOCKET_URL: "http://182.191.93.242:3000/",
    REACT_APP_IMAGE_URL: "http://182.191.93.242:3000/",

    // For NayaTel
    // REACT_APP_TITLE: "MACS Core Alpha UAT QA",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "http://124.109.39.158:3000/api",
    // REACT_APP_SOCKET_URL: "http://124.109.39.158:3000/",
    // REACT_APP_IMAGE_URL: "http://124.109.39.158:3000/",

    // VPS-1 - domain name
    // REACT_APP_TITLE: "MACS Core Alpha UAT QA",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "http://digitalcustodian.co:3000/api",
    // REACT_APP_SOCKET_URL: "http://digitalcustodian.co:3000/",
    // REACT_APP_IMAGE_URL: "http://digitalcustodian.co:3000/",

    // for Production
    // REACT_APP_TITLE: "MACS Core Beta",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "https://prod.macs-core.com:3000/api",
    // REACT_APP_SOCKET_URL: "https://prod.macs-core.com:3000/",
    // REACT_APP_IMAGE_URL: "https://prod.macs-core.com:3000/",
  }
  export const env: EnvType = { ...process.env, ...window.env }