
import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';

export const transferInFundRTGSEmailTemplate = (transaction: any, transType:any, amtWords: any, transNetAmount: any, transGrossAmount: any, grossAmtWords:any) => {


    let date = new Date();
    let dateFormat = moment(date).format('DD-MM-YYYY');
    let q="qrcodeinflow";
    let id="www.digitalcustodian.co/v/"+transaction.txn_id;  
    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 80,
             height : 80
         });
           qrcode.makeCode(id);
     }
    
    } , 500);

    let amount = transType === 'saleofunit' ? transGrossAmount : transNetAmount; 
    let amountInWords = transType === 'saleofunit' ? grossAmtWords : amtWords;
 
    return `<html>
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
                <tr>
                    <td>
                        <img src="${DCClogo}" alt="Trustee" height="100" />
                    </td>
                    <td valign="bottom">
                    </td>
                    <td valign="bottom">
                    </td>
                    <td align="right" valign="bottom">
                    <div style='text-align: right; height:100%; float: right;' id="${q}">

                    </div>
    
                    </td>
                </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br/>
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${transaction.branch},</div>
            <br />
            <div><b>Sub. Receive of funds through RTGS from "${transaction.counter_bank}". </b></div>
            <br/>

            <div> Dear Sir/Madam, </div>

            <br />
            <div> You are requested to kindly credit the Account as below details.</div>       
            <br />
 
            <table style="width: 100%;" > 
                <tr>
                    <td style="width:110px"> Account Title</td> 
                    <td><b>${transaction.account_title} </b></td> 
                </tr>
                <tr>
                    <td> Credit A/C </td>
                    <td><b>${transaction.account_number} (${transaction.bank},${transaction.branch})   </b></td>
                   
                </tr>

                <tr>
                    <td> RTGS Number</td> 
                    <td><b> </b></td> 
                </tr>
                <tr>
                    <td> Value Date</td> 
                    <td><b>${moment(transaction.execution_date).format('DD-MM-YYYY')} </b></td> 

                </tr>
                <tr>
                    <td> Amount</td> 
                    <td><b>PKR: ${amount} (Rupees: ${amountInWords})   </b></td> 
                </tr>
    
            </table>
            <br /> 
            <div>Please acknowledge receipt of the said letter.</div>
            <br />
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <table style="width: 100%;">
                <tr>
                    <td></td>
                    <td >
                        <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                    </td>
                    <td style="width: 40%;"> </td>
                    <td>
                        <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                    </td>
                    <td></td>
                </tr>
            </table>
    </body>
    </html>
    `
}
