import React, { useState } from 'react';	
import { Container } from 'reactstrap';	
import { useHistory } from 'react-router-dom';	
import moment from 'moment';	
import { getAllPendingTransactions } from '../../stores/services/transactions.service';	
import ReactTooltip from 'react-tooltip';	
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { BsTable } from 'react-icons/bs';	
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';	
import { Modal } from 'react-bootstrap';	
import { getChecklist } from '../../stores/services/checklist.service';	
import CheckListItem from '../../components/checklist-item';	
import { updateBulkTransactionStatus } from '../../stores/services/transactions.service';	
  
const ComplianceSignatoryTransactions = (props: any) => {	

  const history = useHistory();	
  let [searchValue, setSearchValue] = useState('');	
  let [transactions, setTransactions] = useState([]);	
  let [headLabel, setHeadLabel] = useState('');	
  let [activePage, setActivePage] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  let getUrlPath = (window.location.pathname.split('/').pop() || '').toString();
  React.useEffect(() => {	
    getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',	
      getUrlPath.toUpperCase() === 'COMPLIANCE'	
        ? 'COMPLIANCE'	
        : getUrlPath.toUpperCase() === 'SIGNATORY-A'	
        ? 'INREVIEW,SIGNATORY-A'	
        : 'INREVIEW,SIGNATORY-B',	
      searchValue,	
      '',	
      activePage	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);	
      })	
      .catch((err) => {	
       if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error("Request Failed!")
      }
      });	
  }, [activePage]);	
  React.useEffect(() => {	
    setHeadLabel(getUrlPath);	
    setSearchValue('');	
    getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',	
      getUrlPath.toUpperCase() === 'COMPLIANCE'	
        ? 'COMPLIANCE'	
        : getUrlPath.toUpperCase() === 'SIGNATORY-A'	
        ? 'INREVIEW,SIGNATORY-A'	
        : 'INREVIEW,SIGNATORY-B',	
      '',	
      '',	
      activePage	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);	
      })	
      .catch((err) => {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }
 
      });	
  }, [getUrlPath]);	
  const CapTxnType = (type: any) => {	
    let i, txType;	
    if (txnTypes) {	
      for (i = 0; i < txnTypes.length; i++) {	
        if (txnTypes[i].api_name === type) {	
          txType = txnTypes[i].dis_name;	
          break;	
        }	
      }	
    }	
    return txType;	
  };	
  const searchFilters = (searchVar = '') => {	
    return new Promise((resolve, reject) => {	
      getAllPendingTransactions(	
        sessionStorage.getItem('email') || '',	
        getUrlPath.toUpperCase() === 'COMPLIANCE'	
          ? 'COMPLIANCE'	
          : getUrlPath.toUpperCase() === 'SIGNATORY-A'	
          ? 'INREVIEW,SIGNATORY-A'	
          : 'INREVIEW,SIGNATORY-B',	
        searchVar,	
        '',	
        activePage	
      )	
        .then((response) => {	
          let pageArr = [''];	
          if (response.data.page_info) {	
            const existingPageIndex = paginationArr.findIndex(	
              (x) => x === response.data.page_info.next_page_number	
            );	
            if (	
              existingPageIndex === -1 &&	
              response.data.data.length === 10 &&	
              10 * pageArr.length !==	
                parseInt(response.data.page_info.total_count)	
            ) {	
              pageArr.push(response.data.page_info.next_page_number);	
            }	
          }	
          setPaginationArr(pageArr);
          setTransactions(response.data.data);	
          resolve('Success');	
        })	
        .catch((err) => {	
          setTransactions([]);	
          toast.error(err.response.data.message);	
          if(err.response !== undefined ) {
            toast.error(err.response.data.message);	
          }else {
            toast.error("Request Failed!")
          }
          reject('Error');	
        });	
    });	
  };	
  
  let [bulkProcessingData, setBulkProcessingData] = useState<any>([]);	
  const [Loading, setLoading] = useState(false);	
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);	
  const [checklistData, setCheckListData] = useState<any>([]);	
  const role_name = sessionStorage.getItem('role') || '';	
  const email = sessionStorage.getItem('email') || '';	
  const [reasonText, setReasonText] = useState('');	
  const [reasonTextError, setReasonTextError] = useState('');	
  const [rejectLoading, setRejectLoading] = useState(false);	
  const [status, setStatus] = useState('');	
  const [statusError, setStatusError] = useState('');	
  const [ changeCheck , setChangeCheck ] = useState('');
  let [count, setCount] = useState(0);	
  
  const addTxnForBulk = (txnObj) => {
    count = count + 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id === txnObj.txn_id;
    });
   
    if (check.length === 0) {
      let temp = {
        id: txnObj.txn_id || '',
        status: txnObj.txn_status || '',
        counter:txnObj.counter || '0',
        made_by: txnObj.made_by || ''
        // txn_history: txnObj.txn_history|| ''
      };
      bulkProcessingData.push(temp);
      setBulkProcessingData(bulkProcessingData);
      setChangeCheck(`${txnObj.txn_id}-${count}`);
    }
    setCount(count);	
  };	
  
  const removeTxnFromBulk = (txnObj) => {	
    count = count - 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id !== txnObj.txn_id;
    });
    bulkProcessingData = check;
    setBulkProcessingData(bulkProcessingData);
    setCount(count);
    setChangeCheck(`${txnObj.txn_id}-${count}`);	
  };	
  React.useEffect(() => {	
    const getAllChecklists = async () => {	
      try {	
        const response = await getChecklist(email, role_name);	
        setCheckListData(response.data.data);	
      } catch (err) {	
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }

      }	
    };	
    getAllChecklists();	
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, []);	
  
  const updateFormData = (obj, id) => {	
    let temp = checklistData;	
    temp[id] = obj;	
    setCheckListData(temp);	
  };	
  
  const checkBoolean = (txnId) => {  		
    let tx = false;	
    bulkProcessingData.map((item, index) => {	
        if (item.id === txnId) {
            tx = true;	
        }	
    })	
    return tx;	
}	
  
  // const updateBulkTxStatus = async () => {	
  //   const isValid = validate();
  //   if (isValid) {
  //     if (status === 'accept') { 
  //       setStatusError('');
  //       setLoading(true);	
  //       let data = JSON.stringify(bulkProcessingData);	
  //       try {	
  //         const response = await updateBulkTransactionStatus(	
  //           email,	
  //           role_name,	
  //           status,	
  //           reasonText,	
  //           checklistData,	
  //           data,
  //           headLabel.toUpperCase()
  //         );		
  //         setCheckListSetPopup(false);	
  //         searchFilters('').then(() => {	
  //           setSearchValue('');	
  //           setActivePage('');	
  //         });	
  //         bulkProcessingData.length = 0 ;	
  //         setBulkProcessingData(bulkProcessingData);	
  //         toast.success(response.data.message)	
  //       } catch (error) {	
  //         setLoading(false);	
  //         toast.error(error.response.data.message || 'Error is Processing');	
  //       }	
  //       setLoading(false);
  //     }else{
  //       setStatusError('');
  //       if (reasonText.trim() === '') {
  //         setReasonTextError('Required');
  //       } else {
  //         setReasonTextError('');
  //         if (obj.made_by === 'AMC') {
  //           acceptRejectTransaction('REJECTED-AMC');
  //         } else acceptRejectTransaction('REJECTED-TRUSTEE');
  //       }
  //     }
    	
  // }
  // };	

  const updateBulkTxStatus = async () => {
    const isValid = validate();	
    if (isValid) {
      if (status === 'accept') {
        setReasonTextError('');
        setLoading(true);
        let data = JSON.stringify(bulkProcessingData);
        try {
          const response = await updateBulkTransactionStatus(
            email,
            role_name,
            status,
            reasonText,
            checklistData,
            data,
             headLabel.toUpperCase()
          );
          setCheckListSetPopup(false);
          searchFilters('').then(() => {
            setSearchValue('');
            setActivePage('');
          });
          bulkProcessingData.length = 0;
          setBulkProcessingData(bulkProcessingData);
          if(response.data.status===409){
            toast.error(response.data.message);
          }else{
          toast.success(response.data.message);
        }
        } catch (err:any) {
          setLoading(false);
          if(err.response !== undefined ) {
            toast.error(err.response.data.message || 'Error is Processing');
          }else {
            toast.error(err.message)
          }
        }
        setCount(0);
        setLoading(false);
      }else{
        // toast.success("reject")
        setStatusError('');	
        if (reasonText.trim() === '') {	
          setReasonTextError('Required');	
        } else {	
          setReasonTextError('');	
          setLoading(true);
        let data = JSON.stringify(bulkProcessingData);
        try {
          const response = await updateBulkTransactionStatus(
            email,
            role_name,
            status,
            reasonText,
            checklistData,
            data,
            headLabel.toUpperCase()
          );
          setCheckListSetPopup(false);
          searchFilters('').then(() => {
            setSearchValue('');
            setActivePage('');
          });
          bulkProcessingData.length = 0;
          setBulkProcessingData(bulkProcessingData);
          if(response.data.status===409){
            toast.error(response.data.message);
          }else{
          toast.success(response.data.message);
        }
        } catch (error) {
          setLoading(false);
          toast.error(error.response.data.message || 'Error is Processing');
        }
        setCount(0);
        setLoading(false);	
        }	
      }
    
}// end of isvalid
  };
  

  const validate = () => {	
    let statusErr = '';	
    status.trim() === '' ? (statusErr = 'Required') : (statusErr = '');	
    if (statusErr) {	
      setStatusError(statusErr);	
      return false;	
    } else {	
      return true;	
    }	
  };

  const renderCheckListPopup = () => {	
    switch (checklistSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
          >	

          <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {	
                  setCheckListSetPopup(false);	
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Approval Check List </h6>
             </div> 


            <div className="modal-body">	
              <div className="table-responsive" >	 
                <div className={'checlist-scroll'}>	
                  {checklistData.map((val: any, ind: any) => {	
                    return (	
                      <CheckListItem	
                        updateFormData={updateFormData}	
                        val={val}	
                        ind={ind}	
                      />	
                    );	
                  })}	
                </div>	
                <div> Transactions Selected: <span className="text-primary">{count}</span>  </div>
                <Row className="pb-3">
                <Col md="12">		
                  <div className="d-flex">	 
                      <FormGroup check className="mr-4">
                        <Label check>
                          <Input checked={status === 'accept'}	
                            type="checkbox"	
                            value="accept"	
                            onChange={(e) => {	
                              setReasonText('');	
                              setStatusError('');	
                              setStatus(e.target.value);	
                            }} />
                          <span className="form-check-sign" />
                          Accept
                        </Label>
                      </FormGroup>
 
                    <FormGroup check className="ml-1">
                        <Label check>
                          <Input  type="checkbox"	
                            checked={status === 'reject'}	
                            value="reject"	
                            onChange={(e) => {	
                              setStatusError('');	
                              setStatus(e.target.value);	
                            }} />
                          <span className="form-check-sign" />
                          Reject
                        </Label>
                      </FormGroup>


                  </div>
                  </Col>		
                </Row>	
                <Row>	
                  {statusError ? (	
                    <text className="error-lab">{statusError}</text>	
                  ) : (	
                    ''	
                  )}	
                </Row>	
                {status === 'reject' && (	
                  <Row>	
                    <Col >
                    <div className="form-group">	
                      <textarea	
                        placeholder="Reason"	
                        className={'form-control w-100'}	
                        value={reasonText}	
                        onChange={(e) => {	
                          setReasonText(e.target.value);	
                          setReasonTextError('');	
                        }}	
                      ></textarea>	
                    </div>	
                    {reasonTextError && (	
                      <text className="error-lab">{reasonTextError}</text>	
                    )}	
                    </Col>
                  </Row>	
                )}	
                <Row>	 
                  <Col md="12"> 
                      <button	
                        className="btn btn-primary mr-3"	
                        disabled={Boolean(Loading)}	
                        onClick={() => {	
                          updateBulkTxStatus();	
                        }}	
                      >	
                        {Loading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Submit'	
                        )}	
                      </button>	 
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setCheckListSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    {/* </div> */}
                    </Col>	 
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  return (	
    <>
    <div className="content">
        <Row>
          <Col md="12">
	
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
          <div className="card-header">	
            <h4 className="card-title">{headLabel.toUpperCase()} Transactions</h4>	
          </div>	
          <div className="card-body">	
            <div className=" ">	
            <div className="d-flex">		
                <div className="form-group inline-box">		
                  <ReactTooltip		
                    textColor="white"		
                    backgroundColor="black"		
                    effect="float"		
                  />		
                  <input		
                    type="search"		
                    id="myInput"		
                    placeholder="Transaction Id"		
                    data-tip="Search Transaction"		
                    className="form-control mr-2"		
                    value={searchValue}		
                    onChange={(e) => {		
                      setSearchValue(e.target.value);		
                    }}		
                  />		
                <div		
                  className="btn btn-primary mr-2 ml-2  mt-a"		
                  onClick={() => {		
                    if (searchValue) {	
                      // setChangeCheck(searchValue)
                      // bulkProcessingData.length = 0;
                      setChangeCheck(searchValue);
                      setBulkProcessingData(bulkProcessingData);
                      searchFilters(searchValue).then(() => {		
                        setActivePage('');		
                      });		
                    }		
                  }}		
                >		
                  Search		
                </div>		
                <div		
                  className="btn btn-default mt-a"		
                       
                  onClick={() => {		
                    searchFilters('').then(() => {		
                      setSearchValue('');		
                      setActivePage('');	
                      // count = count + 1;
                      // bulkProcessingData.length = 0;
                      // setBulkProcessingData(bulkProcessingData);
                      // setCount(count);
                      // setChangeCheck(`${count}`);	
                    });		
                  }}		
                >		
                  Clear		
                </div>		
                </div> 	
                    
  
              </div>	
            </div>	
            <div className="">	
              {transactions.length > 0 ? (	
                <table className="table">	
                  <thead>	
                    <tr>	
                      <th>Select</th>	
                      <th>Transaction Id</th>	
                      <th>AMC Code</th>	
                      <th>Transaction Type</th>	
                      <th>Net Amount</th>
                      <th>Created By</th>	
                      <th>Date Created</th>	
                      <th>Status</th>	
                      <th>Actions</th>	
                    </tr>	
                  </thead>	
                  <tbody>	
                    {transactions.map((transaction: any) => {	
                      return (	
                        <tr>	
                          <td>	 
                            <FormGroup check>	
                              <Label check>	
                                <Input type="checkbox"	
                                checked={checkBoolean(transaction.txn_id)}	
                                onChange={(e) => {	
                                  if (e.target.checked === true) {	
                                    addTxnForBulk(transaction);	
                                  } else {	
                                    removeTxnFromBulk(transaction);	
                                  }	
                                }} />	
                                <span className="form-check-sign" />	 
                              </Label>	
                            </FormGroup> 

                            {/* <input
                              type="checkbox"
                              checked={checkBoolean(transaction.txn_id)}
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  addTxnForBulk(transaction);
                                } else {
                                  removeTxnFromBulk(transaction);
                                }
                              }}
                            /> */}

                          </td>	
                          <td>{transaction.txn_id}</td>	
                          <td>	
                            {transaction.amc_code ? transaction.amc_code : ''}	
                          </td>	
                          <td>	
                            {transaction.txnr_type	
                              ? CapTxnType(transaction.txnr_type)	
                              : 'N/A'}	
                          </td>	
                          <td className="text-right" > {numberWithCommas(transaction.net_amount)}</td>
                          <td>	
                            {transaction.created_by	
                              ? transaction.created_by	
                              : 'N/A'}	
                          </td>	
                          <td>	
                            {transaction.txn_history	
                              ? transaction.txn_history[0]?.created_at	
                                ? moment(	
                                    transaction.txn_history[0].created_at	
                                  ).format('DD-MM-YYYY')	
                                : 'N/A'	
                              : 'N/A'}	
                          </td>	
                          <td>{transaction.txn_status}</td>	
                          <td className=" ">	
                            <div className="multi-icons pointer">	 
                                <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {	
                                      sessionStorage.setItem(	
                                        'csab-txn',	
                                        JSON.stringify(transaction)	
                                      );	
                                      // history.replace(`/admin/signatory-a/${headLabel}`);	
                                      history.replace(`/admin/details/${headLabel}`);	
                                    }}	>
                                        <i className="fa fa-eye">  </i>
                                </button>
                            </div>	
                          </td>	
                        </tr>	
                      );	
                    })}	
                  </tbody>	
                </table>	
              ) : (	
                <div className="table-empty">	
                  <BsTable className="icon" />	
                  <p className="text-center empty-text">No record found</p>	
                </div>	
              )}	
              <div	
                className="row pointer mr-3"	
                style={{	
                  float: 'right',	
                }}	
              >	
                {activePage !== paginationArr[0] ? (	
                  <i	
                    className="fa fa-arrow-left"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index - 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
                {/* NEXT BUTTON */}	
                {activePage !== paginationArr[paginationArr.length - 1] ? (	
                  <i	
                    className="fa ml-3 fa-arrow-right"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(pageArr[index + 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
              </div>	
              
              {count===0 ?(
              ''
              ):(
              <div className="mb-2">
                 <span className="text-primary">{count}</span> transactions selected. &nbsp; <a onClick={()=>{
                bulkProcessingData.length = 0;
                setBulkProcessingData(bulkProcessingData);
                setCount(0);
                setChangeCheck(`${count}`);
              }}>Clear Selection </a> </div> 
              )} 

              {bulkProcessingData.length > 0 && (	
                <Row>	
                  <Col md="6">	
                    <div className="d-flex">	
                      <div className="">	
                        <button	
                          className="btn btn-primary"	
                          disabled={Boolean(Loading)}	
                          onClick={() => {	
                            setCheckListSetPopup(true);	
                          }}	
                        >	
                          Action	
                        </button>	
                      </div>	
                    </div>	
                  </Col>	
                </Row>	
              )}	
              {renderCheckListPopup()}	
            </div>	
          </div>	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
  
export default ComplianceSignatoryTransactions;	
