import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ToggleButton from 'react-toggle-button';
import { Dropdown } from 'react-bootstrap';

const ViewAmc = () => {
  const [amcName, setAmcName] = useState('');
  const [amcCode, setAmcCode] = useState('');
  const [focalPerEmail, setFocalPerEmail] = useState('');
  const [focalPerName, setFocalPerName] = useState('');
  const [amcSig, setAmcSig] = useState('');
  const [amcAud, setAmcAud] = useState('');
  const [taxAdv, setTaxAdv] = useState('');
  const [conOffericer, setConOfficer] = useState('');
  const [Suboffericer, setSubOffericer] = useState('');
  const [txCreater, setTxCreater] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [filename1, setFile1Name] = useState('');
  const [filename2, setFile2Name] = useState('');
  const [filename3, setFile3Name] = useState('');
  const [accTitle, setAccTitle] = useState('');
  const [accNo, setAccNo] = useState('');
  const [bankname, setBankName] = useState('');
  const [tax_exemption, setTax_exemption] = useState(false);
  const [revenueAuthority, setRevenueAuthority] = useState('');
  const [branchName, setBranchName] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [emailArray, setEmailArray] = useState([]);
  const [creatorEmailArray, setCreatorEmailArray] = useState([]);
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('amcObj') || '');
    setAmcName(obj.name);
    setAmcCode(obj.amc_code);
    setFocalPerEmail(obj.focal_person_email);
    setFocalPerName(obj.focal_person_name);
    setMobile(obj.focal_person_mobile);
    setPhone(obj.focal_person_phone);
    setAmcSig(obj.amc_signatories);
    setConOfficer(obj.concerned_officer);
    setSubOffericer(obj.subtitute_concerned_officer);
    setTxCreater(obj.txn_creator_field);
    if (obj.txn_creator_field !== '' && obj.txn_creator_field !== '[]') {
      setCreatorEmailArray(JSON.parse(obj.txn_creator_field));
    }
    if (obj.amc_signatories !== '' && obj.amc_signatories !== '[]') {
      setEmailArray(JSON.parse(obj.amc_signatories));
    }
    setFrom(obj.from);
    setTo(obj.to);
    setFile1Name(obj.amc_br);
    setFile2Name(obj.auth_amc_signatories);
    setFile3Name(obj.tax_exemption);
    setAccTitle(obj.account_title);
    setAccNo(obj.account_no);
    setBankName(obj.bank);
    setBranchName(obj.branch);
    setRevenueAuthority(obj.tax_collector);
  }, []);

  const borderRadiusStyle = { borderRadius: 2 };

  return (
    <>
    
<div className="content">
        <Row>
          <Col md="12">
      <Container fluid>

        <div className="input-holder">
               <h4 className="card-title">View - AMC</h4> 
                <Link to="/admin/amc" className="t-3 ml-auto btn btn-primary btn-sm" replace>
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
          </div>

          <Row>
              <Col md="4">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                       AMC Detail
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group  ">
                      <label>Name of AMC</label>
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        value={amcName}
                      />
                    </div>

                    <div className="form-group">
                      <label>AMC Code</label>
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        value={amcCode}
                      />
                  </div>

                  <div className="form-group">
                      <label>Revenue Authority</label>
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        value={revenueAuthority}
                      />
                  </div>

                  {tax_exemption && (
                    <>
                      <h3>Tax Exemption</h3>
                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Exempted From</label>
                            <input
                              readOnly
                              type="date"
                              className="form-control"
                              value={from}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group right">
                            <label>Exempted To</label>
                            <input
                              readOnly
                              type="date"
                              className="form-control"
                              value={to}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Evidence</label>
                            <div className="multi-input">
                              <div className="input-2">
                                {filename1.slice(0, 20)}
                              </div>
                              <div className="icon">
                                <img src="assets/upload.svg" alt="" width="20" />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row> 
                    </>
                  )}

                  </div>
                </div>

                <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                      Focal Person Detail
                  </h4>
                </div>
                <div className="card-body">
                    <div className="form-group  ">
                      <label>AMC Focal Person Email</label>
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        defaultValue={focalPerEmail}
                      />
                  </div>

                  <div className="form-group">
                    <label>AMC Focal Person Name</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={focalPerName}
                    />
                  </div>

                  <div className="form-group  ">
                    <label>Phone</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      defaultValue={phone}
                    />
                  </div>

                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={mobile}
                    />
                  </div>

                </div>
              </div>
              </Col>

              <Col md="4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                  Bank Details
                  </h4>
                </div>
                <div className="card-body">
                  <div className="form-group  ">
                    <label>Account Title</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={accTitle}
                    />
                  </div>

                  <div className="form-group  ">
                    <label>Account No</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={accNo}
                    />
                  </div>

                  <div className="form-group  ">
                    <label>Bank</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={bankname}
                    />
                  </div>

                  <div className="form-group  ">
                    <label>Branch</label>
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={branchName}
                    />
                  </div>

                </div>
              </div>
            </Col>

              

              <Col md="4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                      Authorized AMC Users
                  </h4>
                </div>
                <div className="card-body">

                 <div className="form-group">
                    <label>Transaction Creator Field</label>
                    <div className="multi-input">
                      <div className={`   w-100`}>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="input-sign"
                            variant="success"
                            id="dropdown-basic"
                          >
                            {creatorEmailArray.length} Added
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {creatorEmailArray.map((obj: any, i: any) => (
                              <Dropdown.Item className="drop-item " key={i}>
                                <div className="row justify-content-between" style={{fontSize : '15px'}}>
                                  <div className="mr-auto">{obj.email}</div>
                                </div>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>


                  <div className="form-group  ">
                    <label>AMC Signatories</label> 
                      <div className="multi-input">
                          <div className={`   w-100`}>
                            <Dropdown>
                              <Dropdown.Toggle
                                className="input-sign"
                                variant="success"
                                id="dropdown-basic"
                              >
                                {emailArray.length} Added
                              </Dropdown.Toggle>
                                <Dropdown.Menu>
                                {emailArray.map((obj: any, i: any) => (
                                  <Dropdown.Item className="drop-item " key={i}>
                                    <div className="d-flex justify-content-between" style={{fontSize : '15px'}}>
                                    <div
                                        className="mr-auto"
                                        style={{ fontSize: '15px' }}
                                      >
                                        <span style={{ color: 'green' }}>
                                          Sig{obj.role.slice(-1)}
                                        </span>{' '}
                                        {obj.email}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                      </div> 
                    </div>

                   

                  <div className="form-group  ">
                      <label>Concerned Officer</label>
                      <input
                        readOnly
                        type="text"
                        value={conOffericer}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group  ">
                      <label>Substitute Concerned Officer</label>
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        value={Suboffericer}
                      />
                  </div>


                </div>
              </div>
                
              </Col>
          </Row>
          {/* 2nd row */}
          <Row> 

          <Col md="4">
              
              </Col>
 
            
          </Row>
 
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewAmc;
