// import React, {useRef, useState, useEffect } from 'react';	
// import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';	
// import ReactTooltip from 'react-tooltip';	
// import { Link } from 'react-router-dom';	
// import moment from 'moment';	
// import { Modal } from 'react-bootstrap';	
// import ViewTransaction from './../transactions/view-beta-txn';	
// import { FaRegMoneyBillAlt } from 'react-icons/fa';	
// import {	
//   getMailTemplates,
//   inWords,	
//   getAllLetterTemplates,	
//   SendMailTo,
// } from './../../stores/services/template.service';	
// import { getAllPendingTransactions } from '../../stores/services/transactions.service';	
// import * as htmlToImage from 'html-to-image';	
// import jsPDF  from 'jspdf';	
// import { useHistory } from 'react-router-dom';		
// import { RiMailSendFill } from 'react-icons/ri';
// import { BsFileRichtext } from 'react-icons/bs';	
// import { BsTable } from 'react-icons/bs';	
// import { getAllBanks } from '../../stores/services/bank.service';	
// import { toast, ToastContainer } from 'react-toastify';  

// import { ReceiveOfFundRTGSEmailTemplate } from '../letterTemplates/inflowRTGS.letter.template'; 
// import { OnlineTransferOfFundLetter } from '../letterTemplates/onlineTF.letter.template'; 
// import { TransferOfFundRTGSEmailTemplate } from '../letterTemplates/outflowRTGS.letter.template'; 
// import { MoneyMarketPurchaseLetter } from '../letterTemplates/mmPurcahse.letter.template'; 
// import { mmMaturityOfSecurityLetter } from '../letterTemplates/mmMaturityOfSecurity.letter.template'; 
// import { mmMaturityOfCouponLetter } from '../letterTemplates/mmMaturityOfCoupon.letter.template'; 
// import { MoneyMarketSaleLetter } from '../letterTemplates/mmSale.letter.template'; 
// import { equityInvestmentLetter } from '../letterTemplates/equityInvestment.letter.template'; 
// import createDOMPurify from 'dompurify';
// const DOMPurify = createDOMPurify(window);

// const PendingTransactions = () => {	
//   const history = useHistory();	
//   let [transactions, setTransactions] = useState<any>([]);	
//   let [status, setStatus] = useState('-1');	
//   let [searchValue, setSearchValue] = useState('');	
//   let [activePage, setActivePage] = useState('');	
//   let [paginationArr, setPaginationArr] = useState(['']);	
//   let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
//   const email = sessionStorage.getItem('email') || '';
//   const role = sessionStorage.getItem('role') || '';	
//   const [allLetterTemplates, setAllLetterTemplates] = useState<any>([]);	
//   const [letterLoading, setLetterLoading] = useState(false);	
//   const [tempLoading, setTempLoading] = useState(false);	

//   const [mailModelBoolean, setMailModelBoolean] = useState(false);
//   const [mailTempLoading, setMailTempLoading] = useState(false);
//   const [mailTempData, setMailTempData] = useState<any>([]);

//   const [chooseTemplate, setChooseTemplate] = useState('');
//   const [securityData, setSecurityData] = useState<any>([]); 
//   const [transType, setTransType] = useState<any>([]);
//   const [transMode, setTransMode] = useState<any>([]);
//   const [mmCoupon, setMMCoupon] = useState<any>([]); 
//   const [amtWords, setAmtWords] = useState<any>([]);
//   const [grossAmtWords, setGrossAmtWords] = useState<any>([]);
//   const [transNetAmount, setTransNetAmount] = useState<any>([]);
//   const [transGrossAmount, setTransGrossAmount] = useState<any>([]);
//   const [paymentType, setPaymentType] = useState<any>('');
//   const [mmType, setMMType] = useState<any>([]); 

//   // let date=new Date();

//   // const [selectedDate, setSelectedDate] = useState(moment(date).format('YYYY-MM-DD'));

//   useEffect(() => {
//     const fetchMailTemp = async () => {
//       try {
//         const response = await getMailTemplates();
//         setMailTempData(response.data.template);
//         if (response.data.template.length < 1) {
//           setMailTempLoading(true);
//         } else {
//           setMailTempLoading(false);
//         }
//       } catch (error) {}
//     };
//     fetchMailTemp();
//   }, []);

//   // let txnTypes = JSON.parse(sessionStorage.getItem(s'txnType') || ''); //All transaction Types

//     //adject transaction name format
//     // const CapTxnType = (type: any) => {
//     //   let i, txType;
//     //   for (i = 0; i < txnTypes.length; i++) {
//     //     if (txnTypes[i].api_name === type) {
//     //       txType = txnTypes[i].dis_name;
//     //       break;
//     //     }
//     //   }
//     //   return txType;
//     // };
	
//   const getLetterTemplateCall = async (bankName) => {	
//     try {	
//       const response = await getAllLetterTemplates(email, bankName);	
//       setAllLetterTemplates(response.data.data);	
//       if (response.data.data.length < 1) {	
//         setLetterLoading(true);	
//       } else {	
//         setLetterLoading(false);	
//       }	
//     } catch (error) {}	
//   };		

//     //render dropdown for mail data
//     const renderMailTemplateDropdown = () => {
//       return mailTempData.map((item: any, index: string) => {
//         return (
//           <option key={index} value={item.value}>
//             {item.name}
//           </option>
//         );
//       });
//     };

    
//   //render dropdown for security data	
//   const renderLetterTemplateDropdown = () => {	
//     return allLetterTemplates.map((item: any, index: string) => {	
//       return (	
//         <option key={index} value={item.template_detail}>	
//           {item.template_name}	
//         </option>	
//       );	
//     });	
//   };	
//   React.useEffect(() => {	
//     getAllPendingTransactions(	
//       sessionStorage.getItem('email') || '',	
//       status,	
//       searchValue,	
//       '',	
//       activePage
//     )	
//       .then((response) => {	
//         let pageArr = paginationArr;	
//         let existingPageIndex = paginationArr.findIndex(	
//           (x) => x === response.data.page_info.next_page_number	
//         );	
//         if (	
//           existingPageIndex === -1 &&	
//           response.data.data.length === 10 &&	
//           10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
//         ) {	
//           pageArr.push(response.data.page_info.next_page_number);	
//         }	
//         setPaginationArr(pageArr);	
//         setTransactions(response.data.data);	
//       })	
//       .catch((err) => {	
//        // console.log(err);	
//       });	
//   }, [activePage]);	
//   const [bankData, setBankData] = useState<any>([]);	
//   React.useEffect(() => {	
//     const fetchBank = async () => {	
//       try {	
//         const bankResponse = await getAllBanks(email);	
//         setBankData(bankResponse.data.data);	
//       } catch (error) {	
//         // console.log(error);	
//       }	
//     };	
//     fetchBank();	
//   }, []);	

//   React.useEffect(() => {	
//     getAllPendingTransactions(sessionStorage.getItem('email') || '', '', '')	
//       .then((response) => {	
//         let pageArr = paginationArr;	
//         let existingPageIndex = paginationArr.findIndex(	
//           (x) => x === response.data.page_info.next_page_number	
//         );	
//         if (	
//           existingPageIndex === -1 &&	
//           response.data.data.length === 10 &&	
//           10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
//         ) {	
//           pageArr.push(response.data.page_info.next_page_number);	
//         }	
//         setPaginationArr(pageArr);	
//         setTransactions(response.data.data);	
//       })	
//       .catch((err) => {	
//        // console.log(err);	
//       });	
//   }, []);	
//   const CapTxnType = (type: any) => {	
//     let i, txType;	
//     for (i = 0; i < txnTypes.length; i++) {	
//       if (txnTypes[i].api_name === type) {	
//         txType = txnTypes[i].dis_name;	
//         break;	
//       }	
//     }	
//     return txType;	
//   };	

//   const bankName=(bankcode)=>{
//     const bankdata= bankData.filter((item: any, index: string)=>{
//       return (item.bank_code===bankcode);
//      });
//      if(bankdata.length>0){
//      return bankdata[0].bank_name;
//     }
//      else{
//      return bankcode;
//      }
//   }

//   const searchFilters = (statusVar = '', searchVar = '') => {	
//     return new Promise((resolve, reject) => {	
//       getAllPendingTransactions(	
//         sessionStorage.getItem('email') || '',	
//         statusVar === '-1' ? '' : statusVar,	
//         searchVar,	
//         '',	
//         activePage	
//       )	
//         .then((response) => {	
//           let pageArr = paginationArr;	
//           let existingPageIndex = paginationArr.findIndex(	
//             (x) => x === response.data.page_info.next_page_number	
//           );	
//           if (response.data.data.length !== 10) {	
//             if (	
//               existingPageIndex === -1 &&	
//               response.data.data.length === 10 &&	
//               10 * pageArr.length !==	
//                 parseInt(response.data.page_info.total_count)	
//             ) {	
//               pageArr.push(response.data.page_info.next_page_number);	
//             }	
//           }	
//           setPaginationArr(pageArr);	
//           setTransactions(response.data.data);	
//           resolve('Success');	
//         })	
//         .catch((err) => {	
//         // console.log(err);	
//           reject('Error');	
//         });	
//     });	
//   };	
//   const [setLoading] = useState(false);	
//   const [view, setView] = useState(false);	
//   const [data, setData] = useState<any>([]);	
//   const titleCase = (value: string) => {	
//     let sentence = value.toLowerCase().split('_');	
//     for (let i = 0; i < sentence.length; i++) {	
//       sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
//     }	
//     return sentence.join(' ');	
//   };

//   const [trx_history, setTrxHistory] = useState([]);	
  	
//   const renderPsxData = () => {	
//     return securites.map((items: any, index: number) => {	
//       return (	
//         <tr key={index}>	
//           <td>{items.type}</td>	
//           <td>{items.market}</td>	
//           <td>{items.symbol}</td>	
//           <td>{items.quantity}</td>	
//           <td>{items.avg_rate}</td>	
//           <td>{items.gross_amount}</td>	
//           <td>{items.deductions}</td>	
//           <td>{items.additions}</td>	
//           <td>{items.net_amount}</td>	
//         </tr>	
//       );	
//     });	
//   };	
//   const [flagForSecutities, setFlagForSecutities] = useState(false);	
//   const [securites, setSecurites] = useState<any>([]);	
//   const renderSecuritiesModalPopup = () => {	
//     switch (flagForSecutities) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="lg"	
//             backdrop={true}	
//             onHide={() => { setFlagForSecutities(false) }}	
//           > 	
//             <div className="modal-header ">	
//                         <button	
//                           aria-hidden={true}	
//                           className="close"	
//                           data-dismiss="modal"	
//                           type="button"	
//                           onClick={() => {	
//                             setFlagForSecutities(false);	
//                             // clearTaxobject();	
//                           }}	
//                         >	
//                           <i className="tim-icons icon-simple-remove" />	
//                         </button>	
//                         <h6 className="title title-up">   View Security</h6>	
//                       </div>	
//             <div className="modal-body">	
//               <div className="">	
//                 {securites.length > 0 && (	
//                   <div className="input-size-pos">	
//                     <table className="table">	
//                       <thead>	
//                         <tr>	
//                           <th>Type</th>	
//                           <th>Market</th>	
//                           <th>Symbol</th>	
//                           <th>Quantity</th>	
//                           <th>Avg Rate</th>	
//                           <th>Gross Amount</th>	
//                           <th>Deduction</th>	
//                           <th>Addition</th>	
//                           <th>Net Amount</th>	
//                         </tr>	
//                       </thead>	
//                       <tbody >	
//                         {renderPsxData()}	
//                       </tbody>	
//                     </table>	
//                   </div>)}	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   //render table data for tax	
//   //view tax table	
//   const [viewTaxModal, setTaxModal] = useState(false);	
//   const [totalTax, setTotalTax] = useState<any>([]);	
//   //render Tax table data	
//   const renderTaxData = () => {	
//     return totalTax.map((items: any, index: number) => {	
//       return (	
//         <tr key={index}>	
//           <td>{items.name}</td>	
//           <td>{items.amount}</td>	
//           <td>{items.calculatedAmount}</td>	
//         </tr>	
//       );	
//     });	
//   };	
//   const renderTaxPopup = () => {	
//     switch (viewTaxModal) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="lg"	
//             backdrop={true}	
//             onHide={() => { setTaxModal(false) }}	
//           > 	
            	
//             <div className="modal-header justify-content-center">	
//                         <button	
//                           aria-hidden={true}	
//                           className="close"	
//                           data-dismiss="modal"	
//                           type="button"	
//                           onClick={() => {	
//                             setTaxModal(false);	
//                             // clearTaxobject();	
//                           }}	
//                         >	
//                           <i className="tim-icons icon-simple-remove" />	
//                         </button>	
//                         <h6 className="title title-up"> View Txn Charges  </h6>	
//                       </div>	
//             <div className="modal-body">	
//               <div className="">	
//                 {totalTax.length > 0 && (	
//                   <div className="input-size-pos">	
//                     <table className="table my-table">	
//                       <thead>	
//                         <tr>	
//                           <th>Tax Name</th>	
//                           <th>Tax Amount</th>	
//                           <th>Calculated Amount</th>	
//                         </tr>	
//                       </thead>	
//                       <tbody >	
//                         {renderTaxData()}	
//                       </tbody>	
//                     </table>	
//                   </div>)}	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   const renderData = () => {		
//     return data.map((value: any, index: number) => {	
//       return (	
//         <>	
//           {value[0] !== 'txn_history' &&	
//           value[0] !== 'file' &&	
//           value[1] !== '' &&	
//           value[1] !== 0 ? (	
//             <Col md="6" key={index}>	
//               <div className="input-holder left">	
//                 <p className="label">{titleCase(value[0])}</p>	
//                 {/* <div className="input-1 hide-overflow">	
//                   <p	
//                     style={{	
//                       fontSize: '12px',	
//                     }}	
//                   >	
//                     {typeof value[1] === 'string'	
//                       ? value[0] === 'file'	
//                         ? 'N/A'	
//                         : value[1]	
//                       : value[0] === 'file'	
//                         ? 'N/A'	
//                         : ''}	
//                   </p>	
//                 </div> */}	
//                 {value[0] === 'securities' ? (	
//                   <div	
//                     className="input-1 flex justify-content-center"	
//                     style={{	
//                       background: '#0e4646',	
//                       color: 'white',	
//                       cursor: 'pointer',	
//                     }}	
//                     onClick={() => {	
//                       setFlagForSecutities(true);	
//                       setSecurites(JSON.parse(value[1]));	
//                     }}	
//                   >	
//                     View Securities	
//                   </div>	
//                 ) : value[0] === 'txn_charges' ? (	
//                   <div	
//                     className="input-1 flex justify-content-center"	
//                     style={{	
//                       background: '#0e4646',	
//                       color: 'white',	
//                       cursor: 'pointer',	
//                     }}	
//                     onClick={() => {	
//                       setTaxModal(true);	
//                       setTotalTax(JSON.parse(value[1]));	
//                     }}	
//                   >	
//                     View Txn Charges	
//                   </div>	
//                 ) : (	
//                   // <AiFillEye className="m-3"/>	
//                   <div className="input-1">	
//                     <p	
//                       style={{	
//                         fontSize: '15px',	
//                       }}	
//                     >	
//                       {value[1] &&	
//                       value[1].toString().includes('Pakistan Standard')	
//                         ? moment(value[1].toString()).format(	
//                             'DD-MM-YYYY h:mm a'	
//                           )	
//                         : value[1] && value[1].toString()}	
//                     </p>	
//                   </div>	
//                 )}	
//               </div>	
//             </Col>	
//           ) : (	
//             ''	
//           )}	
//         </>	
//       );	
//     });	
//   };	
	
//    // render Model for adding Fund	
//   const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false); 		
	

//   const [note, setNote] = useState('');
//   const [viewChecklist, setViewCheckList] = useState(false);	
//   const [trx_checklist, setTrxCheckList] = useState([]);	
//   const [templateDD, setTemplateDD ] = useState();	
//   const viewCheckListPopup = () => {	
//     switch (viewChecklist) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="xl"	
//           >	
            	
//           <div className="modal-header ">	
//                 <button	
//                   aria-hidden={true}	
//                   className="close"	
//                   data-dismiss="modal"	
//                   type="button"	
//                   onClick={() => {	
//                     setViewCheckList(false);	
//                   }}	
//                 >	
//                   <i className="tim-icons icon-simple-remove" />	
//                 </button>	
//                 <h6 className="title title-up">Approval Check List </h6>	
//               </div>	
//             <div className="modal-body">	
//               <div className=""> 	
//                 <div className={'checlist-scroll'}>	
//                   {trx_checklist.map((val: any, ind: any) => {	
//                     return (	
//                       <Row key={ind} className="pt-3">		
                      		
//                       <Col md="5">		
//                       <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>		
//                       </Col>		
//                       <Col md="7">		
//                         <Row>		
//                           <Col md="3">		
                         		
//                           <FormGroup check>		
//                             <Label check>		
//                               <Input 		
//                               checked={val.value == 'compliant'}		
//                               type="checkbox"		
//                               value="compliant"		
//                               readOnly />		
//                               <span className="form-check-sign" />		
//                               Compliant		
//                             </Label>		
//                           </FormGroup>		
//                           </Col>		
//                           <Col md="2"> 		
//                           <FormGroup check>		
//                             <Label check>		
//                               <Input type="checkbox" 		
//                               checked={val.value == 'na'}		
//                               value="na"		
//                               readOnly />		
//                               <span className="form-check-sign" />		
//                               N /A		
//                             </Label>		
//                           </FormGroup>		
//                           </Col>		
//                           <Col md="7">		
//                             <FormGroup>		
//                               <input		
//                                 type="text"		
//                                 placeholder="Comments"		
//                                 value={val.comment}		
//                                 className="form-control"		
//                                 readOnly		
//                               />		
//                               </FormGroup>		
                            		
//                           </Col>		
//                         </Row>	 	
//                       </Col>		
//                     </Row>	
//                     );	
//                   })}	
//                 </div>	
//                 {note !== '' && (	
//                   <Row>	
//                     <Col md="12">
                   
//                     <div className="form-group">	
//                     <label>Reason of Reject</label>	
//                       <textarea	
//                         placeholder="Reason"	
//                         className={'form-control w-100'}	
//                         value={note}	
//                         readOnly	
//                       ></textarea>	
//                     </div>	
//                     </Col>
//                   </Row>	
//                 )}	
//                 <Row>	
//                   <Col>	
//                     <div className=" ">	
//                       <button	
//                         className="btn btn-default"	
//                         onClick={() => {	
//                           setViewCheckList(false);	
//                         }}	
//                       >	
//                         Cancel	
//                       </button>	
//                     </div>	
//                   </Col>	
//                 </Row>	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };

//   let letterState = [];

 
//   const [displayLetterCheck, setDisplayLetterCheck] = useState(false);	  
//   const renderModalForLetterPopup = () => {	
//     switch (displayLetterCheck) {	
//       case true:	
//         return (	
//           <Modal	
//           className=""	
//           dialogClassName="modal90w"	
//           show={true}	
//           size="lg"	
//           backdrop={true}	
//           onHide={() => { setDisplayLetterCheck(false) }}	
//         > 	
//           <div className="modal-header ">	
//             <button	
//               aria-hidden={true}	
//               className="close"	
//               data-dismiss="modal"	
//               type="button"	
//               onClick={() => {	
//                 setDisplayLetterCheck(false);
//               }}	
//             >	
//               <i className="tim-icons icon-simple-remove" />	
//             </button>	
//             <h6 className="title title-up">  Select Template</h6>	
//           </div>	
//           <div className="modal-body">
//             <div className="row">	
//               <div className="col-md-12">	
//               <div className="form-group">	
//                 <label>Select</label>	
//                 <ReactTooltip textColor='white' backgroundColor='Black' effect="float" />	
//                 <select className="form-control" 
//                   onChange={(e) => {	
//                      // eslint-disable-next-line  
//                     e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
//                     setChooseTemplate(e.target.value); 
//                     setLetterTempates(true);
//                     setResetPaymentType(true);
//                   }}
//                   >	
//                   <option value="" defaultChecked hidden>{tempLoading ? 'No Template Found' : 'Select Letter Template'}</option>	

//                   {/* {transType !== 'debtmarketinvestment' && transMode === 'RTGS' && paymentType === 'Receipt'   && (<option value="ReceivedRTGS">RTGS Inflow</option>) }
//                   {transType !== 'debtmarketinvestment' && transMode === 'RTGS' && paymentType === 'Payment' && (<option value="TransferRTGS"> RTGS Outflow</option>) } */}
//                       {transType !== 'debtmarketinvestment' && transMode === 'RTGS' &&  (paymentType === 'Receipt' || paymentType === 'Payment or Receipt' ) &&   (<option value="ReceivedRTGS">RTGS Inflow</option>) }
//                   {transType !== 'debtmarketinvestment' && transMode === 'RTGS' && (paymentType === 'Payment' || paymentType === 'Payment or Receipt' ) && (<option value="TransferRTGS"> RTGS Outflow</option>) }
                  
//                   {transType !== 'debtmarketinvestment' && transMode === 'Online' && (paymentType === 'Payment' || paymentType === 'Payment or Receipt' ) &&( <option value="OnlineTransferFund"> Online Transfer of Fund</option>) }
                  
//                   {transType === 'debtmarketinvestment' && mmType === 'Buy' && (  <option value="MMPurchase"> Money Market Purchase</option>) }
//                   {transType === 'debtmarketinvestment' && mmType === 'Sell' && (  <option value="MMSale"> Money Market Sale</option> ) }
//                   {transType === 'debtmarketinvestment' && mmType === 'Maturity' && (  <option value="MMMaturitySecurity"> Money Market Maturity of Security</option> ) }
//                   {transType === 'debtmarketinvestment' && mmType === 'Coupon' && (  <option value="MMMaturityCoupon"> Money Market Maturity of Coupon</option> ) }
                 
//                 </select>	
//                   {/* <input type="text" name="" value={transType }/> 
//                   <input type="text" name="" value={mmType }/> 
//                   <input type="text" name="" value={transMode}/>
//                   <input type="text" name="" value={paymentType}/>   */}
//               </div>	
//               </div>	 
 
//               <div className="col-md-12 ">
//               {showTemplates === true && chooseTemplate==='ReceivedRTGS' &&(
//                 <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(receivedFundRTGS) }} />
//               </div>
//               )}
//               {showTemplates === true && chooseTemplate==='TransferRTGS' &&(
//                 <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transferFundRTGS) }} />
//               </div>
//               )}
//               {showTemplates === true && chooseTemplate==='OnlineTransferFund' &&(
//                 <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineFT) }} />
//               </div>
//               )}
//               {showTemplates === true && chooseTemplate==='MMSale' &&(
//                 <div className="a4-page-letter saleLetter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmSale) }} />
//               </div>
//               )}
//               {showTemplates  === true && chooseTemplate==='MMPurchase' &&(
//                 <div className="a4-page-letter saleLetter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmPurchase) }} />
//               </div>
//               )}
//               {showTemplates  === true && chooseTemplate==='MMMaturitySecurity' &&(
//                 <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmMaturitySecurity) }} />
//               </div>
//               )}
//               {showTemplates === true && chooseTemplate==='MMMaturityCoupon' &&(
//                 <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
//                 <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmMaturityCoupon) }} />
//               </div>
//               )} 
//               </div>
//               <div className="col-md-12 mt-3">	
//                 <button className="btn btn-primary btn-block" onClick={() => {	
//                   printContentLetterFormat();	 

//                 }} disabled={Boolean(disableAddBtn)}>	
//                   {disableAddBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
//                     <span className="login-txt"> Loading...</span></> : <span>Print</span>}	
//                 </button>	
//               </div>	
              
//             </div>	
         

//           </div>	
//         </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   const canvasRefs = useRef(null);

//   // setObj for letter Printing	
//   // const printLetter = () => {	
//   //   let tempData = [...selectedTemplate];	
//   //   let txn_name = CapTxnType(data.txnr_type);	
//   //   let dateValue = new Date();	
//   //   tempData[0].label = data.instrument_date || '';	
//   //   tempData[1].value = `${data.bank} / ${data.branch} / 2021`;	
//   //   tempData[3].value = data.bank;	
//   //   tempData[4].value = data.branch;	
//   //   tempData[5].value = data.branch;	
//   //   tempData[8].value = data.account_title;	
//   //   tempData[9].value = data.account_number;	
//   //   tempData[10].value = data.broker;	
//   //   tempData[11].value = data.mode_of_payment;	
//   //   tempData[12].value = data.net_amount; // settlement Amount	
//   //   tempData[14].value = data.instrument_type;	
//   //   tempData[15].value = data.issue_date;	
//   //   tempData[16].value = data.maturity_date;	
//   //   tempData[17].value = data.face_value;	
//   //   tempData[18].value = data.coupon_rate;	
//   //   tempData[20].value = data.counter_bank;	
//   //   tempData[21].value = data.counter_bank;	
//   //   tempData[22].value = data.counter_bank;	
//   //   tempData[23].label = 'Text Should Be here';	
//   //   tempData[24].value = data.net_amount;	
//   //   tempData[27].value = data.amc_name;	
//   //   tempData[29].value = data.nav;	
//   //   tempData[30].value = data.gross_amount;	
//   //   tempData[31].value = data.net_amount;	
//   //   tempData[32].value = data.folio_no;	
//   //   tempData[33].value = data.units;	
//   //   tempData[34].value = data.sale_date;	
//   //   sessionStorage.setItem('letterTemplate', JSON.stringify(tempData));	
//   //   history.replace('/admin/print-letter-screen/pending');	
//   // };	


//   const  numberWithCommas = (amount:any) => {
//     return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   }
 
//   const [disableSendBtn, setdisableSendBtn] = useState(false);
//   const [tempValue, setTempValue] = useState('');
//   const [fund_account, setFund_account] = useState('');
//   const [txnData, setTxnData] = useState('');

//     let receivedFundRTGS = ReceiveOfFundRTGSEmailTemplate(data, transType, amtWords, transNetAmount, transGrossAmount, grossAmtWords);
//     let onlineFT = OnlineTransferOfFundLetter(data, amtWords, transMode, transType, transNetAmount, transGrossAmount);

//     let transferFundRTGS = TransferOfFundRTGSEmailTemplate(data,  amtWords, transNetAmount, transGrossAmount);
//     let mmPurchase = MoneyMarketPurchaseLetter(data,securityData, amtWords, transNetAmount,  transGrossAmount);
   
//     let equity = equityInvestmentLetter(data, securityData, transType);

//     let mmMaturitySecurity = mmMaturityOfSecurityLetter(data,securityData, transType, amtWords, transNetAmount, transGrossAmount);
//     let mmMaturityCoupon = mmMaturityOfCouponLetter(data,securityData,  amtWords, transNetAmount, transGrossAmount);
//     let mmSale = MoneyMarketSaleLetter(data,securityData, amtWords, transNetAmount, transGrossAmount);

//     const [showTemplates, setLetterTempates] = useState(false);
//     const [resetPaymentType, setResetPaymentType] = useState(false);
  
//   const printContentLetterFormat = () => { 

//       var doc = new jsPDF('p', 'pt', 'a4'); 

//       doc.html(document.getElementById('letterview') as HTMLElement, {
//           callback: function (doc) {
//             doc.save(`Letter ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`);
//           },
//           html2canvas: {
//             useCORS: true,
//             scale: 0.94
//             // width: 500
//           },
//           margin: [30, 0, 0, 0],
//           x: 0,
//           y: 0,
//         });

//   }  
 
//   const SendMail = async () => {
//     if (tempValue !== '') {
//       setdisableSendBtn(true);
//       try {
//         const response = await SendMailTo(tempValue, fund_account, txnData);
//         toast.success(response.data.message);
//         setTxnData('');
//         setFund_account('');
//         setTempValue('');
//         setdisableSendBtn(false);
//         setMailModelBoolean(false);  
//       } catch (error) {
//         toast.error(error.response.data.message);
//       }
//     } else {
//       toast.info('First Select Template');
//     }
//     setdisableSendBtn(false);
//   };

//   const renderMailPopup = () => {
//     switch (mailModelBoolean) {
//       case true:
//         return (
//           <Modal
//             className=""
//             dialogClassName="modal60w"
//             show={true}
//           > 

//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setMailModelBoolean(false);
//                 }}
//                 >
//                   <i className="tim-icons icon-simple-remove" />
//                 </button>
//                 <h6 className="title title-up"> Select Template </h6>
//              </div>   

//             <div className="modal-body"> 
//                 <Row> 
//                     <div className="col-md-12">
//                       <div className="form-group">
//                         <select
//                           className="form-control"
//                           onChange={(e) => {
//                             setTempValue(e.target.value);
//                           }}
//                         >
//                           <option value="" defaultChecked hidden>
//                             {mailTempLoading
//                               ? 'No Template Found'
//                               : 'Select Template'}
//                           </option>
//                           {renderMailTemplateDropdown()}
//                         </select>
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <button
//                         className="btn btn-primary btn-block"
//                         onClick={() => {
//                           SendMail();
//                         }}
//                         disabled={Boolean(disableSendBtn)}
//                       >
//                         {disableSendBtn ? (
//                           <>
//                             <span
//                               className="spinner-border login-txt spinner-border-sm"
//                               role="status"
//                               aria-hidden="true"
//                             ></span>
//                             <span className="login-txt"> Loading...</span>
//                           </>
//                         ) : (
//                           <span>Send</span>
//                         )}
//                       </button>
//                     </div> 
//                 </Row> 
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };
 

//   return (	
//     <>	
//     <div className="content">
//         <Row>
//           <Col md="12">
//       <Container fluid>	
//       <ToastContainer limit={1} />	
//         {!view ? (	
//           <div className="card">	
//             <div className="card-header">	 
//               <h4 className="card-title">Transactions</h4>	
//             </div>	
//             <div className="card-body">	
//               <div className="">	
//                 <div className="d-flex inline-box">	
//                 <div className="form-group">	
//                     <ReactTooltip	
//                       textColor="white"	
//                       backgroundColor="black"	
//                       effect="float"	
//                     />	
//                     <input	
//                       type="search"	
//                       id="myInput"	
//                       placeholder="Transaction Id"	
//                       data-tip="Search Transaction"	
//                       className="form-control"	
//                       value={searchValue}	
//                       onChange={(e) => {	
//                         setSearchValue(e.target.value);	
//                       }}	
//                     />	
//                   </div> 	
//                   <div className=" inline-box">	
//                     <div className="form-group ml-2 mr-2">	
//                       <select	
//                         className="form-control"	
//                         onChange={(e) => {	
//                           setStatus(e.target.value);	
//                         }}	
//                         value={status}	
//                       >	
//                         <option value="-1">Select type</option>	
//                         <option value="COMPLIANCE">Compliance</option>	
//                         <option value="INREVIEW">In Review</option>	
//                         <option value="SIGNATORY-A">Signatory A</option>	
//                         <option value="SIGNATORY-B">Signatory B</option>	
//                         <option value="COMPLETED">Completed</option>	
//                         <option value="REJECTED">Rejected</option>	
//                       </select> 	
//                       </div>	
                     	
//                       {/* <div className="form-group ml-2 mr-2">	
//                       <input type="date" value={selectedDate} className="form-control" 
//                       onChange={(e)=>{
//                         setSelectedDate(e.target.value);
//                       }}/>	
//                       </div> */}

//                   <button	
//                     className="btn btn-primary mt-a"	
//                     onClick={() => {	
//                       if (searchValue || status !== '-1') {	
//                         searchFilters(status, searchValue).then(() => {	
//                           setActivePage('');	
//                         });	
//                       }	
//                     }}	
//                   >	
//                      Search 	
//                   </button>	
//                   <button	
//                     className="btn btn-default mt-a"	
                    	
//                     onClick={() => {	
//                       searchFilters('', '').then(() => {	
//                         setStatus('-1');	
//                         setSearchValue('');	
//                         setActivePage('');	
//                       });	
//                     }}	
//                   >	
//                    Clear 	
//                   </button>	
//                 </div>	
//               </div>	
//               </div>	

              
//               <div className="">	
//                 {transactions.length > 0 ? (	
//                   <table className="table mt-2 ">	
//                     <thead>	
//                       <tr>	
//                         <th>Transaction Id</th>	
//                         <th>AMC Code</th>	
//                         <th>Transaction Type</th>
//                         <th>Net Amount</th>	
//                         <th>Created By</th>	
//                         <th>Date Created</th>	
//                         <th>Status</th>	
//                         <th>Actions</th>	
//                       </tr>	
//                     </thead>	
//                     <tbody>	
//                       {transactions.map((transaction: any, index: number) => {	
//                         return (	
//                           <tr key={index}>	
//                             <td>{transaction.txn_id}</td>	 
//                             <td>	
//                               {transaction.amc_code	
//                                 ? transaction.amc_code	
//                                 : 'N/A'}	
//                             </td>	
//                             <td>	
//                               {transaction.txnr_type	
//                                 ? CapTxnType(transaction.txnr_type)	
//                                 : 'N/A'}	
//                               {/* {transaction.txnr_type	
//                             ? CapTxnType(transaction.txnr_type)	
//                             : 'N/A'} */}	
//                             </td>	
//                             <td className="text-right pr-3">{numberWithCommas(transaction.net_amount)}</td>
//                             <td>	
//                               {transaction.created_by	
//                                 ? transaction.created_by	
//                                 : 'N/A'}	
//                             </td>	
//                             <td>	
//                             {transaction.txn_history	
//                               ? transaction.txn_history[0]?.created_at	
//                                 ? moment(	
//                                     transaction.txn_history[0].created_at	
//                                   ).format('DD-MM-YYYY')	
//                                 : 'N/A'	
//                               : 'N/A'}	
//                             </td>	
//                             <td>{transaction.txn_status}</td>	 
//                             <td className=" ">	
//                               <div className="multi-icons pointer">	 
//                               <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6" onClick={() => {	
//                                     // setData(transaction);
//                                     let txnData = transaction;
//                                     let bankname=bankName(transaction.bank.toString())	
//                                     let counterbankname=bankName(transaction.counter_bank.toString())	
//                                     setData({
//                                       ...txnData,
//                                       bank:bankname,
//                                       counter_bank:counterbankname
//                                     });
//                                     setView(true);	
//                                     setTrxHistory(transaction.txn_history);	
//                                   }}	>
//                                   <i className="fa fa-eye"> </i>
//                               </button>
                               
//                                 {/* {transaction.txn_status === 'COMPLETED' && transaction.txnr_type!=='equityinvestment' && (	 */}
//                                 {transaction.txn_status === 'COMPLETED' &&  
//                                 ((transaction.mode_of_payment !== 'Cheque' && 
//                                   transaction.instrument_type !== 'Cheque') || 
//                                   (transaction.mode_of_payment === 'Online') || 
//                                   (transaction.mode_of_payment === 'RTGS') ) &&
//                                 (	
//                                   <>	
//                                     <ReactTooltip	
//                                       textColor="white"	
//                                       backgroundColor="black"	
//                                       effect="float"	
//                                     />	
//                                     <BsFileRichtext	
//                                       data-tip={'Print Letter'}	
//                                       onClick={() => { 
//                                         let txnData=transaction;	
//                                         let bankname=bankName(transaction.bank.toString())	
//                                         let counterbankname=bankName(transaction.counter_bank.toString())	
//                                         getLetterTemplateCall(transaction.bank);
//                                         setData({
//                                           ...txnData,
//                                           bank:bankname,
//                                           counter_bank:counterbankname
//                                         });

//                                         let securities = JSON.parse(transaction.securities);  
//                                         setSecurityData(securities); 

                                    
                                        
//                                         setMMType(securities.filter(u => u.type === 'Maturity' || u.type === 'Buy' || u.type === 'Sell' || u.type === 'Coupon').map(e=>e.type)[0]);
                                

 
//                                         setTransType(transaction.txnr_type); 
//                                         setTransMode(transaction.mode_of_payment);
//                                         setPaymentType(transaction.payment_type); 
//                                         // Net Amount
//                                         transaction.net_amount=transaction.net_amount.replace(",","");
//                                         let amount= transaction.net_amount.split('.'); 
//                                         setAmtWords(inWords(amount[0])); 
//                                         setTransNetAmount(numberWithCommas(transaction.net_amount));

//                                         // Gross Amount
//                                         transaction.gross_amount=transaction.gross_amount.replace(",","");
//                                         let grossAmount= transaction.gross_amount.split('.'); 
//                                         setGrossAmtWords(inWords(grossAmount[0]));   
//                                         setTransGrossAmount(numberWithCommas(transaction.gross_amount));

//                                         setDisplayLetterCheck(true);  
//                                         setLetterTempates(false);
//                                         setResetPaymentType(false)
//                                       }}	
//                                     /> 	

//                                   <ReactTooltip
//                                       textColor="white"
//                                       backgroundColor="black"
//                                       effect="float"
//                                     />
//                                     <RiMailSendFill
//                                       className={'ml-1'}
//                                       data-tip={'Send Mail'}
//                                       onClick={() => {
//                                         setTxnData(JSON.stringify(transaction));
//                                         setFund_account(
//                                           transaction.fund_account
//                                         );
//                                         setMailModelBoolean(true);
//                                       }}
//                                     />
//                                   </>	
//                                 )}	

//                               </div>	
//                             </td>	
//                           </tr>	
//                         );	
//                       })}	
//                     </tbody>	
//                   </table>	
//                 ) : (	
//                   <div className="table-empty">	
//                     <BsTable className="icon" />	
//                     <p className="text-center empty-text">No record found</p>	
//                   </div>	
//                 )}	
//                 <div	
//                   className="row pointer mr-3"	
//                   style={{	
//                     float: 'right',	
//                   }}	
//                 >	
//                   {/* PREVIOUS BUTTON */}	
//                   {activePage !== paginationArr[0] ? (	
//                     <i	
//                       className="fa fa-arrow-left"	
//                       aria-hidden="true"	
//                       onClick={() => {	
//                         let pageArr = paginationArr;	
//                         let index = pageArr.findIndex((x) => x === activePage);	
//                         if (index !== -1) {	
//                           setActivePage(paginationArr[index - 1]);	
//                         }	
//                       }}	
//                     ></i>	
//                   ) : (	
//                     ''	
//                   )}	
//                   {/* NEXT BUTTON */}	
//                   {activePage !== paginationArr[paginationArr.length - 1] ? (	
//                     <i	
//                       className="fa ml-3 fa-arrow-right"	
//                       aria-hidden="true"	
//                       onClick={() => {	
//                         let pageArr = paginationArr;	
//                         let index = pageArr.findIndex((x) => x === activePage);	
//                         if (index !== -1) {	
//                           setActivePage(paginationArr[index + 1]);	
//                         }	
//                       }}	
//                     ></i>	
//                   ) : (	
//                     ''	
//                   )}	
//                 </div>	
//               </div>	
//             </div>	
//           </div>	
//         ) : (	
//           <div className="">	
            	
//             <h4>		
//                   <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {		
//                     setView(false);		
//                   }}></i>		
//                   View Transaction   
 
//                 </h4>		
//             <ViewTransaction data={data} />	
//             {/* <div className="form-holder">	
//                 {data ? <Row>{renderData()}</Row> : ''}	
//               </div> */}	

//             <div className="line"></div>	  
            
//             <Row>	
//               {trx_history.map((hist: any, index: number) => {	
//                 return (	
//                   <Col	
//                     md="3"	
//                     onClick={() => {	
//                       if (hist.action === 'REJECTED-TRUSTEE' || hist.action === 'REJECTED-AMC') {	
//                         setNote(hist.note);	
//                       } else {	
//                         setNote('');	
//                       }	
//                       if (hist.action === 'CREATED') {	
//                         return;	
//                       }	
//                       if (!hist.check_list) return;	
//                       setTrxCheckList(hist.check_list);	
//                       setViewCheckList(true);	
//                     }}	
//                   > 	
//                     <div className="card process-card pointer">	
//                       <div className="card-header">	
//                       <h4 className="card-title">	
//                           <span className="card-number">
//                           {index + 1}
//                           </span>
//                            <span className="mr-1">
//                                 </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
//                           </h4>	
//                       </div> 	
//                       <div className="card-body text-center">	 
                            
//                           <p className="small mt-2">Status:</p>	
//                           <h3 className="accepted text-primary">  {hist.action}    </h3>	 
//                           {hist.updated_at !== '' ? (	
//                             <>	
//                               <p className="small mt-2"> by</p>	
//                               <h4 className="comment text-primary">{(hist.updated_by)}</h4>	 
//                               <p className="small mt-2">at</p>	
//                               <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>	 
//                             </>	
//                           ) : (	
//                               ''	
//                             )}	
//                           {hist.note !== '' ? (	
//                             <>	
//                                <p className="small mt-2">Comment</p>	
//                             <small className="comment dot-text" title={hist.note}>{hist.note}</small>	
//                             </>	
//                           ) : (	
//                               ''	
//                             )}	
//                           {	
//                             hist.created_by !== '' ? (	
//                               <>	
//                                 <p className="small mt-2"> by</p>	
//                                 <h4 className="text-primary">{hist.created_by}</h4>
//                                 <p className="small mt-2"> at</p>	
//                                 <h4 className="comment text-primary">  {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")}</h4>	
                                	
//                               </>	
//                             ) : (	
//                                 ''	
//                               )}	
//                         </div>	
//                     </div>	
//                   </Col>	
//                 );	
//               })}	
//             </Row>	
//           </div>	
//         )}	
//         {renderSecuritiesModalPopup()}	
//         {renderTaxPopup()}
//         {renderModalForLetterPopup()}	
//         {viewCheckListPopup()}	
//         {renderMailPopup()}
//       </Container>	
//       </Col>
//         </Row>
//       </div>
//     </>	
//   );	
// };	
// export default PendingTransactions;	


import React, {useRef, useState, useEffect } from 'react';	
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';	
import ReactTooltip from 'react-tooltip';	
import { Link } from 'react-router-dom';	
import moment from 'moment';	
import { Modal } from 'react-bootstrap';	
import ViewTransaction from './../transactions/view-beta-txn';	
import { FaRegMoneyBillAlt } from 'react-icons/fa';	
import {	
  getMailTemplates,
  inWords,	
  getAllLetterTemplates,	
  SendMailTo,
} from './../../stores/services/template.service';	
import { getAllPendingTransactions } from '../../stores/services/transactions.service';	
import * as htmlToImage from 'html-to-image';	
import jsPDF  from 'jspdf';	
import { useHistory } from 'react-router-dom';		
import { RiMailSendFill } from 'react-icons/ri';
import { BsFileRichtext } from 'react-icons/bs';	
import { BsTable } from 'react-icons/bs';	
import { getAllBanks } from '../../stores/services/bank.service';	
import { toast, ToastContainer } from 'react-toastify';  

import { ReceiveOfFundRTGSEmailTemplate } from '../letterTemplates/inflowRTGS.letter.template'; 
import { OnlineTransferOfFundLetter } from '../letterTemplates/onlineTF.letter.template'; 
import { TransferOfFundRTGSEmailTemplate } from '../letterTemplates/outflowRTGS.letter.template'; 
import { MoneyMarketPurchaseLetter } from '../letterTemplates/mmPurcahse.letter.template'; 
import { mmMaturityOfSecurityLetter } from '../letterTemplates/mmMaturityOfSecurity.letter.template'; 
import { mmMaturityOfCouponLetter } from '../letterTemplates/mmMaturityOfCoupon.letter.template'; 
import { MoneyMarketSaleLetter } from '../letterTemplates/mmSale.letter.template'; 
import { equityInvestmentLetter } from '../letterTemplates/equityInvestment.letter.template'; 
import { transferInFundRTGSEmailTemplate } from '../letterTemplates/transferIn.letter.template'; 
import createDOMPurify from 'dompurify';
const DOMPurify = createDOMPurify(window);

const PendingTransactions = () => {	
  const history = useHistory();	
  let [transactions, setTransactions] = useState<any>([]);	
  let [status, setStatus] = useState('-1');	
  let [searchValue, setSearchValue] = useState('');	
  let [activePage, setActivePage] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  const email = sessionStorage.getItem('email') || '';
  const role = sessionStorage.getItem('role') || '';	
  const [allLetterTemplates, setAllLetterTemplates] = useState<any>([]);	
  const [letterLoading, setLetterLoading] = useState(false);	
  const [tempLoading, setTempLoading] = useState(false);	

  const [mailModelBoolean, setMailModelBoolean] = useState(false);
  const [mailTempLoading, setMailTempLoading] = useState(false);
  const [mailTempData, setMailTempData] = useState<any>([]);

  const [chooseTemplate, setChooseTemplate] = useState('');
  const [securityData, setSecurityData] = useState<any>([]); 
  const [transType, setTransType] = useState<any>([]);
  const [transMode, setTransMode] = useState<any>([]);
  const [mmCoupon, setMMCoupon] = useState<any>([]); 
  const [amtWords, setAmtWords] = useState<any>([]);
  const [grossAmtWords, setGrossAmtWords] = useState<any>([]);
  const [transNetAmount, setTransNetAmount] = useState<any>([]);
  const [transGrossAmount, setTransGrossAmount] = useState<any>([]);
  const [paymentType, setPaymentType] = useState<any>('');
  const [mmType, setMMType] = useState<any>([]); 

  // let date=new Date();

  // const [selectedDate, setSelectedDate] = useState(moment(date).format('YYYY-MM-DD'));

  useEffect(() => {
    const fetchMailTemp = async () => {
      try {
        const response = await getMailTemplates();
        setMailTempData(response.data.template);
        if (response.data.template.length < 1) {
          setMailTempLoading(true);
        } else {
          setMailTempLoading(false);
        }
      } catch (error) {}
    };
    fetchMailTemp();
  }, []);

  // let txnTypes = JSON.parse(sessionStorage.getItem(s'txnType') || ''); //All transaction Types

    //adject transaction name format
    // const CapTxnType = (type: any) => {
    //   let i, txType;
    //   for (i = 0; i < txnTypes.length; i++) {
    //     if (txnTypes[i].api_name === type) {
    //       txType = txnTypes[i].dis_name;
    //       break;
    //     }
    //   }
    //   return txType;
    // };
	
  const getLetterTemplateCall = async (bankName) => {	
    try {	
      const response = await getAllLetterTemplates(email, bankName);	
      setAllLetterTemplates(response.data.data);	
      if (response.data.data.length < 1) {	
        setLetterLoading(true);	
      } else {	
        setLetterLoading(false);	
      }	
    } catch (error) {}	
  };		

    //render dropdown for mail data
    const renderMailTemplateDropdown = () => {
      return mailTempData.map((item: any, index: string) => {
        return (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        );
      });
    };

    
  //render dropdown for security data	
  const renderLetterTemplateDropdown = () => {	
    return allLetterTemplates.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.template_detail}>	
          {item.template_name}	
        </option>	
      );	
    });	
  };	
  React.useEffect(() => {	
    getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',	
      status,	
      searchValue,	
      '',	
      activePage
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);	
      })	
      .catch((err) => {	
       // console.log(err);	
      });	
  }, [activePage]);	
  const [bankData, setBankData] = useState<any>([]);	
  React.useEffect(() => {	
    const fetchBank = async () => {	
      try {	
        const bankResponse = await getAllBanks(email);	
        setBankData(bankResponse.data.data);	
      } catch (error) {	
        // console.log(error);	
      }	
    };	
    fetchBank();	
  }, []);	

  React.useEffect(() => {	
    getAllPendingTransactions(sessionStorage.getItem('email') || '', '', '')	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);	
      })	
      .catch((err) => {	
       // console.log(err);	
      });	
  }, []);	
  const CapTxnType = (type: any) => {	
    let i, txType;	
    for (i = 0; i < txnTypes.length; i++) {	
      if (txnTypes[i].api_name === type) {	
        txType = txnTypes[i].dis_name;	
        break;	
      }	
    }	
    return txType;	
  };	

  const bankName=(bankcode)=>{
    const bankdata= bankData.filter((item: any, index: string)=>{
      return (item.bank_code===bankcode);
     });
     if(bankdata.length>0){
     return bankdata[0].bank_name;
    }
     else{
     return bankcode;
     }
  }

  const searchFilters = (statusVar = '', searchVar = '') => {	
    return new Promise((resolve, reject) => {	
      getAllPendingTransactions(	
        sessionStorage.getItem('email') || '',	
        statusVar === '-1' ? '' : statusVar,	
        searchVar,	
        '',	
        activePage	
      )	
        .then((response) => {	
          let pageArr = paginationArr;	
          let existingPageIndex = paginationArr.findIndex(	
            (x) => x === response.data.page_info.next_page_number	
          );	
          if (response.data.data.length !== 10) {	
            if (	
              existingPageIndex === -1 &&	
              response.data.data.length === 10 &&	
              10 * pageArr.length !==	
                parseInt(response.data.page_info.total_count)	
            ) {	
              pageArr.push(response.data.page_info.next_page_number);	
            }	
          }	
          setPaginationArr(pageArr);	
          setTransactions(response.data.data);	
          resolve('Success');	
        })	
        .catch((err) => {	
        // console.log(err);	
          reject('Error');	
        });	
    });	
  };	
  const [setLoading] = useState(false);	
  const [view, setView] = useState(false);	
  const [data, setData] = useState<any>([]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };

  const [trx_history, setTrxHistory] = useState([]);	
  	
  const renderPsxData = () => {	
    return securites.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.type}</td>	
          <td>{items.market}</td>	
          <td>{items.symbol}</td>	
          <td>{items.quantity}</td>	
          <td>{items.avg_rate}</td>	
          <td>{items.gross_amount}</td>	
          <td>{items.deductions}</td>	
          <td>{items.additions}</td>	
          <td>{items.net_amount}</td>	
        </tr>	
      );	
    });	
  };	
  const [flagForSecutities, setFlagForSecutities] = useState(false);	
  const [securites, setSecurites] = useState<any>([]);	
  const renderSecuritiesModalPopup = () => {	
    switch (flagForSecutities) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => { setFlagForSecutities(false) }}	
          > 	
            <div className="modal-header ">	
                        <button	
                          aria-hidden={true}	
                          className="close"	
                          data-dismiss="modal"	
                          type="button"	
                          onClick={() => {	
                            setFlagForSecutities(false);	
                            // clearTaxobject();	
                          }}	
                        >	
                          <i className="tim-icons icon-simple-remove" />	
                        </button>	
                        <h6 className="title title-up">   View Security</h6>	
                      </div>	
            <div className="modal-body">	
              <div className="">	
                {securites.length > 0 && (	
                  <div className="input-size-pos">	
                    <table className="table">	
                      <thead>	
                        <tr>	
                          <th>Type</th>	
                          <th>Market</th>	
                          <th>Symbol</th>	
                          <th>Quantity</th>	
                          <th>Avg Rate</th>	
                          <th>Gross Amount</th>	
                          <th>Deduction</th>	
                          <th>Addition</th>	
                          <th>Net Amount</th>	
                        </tr>	
                      </thead>	
                      <tbody >	
                        {renderPsxData()}	
                      </tbody>	
                    </table>	
                  </div>)}	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  //render table data for tax	
  //view tax table	
  const [viewTaxModal, setTaxModal] = useState(false);	
  const [totalTax, setTotalTax] = useState<any>([]);	
  //render Tax table data	
  const renderTaxData = () => {	
    return totalTax.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.name}</td>	
          <td>{items.amount}</td>	
          <td>{items.calculatedAmount}</td>	
        </tr>	
      );	
    });	
  };	
  const renderTaxPopup = () => {	
    switch (viewTaxModal) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => { setTaxModal(false) }}	
          > 	
            	
            <div className="modal-header justify-content-center">	
                        <button	
                          aria-hidden={true}	
                          className="close"	
                          data-dismiss="modal"	
                          type="button"	
                          onClick={() => {	
                            setTaxModal(false);	
                            // clearTaxobject();	
                          }}	
                        >	
                          <i className="tim-icons icon-simple-remove" />	
                        </button>	
                        <h6 className="title title-up"> View Txn Charges  </h6>	
                      </div>	
            <div className="modal-body">	
              <div className="">	
                {totalTax.length > 0 && (	
                  <div className="input-size-pos">	
                    <table className="table my-table">	
                      <thead>	
                        <tr>	
                          <th>Tax Name</th>	
                          <th>Tax Amount</th>	
                          <th>Calculated Amount</th>	
                        </tr>	
                      </thead>	
                      <tbody >	
                        {renderTaxData()}	
                      </tbody>	
                    </table>	
                  </div>)}	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderData = () => {		
    return data.map((value: any, index: number) => {	
      return (	
        <>	
          {value[0] !== 'txn_history' &&	
          value[0] !== 'file' &&	
          value[1] !== '' &&	
          value[1] !== 0 ? (	
            <Col md="6" key={index}>	
              <div className="input-holder left">	
                <p className="label">{titleCase(value[0])}</p>	
                {/* <div className="input-1 hide-overflow">	
                  <p	
                    style={{	
                      fontSize: '12px',	
                    }}	
                  >	
                    {typeof value[1] === 'string'	
                      ? value[0] === 'file'	
                        ? 'N/A'	
                        : value[1]	
                      : value[0] === 'file'	
                        ? 'N/A'	
                        : ''}	
                  </p>	
                </div> */}	
                {value[0] === 'securities' ? (	
                  <div	
                    className="input-1 flex justify-content-center"	
                    style={{	
                      background: '#0e4646',	
                      color: 'white',	
                      cursor: 'pointer',	
                    }}	
                    onClick={() => {	
                      setFlagForSecutities(true);	
                      setSecurites(JSON.parse(value[1]));	
                    }}	
                  >	
                    View Securities	
                  </div>	
                ) : value[0] === 'txn_charges' ? (	
                  <div	
                    className="input-1 flex justify-content-center"	
                    style={{	
                      background: '#0e4646',	
                      color: 'white',	
                      cursor: 'pointer',	
                    }}	
                    onClick={() => {	
                      setTaxModal(true);	
                      setTotalTax(JSON.parse(value[1]));	
                    }}	
                  >	
                    View Txn Charges	
                  </div>	
                ) : (	
                  // <AiFillEye className="m-3"/>	
                  <div className="input-1">	
                    <p	
                      style={{	
                        fontSize: '15px',	
                      }}	
                    >	
                      {value[1] &&	
                      value[1].toString().includes('Pakistan Standard')	
                        ? moment(value[1].toString()).format(	
                            'DD-MM-YYYY h:mm a'	
                          )	
                        : value[1] && value[1].toString()}	
                    </p>	
                  </div>	
                )}	
              </div>	
            </Col>	
          ) : (	
            ''	
          )}	
        </>	
      );	
    });	
  };	
	
   // render Model for adding Fund	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false); 		
	

  const [note, setNote] = useState('');
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  const [templateDD, setTemplateDD ] = useState();	
  const viewCheckListPopup = () => {	
    switch (viewChecklist) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="xl"	
          >	
            	
          <div className="modal-header ">	
                <button	
                  aria-hidden={true}	
                  className="close"	
                  data-dismiss="modal"	
                  type="button"	
                  onClick={() => {	
                    setViewCheckList(false);	
                  }}	
                >	
                  <i className="tim-icons icon-simple-remove" />	
                </button>	
                <h6 className="title title-up">Approval Check List </h6>	
              </div>	
            <div className="modal-body">	
              <div className=""> 	
                <div className={'checlist-scroll'}>	
                  {trx_checklist.map((val: any, ind: any) => {	
                    return (	
                      <Row key={ind} className="pt-3">		
                      		
                      <Col md="5">		
                      <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>		
                      </Col>		
                      <Col md="7">		
                        <Row>		
                          <Col md="3">		
                         		
                          <FormGroup check>		
                            <Label check>		
                              <Input 		
                              checked={val.value == 'compliant'}		
                              type="checkbox"		
                              value="compliant"		
                              readOnly />		
                              <span className="form-check-sign" />		
                              Compliant		
                            </Label>		
                          </FormGroup>		
                          </Col>		
                          <Col md="2"> 		
                          <FormGroup check>		
                            <Label check>		
                              <Input type="checkbox" 		
                              checked={val.value == 'na'}		
                              value="na"		
                              readOnly />		
                              <span className="form-check-sign" />		
                              N /A		
                            </Label>		
                          </FormGroup>		
                          </Col>		
                          <Col md="7">		
                            <FormGroup>		
                              <input		
                                type="text"		
                                placeholder="Comments"		
                                value={val.comment}		
                                className="form-control"		
                                readOnly		
                              />		
                              </FormGroup>		
                            		
                          </Col>		
                        </Row>	 	
                      </Col>		
                    </Row>	
                    );	
                  })}	
                </div>	
                {note !== '' && (	
                  <Row>	
                    <Col md="12">
                   
                    <div className="form-group">	
                    <label>Reason of Reject</label>	
                      <textarea	
                        placeholder="Reason"	
                        className={'form-control w-100'}	
                        value={note}	
                        readOnly	
                      ></textarea>	
                    </div>	
                    </Col>
                  </Row>	
                )}	
                <Row>	
                  <Col>	
                    <div className=" ">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setViewCheckList(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };

  let letterState = [];

 
  const [displayLetterCheck, setDisplayLetterCheck] = useState(false);	  
  const renderModalForLetterPopup = () => {	
    switch (displayLetterCheck) {	
      case true:	
        return (	
          <Modal	
          className=""	
          dialogClassName="modal90w"	
          show={true}	
          size="lg"	
          backdrop={true}	
          onHide={() => { setDisplayLetterCheck(false) }}	
        > 	
          <div className="modal-header ">	
            <button	
              aria-hidden={true}	
              className="close"	
              data-dismiss="modal"	
              type="button"	
              onClick={() => {	
                setDisplayLetterCheck(false);
              }}	
            >	
              <i className="tim-icons icon-simple-remove" />	
            </button>	
            <h6 className="title title-up">  Select Template</h6>	
          </div>	
          <div className="modal-body">
            <div className="row">	
              <div className="col-md-12">	
              <div className="form-group">	
                <label>Select</label>	
                <ReactTooltip textColor='white' backgroundColor='Black' effect="float" />	
                <select className="form-control" 
                  onChange={(e) => {	
                     // eslint-disable-next-line  
                    e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                    setChooseTemplate(e.target.value); 
                    setLetterTempates(true);
                    setResetPaymentType(true);
                  }}
                  >	
                  <option value="" defaultChecked hidden>{tempLoading ? 'No Template Found' : 'Select Letter Template'}</option>	
                  {transType !== 'debtmarketinvestment' && transMode === 'RTGS' &&  paymentType === 'Receipt'  &&  (<option value="TransferInRTGS">RTGS Inflow </option>) }
                  {transType !== 'debtmarketinvestment' && transMode === 'RTGS' && ( paymentType === 'Payment or Receipt')  &&   (<option value="ReceivedRTGS">RTGS Inflow Fund Transfer</option>) }

                      {/* {transType !== 'debtmarketinvestment' && transMode === 'RTGS' &&  (paymentType === 'Receipt' || paymentType === 'Payment or Receipt' ) &&   (<option value="ReceivedRTGS">RTGS Inflow</option>) } */}
                  {transType !== 'debtmarketinvestment' && transMode === 'RTGS' && (paymentType === 'Payment' || paymentType === 'Payment or Receipt' ) && (<option value="TransferRTGS"> RTGS Outflow</option>) }
                  
                  {transType !== 'debtmarketinvestment' && transMode === 'Online' && (paymentType === 'Payment' || paymentType === 'Payment or Receipt' ) &&( <option value="OnlineTransferFund"> Online Transfer of Fund</option>) }
                  
                  {transType === 'debtmarketinvestment' && mmType === 'Buy' && (  <option value="MMPurchase"> Money Market Purchase</option>) }
                  {transType === 'debtmarketinvestment' && mmType === 'Sell' && (  <option value="MMSale"> Money Market Sale</option> ) }
                  {transType === 'debtmarketinvestment' && mmType === 'Maturity' && (  <option value="MMMaturitySecurity"> Money Market Maturity of Security</option> ) }
                  {transType === 'debtmarketinvestment' && mmType === 'Coupon' && (  <option value="MMMaturityCoupon"> Money Market Maturity of Coupon</option> ) }
                 
                </select>	
                  {/* <input type="text" name="" value={transType }/> 
                  <input type="text" name="" value={mmType }/> 
                  <input type="text" name="" value={transMode}/>
                  <input type="text" name="" value={paymentType}/>   */}
              </div>	
              </div>	 
 
              <div className="col-md-12 ">
              {showTemplates === true && chooseTemplate==='TransferInRTGS' &&(
                <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transferInFundRTGS) }} />
              </div>
              )}

              {showTemplates === true && chooseTemplate==='ReceivedRTGS' &&(
                <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(receivedFundRTGS) }} />
              </div>
              )}
              {showTemplates === true && chooseTemplate==='TransferRTGS' &&(
                <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transferFundRTGS) }} />
              </div>
              )}
              {showTemplates === true && chooseTemplate==='OnlineTransferFund' &&(
                <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineFT) }} />
              </div>
              )}
              {showTemplates === true && chooseTemplate==='MMSale' &&(
                <div className="a4-page-letter saleLetter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmSale) }} />
              </div>
              )}
              {showTemplates  === true && chooseTemplate==='MMPurchase' &&(
                <div className="a4-page-letter saleLetter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmPurchase) }} />
              </div>
              )}
              {showTemplates  === true && chooseTemplate==='MMMaturitySecurity' &&(
                <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmMaturitySecurity) }} />
              </div>
              )}
              {showTemplates === true && chooseTemplate==='MMMaturityCoupon' &&(
                <div className="a4-page-letter" id={'letterview'} ref={canvasRefs}>
                <div className="p-letter" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mmMaturityCoupon) }} />
              </div>
              )} 
              </div>
              <div className="col-md-12 mt-3">	
                <button className="btn btn-primary btn-block" onClick={() => {	
                  printContentLetterFormat();	 

                }} disabled={Boolean(disableAddBtn)}>	
                  {disableAddBtn ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                    <span className="login-txt"> Loading...</span></> : <span>Print</span>}	
                </button>	
              </div>	
              
            </div>	
         

          </div>	
        </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const canvasRefs = useRef(null);

  // setObj for letter Printing	
  // const printLetter = () => {	
  //   let tempData = [...selectedTemplate];	
  //   let txn_name = CapTxnType(data.txnr_type);	
  //   let dateValue = new Date();	
  //   tempData[0].label = data.instrument_date || '';	
  //   tempData[1].value = `${data.bank} / ${data.branch} / 2021`;	
  //   tempData[3].value = data.bank;	
  //   tempData[4].value = data.branch;	
  //   tempData[5].value = data.branch;	
  //   tempData[8].value = data.account_title;	
  //   tempData[9].value = data.account_number;	
  //   tempData[10].value = data.broker;	
  //   tempData[11].value = data.mode_of_payment;	
  //   tempData[12].value = data.net_amount; // settlement Amount	
  //   tempData[14].value = data.instrument_type;	
  //   tempData[15].value = data.issue_date;	
  //   tempData[16].value = data.maturity_date;	
  //   tempData[17].value = data.face_value;	
  //   tempData[18].value = data.coupon_rate;	
  //   tempData[20].value = data.counter_bank;	
  //   tempData[21].value = data.counter_bank;	
  //   tempData[22].value = data.counter_bank;	
  //   tempData[23].label = 'Text Should Be here';	
  //   tempData[24].value = data.net_amount;	
  //   tempData[27].value = data.amc_name;	
  //   tempData[29].value = data.nav;	
  //   tempData[30].value = data.gross_amount;	
  //   tempData[31].value = data.net_amount;	
  //   tempData[32].value = data.folio_no;	
  //   tempData[33].value = data.units;	
  //   tempData[34].value = data.sale_date;	
  //   sessionStorage.setItem('letterTemplate', JSON.stringify(tempData));	
  //   history.replace('/admin/print-letter-screen/pending');	
  // };	


  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
 
  const [disableSendBtn, setdisableSendBtn] = useState(false);
  const [tempValue, setTempValue] = useState('');
  const [fund_account, setFund_account] = useState('');
  const [txnData, setTxnData] = useState('');

    let transferInFundRTGS = transferInFundRTGSEmailTemplate(data, transType, amtWords, transNetAmount, transGrossAmount, grossAmtWords);

    let receivedFundRTGS = ReceiveOfFundRTGSEmailTemplate(data, transType, amtWords, transNetAmount, transGrossAmount, grossAmtWords);
    let onlineFT = OnlineTransferOfFundLetter(data, amtWords, transMode, transType, transNetAmount, transGrossAmount);

    let transferFundRTGS = TransferOfFundRTGSEmailTemplate(data,  amtWords, transNetAmount, transGrossAmount);
    let mmPurchase = MoneyMarketPurchaseLetter(data,securityData, amtWords, transNetAmount,  transGrossAmount);
   
    let equity = equityInvestmentLetter(data, securityData, transType);

    let mmMaturitySecurity = mmMaturityOfSecurityLetter(data,securityData, transType, amtWords, transNetAmount, transGrossAmount);
    let mmMaturityCoupon = mmMaturityOfCouponLetter(data,securityData,  amtWords, transNetAmount, transGrossAmount);
    let mmSale = MoneyMarketSaleLetter(data,securityData, amtWords, transNetAmount, transGrossAmount);

    const [showTemplates, setLetterTempates] = useState(false);
    const [resetPaymentType, setResetPaymentType] = useState(false);
  
  const printContentLetterFormat = () => { 

      var doc = new jsPDF('p', 'pt', 'a4'); 

      doc.html(document.getElementById('letterview') as HTMLElement, {
          callback: function (doc) {
            doc.save(`Letter ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`);
          },
          html2canvas: {
            useCORS: true,
            scale: 0.94
            // width: 500
          },
          margin: [30, 0, 0, 0],
          x: 0,
          y: 0,
        });

  }  
 
  const SendMail = async () => {
    if (tempValue !== '') {
      setdisableSendBtn(true);
      try {
        const response = await SendMailTo(tempValue, fund_account, txnData);
        toast.success(response.data.message);
        setTxnData('');
        setFund_account('');
        setTempValue('');
        setdisableSendBtn(false);
        setMailModelBoolean(false);  
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      toast.info('First Select Template');
    }
    setdisableSendBtn(false);
  };

  const renderMailPopup = () => {
    switch (mailModelBoolean) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setMailModelBoolean(false);
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Select Template </h6>
             </div>   

            <div className="modal-body"> 
                <Row> 
                    <div className="col-md-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setTempValue(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {mailTempLoading
                              ? 'No Template Found'
                              : 'Select Template'}
                          </option>
                          {renderMailTemplateDropdown()}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => {
                          SendMail();
                        }}
                        disabled={Boolean(disableSendBtn)}
                      >
                        {disableSendBtn ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Send</span>
                        )}
                      </button>
                    </div> 
                </Row> 
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
 

  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
      <ToastContainer limit={1} />	
        {!view ? (	
          <div className="card">	
            <div className="card-header">	 
              <h4 className="card-title">Transactions</h4>	
            </div>	
            <div className="card-body">	
              <div className="">	
                <div className="d-flex inline-box">	
                <div className="form-group">	
                    <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      effect="float"	
                    />	
                    <input	
                      type="search"	
                      id="myInput"	
                      placeholder="Transaction Id"	
                      data-tip="Search Transaction"	
                      className="form-control"	
                      value={searchValue}	
                      onChange={(e) => {	
                        setSearchValue(e.target.value);	
                      }}	
                    />	
                  </div> 	
                  <div className=" inline-box">	
                    <div className="form-group ml-2 mr-2">	
                      <select	
                        className="form-control"	
                        onChange={(e) => {	
                          setStatus(e.target.value);	
                        }}	
                        value={status}	
                      >	
                        <option value="-1">Select type</option>	
                        <option value="COMPLIANCE">Compliance</option>	
                        <option value="INREVIEW">In Review</option>	
                        <option value="SIGNATORY-A">Signatory A</option>	
                        <option value="SIGNATORY-B">Signatory B</option>	
                        <option value="COMPLETED">Completed</option>	
                        <option value="REJECTED">Rejected</option>	
                      </select> 	
                      </div>	
                     	
                      {/* <div className="form-group ml-2 mr-2">	
                      <input type="date" value={selectedDate} className="form-control" 
                      onChange={(e)=>{
                        setSelectedDate(e.target.value);
                      }}/>	
                      </div> */}

                  <button	
                    className="btn btn-primary mt-a"	
                    onClick={() => {	
                      if (searchValue || status !== '-1') {	
                        searchFilters(status, searchValue).then(() => {	
                          setActivePage('');	
                        });	
                      }	
                    }}	
                  >	
                     Search 	
                  </button>	
                  <button	
                    className="btn btn-default mt-a"	
                    	
                    onClick={() => {	
                      searchFilters('', '').then(() => {	
                        setStatus('-1');	
                        setSearchValue('');	
                        setActivePage('');	
                      });	
                    }}	
                  >	
                   Clear 	
                  </button>	
                </div>	
              </div>	
              </div>	

              
              <div className="">	
                {transactions.length > 0 ? (	
                  <table className="table mt-2 ">	
                    <thead>	
                      <tr>	
                        <th>Date</th>
                        <th>Transaction</th>	
                        <th>Fund</th>	
                        <th>Amount</th>
                        <th>Bank</th>	
                        <th>Counter Acc</th>
                        <th>Instrument</th>	
                        <th>Created</th>	
                        <th>Status</th>	
                        <th>Act</th>	
                      </tr>	
                    </thead>	
                    <tbody>	
                      {transactions.map((transaction: any, index: number) => {	
                        return (	
                          <tr key={index}>	
                          <td>{moment(transaction.instruction_date).format('DD-MM-YYYY')} <br/>
                          <span className="sub-content">{moment(transaction.execution_date).format('DD-MM-YYYY')}</span>
                          </td>

                            <td>{transaction.txnr_type	
                                ? CapTxnType(transaction.txnr_type)	
                                : 'N/A'}<br/> <span className="sub-content">{transaction.txn_id}</span></td>	

                            <td>{transaction.fund_code}	<br/>
                            <span className="sub-content">{transaction.amc_code	
                                ? transaction.amc_code	
                                : 'N/A'}	</span>
                            </td>	

                            <td className="text-right pr-3">{numberWithCommas(transaction.net_amount)} <br/>
                            <span className="sub-content">{numberWithCommas(transaction.gross_amount)}</span>
                            </td>

                            <td>{transaction.bank}<br/>
                            <span className="sub-content">{transaction.account_number}</span></td>

                            <td>{transaction.counter_bank} <br/>
                            <span className="sub-content">{transaction.counter_account_number}</span></td>

                              <td>{transaction.mode_of_payment} - {transaction.instrument_type} <br/>
                              <span className="sub-content">{transaction.instrument_no!=='' ? transaction.instrument_no +',': ''} {transaction.instrument_date!=='' ? moment(transaction.instrument_date).format('DD-MM-YYYY') : ''}</span></td>

                            <td>{moment(transaction.created_at).format('DD-MM-YYYY')} <br/>	
                            <span className="sub-content">{transaction.created_by	
                                ? transaction.created_by	
                                : 'N/A'}</span>	
                            </td>		
                            <td>{transaction.txn_status}</td>	 
                            <td className=" ">	
                              <div className="multi-icons pointer">	 
                              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm  mt-minus6" onClick={() => {	
                                    // setData(transaction);
                                    let txnData = transaction;
                                    let bankname=bankName(transaction.bank.toString())	
                                    let counterbankname=bankName(transaction.counter_bank.toString())	
                                    setData({
                                      ...txnData,
                                      bank:bankname,
                                      counter_bank:counterbankname
                                    });
                                    setView(true);	
                                    setTrxHistory(transaction.txn_history);	
                                  }}	>
                                  <i className="fa fa-eye"> </i>
                              </button>
                               
                                {/* {transaction.txn_status === 'COMPLETED' && transaction.txnr_type!=='equityinvestment' && (	 */}
                                {transaction.txn_status === 'COMPLETED' &&  
                                ((transaction.mode_of_payment !== 'Cheque' && 
                                  transaction.instrument_type !== 'Cheque') || 
                                  (transaction.mode_of_payment === 'Online') || 
                                  (transaction.mode_of_payment === 'RTGS') ) &&
                                (	
                                  <>	
                                    <ReactTooltip	
                                      textColor="white"	
                                      backgroundColor="black"	
                                      effect="float"	
                                    />	
                                    <BsFileRichtext	
                                      data-tip={'Print Letter'}	
                                      onClick={() => { 
                                        let txnData=transaction;	
                                        let bankname=bankName(transaction.bank.toString())	
                                        let counterbankname=bankName(transaction.counter_bank.toString())	
                                        getLetterTemplateCall(transaction.bank);
                                        setData({
                                          ...txnData,
                                          bank:bankname,
                                          counter_bank:counterbankname
                                        });

                                        let securities = JSON.parse(transaction.securities);  
                                        setSecurityData(securities); 
                                        
                                        setMMType(securities.filter(u => u.type === 'Maturity' || u.type === 'Buy' || u.type === 'Sell' || u.type === 'Coupon').map(e=>e.type)[0]);

                                        setTransType(transaction.txnr_type); 
                                        setTransMode(transaction.mode_of_payment);
                                        setPaymentType(transaction.payment_type); 
                                        // Net Amount
                                        transaction.net_amount=transaction.net_amount.replace(",","");
                                        let amount= transaction.net_amount.split('.'); 
                                        setAmtWords(inWords(amount[0])); 
                                        setTransNetAmount(numberWithCommas(transaction.net_amount));

                                        // Gross Amount
                                        transaction.gross_amount=transaction.gross_amount.replace(",","");
                                        let grossAmount= transaction.gross_amount.split('.'); 
                                        setGrossAmtWords(inWords(grossAmount[0]));   
                                        setTransGrossAmount(numberWithCommas(transaction.gross_amount));

                                        setDisplayLetterCheck(true);  
                                        setLetterTempates(false);
                                        setResetPaymentType(false)
                                      }}	
                                    /> 	

                                  <ReactTooltip
                                      textColor="white"
                                      backgroundColor="black"
                                      effect="float"
                                    />
                                    <RiMailSendFill
                                      className={'ml-1'}
                                      data-tip={'Send Mail'}
                                      onClick={() => {
                                        setTxnData(JSON.stringify(transaction));
                                        setFund_account(
                                          transaction.fund_account
                                        );
                                        setMailModelBoolean(true);
                                      }}
                                    />
                                  </>	
                                )}	

                              </div>	
                            </td>	
                          </tr>	
                        );	
                      })}	
                    </tbody>	
                  </table>	
                ) : (	
                  <div className="table-empty">	
                    <BsTable className="icon" />	
                    <p className="text-center empty-text">No record found</p>	
                  </div>	
                )}	
                <div	
                  className="row pointer mr-3"	
                  style={{	
                    float: 'right',	
                  }}	
                >	
                  {/* PREVIOUS BUTTON */}	
                  {activePage !== paginationArr[0] ? (	
                    <i	
                      className="fa fa-arrow-left"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {	
                          setActivePage(paginationArr[index - 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                  {/* NEXT BUTTON */}	
                  {activePage !== paginationArr[paginationArr.length - 1] ? (	
                    <i	
                      className="fa ml-3 fa-arrow-right"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {	
                          setActivePage(paginationArr[index + 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                </div>	
              </div>	
            </div>	
          </div>	
        ) : (	
          <div className="">	
            	
            <h4>		
                  <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {		
                    setView(false);		
                  }}></i>		
                  View Transaction   
 
                </h4>		
            <ViewTransaction data={data} />	
            {/* <div className="form-holder">	
                {data ? <Row>{renderData()}</Row> : ''}	
              </div> */}	

            <div className="line"></div>	  
            
            <Row>	
              {trx_history.map((hist: any, index: number) => {	
                return (	
                  <Col	
                    md="3"	
                    onClick={() => {	
                      if (hist.action === 'REJECTED-TRUSTEE' || hist.action === 'REJECTED-AMC') {	
                        setNote(hist.note);	
                      } else {	
                        setNote('');	
                      }	
                      if (hist.action === 'CREATED') {	
                        return;	
                      }	
                      if (!hist.check_list) return;	
                      setTrxCheckList(hist.check_list);	
                      setViewCheckList(true);	
                    }}	
                  > 	
                    <div className="card process-card pointer">	
                      <div className="card-header">	
                      <h4 className="card-title">	
                          <span className="card-number">
                          {index + 1}
                          </span>
                           <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                          </h4>	
                      </div> 	
                      <div className="card-body text-center">	 
                            
                          <p className="small mt-2">Status:</p>	
                          <h3 className="accepted text-primary">  {hist.action}    </h3>	 
                          {hist.updated_at !== '' ? (	
                            <>	
                              <p className="small mt-2"> by</p>	
                              <h4 className="comment text-primary">{(hist.updated_by)}</h4>	 
                              <p className="small mt-2">at</p>	
                              <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>	 
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {hist.note !== '' ? (	
                            <>	
                               <p className="small mt-2">Comment</p>	
                            <small className="comment dot-text" title={hist.note}>{hist.note}</small>	
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {	
                            hist.created_by !== '' ? (	
                              <>	
                                <p className="small mt-2"> by</p>	
                                <h4 className="text-primary">{hist.created_by}</h4>
                                <p className="small mt-2"> at</p>	
                                <h4 className="comment text-primary">  {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")}</h4>	
                                	
                              </>	
                            ) : (	
                                ''	
                              )}	
                        </div>	
                    </div>	
                  </Col>	
                );	
              })}	
            </Row>	
          </div>	
        )}	
        {renderSecuritiesModalPopup()}	
        {renderTaxPopup()}
        {renderModalForLetterPopup()}	
        {viewCheckListPopup()}	
        {renderMailPopup()}
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default PendingTransactions;	


