import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { getAllUnitHolders } from '../../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BsTable } from 'react-icons/bs';

const csv = require('csvtojson/v2');

const ClientPortfolio = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);

  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getUnitHolders = async () => {
      setLoading(true);
      try {
        const response = await getAllUnitHolders(email);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.name}-${val.folio_no}`,
            value: val.folio_no,
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });
        setData(temp);
      } catch (err:any) {
        // toast.error(error.response.data.message[0]);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getUnitHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderData = () => {
    return selected.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td   >
            {items.fund_code ? items.fund_code : 'N/A'}
          </td>
          <td> {items.fund_code ? items.fund_code : 'N/A'}</td>
          <td>
            {parseFloat(items.fund_unit).toFixed(4)}
          </td>
          {/* <td   >{parseFloat(items.nav).toFixed(4)}</td> */}
          <td>{(+items.fund_unit * +items.nav).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const [selected, setSelected] = useState<any>([]);
  const [folio_no, setFolio_no] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [totalUnits, setUnits] = useState('');

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="d-flex align-items-center"></div>
          {!loading ? (
            <div className="">
              <div className="card-header">
                <h4 className="card-title">
                Unit Holder Portfolio
                </h4>
              </div> 
              <div className="card-body">
              <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  {/* <div>Folio Number</div> */}
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    label={'Select'}
                    isDisabled={false}
                    onChange={(e) => {
                      setUnitLoading(true);
                      let value = data.filter((item: any) => {
                        return item.folio_no === e.value;
                      });
                      setFolio_no(value[0].folio_no);
                      if (
                        value[0].balance_unit !== '' &&
                        value[0].balance_unit !== '[]' &&
                        value[0].balance_unit !== undefined
                      ) {
                        let unitBlance = JSON.parse(value[0].balance_unit);
                        let unit = 0;
                        let amount = 0;
                        unitBlance.map((item, index) => {
                          unit = unit + parseFloat(item.fund_unit);
                          amount =
                            amount +
                            parseFloat(item.fund_unit) * parseFloat(item.nav);
                        });
                        setTotalAmount(amount.toFixed(2));
                        setUnits(unit.toFixed(2));
                        setSelected(unitBlance);
                      } else {
                        selected.length = 0;
                        setSelected(selected);
                      }
                      setUnitLoading(false);
                      // selected()
                    }}
                    value={data.filter((option) => option.value === folio_no)}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={data}
                  />
                </div>
                </div>
              </div>
              <div className="">
                {selected.length > 0 ? (
                  <table className="table mt-3">
                    <thead>
                      <tr>
                        <th   >Fund </th>
                        <th   ></th>
                        <th   >Units Hold</th>
                        {/* <th   >NAV</th> */}
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderData()}
                      <tr className="border-top">
                        <td   ><h3>Total</h3></td>
                        <td></td>
                        <td>{totalUnits}</td>
                        {/* <td></td> */}
                        <td >{totalAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : unitLoading ? (
                  ''
                ) : (
                  <div className="table-empty">
                    <BsTable className="icon" />
                    {folio_no === '' ? (
                      <p className="text-center empty-text">
                        Select Unit Holder
                      </p>
                    ) : (
                      <p className="text-center empty-text">Empty</p>
                    )}
                  </div>
                )}
              </div>
            </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center my-5">
             <i className="fa fa-spinner fa-spin fa-3x" ></i> 
            </div>
          )}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ClientPortfolio;
