import React, { useRef, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { Container, FormGroup, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import * as htmlToImage from 'html-to-image';  
import { useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import { $mobx } from 'mobx';
import html2canvas from 'html2canvas';

type Coordinates = {
  id: string;
  x: number;
  y: number;
  label: string;
  prefix: string;
  postFix: string;
};

const postionCoordinates: Coordinates[] = [
  {
    id: 'E1',
    x: 0,
    y: 0,
    label: 'Date',
    prefix: '',
    postFix: '',
  },
  {
    id: 'E2',
    x: 0,
    y: 30,
    label: 'Name',
    prefix: '',
    postFix: '',
  },
  {
    id: 'E3',
    x: 0,
    y: 50,
    label: 'Amount Fig',
    prefix: '=',
    postFix: '/-',
  },
  {
    id: 'E4',
    x: 0,
    y: 70,
    label: 'Amount Words',
    prefix: '',
    postFix: '',
  },
];

const PrintCheque = React.forwardRef((props: any, ref) => {
  const history = useHistory();
  let templateData = JSON.parse(sessionStorage.getItem('template') || '');
  const [headLabel, setHeadLabel] = React.useState(''); // label represent transaction name

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
  }, [props.match.params.type]);

  const [coordinates, setCoordinates] = useState(templateData);
  const email = sessionStorage.getItem('email') || '';
  const [mamoCheck, setMemoCheck] = useState<boolean>(true);
  const [payeeCheck, SetPayeeCheck] = useState<boolean>(true);

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const Prints = () => ( 

    <div className="a4-page" id={'dateElement'} ref={canvasRefs}>
      
      {coordinates &&
        coordinates.map((element, index) => {
          if (index < 4) {
            return (
              <div
                key={element.id}
                className="position-absolute rotate-parent cheque-index"
                id={element.id}                
                style={{
                  top:
                    element.rotate === 90
                      ? `${element.y }px`//+ 139 + 18
                      : `${element.y}px`,
                  left:
                    element.rotate === 90
                      ? `${element.x}px`//- 139 - 18
                      : `${element.x}px`,
                  fontSize: '12px',
                  width: `${element.width}`,
                  lineHeight:'1.2',
                  height:`${element.heigth}`,
                  // overflowWrap: 'break-word',
                  transform: `rotateZ(${element.rotate}deg)`,
                }}
              >
                {element.prefix}
               <span className="">{index==2 ? numberWithCommas(element.label) : element.label}</span>  {element.postFix}
              </div>
            );
          }
        })}
      {payeeCheck && (
        <div
          key={coordinates[4].id}
          className="position-absolute cross-cheque"
          id={coordinates[4].id}
          style={{
            top:
              coordinates[4].rotate === 60
                ? `${coordinates[4].y }px`//+ 43 + 10
                : `${coordinates[4].y}px`,
            left:
              coordinates[4].rotate === 60
                ? `${coordinates[4].x }px`//+ 43 + 10
                : `${coordinates[4].x}px`,
            fontSize: '12px',
            overflowWrap: 'break-word',
            width:`${coordinates[4].width}`,
            height:`${coordinates[4].height}`,
            transform: `rotateZ(${coordinates[4].rotate}deg)`,
          }}
        >
          {coordinates[4].prefix}
          {coordinates[4].label} {coordinates[4].postFix}
        </div>
      )}
      {mamoCheck && (
        <div
          key={coordinates[5].id}
          className="position-absolute"
          id={coordinates[5].id}
          style={{
            top:
              coordinates[5].rotate === 90
                ? `${coordinates[5].y}px`// + 139 + 18
                : `${coordinates[5].y}px`,
            left:
              coordinates[5].rotate === 90
                ? `${coordinates[5].x}px`//- 139 - 18
                : `${coordinates[5].x}px`,
            fontSize: '12px',
            overflowWrap: 'break-word',
            width:`${coordinates[5].width}`,
            height:`${coordinates[5].height}`,
            transform: `rotateZ(${coordinates[5].rotate}deg)`,
          }}
        >
          {coordinates[5].prefix}
          {coordinates[5].label} {coordinates[5].postFix}
        </div>
      )}
    </div>
 
  );

  const canvasRefs = useRef(null);

  // const printContent = () => {
  //   var node = document.getElementById('dateElement') as HTMLElement;
  //   var Options = {
  //     width: 480,
  //     height: 642,
  //   };
  //   htmlToImage
  //     .toPng(node)
  //     .then(function (dataUrl) {
  //       // var img = new Image(680, 842);
  //       //img.src = dataUrl;
  //       var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
  //       // var doc = new jsPDF('l', 'pt', 'letter'); // make letter size Pdf file
  //       const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
  //       const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
  //       // doc.addImage(dataUrl, 'PNG', 0, 0, 446,630,"che", 'NONE' ); // add image to Pdf
  //       doc.addImage(dataUrl, 'PNG', 0, 0, 446,630,"che", 'NONE' ); // add image to Pdf
  //       // doc.html(dataUrl)
  //       // doc.save('Cheque.pdf'); // Save the cheque
  //       var data= JSON.parse(sessionStorage.getItem('cheque') || '');
  //       doc.save(`Cheque ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`); // Save the cheque
  //     })
  //     .catch(function (error) {
  //       console.error('oops, something went wrong!', error);
  //     });
      
  // };

  const printContent = () => {
    var node = document.getElementById('dateElement') as HTMLElement;

     html2canvas(node)
    .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'px', 'a4');
        pdf.addImage(imgData, 'PNG',0,0,0,0,"SRK","NONE");
        var data= JSON.parse(sessionStorage.getItem('cheque') || '');
        if(data.txn_id===undefined){
          pdf.save(`Cheque ${data.collective_payment_id} ${data.amc_code} ${data.created_at}.pdf`); // Save the cheque
        }else{
          pdf.save(`Cheque ${data.txn_id} ${data.amc_code} ${data.transaction_date}.pdf`); // Save the cheque
        }
        
    })
      
  };

  // const printContent = () => { 
  //   var doc = new jsPDF('p', 'pt', 'a4'); 

  //  doc.html(document.getElementById('dateElement') as HTMLElement, {
  //     callback: function (doc) {
        
  //       let data= JSON.parse(sessionStorage.getItem('cheque') || '');
  //       doc.save(`Letter ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`);
  //     },
  //     html2canvas: {
  //       scale: 0.8
  //       // width: 500
  //     },
  //     margin: [20, 20, 20, 20],
  //     x: 32,
  //     y: 32,
  //   });
  // } 
  
  const [nameTab, setNameTab] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);

  return (
    <>
        
      <Container fluid className="pl-1 pr-0">
        
        <ToastContainer limit={1} />
        <div className="no-gutters">
          <Prints />
          <div className="row ml-1 mt-2 mb-2">
            <div className="">
              <button
                className="btn btn-default "
                onClick={() => {
                  if (headLabel === 'list') {
                    history.replace('/admin/transaction-listing');
                  } else {
                    history.replace('/admin/transaction-status');
                  }
                }}
              >
                Back
              </button>
            </div>
            <div className=" ">
              <button
                className="btn btn-primary ml-2"
                onClick={() => {
                  printContent();
                }}
              >
                Print
              </button>
            </div>
            <div className="ml-5">
            <div className="row">
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" checked={mamoCheck}
                    className="mr-3"
                    onChange={(e) => {
                    setMemoCheck(!mamoCheck);
                    }} />
                    <span className="form-check-sign" />
                    Memo
                </Label>
            </FormGroup>
 

            <FormGroup check className="ml-2">
                <Label check>
                    <Input type="checkbox"  checked={payeeCheck}
                    onChange={(e) => {
                    SetPayeeCheck(!payeeCheck);
                    }} />
                    <span className="form-check-sign" />
                  Cross Check
                </Label>
            </FormGroup>


            </div>
          </div>
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <Header />
        </div>
      </Container>
    </>
  );
});

export default PrintCheque;
