import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from "react-router-dom";
import { getAllRoles, updateRoleStatus } from "../../stores/services/role.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const RoleManage = () => {
  const history = useHistory();
  const [roles, setRoles] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  React.useEffect(() => {
    const getUserList = async () => {
      setLoading(true);
      try {
        const response = await getAllRoles(email);
        setRoles(response.data.data);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRoleListUpdated = async () => {
    setLoading(true);
    try {
      const response = await getAllRoles(email);
      setRoles(response.data.data)
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (roles) {
      let i;
      res.length = 0;
      setRes(res)
      for (i = 0; i < roles.length; i++) {
        res.push(roles[i]);
        setRes(res)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < roles.length; i++) {
      td = roles[i].role_name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(roles[i]);
          setRes(res)
        } else {

        }
      }
    }
  }
  const email = sessionStorage.getItem('email') || '';
  const updateStatus = async () => {
    setbankLoading(true);
    let status = '';
    if (data.status === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      await updateRoleStatus(email, data.role_name, data.description, data.features, status)
      toast.success('Status Updated Successfully');
      setStatusSetPopup(false);
      getRoleListUpdated();
    } catch (error) { }
    setbankLoading(false);
  }
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [bankLoading, setbankLoading] = useState(false);
  const renderUpdateStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
          >
                <div className="modal-header justify-content-center">
                            <button
                              aria-hidden={true}
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => { setStatusSetPopup(false) }}
                            >
                              <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up">Action</h6>
                          </div>

            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {data.status === 'active'  ? ( <img src="assets/ban.svg" alt="" width="70" /> ) : (<img src="assets/check.svg" alt="" width="70" />  )}
                    <h1 className="pb-3">Are You Sure?</h1>
                        Are you sure you want to {data.status === 'active' ? 'Deactivate' : 'Activate'} this Role?
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col >
                    <div >
                      <button className="btn btn-primary" disabled={Boolean(bankLoading)} onClick={() => { updateStatus(); }}>
                        {bankLoading ? ( <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="login-txt"> Loading...</span></>  ) : ( 'Yes' )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button className="btn btn-default" onClick={() => { setStatusSetPopup(false) }}>
                        Cancel
                    </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal >
        );
      default:
        return '';
    }
  };
  const renderData = () => {
    return res.map((role: any, index: any) => {
      return (
        <tr key={index}>
          <td>{role.role_name}</td>
          <td>{role.description}</td>
          <td className="captilize">{role.status}</td>
          <td>
            <div className="multi-icons pointer">

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"
                 onClick={() => {
                  sessionStorage.setItem('roleObj', JSON.stringify(role));
                  sessionStorage.setItem('rolePageType', 'view');
                  history.replace('/admin/edit-role');
                }}
                >
                    <i className="fas fa-eye"></i> 
                </button>  
              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  sessionStorage.setItem('roleObj', JSON.stringify(role));
                  sessionStorage.setItem('rolePageType', 'edit');
                  history.replace('/admin/edit-role');
                }
                }>
              <i className="tim-icons icon-pencil"></i></button>


              {role.status === 'active' ? (
                  <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                      onClick={() => {
                        setStatusSetPopup(true);
                        setData(role);
                      }}>
                  <i className="tim-icons icon-simple-remove"></i>
               </button>
                  
                  ) : (

                  <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setData(role);
                  }} >
                    <i className="fas fa-check"></i> 
              </button>
                   
                    
                   )}
            </div>
          </td>
        </tr>
      );
    })
  }
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="card-header">
          <h4 className="card-title">Role Management</h4>
          </div>
          {!Loading  ? (
            <div className="card-body">
              <div className="input-holder">
                <div className="form-group">
                  <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                  <input type="search" id="myInput" className="form-control" data-tip="Search Role" placeholder="Search Name" value={searchValue} onChange={(e) => {
                    setSearchValue(e.target.value);
                    myFunction(e);
                  }} />
                </div> 
                <button className="btn btn-primary btn-sm ml-4" onClick={
                  () => {
                    history.replace("/admin/add-role");
                  }
                }>
                  <i className="fa fa-plus mr-2"></i> Add New
                </button>
              </div>
              <div className="">
              <table className="table ">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>status</th>
                    <th >Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    renderData()
                  }
                </tbody>
              </table>

            </div>
            </div>
           ) : (
            <div className="d-flex justify-content-center">
              <div className="fa fa-spinner fa-spin fa-3x mt-5 mb-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div> )} 
          {renderUpdateStatusPopup()}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default RoleManage;