import React from 'react';
import { useState, useRef } from 'react';
import { addFund, getFundType } from '../../../stores/services/funds.service';
import { getAmc } from '../../../stores/services/amc.service';
import { Container, Row, Col,FormGroup,Label,Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { BsPlusCircleFill } from 'react-icons/bs';
import {
  getAllSecurities,
  getAllPsx,
} from '../../../stores/services/security.service';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/customFunction';
import {JsonToTable} from 'react-json-to-table';
import { json } from 'is';
import xls from "xlsx";
const csv = require('csvtojson/v2');
//const xlsx = require('xlsx-to-json');

const SetupFunds = () => {
  //hooks for getting all inputs from user
  const [fundName, setFundName] = useState('');
  const [code, setCode] = useState('');
  const [amcName, setAmcName] = useState('');
  const [fundType, setFundType] = useState('');
  let date = new Date();
  const [incorporationDate, setIncorporationDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [revocationDate, setRevocationDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [psxListing, setPsxListing] = useState('true');
  const [nav, setNav] = useState('');
  const [faceValue, setFaceValue] = useState('');

  const [cdc_participant_id, setCDCParticipantID] = useState('');
  const [cdc_account_number, setCDCAccountNumber] = useState('');

  const email = sessionStorage.getItem('email') || '';
  //error getting hooks
  const [namelError, setNameError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [amcNameError, setAmcError] = useState('');
  const [fundTypeError, setFundTypeError] = useState('');
  const [incorporationDateError, setIncorporationDateError] = useState('');
  const [psxListingError, setPsxListingError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [navError, setNavError] = useState('');
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [fundTpedata, setFundTpedata] = useState<any>([]);
  const [isCheckBoxSelected, setCheckBoxSelected] = useState(true);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [psxList, setPsxList] = useState<any>([]); // PSX securities Hook
  const [stockModal, setStockModal] = useState(false);
  const [termDepositModal, setTermDepositModal] = useState(false);
  const myRef1 = useRef<HTMLInputElement>(null);
  const myRef2 = useRef<HTMLInputElement>(null);
  const myRef3 = useRef<HTMLInputElement>(null);
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [fileName2, setFileName2] = useState('');
  const [fileError2, setFileError2] = useState('');
  const [fileName3, setFileName3] = useState('');
  const [fileError3, setFileError3] = useState('');

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);

  function myTrim(x) {
    return x.replace(/^\s+|\s+$/gm, '');
  }
  const fundCodeRegex = new RegExp('^[A-Z]{1,5}[A-Z]{1,5}$');

  const validate = () => {
    let nameError,
      codeError,
      amcErr,
      typeErr,
      inDateerr,
      psxErr,
      navErr = '';
    fundName.trim() === '' ? (nameError = 'Required') : (nameError = '');
    code.trim() === ''
      ? (codeError = 'Required')
      : fundCodeRegex.test(code) !== true
      ? (codeError = 'Invalid')
      : (codeError = '');
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');
    fundType.trim() === '' ? (typeErr = 'Required') : (typeErr = '');
    // incorporationDate.trim() === '' ? inDateerr = "Required" :
    //   inDateerr = "";
    psxListing.trim() === '' ? (psxErr = 'Required') : (psxErr = '');
    nav.trim() === '' ? (navErr = 'Required') : (navErr = '');
    if (
      nameError ||
      codeError ||
      amcErr ||
      typeErr ||
      inDateerr ||
      psxErr ||
      navErr
    ) {
      setNameError(nameError);
      setCodeError(codeError);
      setAmcError(amcErr);
      setNavError(navErr);
      setFundTypeError(typeErr);
      setIncorporationDateError(inDateerr);
      setPsxListingError(psxErr);
      return false;
    } else {
      return true;
    }
  };
  const AddFunds = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await addFund(
          email,
          amcName,
          fundType,
          incorporationDate,
          myTrim(fundName),
          code,
          revocationDate,
          psxListing,
          nav,
          JSON.stringify(moneyMarket),
          JSON.stringify(stockMarket),
          faceValue, 
          JSON.stringify(termDeposit),
          cdc_participant_id,
          cdc_account_number
        );
        setCheckBoxSelected(true);
        setFundName('');
        setCode('');
        stockMarket.length = 0;
        setStockMarket(stockMarket);
        moneyMarket.length = 0;
        setMoneyMarket(moneyMarket);
        termDeposit.length = 0;
        setTermDeposit(termDeposit);
        setNav('');
        setAmcName('');
        setFundType('');
        setIncorporationDate('');
        setRevocationDate('');
        setPsxListing('true');
        setFaceValue('');
        setCDCParticipantID('');
        setCDCAccountNumber('');
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };

  const changeCodeValue = (value: any) => {
    setCode(value);
    if (value.trim() === '') {
      setCodeError('Required');
    } else {
      fundCodeRegex.test(value) !== true
        ? setCodeError('Invalid')
        : setCodeError('');
    }
  };

  React.useEffect(() => {
    const fetchAmc = async () => {
      try {
        const amcResponse = await getAmc(email);
        setAmcdata(amcResponse.data.data);
      } catch (error) {}
      try {
        const response = await getFundType(email);
        setFundTpedata(response.data.data);
      } catch (error) {}
      try {
        const motResponse = await getAllSecurities(email);
        setSecurityTypeData(motResponse.data.data);
      } catch (error) {}
      try {
        const response = await getAllPsx(email);
        setPsxList(response.data.data);
      } catch (error) {}

      // get all banks
      try {
        const bankResponse = await getAllBanks(email);
        // temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(bankResponse.data.data);
      } catch (error) {}

    };
    fetchAmc();
  }, []);

  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setBranchData(response.data.data);
    }catch(error){}

  };
  
  const renderBankDropdown = () => {
    return bankNameData.map((item: any) => {
      return (
        <option key={item.bank_code} value={item.bank_code}>
          {item.bank_name} ({item.bank_code})
        </option>
      );
    });
  };

  const renderBranchDropdown = () => {
    return branchData.map((item: any) => {
      return (
        <option key={item.branch_code} value={item.branch_name}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };

  const renderAmcDropdown = () => {
    return amcdata.map((item: any) => {
      return (
        <option key={item.amc_code} value={item.amc_code}>
          {item.name} ({item.amc_code})
        </option>
      );
    });
  };
  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code}>
          {item.name} ({item.code}) 
        </option>
      );
    });
  };

  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.symbol}
          {/* {item.fund_name} ({item.symbol_code}) */}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);

const renderFilterSecurityDataDropdown = (security_type:string) => {
  const check= securityTypeData.filter((item: any, index: string)=>{
   return (item.security_type===security_type);
  });
  setSecurityData(check);
};

const renderSecuritiesDropDown=()=>{
  return securityData.map((item: any, index: number) => {
    return (
      <option key={index} value={item.code}>
        {item.name}
      </option>
    );
  });
}

  const [modalView, setModalView] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [moneyMarket, setMoneyMarket] = useState<any>([]);
  const [moneyMarketEntity, setMoneyMarketEntity] = useState<any>({
    code: '',
    total_amount: '0',
    // name: '',
  });
  // ADD TAX Entity inside tax Array
  const addMoneyMarket = async () => {
    setDisableAddBtn(true);
    let count = 0;
    //push data inside array
    for (let i in moneyMarketEntity) {
      if (moneyMarketEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      setDisableAddBtn(false);
    } else {
      setModalView(false);
      // const check = moneyMarket.filter((item) => {
      //   // return item.code === moneyMarketEntity['code'];
      //   return item.code === moneyMarketEntity['code'];
      // });
      // if (check.length > 0) {
      //   moneyMarket.map((item, index) => {
      //     if (item.security_type === moneyMarketEntity['security_type'] && item.issue_date === moneyMarketEntity['issue_date'] && item.maturity_date === moneyMarketEntity['maturity_date'] ) {
      //       item.total_amount = (+item.total_amount + +moneyMarketEntity['total_amount']).toString();
      //       item.units = (+item.units + +moneyMarketEntity['units']).toString();
      //     }
      //   });
      // } else {
        moneyMarket.push(moneyMarketEntity);
        setMoneyMarket(moneyMarket);
      // }
      const filedEntirty = {
        code: '',
        total_amount: '0',
        // name: '',
      };
      setMoneyMarketEntity(filedEntirty);
      setDisableAddBtn(false);
    }
  };

  //render Fund table data
  const renderFundDataInTable = () => {
    return moneyMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td> 
          <td>{items.maturity_date}</td> 
          <td>{numberWithCommas(items.coupon_rate || '')}</td> 
          <td>{numberWithCommas(items.yield || '')}</td> 
          <td>{numberWithCommas(items.face_value || '')}</td>
          <td>{numberWithCommas(items.price || '')}</td> 
          <td>{numberWithCommas(items.units || '')}</td> 
          <td> {numberWithCommas(items.total_amount || '') && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td> 
          <td>{items.tenor}</td> 
          {/* <td>{items.calculatedAmount}</td> */}
          <td   >
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...moneyMarket];
                array.splice(index, 1);
                setMoneyMarket(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

// Uploaders
const uploadMoneyMarketPortfolio = async (e: any) => {
  setFileError('');
  let file = e?.target.files[0];
  let type = file.name.substring(file.name.lastIndexOf('.') + 1);
  if (type === 'csv') {
    //code here
    setFile(file);
    setFileName(file.name);
    //start
    let jsonarray = [];
    csv()
      .fromFile(file.path)
      .then((jsonObj) => {
        jsonarray = jsonObj;
        jsonarray.forEach((element) => {});
        setMoneyMarket(jsonarray)
      });

    //end
  }else if(type==="xlsx"){
    setFile(file);
    setFileName(file.name);
    //start
  //   let jsonarray = [];
  //   xlsx(
  //     {
  //         input: file.path.toString(), 
  //         output: "",//file.path.replace(".xlsx",".txt"),
  //         // sheet: "Sheet1", // specific sheetname
  //         rowsToSkip: 0, // number of rows to skip at the top of the sheet; defaults to 0
  //         allowEmptyKey: false, // avoids empty keys in the output, example: {"": "something"}; default: true
  //     },
  //     function (err, result) {
  //     if (err) {
  //      console.error(err);
  //      } else {
  //       jsonarray = result;
  //       jsonarray.forEach((element) => {});
  //       setMoneyMarket(jsonarray);
  //       }
  //     }
  // );

  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target?.result;
    const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = xls.utils.sheet_to_json(ws, { header: 2 });
    /* Update state */
  
   if(data){
    setMoneyMarket(data);
    //setFileDataCount(data.length)
   }
  
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);

  } else {
    toast.error('Please Upload Correct Format of File');
  }
};

const uploadStockMarketPortfolio = async (e: any) => {
  setFileError2('');
  let file = e?.target.files[0];
  let type = file.name.substring(file.name.lastIndexOf('.') + 1);
  if (type === 'csv') {
    //code here
    setFile(file);
    setFileName2(file.name);
    //start
    let jsonarray = [];
    csv()
      .fromFile(file.path)
      .then((jsonObj) => {
        jsonarray = jsonObj;
        jsonarray.forEach((element) => {});
        setStockMarket(jsonarray)
      });

    //end
  }else if(type==="xlsx"){
    setFile(file);
    setFileName2(file.name);
    //start
  //   let jsonarray = [];
  //   xlsx(
  //     {
  //         input: file.path.toString(), 
  //         output: "",//file.path.replace(".xlsx",".txt"),
  //         // sheet: "Sheet1", // specific sheetname
  //         rowsToSkip: 0, // number of rows to skip at the top of the sheet; defaults to 0
  //         allowEmptyKey: false, // avoids empty keys in the output, example: {"": "something"}; default: true
  //     },
  //     function (err, result) {
  //     if (err) {
  //      console.error(err);
  //      } else {
  //       jsonarray = result;
  //       jsonarray.forEach((element) => {});
  //       setStockMarket(jsonarray); 
  //       }
  //     }
  // );
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target?.result;
    const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = xls.utils.sheet_to_json(ws, { header: 2 });
    /* Update state */
  
   if(data){
    setStockMarket(data);
    //setFileDataCount(data.length)
   }
  
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);

  } else {
    toast.error('Please Upload Correct Format of File');
  }
};

const uploadTermDepositPortfolio = async (e: any) => {
  setFileError2('');
  let file = e?.target.files[0];
  let type = file.name.substring(file.name.lastIndexOf('.') + 1);
  if (type === 'csv') {
    //code here
    setFile(file);
    setFileName3(file.name);
    //start
    let jsonarray = [];
    csv()
      .fromFile(file.path)
      .then((jsonObj) => {
        jsonarray = jsonObj;
        jsonarray.forEach((element) => {});
        setTermDeposit(jsonarray)
      });

    //end
  }else if(type==="xlsx"){
    setFile(file);
    setFileName3(file.name);
    //start
  //   let jsonarray = [];
  //   xlsx(
  //     {
  //         input: file.path.toString(), 
  //         output: "",//file.path.replace(".xlsx",".txt"),
  //         // sheet: "Sheet1", // specific sheetname
  //         rowsToSkip: 0, // number of rows to skip at the top of the sheet; defaults to 0
  //         allowEmptyKey: false, // avoids empty keys in the output, example: {"": "something"}; default: true
  //     },
  //     function (err, result) {
  //     if (err) {
  //      console.error(err);
  //      } else {
  //       jsonarray = result;
  //       jsonarray.forEach((element) => {});
  //       setTermDeposit(jsonarray);
  //       }
  //     }
  // );
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target?.result;
    const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = xls.utils.sheet_to_json(ws, { header: 2 });
    /* Update state */
  
   if(data){
    setTermDeposit(data);
    //setFileDataCount(data.length)
   }
  
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);

  } else {
    toast.error('Please Upload Correct Format of File');
  }
};

  const [codeChange, setCodeChange] = useState('');

  // render Model for adding Fund
  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setModalView(false);
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> {stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
             </div>    

            <div className="modal-body">
              <Row>
                <Col>
                <div className="form-group">
                    <div>Select Security Type</div>
                <select 
                      className='form-control'
                      value={moneyMarketEntity['security_type']}
                      onChange={(e) => {
                        renderFilterSecurityDataDropdown(e.target.value)
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          security_type:e.target.value
                        });
                       
                    }}>
                       <option value="" defaultChecked hidden>
                              {' '}
                        Select Security</option>
                      <optgroup>Money Market</optgroup>
                      <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasuary bill</option>

                      <optgroup>Debt Market</optgroup>
                      <option value='SUKUK'>Sukuk Certificates</option>
                      <option value='TFC'>Term Finance Certificates</option>
                      <option value='CP'>Commercial Papers</option>

                      <optgroup>Term Deposits</optgroup>
                      <option value='TDR'>Term Deposit Receipts</option>
                      <option value='CDR'>Call Deposit Receipts</option>
                      <option value='LOP'>Letter of Placement</option>
                      </select>
                    </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <div>Select Security</div>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />

                    <select
                      className="form-control"
                      value={moneyMarketEntity['code']}
                      onChange={(e) => {
                        // let value = securityTypeData.filter((item: any) => {
                        //   return item.code === e.target.value;
                        // });
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          // code: e.target.value,
                          code:e.target.value,
                          name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text,
                        });
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Security
                      </option>
                      {/* {renderSecurityDataDropdown()} */}
                      {renderSecuritiesDropDown()}
                    </select>
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="form-group">
                    <div>Symbol</div>
                    <input
                      type="text"
                      className="form-control w-100"
                      value={moneyMarketEntity['code']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          code: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col> */}
                
               
              </Row>
              {/* <Row>
              <Col md="6">
                  <div className="form-group">
                      <div>Name</div>
                      <input
                        type="text"
                        className="form-control w-100"
                        value={moneyMarketEntity['name']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                </Col>
              
              </Row> */}
              <Row>
              <Col md="4">
                  <div className="form-group">
                      <div>Issue Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity['issue_date']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            issue_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                </Col>
              <Col md="4">
                  <div className="form-group">
                      <div>Maturity Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity['maturity_date']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            maturity_date: e.target.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                <Col md="4">
                   <div className="form-group">
                      <div>Coupon Rate</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity['coupon_rate']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            coupon_rate: e.target.value,
                          });
                        }}
                      />
                    </div>
                </Col>

              </Row>
              <Row>
                
              <Col md="6">
                  <div className="form-group">
                      <div>Yield</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity['yield']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            yield: e.target.value,
                          });
                        }}
                      />
                    </div>

                </Col>
              <Col md="6">
                  <div className="form-group">
                      <div>Face Value</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity['face_value']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            face_value: e.target.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                
              </Row>

              <Row>
              <Col md="6">
                  <div className="form-group">
                      <div>Price</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity['price']}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            price: value,
                          });
                        }}
                      />
                    </div>
                  
                </Col>
              <Col md="6">
                  <div className="form-group">
                    <div>Units</div>
                    <input
                      type="number"
                      className="form-control w-100"
                      value={moneyMarketEntity['units']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          units: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                </Row>
                <Row>
              <Col md="6">
                  <div className="form-group">
                    <div>Settlement Amount</div>
                    <input
                      type="number"
                      className="form-control w-100"
                      value={moneyMarketEntity['total_amount']}
                      onChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          total_amount: e.target.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">

                  <div className="form-group">
                      <div>Tenor</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity['tenor']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            tenor: e.target.value,
                          });
                        }}
                      />
                    </div>
                  
                </Col> 
                </Row>
              
              <div className="row"> 
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      addMoneyMarket();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  //for stock Market

  const [equityModalFlag, setEquityModalFlag] = useState(false);
  const [disableEquityBtn, setDisableEquityBtn] = useState<boolean>(false);
  const [stockMarket, setStockMarket] = useState<any>([]);
  const [stockEntity, setStockEntity] = useState<any>({
    code: '',
    unit: '',
    amount: '0',
    close_rate: '0',
  });
  // ADD TAX Entity inside tax Array
  const addEquityStock = async () => {
    setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in stockEntity) {
      if (stockEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      setDisableEquityBtn(false);
    } else {
      setEquityModalFlag(false);
      const check = stockMarket.filter((item) => {
        return item.code === stockEntity['code'];
      });
      if (check.length > 0) {
        stockMarket.map((item, index) => {
          if (item.code === stockEntity['code']) {
            item.unit = (+item.unit + +stockEntity['unit']).toString();
            item.amount = (parseFloat(item.unit) * parseFloat(item.close_rate)).toFixed(2); 
          }
        });
      } else {
        stockMarket.push(stockEntity);
        setStockMarket(stockMarket);
      }
      const filedEntirty = {
        code: '',
        unit: '',
        amount: '0',
        close_rate: '0',
      };
      setStockEntity(filedEntirty);
      setDisableEquityBtn(false);
    }
  };

  //render equity table data
  const renderEquityInTable = () => {
    return stockMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>  {numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(2))}  </td>
          <td>  {numberWithCommas(items.unit || '') && numberWithCommas(items.unit || '')}  </td>
          <td> {numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}   </td>
          <td>  {items.market} </td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td   >
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...stockMarket];
                array.splice(index, 1);
                setStockMarket(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  // render Model for adding Equity
  const renderEquityPopup = () => {
    switch (equityModalFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setEquityModalFlag(false);
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setEquityModalFlag(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> {stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
             </div> 


            <div className="modal-body ">
              <div className="">
                <Row>
                  <Col md="6">
                    <div className="form-group">
                        Market*
                        <select
                          className="form-control w-100"
                          value={stockEntity.market}
                          onChange={(e) => {
                            setStockEntity({
                              ...stockEntity,
                              market: e.target.value
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>Select Market</option>
                          <option value="REG">Regular</option>
                          <option value="ODL">Odd Lot</option>
                          <option value="FUT">Future</option>
                          <option value="BNB">Bills and Bonds</option>
                          <option value="IPO">Initial Public Offering</option>
                          <option value="BKB">Book Building</option>
                          <option value="CSF">Cash Settled Futures</option>
                          <option value="DSF">Direct Settled Futures</option>
                          <option value="SIF">Stock Index Futures</option>
                          <option value="NDM">Negotiable Deal Market</option>
                          <option value="FRO">Future Rollover Contract</option>
                          <option value="MTS">Margin Trading System</option>
                          <option value="SQR">Square Up</option> 
                        </select>
                      </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group">
                      Select Security*
                      <select
                        className="form-control w-100"
                        value={stockEntity['code']}
                        onChange={(e) => {
                          let value = psxList.filter((item: any) => {
                            return item.symbol === e.target.value;
                          });
                          setStockEntity({
                            ...stockEntity,
                            code: e.target.value,
                            close_rate: value[0].close_rate,
                            amount: (
                              parseFloat(e.target.value) *
                              parseFloat(stockEntity.Unit)
                            ).toFixed(2),
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Security
                        </option>
                        {renderPsxDataDropdown()}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                      Average Rate
                      <input
                        type="number"
                        className="form-control w-100 disable-input"
                        value={stockEntity['close_rate']}
                        readOnly
                      />
                    </div>

                  </Col>
                  <Col md="6">
                  <div className="form-group">
                      Units*
                      <input
                        type="number"
                        className="form-control w-100"
                        value={stockEntity['unit']}
                        onChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            unit: e.target.value,
                            amount: (
                              parseFloat(e.target.value) *
                              parseFloat(stockEntity.close_rate)
                            ).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                      Amount
                      <input
                        type="number"
                        className="form-control w-100 disable-input"
                        value={stockEntity['amount']}
                        readOnly
                      />
                    </div> 
                  </Col> 
                </Row> 
               
               
                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addEquityStock();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [termDepositEntity, setTermDepositEntity] = useState<any>({
    code:'',
    name:'',
    principal_amount:'',
    interest_rate: '',
    from_date: '',
    to_date: '',
    bank:'',
    branch:'',
  });
  const addTermDeposit = async () => {
    // setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in termDepositEntity) {
      if (termDepositEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      // setDisableEquityBtn(false);
    } else {
      setTermDepositModal(false);
      // const check = termDeposit.filter((item) => {
      //   return item.interest_rate === termDepositEntity['interest_rate'];
      // });
      // if (check.length > 0) {
      //   termDeposit.map((item, index) => {
      //     if (item.code === termDepositEntity['code']) {
      //       item.interest_rate = (+item.interest_rate + +termDepositEntity['interest_rate']).toString();
      //     }
      //   });
      // } else {
        termDeposit.push(termDepositEntity);
        setTermDeposit(termDeposit);
      // }
      const filedEntirty = {
        code:'',
        name:'',
        principal_amount:'',
        interest_rate: '',
        from_date: '',
        to_date: '',
        bank:'',
        branch:'',
      };
      setTermDepositEntity(filedEntirty);
      // setDisableEquityBtn(false);
    }
  }

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount)}</td>
          <td>{numberWithCommas(items.interest_rate)}</td>
          <td>{items.from_date}</td>
          <td>{items.to_date}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...termDeposit];
                array.splice(index, 1);
                setTermDeposit(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTermDepositPopup = () => {
    switch (termDepositModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setTermDepositModal(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTermDepositModal(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Term Deposit</h6>
             </div> 


            <div className="modal-body ">
              <div className="">

              <Row>
                <Col>
                <div className="form-group">
                  Type
                  <select className='form-control w100'
                            onChange={(e)=>{
                              termDepositEntity['code']=e.target.value;
                              termDepositEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            >
                            <option value='' hidden>Select Type</option>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value='CDR'>Call Deposit Receipts</option>
                            <option value='LOP'>Letter of Placement</option>
                    </select>
                </div>
                </Col>
                  <Col>
                  <div className="form-group">
                     Principal Amount
                      <input
                        type="number"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            principal_amount: e.target.value
                          });
                        }}
                      />
                    </div> 
                  </Col> 
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                        Interest Rate
                        <input
                        type='number'
                        className='form-control w100'
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            interest_rate: e.target.value
                          });
                        }}
                        />
                      </div>
                  </Col>
                </Row>
            
                <Row>
                  <Col>
                  <div className="form-group">
                      From Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            from_date: e.target.value
                          });
                        }}
                      />
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div className="form-group">
                  To Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            to_date: e.target.value
                          });
                        }}
                      />
                    </div> 
                  </Col> 
                </Row> 
               
               <Row>
                 <Col>
                    <div className="form-group">
                      Bank
                      <select className="form-control w-100"
                      onChange={(e)=>{
                        setTermDepositEntity({
                          ...termDepositEntity,
                          bank: e.target.value
                        });
                        getCounterBranchByName(e.target.value);
                      }}>

                      <option value="" defaultChecked hidden>
                          {' '}
                          Select Bank
                        </option>
                        {renderBankDropdown()}
                      </select>
                    </div>
                     </Col>
                    <Col>
                      <div className="form-group">
                      Branch
                      <select className="form-control w-100"
                      onChange={(e)=>{
                        setTermDepositEntity({
                          ...termDepositEntity,
                          branch: e.target.value
                        });
                      }}>
                        
                      <option value="" defaultChecked hidden>
                          {' '}
                          Select Branch
                        </option>
                      {renderBranchDropdown()}
                      </select>
                    </div>
                 </Col>
               </Row>
               
                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addTermDeposit();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
    
<div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />

        <div className="input-holder">
          <h4 className="card-title">Setup - Funds</h4>
          <Link to="/admin/funds" className="btn btn-primary btn-sm" replace>
            <i className="fa fa-eye mr-2"></i> View All
          </Link>
        </div>

        <Row>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Fund Details
                </CardTitle>
              </CardHeader>
              <CardBody>
                  <div className="form-group  ">
                    <label>
                      AMC Name <span className="color-amber">*</span>
                    </label>
                    <div  >
                      <select
                        className={`form-control  w-100 ${
                          amcNameError ? 'required-border' : ''
                        }`}
                        value={amcName}
                        onChange={(e) => {
                          setAmcError('');
                          setAmcName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select An AMC
                        </option>
                        {renderAmcDropdown()}
                      </select>
                      {/* {amcNameError ? <p className="error-labels error-message2">{amcNameError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>
                      Fund Name <span className="color-amber">*</span>
                    </label>
                    <div  >
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          namelError ? 'required-border' : ''
                        }`}
                        value={fundName}
                        onChange={(e) => {
                          setFundName(
                            e.target.value.replace(/[^a-z^A-Z ]/, '')
                          );
                          setNameError('');
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            AddFunds();
                          }
                        }}
                      />
                      {/* {namelError ? <p className="error-labels error-message2">{namelError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>
                      Fund Code <span className="color-amber">*</span>
                    </label>
                    <div>
                      <div  >
                        <input
                          type="text"
                          className={`form-control  w-100 ${
                            codeError ? 'required-border' : ''
                          }`}
                          value={code}
                          onChange={(e) => {
                            // var cleaned = e.target.value.replace(/\d/, '');
                            // if (cleaned.length == 5 && !cleaned.includes('-')) {
                            //   // cleaned = cleaned + '-';
                            // } else if (cleaned.length > 10) {
                            //   cleaned = cleaned.substring(0, 10);
                            // }
                            // changeCodeValue(cleaned.toUpperCase());
                            let value = e.target.value.toUpperCase();
                            if((value.length<=12 && value.match(/^[A-Z-]+$/)) || value===''){
                              setCode(value);
                            }
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              AddFunds();
                            }
                          }}
                        />
                      </div>
                      <div className="d-flex">
                        {/* <p style={{ fontSize: '11px' }}>
                          Code Pattern (AAAAA-AAAAA)
                        </p> */}
                        {codeError ? (
                          <span
                            className="ml-auto"
                            style={{ fontSize: '11px' }}
                          >
                            {codeError}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>
                      Nature / Type of Fund{' '}
                      <span className="color-amber">*</span>
                    </label>
                    <div  >
                      <select
                        className={`form-control input-broker  w-100 ${
                          fundTypeError ? 'required-border' : ''
                        }`}
                        value={fundType}
                        onChange={(e) => {
                          setFundType(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select
                        </option>
                        <option value="Agressive Income Fund">
                          Agressive Income Fund
                        </option>
                        <option value="Asset Calculation Fund">
                          Asset Calculation Fund
                        </option>
                        <option value="Income Fund">Income Fund</option>
                        <option value="Money Market Fund">
                          Money Market Fund
                        </option>
                        <option value="Stock Fund">Stock Fund</option>
                        <option value="Private Fund Managment Co">
                          Private Fund Managment Co
                        </option>
                        <option value="Fund of Fund">Fund of Fund</option>
                        <option value="Special Managed Account">Special Managed Account (SMA)</option>
                      </select>
                      {/* {fundTypeError ? <p className="error-labels error-message">{fundTypeError}</p> : ''} */}
                    </div>
                  </div>
                   
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Incorporation
                </CardTitle>
              </CardHeader>
              <CardBody>
                  <div className="form-group  ">
                    <label>Date of Incorporation</label>
                    <div  >
                      <input
                        type="date"
                        className={`form-control  w-100 ${
                          incorporationDateError ? 'required-border' : ''
                        }`}
                        value={incorporationDate}
                        onChange={(e) => {
                          setIncorporationDate(e.target.value);
                          setIncorporationDateError('');
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            AddFunds();
                          }
                        }}
                      />
                      {/* {incorporationDateError ? <p className="error-labels error-message2">{incorporationDateError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Date of Revocation</label>
                    <div  >
                      <input
                        type="date"
                        className="form-control"
                        value={revocationDate}
                        onChange={(e) => {
                          setRevocationDate(e.target.value);
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            AddFunds();
                          }
                        }}
                      />
                    </div>
                  </div>


                 <Row>
                   <Col md="6">
                    <div className="form-group">
                      <label>
                        NAV <span className="color-amber">*</span>
                      </label>
                      <div  >
                        <input
                          type="number"
                          className={`form-control  w-100 ${
                            navError ? 'required-border' : ''
                          }`}
                          value={nav}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 4);
                            setNav(value);
                            setCodeChange(value);
                            setNavError('');
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              AddFunds();
                            }
                          }}
                        /> 
                      </div>
                    </div>  
                   </Col>
                   <Col md="6">
                     <div className="form-group">
                       <label htmlFor="">Face Value <span className="color-amber">*</span></label>
                       <input type="number" className="form-control"  
                       value={faceValue}
                        onChange={(e) => {
                          setFaceValue(e.target.value);
                        }}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            AddFunds();
                          }
                        }} />
                      </div>
                   </Col>
                 </Row>
                  
                 <Row>
                   <Col md="6">
                    <div className="form-group">
                      <label>
                        CDC Participant ID 
                      </label>
                      <div  >
                        <input
                          type="text"
                          className={`form-control  w-100 `}
                          value={cdc_participant_id}
                          onChange={(e) => {
                            setCDCParticipantID(e.target.value);
                          }}
                          
                        /> 
                      </div>
                    </div>  
                   </Col>
                   <Col md="6">
                     <div className="form-group">
                       <label htmlFor="">CDC Account Number </label>
                       <input type="text" className="form-control"  
                       value={cdc_account_number}
                        onChange={(e) => {
                          setCDCAccountNumber(e.target.value); 
                        }}
                        />
                      </div>
                   </Col>
                 </Row>

                  
                  <div className=" ">
                    <label>PSX Listing</label> 
                      <div>
                        <form action=""> 

                          <FormGroup check className="form-check-radio d-inline mr-3">
                            <Label check>
                              <Input
                              type="radio"
                                id="listed"
                                name="listed"
                                value="true"
                                checked={isCheckBoxSelected}
                                onChange={(e) => {
                                  setPsxListingError('');
                                  setPsxListing(e.target.value);
                                  setCheckBoxSelected(true);
                                }}
                              
                              />
                              <span className="form-check-sign" />
                              Listed
                            </Label>
                          </FormGroup> 
                           
                        <FormGroup check className="form-check-radio d-inline">
                            <Label check>
                              <Input
                                type="radio"
                                id="unlisted"
                                name="listed"
                                value="false"
                                onChange={(e) => {
                                  setPsxListingError('');
                                  setPsxListing(e.target.value);
                                  setCheckBoxSelected(false);
                                }}
                              />
                              <span className="form-check-sign" />
                              Unlisted
                            </Label>
                          </FormGroup>
 
                        </form>
                        {psxListingError ? (
                          <p className="error-labels error-mes">
                            {psxListingError}
                          </p>
                        ) : (
                          ''
                        )}
                      </div> 
                  </div> 
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Money Market Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="input-size-pos">

<Row>
<Col>
<label>Add Portfolio</label> 
<div>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />  
                  <button	
                    data-tip="Add Money Maket"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      setModalView(!modalView);
                      setDisableAddBtn(false);
                      setStockModal(false);	
                    }}	
                  > <i className="fa fa-plus"></i> </button>
                  </div>
                  </Col>
  <Col>
  <label>Upload Portfolio</label> 
              <div onClick={() => myRef1?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Choose File">
                          
                            {fileName === '' ? 'Choose File' : fileName}
                          </div> 
                          <input
                            type="file"
                            ref={myRef1}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Choose File"	
                            onChange={(e) => {
                              uploadMoneyMarketPortfolio(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">{fileError}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      </Col>
                     
             </Row>
                </div>

                <div className="table-responsive">
                {moneyMarket.length > 0 && (
                  <div className="table-responsive">
                <div className="input-size-pos mb-3 mt-2">
                  {/* <h3>Money Market Portfolio</h3> */}
                  <table className="table   ">
                    <thead>
                      <tr>
                        <th>Symbol / Code</th>
                        <th>Security Type</th>
                        <th>Name</th>
                        <th>Issue Date</th>
                        <th>Maturity Date</th>
                        <th>Coupon Rate</th>
                        <th>Yield</th>
                        <th>Face Value</th>
                        <th>Price</th>
                        <th>Units</th>
                        <th>Settlement Amount</th>
                        <th>Tenor</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderFundDataInTable()}</tbody>
                  </table>
                </div>
                </div>
              )}

</div>


              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Stock Market Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="input-size-pos ml-3">
                <Row>
                  <Col>
                  <label>Add Portfolio</label> 
                  <div>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  /> 

                  <button	
                    data-tip="Add Stock Market"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      setEquityModalFlag(!equityModalFlag);	
                      setDisableEquityBtn(false);	
                      setStockModal(true);	
                      const filedEntirty = {	
                        code: '',	
                        unit: '',	
                        market: '',	
                        amount: '0',	
                        close_rate: '0',	
                      };	
                      setStockEntity(filedEntirty);	
                    }}	
                  > <i className="fa fa-plus"> </i> </button>	
                  </div>
</Col>
<Col>
<label>Upload Portfolio</label> 
<div onClick={() => myRef2?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Choose File">
                          
                            {fileName2 === '' ? 'Choose File' : fileName2}
                          </div> 
                          <input
                            type="file"
                            ref={myRef2}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Choose File"	
                            onChange={(e) => {
                              uploadStockMarketPortfolio(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError2 ? (
                            <p className="error-labels error-message2">{fileError2}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      </Col>
                      </Row>
                </div>

                <div className="table-responsive">
                {stockMarket.length > 0 && (
                <div className="input-size-pos mb-3 mt-2 ">
                  {/* <h3>Stock Market Portfolio</h3> */}
                  <table className="table   ">
                    <thead>
                      <tr>
                        <th>Symbol / Code</th>
                        <th>Average Rate</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Market</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderEquityInTable()}</tbody>
                  </table>
                </div>
              )}
</div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Term Deposit Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="input-size-pos">

<Row>
<Col>
<label>Add Portfolio</label> 
<div>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />  
                  <button	
                    data-tip="Add Term Deposit"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      // setModalView(!modalView);
                      // setTermDepositModal(!termDepositModal)
                      setDisableAddBtn(false);
                      setTermDepositModal(true);	
                    }}	
                  > <i className="fa fa-plus"></i> </button>
                  </div>
                  </Col>
  <Col>
  <label>Upload Portfolio</label> 
              <div onClick={() => myRef3?.current?.click()}>
                        <div className="form-group">
                          <div className="form-control" data-tip="Choose File">
                          
                            {fileName3 === '' ? 'Choose File' : fileName3}
                          </div> 
                          <input
                            type="file"
                            ref={myRef3}
                            style={{ display: 'none' }}
                            multiple={false}
                            data-tip="Choose File"	
                            onChange={(e) => {
                              uploadTermDepositPortfolio(e);
                            }}
                            onClick={(e)=>{
                              e.currentTarget.value='';
                            }}
                          />
                          {fileError ? (
                            <p className="error-labels error-message2">{fileError}</p>
                          ) : (
                            ''
                          )}
                           
                        </div>
                      </div>
                      {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }
                      </Col>
                     
             </Row>
                </div>

                <div className="table-responsive">
                {termDeposit.length > 0 && (
                <div className="input-size-pos mb-3 mt-2">
                  {/* <h3>Money Market Portfolio</h3> */}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Principal Amount</th>
                        <th>Interest Rate</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Bank</th>
                        <th>Branch</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTermDepositInTable()}</tbody>
                  </table>
                </div>
              )}
</div>
              </CardBody>
            </Card>
          </Col>
          </Row>

              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={AddFunds}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Create</span>
                  )}
                </button>
              </div>

            {renderModalPopup()}
            {renderEquityPopup()} 
            {renderTermDepositPopup()}
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default SetupFunds;
