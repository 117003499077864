// import React from 'react';
// import { useState } from 'react';
// import { forgotPassword, forgotPasswordOtp } from './../../stores/services/auth.service';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Link, useHistory } from "react-router-dom";
// import { isString } from 'lodash';
// import Particles from 'react-particles-js';
// import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
// import {
//   Container,
//   Row,
//   Col,
//   Card,
//   CardHeader,
//   CardBody,
//   CardFooter,
//   CardTitle,
//   FormGroup,
// } from 'reactstrap';
// import '../../css/index.scss';
// import logoImg from '../../assets/macs-logo.png';



// const Forgot = () => {
//   const history = useHistory();
//   const [email, setEmail] = useState('');
//   const [emailError, setEmailError] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [otpView, setOtpView] = useState(false);
//   const forgotPasswordBtn = async () => {
//     setLoading(true);
//     setEmailError(false);
//     if (!email) {
//       setLoading(false);
//       setEmailError(true);
//       return;
//     }

//     try {
//       const response = await forgotPassword(email);
//       toast.success(response.data.message);
//       setOtp('');
//       setPassword('');
//       setConfirmPassword('');
//       setOtpError('');
//       setPasswordError('');
//       setConfirmPasswordError('');
//       setOtpView(true);
//     } catch (err:any) {
//       setLoading(false);
//       if(err.response !== undefined ) {
//         toast.error(err.response.data.message);	
//       }else {
//         toast.error("Request Failed!")
//       }
//     }
//     setLoading(false);
//   };

//   const [viewFlag, setViewFlag] = useState(false);
//   const [otp, setOtp] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [otpLoading, setOtpLoading] = useState(false);

//   const [otpError, setOtpError] = useState('');
//   const [passwordError, setPasswordError] = useState('');
//   const [confirmPasswordError, setConfirmPasswordError] = useState('');
//   const validate = () => {
//     let optErr,
//       passErr,
//       conPassErr = '';

//     otp.trim() === '' ? (optErr = 'OPT Required') : (optErr = '');
//     password.trim() === '' ? (passErr = 'Required') : (passErr = '');
//     confirmPassword.trim() === ''
//       ? (conPassErr = 'Required')
//       : confirmPassword !== password
//       ? (conPassErr = 'Not Match')
//       : (conPassErr = '');

//     if (optErr || passErr || conPassErr) {
//       setOtpError(optErr);
//       setPasswordError(passErr);
//       setConfirmPasswordError(conPassErr);
//       return false;
//     } else {
//       return true;
//     }
//   };

  

//   const forgetCall = async () => {
//     const isValid = validate();
//     if (isValid) {
//       setOtpLoading(true);
//       try {
//         const response = await forgotPasswordOtp(
//           email,
//           otp,
//           password,
//           confirmPassword
//         );
//         toast.success(response.data.message);
//         setViewFlag(false);

//         setTimeout(() => {
//           history.replace(`/`);
//         }, 5000);
//       } catch (error) {
//         setOtpLoading(false);
//         if (isString(error.response.data.message)) {
//           toast.error(error.response.data.message);
//         } else {
//           toast.error(error.response.data.message[0]);
//         }
//       }

//       setLoading(false);
//     }
//   };

//   const [inputType, setInputType] = useState('password');
//   const handleClick = () => {
//     inputType === 'text' ? setInputType('password') : setInputType('text');
//   };
//   const [inputTypeCon, setInputTypeCon] = useState('password');
//   const handleConClick = () => {
//     inputTypeCon === 'text'
//       ? setInputTypeCon('password')
//       : setInputTypeCon('text');
//   };


//   return (
//     <>
//      <Particles params= {{
//       particles:{
//         color: {
//           // value: "#1F8EF1"
//           value: "#fff"
//         },
//         lineLinked: {
//           color: {
//             value: "#1F8EF1"
//             // value: "#fff"
//           },
//           "enable": true,
//           "distance": 150,
//           "opacity": 0.4,
//           "width": 1
//             },
//             number: {
//               value: 250
//             },
//             size: {
//               value: 5
//             },

//             "move": {
//               "enable": true,
//               "speed": 2,
//               "direction": "none",
//               "random": false,
//               "straight": false,
//               "out_mode": "out",
//               "bounce": false,
//               "attract": {
//                 "enable": false,
//                 "rotateX": 600,
//                 "rotateY": 1200
//               }
//             }
        
//         },

//         "interactivity": {
//           "detect_on": "canvas",
//           "events": {
//           "onhover": {
//           "enable": true,
//           "mode": "grab"
//           // "mode": "repulse"
//           },
//           "onclick": {
//             "enable": true,
//             "mode": "push"
//           },
//           "resize": true
//           },

//           "modes": {
//             "grab": {
//               "distance": 150,
//               "line_linked": {
//                 "opacity": 1
//               }
//             },
//             "bubble": {
//               "distance": 400,
//               "size": 40,
//               "duration": 2,
//               "opacity": 8, 
//             },
//             "repulse": {
//               "distance": 200,
//               "duration": 0.4
//             },
//             "push": {
//               "particles_nb": 4
//             },
//             "remove": {
//               "particles_nb": 2
//             }
//           }
//           }
//       }
//     } /> 
 
//             <div className="container-login">
//               <ToastContainer />
//               <Col className="ml-auto mr-auto" lg="4" md="6">
//                 <Card className="card-login card-white bg-transparent ">
//                   <CardHeader className="d-flex justify-content-center py-15" >
//                     <img
//                       alt=""
//                       src={require("../../assets/MACS-Core Logo.png").default} width={"155px"}
//                     />
//                     <br />
//                   </CardHeader>
//             {!otpView ? (
//               <>
//                <CardBody> 
//               <h4 className="text-center" onClick={() => {
//                     setViewFlag(true);
//                   }}>
//                     Forgot Password</h4>
//               <label>Email</label>
//               <FormGroup>
//               <div className="">
//                 <input type="text" className="form-control" placeholder="Enter Your Email" onChange={(e) => {
//                   setEmail(e.target.value);
//                 }} onKeyPress={event => {
//                   if (event.key === 'Enter') {
//                     forgotPasswordBtn();
//                   }
//                 }} />
//                  {emailError === true ? (
//                   <p className="error-lab login-err">Email is Required</p>
//                 ) : (
//                   ''
//                 )}
//               </div>
//               </FormGroup>
             
//               </CardBody>

//             <div className="card-footer mt-1"> 

//                 <button
//                   className="btn btn-primary btn-block  "
//                   disabled={Boolean(loading)}
//                   onClick={() => {
//                     forgotPasswordBtn();
//                   }}
//                 >
//                   {loading ? (
//                     <>
//                       <span
//                         className=" "
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                       <span className="login-txt"> <i className="fa fa-spin fa-spinner mr-2"></i> Loading...</span>
//                     </>
//                   ) : (
//                     <span>Recover Password</span>
//                   )}
//                 </button> 

//               <Link to="/" className="btn btn-default btn-block bg-negative" replace>Cancel</Link> 

//             </div>
//               </>

//               ) : (
//                 <>
//                <div className="card-body">
//                 <div  className="bg-transparent">
//                 <p
//                   className="text-center"
//                   onClick={() => {
//                     setViewFlag(true);
//                   }}
//                 >
//                   OTP Verification
//                 </p>
//                <label>  {otpError ? otpError : 'OTP Code'} </label>
//                 <div className="form-group login-section">
//                   <input
//                     type="text"
//                     className="form-control login-input "
//                     value={otp}
//                     onChange={(e) => {
//                       setOtp(e.target.value);
//                       setOtpError('');
//                     }}
//                     onKeyPress={(event) => {
//                       if (event.key === 'Enter') {
//                         forgetCall();
//                       }
//                     }}
//                   />
//                 </div>
//                 <div className=" form-group">
//                 <label> {passwordError ? passwordError : 'Password'} </label>

//                   <div className=" ">
//                     <input
//                       type={inputType}
//                       value={password}
//                       className="form-control w-100"
//                       onChange={(e) => {
//                         setPassword(e.target.value);
//                         setPasswordError('');
//                       }}
//                     />
//                     {inputType === 'text' ? (
//                       <MdVisibility
//                         className="visibility-icon mt-25"
//                         onClick={() => handleClick()}
//                       />
//                     ) : (
//                       <MdVisibilityOff
//                         className="visibility-icon mt-25"
//                         onClick={() => handleClick()}
//                       />
//                     )}
//                   </div>
//                 </div>
//                 <div className="form-group">
//                   <label >
//                   {confirmPasswordError
//                     ? confirmPasswordError
//                     : 'Confirm Password'} 
//                   </label>
//                   <div className=" ">
//                     <input
//                       type={inputTypeCon}
//                       value={confirmPassword}
//                       className="form-control w-100"
//                       onChange={(e) => {
//                         setConfirmPassword(e.target.value);
//                         setConfirmPasswordError('');
//                       }}
//                     />
//                     {inputTypeCon === 'text' ? (
//                       <MdVisibility
//                         className="visibility-icon mt-25"
//                         onClick={() => handleConClick()}
//                       />
//                     ) : (
//                       <MdVisibilityOff
//                         className="visibility-icon mt-25"
//                         onClick={() => handleConClick()}
//                       />
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>

//                 <div className="card-footer">
//                   <button
//                     className="btn btn-primary btn-block bg-positive mt-3"
//                     disabled={Boolean(otpLoading)}
//                     onClick={() => {
//                       forgetCall();
//                     }}
//                   >
//                     {otpLoading ? (
//                       <>
//                         <span
//                           className=" "
//                           role="status"
//                           aria-hidden="true"
//                         ></span>
//                         <span className="login-txt"> <i className="fa fa-spinner fa-spin"></i> Loading...</span>
//                       </>
//                     ) : (
//                       <span>Confirm</span>
//                     )}
//                   </button>

//                   <button className="btn btn-default btn-block" onClick={() => {
//                     setOtpView(false);
//                   }}> Cancel </button>
              
//                 {/* <IoMdArrowRoundBack
//                   onClick={() => {
//                     setOtpView(false);
//                   }}
//                 />  */}
//             </div>
              
//               </>
//                )}
//                 </Card>
//               </Col>
//             </div>
          
//     </>
//   )
// };

// export default Forgot;


import React from 'react';
import { useState } from 'react';
import { forgotPassword, forgotPasswordOtp } from './../../stores/services/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from "react-router-dom";
import { isString } from 'lodash';
import Particles from 'react-particles-js';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
} from 'reactstrap';
import '../../css/index.scss';
import logoImg from '../../assets/macs-logo.png';
import ReCAPTCHA from "react-google-recaptcha";


const Forgot = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpView, setOtpView] = useState(false);

  const [confirmEnable, setConfirmEnable] = useState(false);

  const forgotPasswordBtn = async () => {
    setLoading(true);
    setEmailError(false);
    if (!email) {
      setLoading(false);
      setEmailError(true);
      return;
    }

    try {
      const response = await forgotPassword(email);
      toast.success(response.data.message);
      setOtp('');
      setPassword('');
      setConfirmPassword('');
      setOtpError('');
      setPasswordError('');
      setConfirmPasswordError('');
      setOtpView(true);
    } catch (err:any) {
      setLoading(false);
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error("Request Failed!")
      }
    }
    setLoading(false);
  };

  const [viewFlag, setViewFlag] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpLoading, setOtpLoading] = useState(false);

  const [otpError, setOtpError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const validate = () => {
    let optErr,
      passErr,
      conPassErr = '';

    otp.trim() === '' ? (optErr = 'OPT Required') : (optErr = '');
    password.trim() === '' ? (passErr = 'Required') : (passErr = '');
    confirmPassword.trim() === ''
      ? (conPassErr = 'Required')
      : confirmPassword !== password
      ? (conPassErr = 'Not Match')
      : (conPassErr = '');

    if (optErr || passErr || conPassErr) {
      setOtpError(optErr);
      setPasswordError(passErr);
      setConfirmPasswordError(conPassErr);
      return false;
    } else {
      return true;
    }
  };

  function onChange(value) {
    const isValid = validate();
    setConfirmEnable(true);
  }

  const forgetCall = async () => {
    const isValid = validate();
    if (isValid) {
      setOtpLoading(true);
      try {
        const response = await forgotPasswordOtp(
          email,
          otp,
          password,
          confirmPassword
        );
        toast.success(response.data.message);
        setViewFlag(false);

        setTimeout(() => {
          history.replace(`/`);
        }, 5000);
      } catch (error) {
        setOtpLoading(false);
        if (isString(error.response.data.message)) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message[0]);
        }
      }

      setLoading(false);
    }
  };

  const [inputType, setInputType] = useState('password');
  const handleClick = () => {
    inputType === 'text' ? setInputType('password') : setInputType('text');
  };
  const [inputTypeCon, setInputTypeCon] = useState('password');
  const handleConClick = () => {
    inputTypeCon === 'text'
      ? setInputTypeCon('password')
      : setInputTypeCon('text');
  };


  return (
    <>
     <Particles params= {{
      particles:{
        color: {
          // value: "#1F8EF1"
          value: "#fff"
        },
        lineLinked: {
          color: {
            value: "#1F8EF1"
            // value: "#fff"
          },
          "enable": true,
          "distance": 150,
          "opacity": 0.4,
          "width": 1
            },
            number: {
              value: 250
            },
            size: {
              value: 5
            },

            "move": {
              "enable": true,
              "speed": 2,
              "direction": "none",
              "random": false,
              "straight": false,
              "out_mode": "out",
              "bounce": false,
              "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
              }
            }
        
        },

        "interactivity": {
          "detect_on": "canvas",
          "events": {
          "onhover": {
          "enable": true,
          "mode": "grab"
          // "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
          },

          "modes": {
            "grab": {
              "distance": 150,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8, 
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
          }
      }
    } /> 
 
            <div className="container-login">
              <ToastContainer />
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Card className="card-login card-white bg-transparent ">
                  <CardHeader className="d-flex justify-content-center py-15" >
                    <img
                      alt=""
                      src={require("../../assets/MACS-Core Logo.png").default} width={"155px"}
                    />
                    <br />
                  </CardHeader>
            {!otpView ? (
              <>
               <CardBody> 
              <h4 className="text-center" onClick={() => {
                    setViewFlag(true);
                  }}>
                    Forgot Password</h4>
              <label>Email</label>
              <FormGroup>
              <div className="">
                <input type="text" className="form-control" placeholder="Enter Your Email" onChange={(e) => {
                  setEmail(e.target.value);
                }} onKeyPress={event => {
                  // if (event.key === 'Enter') {
                  //   forgotPasswordBtn();
                  // }
                }} />
                 {emailError === true ? (
                  <p className="error-lab login-err">Email is Required</p>
                ) : (
                  ''
                )}
              </div>
              </FormGroup>

              <div>
              <ReCAPTCHA
                sitekey="6Le0HoAdAAAAADxrbEVg8ksdFb8AQMLROtEQu2go"
                onChange={onChange}
                onExpired={(e)=>{
                  setConfirmEnable(false);
                }}
              />
              </div>
             
              </CardBody>

            <div className="card-footer mt-1"> 

              {confirmEnable===true && (
                <button
                  className="btn btn-primary btn-block  "
                  disabled={Boolean(loading)}
                  onClick={() => {
                    forgotPasswordBtn();
                  }}
                >
                  {loading ? (
                    <>
                      <span
                        className=" "
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> <i className="fa fa-spin fa-spinner mr-2"></i> Loading...</span>
                    </>
                  ) : (
                    <span>Recover Password</span>
                  )}
                </button> 
              )}

              <Link to="/" className="btn btn-default btn-block bg-negative" replace>Cancel</Link> 

            </div>
              </>

              ) : (
                <>
               <div className="card-body">
                <div  className="bg-transparent">
                <p
                  className="text-center"
                  onClick={() => {
                    setViewFlag(true);
                  }}
                >
                  OTP Verification
                </p>
               <label>  {otpError ? otpError : 'OTP Code'} </label>
                <div className="form-group login-section">
                  <input
                    type="text"
                    className="form-control login-input "
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                      setOtpError('');
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        // forgetCall();
                      }
                    }}
                  />
                </div>
                <div className=" form-group">
                <label> {passwordError ? passwordError : 'Password'} </label>

                  <div className=" ">
                    <input
                      type={inputType}
                      value={password}
                      className="form-control w-100"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('');
                      }}
                    />
                    {inputType === 'text' ? (
                      <MdVisibility
                        className="visibility-icon mt-25"
                        onClick={() => handleClick()}
                      />
                    ) : (
                      <MdVisibilityOff
                        className="visibility-icon mt-25"
                        onClick={() => handleClick()}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label >
                  {confirmPasswordError
                    ? confirmPasswordError
                    : 'Confirm Password'} 
                  </label>
                  <div className=" ">
                    <input
                      type={inputTypeCon}
                      value={confirmPassword}
                      className="form-control w-100"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setConfirmPasswordError('');
                      }}
                    />
                    {inputTypeCon === 'text' ? (
                      <MdVisibility
                        className="visibility-icon mt-25"
                        onClick={() => handleConClick()}
                      />
                    ) : (
                      <MdVisibilityOff
                        className="visibility-icon mt-25"
                        onClick={() => handleConClick()}
                      />
                    )}
                  </div>
                </div>

              </div>
            </div>

                <div className="card-footer">
               
                  <button
                    className="btn btn-primary btn-block bg-positive mt-3"
                    disabled={Boolean(otpLoading)}
                    onClick={() => {
                      forgetCall();
                    }}
                  >
                    {otpLoading ? (
                      <>
                        <span
                          className=" "
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> <i className="fa fa-spinner fa-spin"></i> Loading...</span>
                      </>
                    ) : (
                      <span>Confirm</span>
                    )}
                  </button>

                  <button className="btn btn-default btn-block" onClick={() => {
                    setOtpView(false);
                    setConfirmEnable(false);
                  }}> Cancel </button>
              
              
                {/* <IoMdArrowRoundBack
                  onClick={() => {
                    setOtpView(false);
                  }}
                />  */}
            </div>
              
              </>
               )}
                </Card>
              </Col>
            </div>
          
    </>
  )
};

export default Forgot;