import React, { useState } from 'react';	
import { Container, Row, Col,Label, Input, FormGroup } from 'reactstrap';	
import moment from 'moment';	
import { getAllPendingTransactions } from '../../stores/services/transactions.service';	
import ReactTooltip from 'react-tooltip';	
import { useHistory } from 'react-router-dom';	
import ViewTransaction from './../transactions/view-beta-txn';	
import { EditTransaction } from './../../stores/services/beta-transaction.service';	
import { Modal } from 'react-bootstrap';	
import { getInstrumentType } from '../../stores/services/funds.service';	
import { getChecklist } from '../../stores/services/checklist.service';	
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import CheckListItem from '../../components/checklist-item';	
import { updateTransactionStatus,updateBulkTransactionStatus } from '../../stores/services/transactions.service';	
import { BsTable } from 'react-icons/bs';	

const AmcTransactionListing = (

) => {	
  const history = useHistory();	
  let [bulkProcessingData, setBulkProcessingData] = useState<any>([]);	
  let [transactions, setTransactions] = useState([]);	
  let [activePage, setActivePage] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);	
  let [searchValue, setSearchValue] = useState('');	
  const userEmail = sessionStorage.getItem('email') || '';	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  const [iTypeData, setITypeData] = useState<any>([]); // Instrument Type Data hook	
  const role_name = sessionStorage.getItem('role') || '';	
  const [trx_history, setTrxHistory] = useState([]);	
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  const [checklistData, setCheckListData] = useState<any>([]);	
  const [reasonText, setReasonText] = useState('');	
  const [reasonTextError, setReasonTextError] = useState('');	
  const [rejectLoading, setRejectLoading] = useState(false);	
  const [trx_id, setTrxId] = useState('');	
  const [Loading, setLoading] = useState(false);	
  const [status, setStatus] = useState('');	
  const [statusError, setStatusError] = useState('');	
  const [ changeCheck , setChangeCheck ] = useState('');
  let [count, setCount] = useState(0);
  let [headLabel, setHeadLabel] = useState('');		
  let [counter, setCounter] = useState('');	
  React.useEffect(() => {	
    const getAllChecklists = async () => {	
      try {	
        const response = await getChecklist(email, role_name);	
        setCheckListData(response.data.data);	
      } catch (err:any) {	
        // toast.error(error.response.data.message[0]);	
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }	
    };	
    getAllChecklists();	
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, []);	
  const updateFormData = (obj, id) => {	
    let temp = checklistData;	
    temp[id] = obj;	
    setCheckListData(temp);	
  };
  
  const addTxnForBulk = (txnObj) => {
    count = count + 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id === txnObj.txn_id;
    });
    if (check.length === 0) {
      let temp = {
        id: txnObj.txn_id || '',
        status: txnObj.txn_status || '',
        made_by: txnObj.made_by || '',
      };
      bulkProcessingData.push(temp);
      setBulkProcessingData(bulkProcessingData);
      setChangeCheck(`${txnObj.txn_id}-${count}`);
    }
    setCount(count);	
  };	
  
  const removeTxnFromBulk = (txnObj) => {	
    count = count - 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id !== txnObj.txn_id;
    });
    bulkProcessingData = check;
    setBulkProcessingData(bulkProcessingData);
    setCount(count);
    setChangeCheck(`${txnObj.txn_id}-${count}`);	
  };

  const checkBoolean = (txnId) => {  		
    let tx = false;	
    bulkProcessingData.map((item, index) => {	
        if (item.id === txnId) {
            tx = true;	
        }	
    })	
    return tx;	
}	

const updateBulkTxStatus = async () => {
  const isValid = validate();	
  if (isValid) {
    if (status === 'accept') {
      setReasonTextError('');
      setLoading(true);
      let data = JSON.stringify(bulkProcessingData);
      try {
        const response = await updateBulkTransactionStatus(
          email,
          role_name,
          status,
          reasonText,
          checklistData,
          data,  
          ''
          // headLabel.toUpperCase()
        );
        setBulkCheckListSetPopup(false);
        searchFilters('').then(() => {
          setSearchValue('');
          setActivePage('');
        });
        bulkProcessingData.length = 0;
        setBulkProcessingData(bulkProcessingData);
        toast.success(response.data.message);
      } catch (err:any) {
        setLoading(false);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message || 'Error is Processing');
        }else {
          toast.error(err.message)
        }
      }
      setCount(0);
      setLoading(false);
    }else{
      // toast.success("reject")
      setStatusError('');	
      if (reasonText.trim() === '') {	
        setReasonTextError('Required');	
      } else {	
        setReasonTextError('');	
        setLoading(true);
      let data = JSON.stringify(bulkProcessingData);
      try {
        const response = await updateBulkTransactionStatus(
          email,
          role_name,
          status,
          reasonText,
          checklistData,
          data,
          ''
          // headLabel.toUpperCase()
        );
        setBulkCheckListSetPopup(false);
        searchFilters('').then(() => {
          setSearchValue('');
          setActivePage('');
        });
        bulkProcessingData.length = 0;
        setBulkProcessingData(bulkProcessingData);
        toast.success(response.data.message);
      } catch (err:any) {
        setLoading(false);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message || 'Error is Processing');
        }else {
          toast.error(err.message)
        }
      }
      setCount(0);
      setLoading(false);	
      }	
    }
  
}// end of isvalid
};




  React.useEffect(() => {	
    const fetchInstr = async () => {	
      try {	
        const inTypeResponse = await getInstrumentType(email);	
        setITypeData(inTypeResponse.data.instrument_types);	
      } catch (error) {}	
    };	
    fetchInstr();	
  }, []);	
  const acceptRejectTransaction = async (status: string) => {	
    setReasonText('');	
    setLoading(true);	
    await updateTransactionStatus(	
      email,	
      status,	
      trx_id,	
      reasonText,	
      checklistData,
      counter
    )	
    .then((response) => {
      setLoading(false);
      toast.success(response.data.message);
      setCheckListSetPopup(false);
      setView(false);
      searchFilters('').then(() => {
        setActivePage('');
        setSearchValue('');
      });
    })	
      .catch((err) => {		
        setLoading(false);	
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);
        }else {
          toast.error(err.message)
        }
      });	
  };	
  React.useEffect(() => {	
    getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',	
      'CONCERNED-OFFICER',	
      searchValue,	
      userEmail,	
      activePage	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);	
      })	
      .catch((err) => {	
       // console.log(err);	
      });	
  }, [activePage]);	
  React.useEffect(() => {	
    getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',	
      'CONCERNED-OFFICER',	
      '',	
      userEmail	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);	
      })	
      .catch((err) => {	
       // console.log(err);	
      });	
  }, []);	
  const CapTxnType = (type: any) => {	
    let i, txType;	
    for (i = 0; i < txnTypes.length; i++) {	
      if (txnTypes[i].api_name === type) {	
        txType = txnTypes[i].dis_name;	
        break;	
      }	
    }	
    return txType;	
  };	
  //render dropdown for iTypeData data	
  const renderiTypeDataDropdown = () => {	
    return iTypeData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item}>	
          {item}	
        </option>	
      );	
    });	
  };	
  const searchFilters = (searchVar = '') => {	
    return new Promise((resolve, reject) => {	
      getAllPendingTransactions(	
        sessionStorage.getItem('email') || '',	
        'CONCERNED-OFFICER',	
        searchVar,	
        userEmail,	
        activePage	
      )	
        .then((response) => {	
          let pageArr = [''];	
          let existingPageIndex = paginationArr.findIndex(	
            (x) => x === response.data.page_info.next_page_number	
          );	
          if (	
            existingPageIndex === -1 &&	
            response.data.data.length === 10 &&	
            10 * pageArr.length !==	
              parseInt(response.data.page_info.total_count)	
          ) {		
            pageArr.push(response.data.page_info.next_page_number);	
          }	
          setPaginationArr(pageArr);	
          setTransactions(response.data.data);	
          resolve('Success');	
        })	
        .catch((err) => {	
         // console.log(err);	
          reject('Error');	
        });	
    });	
  };	
  const [view, setView] = useState(false);	
  const [data, setData] = useState<any>([]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };	
  const renderData = () => {	
    return data.map((value: any, index: number) => {	
      return (	
        <>	
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (	
            <Col md="6" key={index}>	
              <div className="input-holder left">	
                <p className="label">{titleCase(value[0])}</p>	
                <div className="input-1 hide-overflow">	
                  <p	
                    style={{	
                      fontSize: '12px',	
                    }}	
                  >	
                    {typeof value[1] === 'string'	
                      ? value[0] === 'file'	
                        ? 'N/A'	
                        : value[1]	
                      : value[0] === 'file'	
                      ? 'N/A'	
                      : ''}	
                  </p>	
                </div>	
              </div>	
            </Col>	
          ) : (	
            ''	
          )}	
        </>	
      );	
    });	
  };	
  const email = sessionStorage.getItem('email') || '';	
  const [addInstrumentModal, setAddInstrumentModal] = useState(false);	
  const [updateTx, setUpdateTx] = useState<any>([]);	
  const [disableIntrBtn, setDisableIntrBtn] = useState(false);	
  const validate = () => {	
    let statusErr = '';	
    status.trim() === '' ? (statusErr = 'Required') : (statusErr = '');	
    if (statusErr) {	
      setStatusError(statusErr);	
      return false;	
    } else {	
      return true;	
    }	
  };	
  const updateTxStatus = async () => {
    const isValid = validate();
    if (isValid) {
      if (status === 'accept') {
        setStatusError('');
        acceptRejectTransaction('COMPLIANCE');
      } else {
        setStatusError('');
        if (reasonText.trim() === '') {
          setReasonTextError('Required');
        } else {
          setReasonTextError('');
          acceptRejectTransaction('REJECTED-AMC');
        }
      }
    }
  };	
  const addInstrumentCall = async () => {	
    updateTx.trx_id = updateTx.txn_id;	
    updateTx.email = email;	
    setDisableIntrBtn(true);	
    try {	
      await EditTransaction(updateTx);	
      setDisableIntrBtn(false);	
      setAddInstrumentModal(false);	
      searchFilters('').then(() => {	
        setSearchValue('');	
        setActivePage('');	
      });	
    } catch (error) {	
      // console.log(error.response);	
      setDisableIntrBtn(false);	
    }	
  };	
  const renderModalForInstrumentPopup = () => {	
    switch (addInstrumentModal) {	
      case true:	
        return (	
          <Modal	
            className=" "	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => {	
              setAddInstrumentModal(false);	
            }}	
          >  	
              <div className="modal-header justify-content-center">	
                        <button	
                          aria-hidden={true}	
                          className="close"	
                          data-dismiss="modal"	
                          type="button"	
                          onClick={() => {	
                            setAddInstrumentModal(false);	
                            // clearTaxobject();	
                          }}	
                        >	
                          <i className="tim-icons icon-simple-remove" />	
                        </button>	
                        <h6 className="title title-up">Add Instrument Detail  </h6>	
                      </div>	
 	
            <div className="modal-body">	
              <div className=" ">	
                <div className="form-group">	
                  <div>Instrument Type</div>	
                  <select	
                    className={'form-control w-100 '}	
                    value={updateTx['instrument_type']}	
                    onChange={(e) => {	
                      setUpdateTx({	
                        ...updateTx,	
                        instrument_type: e.target.value,	
                      });	
                    }}	
                  >	
                    <option value="" defaultChecked hidden>	
                      {' '}	
                      Select Type	
                    </option>	
                    {renderiTypeDataDropdown()}	
                  </select>	
                </div>	
                <div className="form-group">	
                  <div>Instrument No</div>	
                  <input	
                    type="text"	
                    defaultValue={updateTx['instrument_no']}	
                    className="form-control w-100"	
                    onChange={(e) => {	
                      setUpdateTx({	
                        ...updateTx,	
                        instrument_no: e.target.value,	
                      });	
                    }}	
                  />	
                </div>	
                <div className="form-group">	
                  <div>Instrument Date</div>	
                  <input	
                    type="date"	
                    defaultValue={updateTx['instrument_date']}	
                    className="form-control w-100"	
                    onChange={(e) => {	
                      setUpdateTx({	
                        ...updateTx,	
                        instrument_date: e.target.value,	
                      });	
                    }}	
                  />	
                </div>	
                <div className=" ">	
                  <button	
                    className="btn btn-primary btn-block mt-3"	
                    onClick={() => {	
                      addInstrumentCall();	
                    }}	
                    disabled={Boolean(disableIntrBtn)}	
                  >	
                    {disableIntrBtn ? (	
                      <>	
                        <span	
                          className="spinner-border login-txt spinner-border-sm"	
                          role="status"	
                          aria-hidden="true"	
                        ></span>	
                        <span className="login-txt"> Loading...</span>	
                      </>	
                    ) : (	
                      <span>Add</span>	
                    )}	
                  </button>	
                </div>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const [statusSetPopup, setStatusSetPopup] = useState(false);	
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);	
  const [bulkChecklistSetPopup, setBulkCheckListSetPopup] = useState(false);	
  const renderCheckListPopup = () => {	
    switch (checklistSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=" "	
            dialogClassName="modal90w"	
            show={true}	
            size="xl"	
          >	
            <div className="modal-header">	
              <button	
                aria-hidden={true}	
                className="close"	
                data-dismiss="modal"	
                type="button"	
                onClick={() => {	
                  setCheckListSetPopup(false);	
                }}	
              >	
                <i className="tim-icons icon-simple-remove" />	
              </button>	
              <h6 className="title title-up">Approval Check List  </h6>	
            </div>	
            <div className="modal-body">	
              <div className=" "> 
                <div className={'checlist-scroll'}>	
                  {checklistData.map((val: any, ind: any) => {	
                    return (	
                      <CheckListItem	
                        updateFormData={updateFormData}	
                        val={val}	
                        ind={ind}	
                      />	
                    );	
                  })}	
                </div>
                <Row className="pt-2">	
                  <Col md="12" className="d-flex mb-2"> 	
                    	
                  <FormGroup check className="mr-3">	
                        <Label check>	
                          <Input checked={status === 'accept'}	
                          type="checkbox"	
                          value="accept"	
                          onChange={(e) => {	
                            setReasonText('')	
                            setStatusError('');	
                            setStatus(e.target.value);	
                          }} />	
                          <span className="form-check-sign" />	
                          Accept	
                        </Label>	
                      </FormGroup> 	
                    	
                  <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox"	
                          checked={status === 'reject'}	
                          value="reject"	
                          onChange={(e) => {	
                            setStatusError('');	
                            setStatus(e.target.value);	
                          }} />	
                          <span className="form-check-sign" />	
                          Reject	
                        </Label>	
                      </FormGroup>	
                  </Col>	
                </Row>	
                <Row>	
                  <Col md="12">
                  {statusError ? (	
                    <text className="error-lab">{statusError}</text>	
                  ) : (	
                    ''	
                  )}	
                  </Col>
                </Row>	
                {status !== 'accept' && (	
                  <Row>	
                    <Col md="12">
                    <div className="form-group">	 
                      <textarea	
                        placeholder="Reason"	
                        className={	
                          'form-control w-100'	
                        }	
                        value={reasonText}	
                        onChange={(e) => {	
                          setReasonText(e.target.value);	
                          setReasonTextError('');	
                        }}	
                      ></textarea>	
                    </div>	
                    {reasonTextError && (	
                      <text className="error-lab">{reasonTextError}</text>	
                    )}	
                    </Col>
                  </Row>	
                )}	
                <Row>	 
                    <div className="col-md-12 mt-3">	
                      <button	
                        className="btn btn-primary mr-3"	
                        disabled={Boolean(Loading)}	
                        onClick={() => {	
                          updateTxStatus();	
                        }}	
                      >	
                        {Loading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Submit'	
                        )}	
                      </button>

                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setCheckListSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                          </button>	
                    </div>	 	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	

  const renderBulkCheckListPopup = () => {	
    switch (bulkChecklistSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
          >	

          <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {	
                  setBulkCheckListSetPopup(false);	
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> Approval Check List </h6>
             </div> 


            <div className="modal-body">	
              <div  >	 
                <div className={'checlist-scroll'}>	
                  {checklistData.map((val: any, ind: any) => {	
                    return (	
                      <CheckListItem	
                        updateFormData={updateFormData}	
                        val={val}	
                        ind={ind}	
                      />	
                    );	
                  })}	
                </div>	
                <div> Transactions Selected: <span className="text-primary">{count}</span>  </div>
                <Row className="pb-3">
                <Col md="12">		
                  <div className="d-flex">	 
                      <FormGroup check className="mr-4">
                        <Label check>
                          <Input checked={status === 'accept'}	
                            type="checkbox"	
                            value="accept"	
                            onChange={(e) => {	
                              setReasonText('');	
                              setStatusError('');	
                              setStatus(e.target.value);	
                            }} />
                          <span className="form-check-sign" />
                          Accept
                        </Label>
                      </FormGroup>
 
                    <FormGroup check className="ml-1">
                        <Label check>
                          <Input  type="checkbox"	
                            checked={status === 'reject'}	
                            value="reject"	
                            onChange={(e) => {	
                              setStatusError('');	
                              setStatus(e.target.value);	
                            }} />
                          <span className="form-check-sign" />
                          Reject
                        </Label>
                      </FormGroup>


                  </div>
                  </Col>		
                </Row>	
                <Row>	
                  {statusError ? (	
                    <text className="error-lab">{statusError}</text>	
                  ) : (	
                    ''	
                  )}	
                </Row>	
                {status === 'reject' && (	
                  <Row>	
                    <Col >
                    <div className="form-group">	
                      <textarea	
                        placeholder="Reason"	
                        className={'form-control w-100'}	
                        value={reasonText}	
                        onChange={(e) => {	
                          setReasonText(e.target.value);	
                          setReasonTextError('');	
                        }}	
                      ></textarea>	
                    </div>	
                    {reasonTextError && (	
                      <text className="error-lab">{reasonTextError}</text>	
                    )}	
                    </Col>
                  </Row>	
                )}	
                <Row>	 
                  <Col md="12"> 
                      <button	
                        className="btn btn-primary mr-3"	
                        disabled={Boolean(Loading)}	
                        onClick={() => {	
                          updateBulkTxStatus();	
                        }}	
                      >	
                        {Loading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Submit'	
                        )}	
                      </button>	 
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setBulkCheckListSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    {/* </div> */}
                    </Col>	 
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };
  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        {!view ? (	
          <div className="card">	
             <div className="card-header">	
              {/* <h4 className="card-title">AMC Transaction Listing</h4>	 */}
              <h4 className="card-title">Concerned Officer</h4>	
            </div>	
            <div className="card-body"> 	
              <div className="d-flex">	
                <div className="form-group inline-box">	
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  />	
                  <input	
                    type="search"	
                    id="myInput"	
                    placeholder="Transaction Id"	
                    data-tip="Search Transaction"	
                    className="form-control mr-2"	
                    value={searchValue}	
                    onChange={(e) => {	
                      setSearchValue(e.target.value);	
                    }}	
                  />	
                	
                <div	
                  className="btn btn-primary mr-2 ml-2 mt-a"	
                  onClick={() => {	
                    searchFilters(searchValue).then(() => {	
                      setActivePage('');	
                    });	
                  }}	
                >	
                  Search	
                 </div>	
                <div	
                  className="btn btn-default mt-a"	
                  onClick={() => {	
                    searchFilters('').then(() => {	
                      setActivePage('');	
                      setSearchValue('');	
                    });	
                  }}	
                >	
                  Clear	
                </div>
                </div>	
              </div>	
 	
              <div className="">	
                {transactions.length > 0 ? (	
                  <table className="table mt-3">	
                    <thead>	
                      <tr>	
                        <th>Select</th>	
                        <th>Transaction Id</th>	
                        <th>Transaction Type</th>	
                        <th>Created By</th>	
                        <th>Date Created</th>	
                        <th>Status</th>	
                        <th>Net Amount</th>
                        <th>Actions</th>	
                      </tr>	
                    </thead>	
                    <tbody>	
                      {transactions.map((transaction: any) => {	
                        return (	
                          <tr>	
                            <td>
                             <FormGroup check>	
                              <Label check>	
                                <Input type="checkbox"	
                                checked={checkBoolean(transaction.txn_id)}	
                                onChange={(e) => {	
                                  if (e.target.checked === true) {	
                                    addTxnForBulk(transaction);	
                                  } else {	
                                    removeTxnFromBulk(transaction);	
                                  }	
                                }} />	
                                <span className="form-check-sign" />	 
                              </Label>	
                            </FormGroup>
                            </td>
                            <td>{transaction.txn_id}</td>	
                            <td>	
                              {transaction.txnr_type	
                                ? CapTxnType(transaction.txnr_type)	
                                : 'N/A'}	
                            </td>	
                            <td>	
                              {transaction.created_by	
                                ? transaction.created_by	
                                : 'N/A'}	
                            </td>	
                            <td>	
                            {transaction.txn_history	
                              ? transaction.txn_history[0]?.created_at	
                                ? moment(	
                                    transaction.txn_history[0].created_at	
                                  ).format('DD-MM-YYYY')	
                                : 'N/A'	
                              : 'N/A'}	
                            </td>	
                            <td>{transaction.txn_status}</td>	
                            <td className="text-right">{numberWithCommas(transaction.net_amount)}</td>	
                            <td>	
                              <div className="multi-icons pointer">	  

                                <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {	
                                    // let objArr = Object.entries(transaction);	
                                    // setData(objArr);	
                                    // setView(true);	
                                    setView(true);	
                                    setTrxId(transaction.txn_id);	
                                    setCounter(transaction.counter);
                                    setData(transaction);	
                                    setTrxHistory(transaction.txn_history);	
                                  }}>
                                  <i className="fa fa-eye"> </i>
                                </button> 
                              </div>	
                            </td>	
                          </tr>	
                        );	
                      })}	
                    </tbody>	
                  </table>	
                ) : (	
                  <div className="table-empty">	
                    <BsTable className="icon" />	
                    <p className="text-center empty-text">No record found</p>	
                  </div>	
                )}	
                <div	
                  className="row pointer pr-3"	
                  style={{	
                    float: 'right',	
                  }}	
                >	
                  {/* PREVIOUS BUTTON */}	
                  {activePage !== paginationArr[0] ? (	
                    <i	
                      className="fa fa-arrow-left"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {	
                          setActivePage(paginationArr[index - 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                  {/* NEXT BUTTON */}	
                  {activePage !== paginationArr[paginationArr.length - 1] ? (	
                    <i	
                      className="fa ml-3 fa-arrow-right"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {
                          setActivePage(paginationArr[index + 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                </div>	

                {count===0 ?(
              ''
              ):(
              <div className="mb-2">
                 <span className="text-primary">{count}</span> transactions selected. &nbsp; <a onClick={()=>{
                bulkProcessingData.length = 0;
                setBulkProcessingData(bulkProcessingData);
                setCount(0);
                setChangeCheck(`${count}`);
              }}>Clear Selection </a> </div> 
              )}

                {bulkProcessingData.length > 0 && (	
                <Row>	
                  <Col md="6">	
                    <div className="d-flex">	
                      <div className="">	
                        <button	
                          className="btn btn-primary"	
                          disabled={Boolean(Loading)}	
                          onClick={() => {	
                            setBulkCheckListSetPopup(true);	
                          }}	
                        >	
                          Action	
                        </button>	
                      </div>	
                    </div>	
                  </Col>	
                </Row>	
              )}

              </div>	
            </div>	
          </div>	
        ) : (	
          <div className=" ">	
             <h4>	
              <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {	
                setView(false);	
              }}></i>	
                View - Transaction	
             </h4>	
            <ViewTransaction data={data} />	
            <div className=" ">	
              <button	
                className="btn btn-primary mr-4 bg-positive"	
                disabled={Boolean(Loading)}	
                onClick={() => {	
                  setCheckListSetPopup(true);	
                }}	
              >	
                <span>Action</span>	
              </button>	
            </div>	
            <div className="line"></div>	
            <Row>	
              {trx_history.map((hist: any, index: number) => {	
                return (	
                  <Col	
                    md="3"	
                    onClick={() => {	
                      if (hist.action === 'CREATED') {	
                        return;	
                      }	
                      if (!hist.check_list) return;	
                      setTrxCheckList(hist.check_list);	
                      setViewCheckList(true);	
                    }}	
                  >	 
                    <div className="card process-card pointer">
                      <div className="card-header">
                      <h4 className="card-title">	
                          <span className="card-number">
                          {index + 1}
                          </span>
                           <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                          </h4>	
                      </div>	 
                      <div className="card-body text-center">	 
                            
                            <p className="small mt-2">Status:</p>	
                            <h3 className="accepted text-primary">  {hist.action}    </h3>	 
                            {hist.updated_at !== '' ? (	
                              <>	
                              <p className="small mt-2">by</p>	
                              <h4 className="comment text-primary">{(hist.updated_by)}</h4>	 
                              <p className="small mt-2">at</p>	
                              <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>	 
                            </>	
                            ) : (	
                                ''	
                              )}	
                            {hist.note !== '' ? (	
                              <>	
                                <p className="small mt-2">Comment</p>	
                                <h5 className="comment">{hist.note}</h5>	
                              </>	
                            ) : (	
                                ''	
                              )}	
                            {	
                              hist.created_by !== '' ? (	
                                <>	
                                  <p className="small mt-2">Created By</p>	
                                  <h4>{hist.created_by}</h4>	
                                  <p className="small mt-2">Created At</p>	
                                  <h4 className="comment">  {moment(hist.created_at).format("DD-MM-YYYY")}</h4>	
                                </>	
                              ) : (	
                                  ''	
                                )}	
                          </div>		
                    </div>
                  </Col>	
                );	
              })}	
            </Row>	
          </div>	
        )}	
        {renderBulkCheckListPopup()}
        {renderCheckListPopup()}	
        {renderModalForInstrumentPopup()}	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default AmcTransactionListing;	
