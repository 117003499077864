
// import React, { useRef, useEffect, useState } from 'react';
// import jsPDF from 'jspdf';
// import { Container, Row, Col} from 'reactstrap';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// // import Header from '../../components/Header';
// import { renderToString } from 'react-dom/server';
// import * as htmlToImage from 'html-to-image';
// import {
//   addBankTemplate,
//   getAllBankTemplates,
//   UpdateBankTemplates,
// } from './../../stores/services/template.service';
// import { Modal } from 'react-bootstrap';
// import MenuItem from '@material-ui/core/MenuItem';
// import { getAllBanks } from './../../stores/services/bank.service';
// import { GrRotateLeft } from 'react-icons/gr';
// import chequeImg from '../../assets/cheque/MCB-Cheque.jpg'

// type Coordinates = {
//   id: string;
//   x: number;
//   y: number;
//   label: string;
//   prefix: string;
//   postFix: string;
//   rotate: number;
//   isImg: Boolean;
//   bgImg: String;
//   width: string;
//   height: string;
// };

// const postionCoordinates: Coordinates[] = [
//   // {
//   //   id: 'E1',
//   //   x: 700,
//   //   y: 50,
//   //   label: 'Date',
//   //   prefix: '',
//   //   postFix: '',
//   //   rotate: 0,
//   //   isImg: false,
//   //   bgImg: '',
//   //   width: '170px',
//   //   height: '36px',
//   // },
//   // {
//   //   id: 'E2',
//   //   x: 700,
//   //   y: 90,
//   //   label: 'Name',
//   //   prefix: '',
//   //   postFix: '',
//   //   rotate: 0,
//   //   isImg: false,
//   //   bgImg: '',
//   //   width: '384px',
//   //   height: '36px',
//   // },
//   // {
//   //   id: 'E3',
//   //   x: 700,
//   //   y: 140,
//   //   label: 'Amount Fig',
//   //   prefix: '=',
//   //   postFix: '/',
//   //   rotate: 0,
//   //   isImg: false,
//   //   bgImg: '',
//   //   width: '170px',
//   //   height: '36px',
//   // },
//   // {
//   //   id: 'E4',
//   //   x: 700,
//   //   y: 170,
//   //   label: 'Amount Words',
//   //   prefix: '',
//   //   postFix: '',
//   //   rotate: 0,
//   //   isImg: false,
//   //   bgImg: '',
//   //   width: '384px',
//   //   height: '36px',
//   // },
//   // {
//   //   id: 'E5',
//   //   x: 700,
//   //   y: 230,
//   //   label: "Account Payee Only",
//   //   prefix: '',
//   //   postFix: '',
//   //   rotate: -30,
//   //   isImg: false,
//   //   bgImg: '',
//   //   width: '140px',
//   //   height: '36px',
//   // },
//   // {
//   //   id: 'E6',
//   //   x: 700,
//   //   y: 350,
//   //   label: 'Memo Space',
//   //   prefix: '',
//   //   postFix: '',
//   //   rotate: 0,
//   //   isImg: false,
//   //   bgImg: '',
//   //   width: '384px',
//   //   height: '36px',
//   // },
//   // {
//   //   id: 'E7',
//   //   x: 700,
//   //   y: 380,
//   //   label: '',
//   //   prefix: '',
//   //   postFix: '',
//   //   rotate: 0,
//   //   isImg: true,
//   //   // bgImg: './assets/cheque/DUBAI Islamic Cheques Format.png',
//   //   bgImg: chequeImg,
//   //   width: '682px',
//   //   height: '288px',
//   // }

//   {
//     id: 'E1',
//     x: 700,
//     y: 50,
//     label: 'Date',
//     prefix: '',
//     postFix: '',
//     rotate: 0,
//     isImg: false,
//     bgImg: '',
//     width: '170px',
//     height: '36px',
//   },
//   {
//     id: 'E2',
//     x: 700,
//     y: 90,
//     label: 'Name',
//     prefix: '',
//     postFix: '',
//     rotate: 0,
//     isImg: false,
//     bgImg: '',
//     width: '384px',
//     height: '36px',
//   },
//   {
//     id: 'E3',
//     x: 700,
//     y: 140,
//     label: 'Amount Fig',
//     prefix: '=',
//     postFix: '/',
//     rotate: 0,
//     isImg: false,
//     bgImg: '',
//     width: '170px',
//     height: '36px',
//   },
//   {
//     id: 'E4',
//     x: 700,
//     y: 170,
//     label: 'Amount Words',
//     prefix: '',
//     postFix: '',
//     rotate: 0,
//     isImg: false,
//     bgImg: '',
//     width: '384px',
//     height: '36px',
//   },
//   {
//     id: 'E5',
//     x: 700,
//     y: 230,
//     label: "Account Payee Only",
//     prefix: '',
//     postFix: '',
//     rotate: -30,
//     isImg: false,
//     bgImg: '',
//     width: '140px',
//     height: '36px',
//   },
//   {
//     id: 'E6',
//     x: 700,
//     y: 350,
//     label: 'Memo Space',
//     prefix: '',
//     postFix: '',
//     rotate: 0,
//     isImg: false,
//     bgImg: '',
//     width: '384px',
//     height: '36px',
//   },
//   {
//     id: 'E7',
//     x: 700,
//     y: 380,
//     label: '',
//     prefix: '',
//     postFix: '',
//     rotate: 0,
//     isImg: true,
//     // bgImg: './assets/cheque/DUBAI Islamic Cheques Format.png',
//     bgImg: chequeImg,
//     width: '682px',
//     height: '288px',
//   }
// ];

// const CanvasScreen = React.forwardRef((props: any, ref) => {
//   const [coordinates, setCoordinates] = useState(postionCoordinates);
//   const email = sessionStorage.getItem('email') || '';
//   const [name, setName] = useState('');
//   let [count, setCount] = useState(1);
//   const [allTemplates, setAllTemplates] = useState<any>([]);
//   const [editFlag, setEditFlag] = useState(false);
//   const [bank_name, setBank_name] = useState('');
//   const [selectedLabel, setSelectedLabel] = useState('');
//   // useEffect(() => {
//   //     var dateIdElement = document.getElementById("dateElement")
//   //     let tempArray: Coordinates[] = [];
//   //     postionCoordinates.map((item, index) => {
//   //         let x = ((index + 1) * 40);
//   //         item.x = dateIdElement.offsetLeft;
//   //         item.y = x + dateIdElement.offsetTop;
//   //         tempArray.push(item)
//   //     })
//   //     setCoordinates(tempArray)
//   // }, [])
//   const [checkChanges, setCheckChanges] = useState('');
//   function handleMouseMoveOnPage(evt, atrName, atrID, prefix, postFix, rotate, isImg, bgImg, width, height) {
//     let end: Coordinates = {
//       x: 0,
//       y: 0,
//       label: atrName,
//       id: atrID,
//       prefix,
//       postFix,
//       rotate,
//       isImg, 
//       bgImg,
//       width,
//       height
//     };
//     let offsetX,   offsetY = 0;
//       console.log("Client X",evt.clientX)
//       console.log("Client Y",evt.clientY)
//       if(rotate === 90){
//         evt.clientX=evt.clientX-125;
//         evt.clientY=evt.clientY-75;
//         offsetY = evt.clientY + parseInt( width.replace("px",""))/2 - parseInt(height.replace("px",""))/2;//height.replace("px","")/2
//         offsetX = evt.clientX - parseInt(width.replace("px",""))/2 - parseInt(height.replace("px",""))/2; 
//         // offsetX=evt.clientX - evt.target.left //parseInt(document.getElementsByClassName('a4-page'));
//         // offsetY=evt.clientY - evt.target.top //parseInt(document.getElementsByClassName('a4-page'));

//         console.log(evt.clientX,":", offsetX,", ",evt.clientY,":", offsetY) ;
//       }
//       else{
//         offsetX = evt.clientX - 125//- evt.target.left;
//         offsetY = evt.clientY - 65//- evt.target.top;
//       }
//     /*if (evt.clientX > 1000) {
//       offsetX = 700;
//     } else {
//       offsetX = evt.clientX;// + (evt.clientX - end.x);
//     }
//     if (evt.clientY > 840) {//840
//       offsetY = 820;
//     }else if(evt.clientY<10){
//       console.log("Client Y >>>>",evt.clientY)
//       offsetY=30;    
//     } else {
//       offsetY = evt.clientY;// +(evt.clientY-end.y) ;
//     }*/
//     end = {
//       x: offsetX,//-20
//       y: offsetY,//-20
//       label: atrName,
//       id: atrID,
//       prefix,
//       postFix,
//       rotate, 
//       isImg, 
//       bgImg,
//       width,
//       height
//     };
 
//     coordinates.map((item, index) => {
//       if (item.label === atrName) {
//         coordinates[index] = end;
//         setCoordinates(coordinates);
//         setCheckChanges(`${atrName}-${end.x}-${atrID}`);
//       }
//     });
//     // setCoordinates([end])
//   }

//   const [bankNameData, setBankNameData] = useState<any>([]);
//   const chequeRepo = {
//     'DUBAI': './assets/cheque/DUBAI Islamic Cheques Format.png',
//     'JS_Cheque': './assets/cheque/JS Cheques Format.png'
//   }

//   useEffect(() => {
//     const getTemplates = async () => {
//       setLoading(true);
//       try {
//         const response = await getAllBankTemplates(email);
//         for (let i = 0; i < response.data.data.length; i++) {
//           response.data.data[i] = {...response.data.data[i], image: chequeRepo[response.data.data[i]['template_name'].split(' ').join('_')]};
//         }
//         setAllTemplates(response.data.data);
//       } catch (error) {
//         toast.error(error.response.data.message[0]);
//       }
//       try {
//         const response = await getAllBanks(email);
//         setBankNameData(response.data.data);
//       } catch (error) {}
//       setLoading(false);
//     };
//     getTemplates();
//   }, []);
//   //render dropdown for bank name data
//   const renderBankNameDropdown = () => {
//     return bankNameData.map((item: any, index: string) => {
//       return (
//         <option key={index} value={item.bank_code}>
//           {item.bank_name}-{item.bank_code}
//         </option>
//       );
//     });
//   };
//   const getTemplates = async () => {
//     setLoading(true);
//     try {
//       const response = await getAllBankTemplates(email);
//       setAllTemplates(response.data.data);
//     } catch (error) {
//       toast.error(error.response.data.message[0]);
//     }
//     setLoading(false);
//   };

//   const [updateLoadingBtn, setUpdateLoadingBtn] = useState(false);

//   const UpdateTemplateData = async () => {
//     setUpdateLoadingBtn(true);
//     try {
//       const response = await UpdateBankTemplates(
//         email,
//         name,
//         JSON.stringify(coordinates),
//         'MCB'
//       );
//       toast.success(response.data.message);
//       getTemplates();
//       setUpdateLoadingBtn(false);
//     } catch (error) {
//       console.log(error.response.data.message);
//       toast.error(error.response.data.message);
//       setUpdateLoadingBtn(false);
//     }
//   };

//   const Prints = () => (
//     <div className="a4-page" id={'dateElement'} ref={canvasRefs}>
//       {coordinates &&
//         coordinates.map((element, index) => {
//           if (index < 4) {
//             return (              
//               <div
//               tabIndex={0}
//                 key={element.id}
//                 className="position-absolute rotate-parent cheque-index"
//                 id={element.id}
//                 style={{ 
//                   top: `${element.y}px`,
//                   left: `${element.x}px`,
//                   fontSize: '12px',
//                   overflowWrap: 'break-word',
//                   height:`${element.height}`,
//                   width:`${element.width}`,
//                   //transform-origin: "0 0",
//                   transform: `rotateZ(${element.rotate}deg)`
//                 }}
//                 onDragEnd={(e) => {
//                   handleMouseMoveOnPage(
//                     e,
//                     element.label,
//                     element.id,
//                     element.prefix,
//                     element.postFix,
//                     element.rotate,
//                     element.isImg,
//                     element.bgImg,
//                     element.width,
//                     element.height
//                   );
//                 }}
//                 draggable

//                 // onClick={(e)=>{
//                 //   setSelectedLabel(element.id)
//                 //   console.log(element.id)
//                 // }}
        
//                 onKeyDown={(e)=>{
//                   e.preventDefault();
//                   console.log('y');
//                   if(e.keyCode === 38){
//                     console.log('Arrow Up')
//                     element.y =element.y-1;
//                     document.getElementById(element.id)!.style.top = element.y.toString()+"px";
//                   }
//                   if(e.keyCode === 37){
//                     console.log('Arrow Left')
//                     element.x=element.x-1;
//                     document.getElementById(element.id)!.style.left = element.x.toString()+"px";
//                   }
//                   if(e.keyCode === 39){
//                     console.log('Arrow Right')
//                     element.x=element.x+1;
//                     document.getElementById(element.id)!.style.left = element.x.toString()+"px";
//                   } if(e.keyCode === 40){
//                     console.log('Arrow Down')
//                     element.y=element.y+1;
//                     document.getElementById(element.id)!.style.top = element.y.toString()+"px";
        
//                   }
                  
//                 }}
//               >
//                 {element.prefix}
//                 {element.label}
//                 {element.postFix}
//                 <GrRotateLeft
//                   style={{ color: 'green' }}
//                   className="ml-1 rotate-icon"
//                   onClick={() => {
//                     coordinates.map((item, index) => {
//                       if (item.label === element.label) {
//                         let tempObj = coordinates[index];
//                         if (element.rotate === 0) {
//                           tempObj.rotate = 90;
//                         } else {
//                           tempObj.rotate = 0;
//                         }
//                         coordinates[index] = tempObj;
//                         setCoordinates(coordinates);
//                         setCheckChanges(`${element.label}-${tempObj.rotate}`);
//                       }
//                     });
//                   }}
//                 />
//               </div>
//             );
//           }
//         })}

//       {/* cross Cheque */}
//       <div
//       tabIndex={0}
//         key={coordinates[4].id}
//         className="position-absolute rotate-parent cross-cheque cheque-index"
//         // id={coordinates[4].id}
//         id="chequePayees"
//         style={{ 
//           top: `${coordinates[4].y}px`,
//           left: `${coordinates[4].x}px`,
//           fontSize: '12px',
//           overflowWrap: 'break-word',
//           height:`${coordinates[4].height}`,
//           width:`${coordinates[4].width}`,
//           transform: `rotateZ(${coordinates[4].rotate}deg)`,
//         }}
//         onDragEnd={(e) => {
//           handleMouseMoveOnPage(
//             e,
//             coordinates[4].label,
//             coordinates[4].id,
//             coordinates[4].prefix,
//             coordinates[4].postFix,
//             coordinates[4].rotate,
//             coordinates[4].isImg,
//             coordinates[4].bgImg,
//             coordinates[4].width,
//             coordinates[4].height
//           );
//         }}        
//         draggable

//         // onClick={(e)=>{        
//         //   setSelectedLabel(coordinates[4].id)
//         //   console.log(coordinates[4].id)
//         // }}

//         onKeyDown={(e)=>{
//           e.preventDefault();
//           if(e.keyCode === 38){
//             console.log('Arrow Up')
//             coordinates[4].y =coordinates[4].y-1;
//             document.getElementById("chequePayees")!.style.top = coordinates[4].y.toString()+"px";
//           }
//           if(e.keyCode === 37){
//             console.log('Arrow Left')
//             coordinates[4].x=coordinates[4].x-1;
//             document.getElementById("chequePayees")!.style.left = coordinates[4].x.toString()+"px";
//           }
//           if(e.keyCode === 39){
//             console.log('Arrow Right')
//             coordinates[4].x=coordinates[4].x+1;
//             document.getElementById("chequePayees")!.style.left = coordinates[4].x.toString()+"px";
//           } if(e.keyCode === 40){
//             console.log('Arrow Down')
//             coordinates[4].y=coordinates[4].y+1;
//             document.getElementById("chequePayees")!.style.top = coordinates[4].y.toString()+"px";

//           }
          
//         }}
//       >
//         {coordinates[4].prefix}
//         {coordinates[4].label}
//         {coordinates[4].postFix}
//         <GrRotateLeft
//           style={{ color: 'green' }}
//           className="ml-1 rotate-icon"
//           onClick={() => {
//             coordinates.map((item, index) => {
//               if (item.label === coordinates[4].label) {
//                 let tempObj = coordinates[index];
//                 if (coordinates[4].rotate === -30) {
//                   tempObj.rotate = 60;
//                 } else {
//                   tempObj.rotate = -30;
//                 }
//                 coordinates[index] = tempObj;
//                 setCoordinates(coordinates);
//                 setCheckChanges(`${coordinates[4].label}-${tempObj.rotate}`);
//               }
//             });
//           }}
//         />
//       </div>

//       {/* memo box */}
//       <div
//        tabIndex={0}
//         key={coordinates[5].id}
//         className="position-absolute rotate-parent cheque-index"
//         // id={coordinates[5].id}
//         id="memoSpace"
//         style={{
//           top: `${coordinates[5].y}px`,
//           left: `${coordinates[5].x}px`,
          
//           fontSize: '12px',
//           overflowWrap: 'break-word',
//           height:`${coordinates[5].height}`,
//           width:`${coordinates[5].width}`,
//           transform: `rotateZ(${coordinates[5].rotate}deg)`,
//         }}
//         onDragEnd={(e) => {
//           handleMouseMoveOnPage(
//             e,
//             coordinates[5].label,
//             coordinates[5].id,
//             coordinates[5].prefix,
//             coordinates[5].postFix,
//             coordinates[5].rotate,
//             coordinates[5].isImg,
//             coordinates[5].bgImg,
//             coordinates[5].width,
//             coordinates[5].height
//           );
//         }}
//         draggable
//         // onClick={(e)=>{        
//         //   setSelectedLabel(coordinates[5].id)
//         //   console.log(coordinates[5].id)
//         // }}

//         onKeyDown={(e)=>{
//           e.preventDefault();
//           console.log('y');
//           if(e.keyCode === 38){
//             console.log('Arrow Up')
//             coordinates[5].y =coordinates[5].y-1;
//             document.getElementById("memoSpace")!.style.top = coordinates[5].y.toString()+"px";
//           }
//           if(e.keyCode === 37){
//             console.log('Arrow Left')
//             coordinates[5].x=coordinates[5].x-1;
//             document.getElementById("memoSpace")!.style.left = coordinates[5].x.toString()+"px";
//           }
//           if(e.keyCode === 39){
//             console.log('Arrow Right')
//             coordinates[5].x=coordinates[5].x+1;
//             document.getElementById("memoSpace")!.style.left = coordinates[5].x.toString()+"px";
//           } if(e.keyCode === 40){
//             console.log('Arrow Down')
//             coordinates[5].y=coordinates[5].y+1;
//             document.getElementById("memoSpace")!.style.top = coordinates[5].y.toString()+"px";

//           }
          
//         }}

//       >
//         {coordinates[5].prefix}
//         {coordinates[5].label}
//         {coordinates[5].postFix}
//         <GrRotateLeft
//           style={{ color: 'green' }}
//           className="ml-1 rotate-icon"
//           onClick={() => {
//             coordinates.map((item, index) => {
//               if (item.label === coordinates[5].label) {
//                 let tempObj = coordinates[index];
//                 if (coordinates[5].rotate === 0) {
//                   tempObj.rotate = 90;
//                 } else {
//                   tempObj.rotate = 0;
//                 }
//                 coordinates[index] = tempObj;
//                 setCoordinates(coordinates);
//                 setCheckChanges(`${coordinates[5].label}-${tempObj.rotate}`);
//               }
//             });
//           }}
//         />
//       </div>
      
//       <div
//       tabIndex={0}
//         key={coordinates[6].id}
//         className="position-absolute rotate-parent cross-cheque"
//         // id={coordinates[6].id}
//         id="chequeImg"
//         style={{
//           backgroundImage: `url('${coordinates[6].bgImg}')`,
//           // width: `40%`,
//           // height: `300px`,
//           // width: `17cm`,
//           // height: `7.2cm`,
//           width: `${coordinates[6].width}`,
//           height: `${coordinates[6].height}`,
//           backgroundSize: `contain`,
//           backgroundRepeat: `no-repeat`,
//           top: `${coordinates[6].y}px`,
//           left: `${coordinates[6].x}px`,
//           fontSize: '12px',
//           overflowWrap: 'break-word',
//           transform: `rotateZ(${coordinates[6].rotate}deg)`,
//         }}
//         onDragEnd={(e) => {
//           handleMouseMoveOnPage(
//             e,
//             coordinates[6].label,
//             coordinates[6].id,
//             coordinates[6].prefix,
//             coordinates[6].postFix,
//             coordinates[6].rotate,
//             coordinates[6].isImg,
//             coordinates[6].bgImg,
//             coordinates[6].width,
//             coordinates[6].height
//           );
//         }}
//         draggable

//         // onClick={(e)=>{        
//         //   setSelectedLabel(coordinates[6].id)
//         //   console.log(coordinates[6].id)
//         // }}

//         onKeyDown={(e)=>{
//           e.preventDefault();
//           console.log('y');
//           if(e.keyCode === 38){// Arrow Up
//             coordinates[6].y =coordinates[6].y-1;
//             document.getElementById("chequeImg")!.style.top = coordinates[6].y.toString()+"px";
//           }
//           if(e.keyCode === 37){//Arrow Left
//             coordinates[6].x=coordinates[6].x-1;
//             document.getElementById("chequeImg")!.style.left = coordinates[6].x.toString()+"px";
//           }
//           if(e.keyCode === 39){//Arrow Right
//             coordinates[6].x=coordinates[6].x+1;
//             document.getElementById("chequeImg")!.style.left = coordinates[6].x.toString()+"px";
//           } if(e.keyCode === 40){//Arrow Down
//             coordinates[6].y=coordinates[6].y+1;
//             document.getElementById("chequeImg")!.style.top = coordinates[6].y.toString()+"px";

//           }
          
//         }}
        
//       >
//         {coordinates[6].prefix}
//         {coordinates[6].label}
//         {coordinates[6].postFix}
//         <GrRotateLeft
//           style={{ color: 'green' }}
//           className="ml-1 rotate-icon"
//           onClick={() => {
//             coordinates.map((item, index) => {
//               if (item.label === coordinates[6].label) {
//                 let tempObj = coordinates[index];
//                 if (coordinates[6].rotate === 0) {
//                   tempObj.rotate = 90;
//                 } else {
//                   tempObj.rotate = 0;
//                 }
//                 coordinates[index] = tempObj;
//                 setCoordinates(coordinates);
//                 setCheckChanges(`${coordinates[6].label}-${tempObj.rotate}`);
//               }
//             });
//           }}
//         />
//       </div>
    
//     </div>
//   );

//   const canvasRef = React.useRef<HTMLCanvasElement>(null);
//   const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(
//     null
//   );
//   const canvasRefs = useRef(null);

//   const printContent = () => {
//     var node = document.getElementById('dateElement') as HTMLElement;
//     var Options = {
//       width: 680,
//       height: 842,
//     };
//     htmlToImage
//       .toPng(node)
//       .then(function (dataUrl) {
//         // var img = new Image(680, 842);
//         // img.src = dataUrl;
//         var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
//         const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
//         const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
//         doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight); // add image to Pdf
//         doc.save('Cheque.pdf'); // Save the cheque
//       })
//       .catch(function (error) {
//         console.error('oops, something went wrong!', error);
//       });
//   };

//   const saveChequeTemplate = async () => {
//     if (bankname !== '' && name !== '') {
//       setDisableAddBtn(true);
//       try {
//         const response = await addBankTemplate(
//           email,
//           name,
//           JSON.stringify(coordinates),
//           bankname
//         );
//         toast.success(response.data.message);
//         setNameTab(false);
//         getTemplates();
//       } catch (error) {
//         console.log(error.response.data.message);
//         toast.error(error.response.data.message);
//       }
//       setDisableAddBtn(false);
//     } else {
//       toast.error('Kindly Fill All Fields');
//     }
//   };

//   const [nameTab, setNameTab] = useState(false);
//   const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
//   const [bankname, setBankName] = useState('');

//   const renderModalForTempaletePopup = () => {
//     switch (nameTab) {
//       case true:
//         return (
//           <Modal
//             className=""
//             dialogClassName="modal90w"
//             show={true}
//             size="lg"
//             backdrop={true}
//             onHide={() => {
//               setNameTab(false);
//               setDisableAddBtn(false);
//             }}
//           > 
//             <div className="modal-header ">
//               <button
//                   aria-hidden={true}
//                   className="close"
//                   data-dismiss="modal"
//                   type="button"
//                   onClick={() => {
//                       setNameTab(false);
//                       setDisableAddBtn(false); 
//                   }}
//                   >
//                   <i className="tim-icons icon-simple-remove" />
//                   </button>
//                   <h6 className="title title-up"> Add Template </h6>
//               </div>  
//             <div className="modal-body">
//               <div className="row"> 
//                 <div className="col-md-12">
//                                 <div className="form-group">
//                                     Select Bank
//                                         <select className="form-control w-100" value={bankname} onChange={(e) => { setBankName(e.target.value) }}>
//                                         <option value="" defaultChecked hidden> Select Bank</option>
//                                         {renderBankNameDropdown()}
//                                     </select>
//                                 </div>
//                                 </div> 
//                 <div className="col-md-12">
//                                 <div className="form-group">
//                                     Name
//                                     <input type="text" value={name} className="form-control w-100" onChange={(e) => {
//                                         setName(e.target.value);
//                                     }} />
//                                 </div>
//                                 </div>

//                 <div className="col-md-12">
//                   <button
//                     className="btn btn-primary mt-2 w-100 px-3 rounded"
//                     onClick={() => {
//                       saveChequeTemplate();
//                     }}
//                     disabled={Boolean(disableAddBtn)}
//                   >
//                     {disableAddBtn ? (
//                       <>
//                         <span
//                           className="spinner-border login-txt spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>
//                         <span className="login-txt"> Loading...</span>
//                       </>
//                     ) : (
//                       <span>Save</span>
//                     )}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };
//   const [Loading, setLoading] = useState(false);

//   return (
//     <>
//     {/* <div className="content">
//         <Row>
//           <Col md="12"> */}
//       <Container fluid className="pl-1 pr-0 ">
//         <ToastContainer limit={1} />
//         <div className="row no-gutters">
//           <div className="col-12 col-sm-6 pl-0 mt-1 col-md-11 hight-sx">
//             <div className="d-flex">
//               <Prints />
//               <div className="flex glow-sp-x fields-section">
//                 <div className="border h-100">
//                   <h3 className="text-center p-3">Drag And Drop Fields</h3>
//                 </div>
//               </div>
//               <div className="Fieldslex formats-section ">
//                 <div className="border h-100 pointer">
//                   <h3 className="text-center p-3">All Cheque Formats</h3>
//                   <div className="temp-scroll">
//                     {allTemplates &&
//                       allTemplates.map((item, index) => {
//                         return (
//                           <div
//                             key={index}
//                             className="scroll-items border card-title box-report text-center mx-5 mb-3 p-3 box-items" 
//                             onClick={() => {
//                               let tempObj = JSON.parse(item.template_detail);
//                               console.log('>> this', tempObj)
                               
//                               tempObj.push(
//                               {
//                                 id: '7',
//                                 x: 76,
//                                 y: 700,
//                                 label: '',
//                                 prefix: '',
//                                 postFix: '',
//                                 rotate: 0,
//                                 isImg: true,
//                                 bgImg: chequeRepo[item['template_name'].split(' ').join('_')]
//                               });
//                               setCoordinates(tempObj);
//                               setName(item.template_name);
//                               setBank_name(item.bank_name);
//                               setEditFlag(true);
//                             }}
//                           >
//                             {item.template_name}
//                           </div>
//                         );
//                       })}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="d-flex justify-content-center ml-1 mt-2 mb-2">
//               <div className=" ">
//                 <button
//                   className="btn btn-default "
//                   onClick={() => {
//                     count = count + 1;
//                     setCount(count);
//                     setCoordinates(coordinates);
//                     setCheckChanges(`${count}-${nameTab}`);
//                     setEditFlag(false);
//                   }}
//                 >
//                   Clear
//                 </button>
//               </div>
//               <div className=" ">
//                 <button
//                   className="btn btn-secondary  ml-2"
//                   onClick={() => {
//                     printContent();
//                   }}
//                 >
//                   Print
//                 </button>
//               </div>

//               <div className=" ">
//                 <button
//                   className="btn btn-primary ml-2"
//                   onClick={() => {
//                     setNameTab(true);
//                   }}
//                 >
//                   Save
//                 </button>
//               </div>
//               {editFlag && (
//                 <div className=" ">
//                   <button
//                     className="btn btn-info ml-2"
//                     onClick={() => {
//                       UpdateTemplateData();
//                     }}
//                     disabled={Boolean(updateLoadingBtn)}
//                   >
//                     {updateLoadingBtn ? (
//                       <>
//                         <span
//                           className="spinner-border login-txt spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>
//                         <span className="login-txt"> Loading...</span>
//                       </>
//                     ) : (
//                       <span>Update</span>
//                     )}
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div style={{ display: 'none' }}>
//             {/* <Header /> */}
//           </div>
//         </div>
//         {renderModalForTempaletePopup()}
//       </Container>
//       {/* </Col>
//         </Row>
//       </div> */}
//     </>
//   );
// });

// export default CanvasScreen;


import React, { useRef, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { Container, Row, Col} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderToString } from 'react-dom/server';
import * as htmlToImage from 'html-to-image';
import {
  addBankTemplate,
  getAllBankTemplates,
  UpdateBankTemplates,
} from './../../stores/services/template.service';
import { Modal } from 'react-bootstrap';
import MenuItem from '@material-ui/core/MenuItem';
import { getAllBanks } from './../../stores/services/bank.service';
import { GrRotateLeft } from 'react-icons/gr';
import chequeImg from '../../assets/cheque/MCB-Cheque.jpg';

import UBankCheque from '../../assets/cheque/U Bank Cheque.jpg';
import JSCheque from '../../assets/cheque/JS Bank Cheque.png';
import UBLCheque from '../../assets/cheque/UBL Bank Cheque.jpg';
import FaysalBankCheque from '../../assets/cheque/Faysal Bank Cheque.jpg';
import NIBCheque from '../../assets/cheque/NIB Bank Cheque.jpg';
import SindhBankCheque from '../../assets/cheque/Sindh Bank Cheque.jpg';
import BankIslamicCheque from '../../assets/cheque/Bank Islamic Cheque.png';
import TelenorBankCheque from '../../assets/cheque/Telenor Bank Cheque.jpg';
import SambaBankCheque from '../../assets/cheque/Samba Bank Cheque.jpg';
import MCBCheque from '../../assets/cheque/MCB Cheque.jpg';
import DubaiIslamicCheque from '../../assets/cheque/Dubai Islamic Cheque.png';
import html2canvas from 'html2canvas';

type Coordinates = {
  id: string;
  x: number;
  y: number;
  label: string;
  prefix: string;
  postFix: string;
  rotate: number;
  isImg: Boolean;
  bgImg: String;
  width: string;
  height: string;
};

type cheques={
id:string;
bankcode:string,
cheque:string;
};

let chequeList : cheques[]=[
{
id:"1",
bankcode:"",
cheque:UBankCheque
},
{
  id:"2",
  bankcode:"JSBL",
  cheque:JSCheque
  },
  {
    id:"3",
    bankcode:"UNIL",
    cheque:UBLCheque
  },
    {
      id:"4",
      bankcode:"FAYS",
      cheque:FaysalBankCheque
    },
      {
        id:"5",
        bankcode:"",
        cheque:NIBCheque
      },
        {
          id:"6",
          bankcode:"SIND",
          cheque:SindhBankCheque
        },
          {
            id:"7",
            bankcode:"BKIP",
            cheque:BankIslamicCheque
          },
            {
              id:"8",
              bankcode:"",
              cheque:TelenorBankCheque
            },
              {
                id:"9",
                bankcode:"SAMB",
                cheque:SambaBankCheque
              },
              {
                id:"10",
                bankcode:"MUCB",
                cheque:MCBCheque
              },
              {
                id:"11",
                bankcode:"DUIB",
                cheque:DubaiIslamicCheque
              },
];

const postionCoordinates: Coordinates[] = [
  {
    id: 'E1',
    x: 987,
    y: 143,
    label: 'Date',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '170px',
    height: '36px',
  },
  {
    id: 'E2',
    x: 987,
    y: 185,
    label: 'Name',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '384px',
    height: '36px',
  },
  {
    id: 'E3',
    x: 987,
    y: 235,
    label: 'Amount Fig',
    prefix: '=',
    postFix: '/',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '170px',
    height: '36px',
  },
  {
    id: 'E4',
    x: 987,
    y: 280,
    label: 'Amount Words',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '384px',
    height: '36px',
  },
  {
    id: 'E5',
    x: 987,
    y: 350,
    label: "Account Payee Only",
    prefix: '',
    postFix: '',
    rotate: -30,
    isImg: false,
    bgImg: '',
    width: '140px',
    height: '36px',
  },
  {
    id: 'E6',
    x: 987,
    y: 420,
    label: 'Memo Space',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '384px',
    height: '36px',
  },
  {
    id: 'E7',
    x: 930,
    y: 460,
    label: '',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: true,
    //bgImg: chequeImg,
    bgImg:'',
    width: '682px',
    height: '288px',
  }
];

const resetCoordinates: Coordinates[] = [
  {
    id: 'E1',
    x: 987,
    y: 143,
    label: 'Date',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '170px',
    height: '36px',
  },
  {
    id: 'E2',
    x: 987,
    y: 185,
    label: 'Name',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '384px',
    height: '36px',
  },
  {
    id: 'E3',
    x: 987,
    y: 235,
    label: 'Amount Fig',
    prefix: '=',
    postFix: '/',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '170px',
    height: '36px',
  },
  {
    id: 'E4',
    x: 987,
    y: 280,
    label: 'Amount Words',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '384px',
    height: '36px',
  },
  {
    id: 'E5',
    x: 987,
    y: 350,
    label: "Account Payee Only",
    prefix: '',
    postFix: '',
    rotate: -30,
    isImg: false,
    bgImg: '',
    width: '140px',
    height: '36px',
  },
  {
    id: 'E6',
    x: 987,
    y: 420,
    label: 'Memo Space',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: false,
    bgImg: '',
    width: '384px',
    height: '36px',
  },
  {
    id: 'E7',
    x: 930,
    y: 460,
    label: '',
    prefix: '',
    postFix: '',
    rotate: 0,
    isImg: true,
    //bgImg: chequeImg,
    bgImg: '',
    width: '682px',
    height: '288px',
  }
];

const CanvasScreen = React.forwardRef((props: any, ref) => {
  let [selectedCheque, setSelectedCheque] = useState("");
  const [coordinates, setCoordinates] = useState(postionCoordinates);
  const email = sessionStorage.getItem('email') || '';
  const [name, setName] = useState('');
  let [count, setCount] = useState(1);
  const [allTemplates, setAllTemplates] = useState<any>([]);
  const [editFlag, setEditFlag] = useState(false);
  const [bank_name, setBank_name] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [bankCode, setBankCode]= useState('');
  const [bankTemplates, setBankTemplates]= useState<any>([]);
  // useEffect(() => {
  //     var dateIdElement = document.getElementById("dateElement")
  //     let tempArray: Coordinates[] = [];
  //     postionCoordinates.map((item, index) => {
  //         let x = ((index + 1) * 40);
  //         item.x = dateIdElement.offsetLeft;
  //         item.y = x + dateIdElement.offsetTop;
  //         tempArray.push(item)
  //     })
  //     setCoordinates(tempArray)
  // }, [])
  const [checkChanges, setCheckChanges] = useState('');
  function handleMouseMoveOnPage(evt, atrName, atrID, prefix, postFix, rotate, isImg, bgImg, width, height) {
    let end: Coordinates = {
      x: 0,
      y: 0,
      label: atrName,
      id: atrID,
      prefix,
      postFix,
      rotate,
      isImg, 
      bgImg,
      width,
      height
    };
    let offsetX,   offsetY = 0;
      if(rotate === 90){
        evt.clientX=evt.clientX //- 132; //left
        evt.clientY=evt.clientY //- 65; //top
        offsetY = evt.clientY + parseInt( width.replace("px",""))/2 - parseInt(height.replace("px",""))/2;//height.replace("px","")/2
        offsetX = evt.clientX - parseInt(width.replace("px",""))/2 - parseInt(height.replace("px",""))/2; 

       // console.log(evt.clientX,":", offsetX,", ",evt.clientY,":", offsetY) ;
      }
      else{
        offsetX = evt.clientX //- 132//- evt.target.left;
        offsetY = evt.clientY //- 65//- evt.target.top;
      }
    // if (evt.clientX > 1000) {
    //   offsetX = 700;
    // } else {
    //   offsetX = evt.clientX;// + (evt.clientX - end.x);
    // }
    // if (evt.clientY > 840) {//840
    //   offsetY = 820;
    // }else if(evt.clientY<10){
    //   offsetY=30;    
    // } 
    // else {
    //   offsetY = evt.clientY;// +(evt.clientY-end.y) ;
    // }

      if (evt.clientX < 132) {
      offsetX = 135;
    } 
    // else {
    //   offsetX = evt.clientX;// + (evt.clientX - end.x);
    // }
    // if (evt.clientY > 1122) {//840
    //   offsetY = 1122;
    // }
    // else 
    if(evt.clientY<80){
      offsetY=67;
    } 
    // else {
    //   offsetY = evt.clientY;// +(evt.clientY-end.y) ;
    // }
    end = {
      x: offsetX,//-20
      y: offsetY,//-20
      label: atrName,
      id: atrID,
      prefix,
      postFix,
      rotate, 
      isImg, 
      bgImg,
      width,
      height
    };
 
    coordinates.map((item, index) => {
      if (item.label === atrName) {
        coordinates[index] = end;
        setCoordinates(coordinates);
        setCheckChanges(`${atrName}-${end.x}-${atrID}`);
      }
    });
    // setCoordinates([end])
  }

  const [bankNameData, setBankNameData] = useState<any>([]);
  const chequeRepo = {
    'DUBAI': './assets/cheque/DUBAI Islamic Cheques Format.png',
    'JS_Cheque': './assets/cheque/JS Cheques Format.png'
  }

  useEffect(() => {
    const getTemplates = async () => {
      setLoading(true);
      try {
        const response = await getAllBankTemplates(email);
        for (let i = 0; i < response.data.data.length; i++) {
          response.data.data[i] = {...response.data.data[i], image: chequeRepo[response.data.data[i]['template_name'].split(' ').join('_')]};
        }
        setAllTemplates(response.data.data);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      try {
        const response = await getAllBanks(email);
        let resp=response.data.data;
       response.data.data=response.data.data.sort(function(a,b){
         if(a.bank_name < b.bank_name) { return -1; }
          if(a.bank_name > b.bank_name) { return 1; }
          return 0;
        });
        setBankNameData(response.data.data);
      } catch (error) {}
      setLoading(false);
    };
    getTemplates();
  }, []);
  //render dropdown for bank name data
  const templateOfBank=(bankcode:string)=>{
    const temps= allTemplates.filter((item: any, index: string) => {
      return (item.bank_name===bankcode);
    });

    const selectCheque = chequeList.filter((item:any)=>{
       return(item.bankcode==bankcode);
    });
    if(selectCheque.length>0){
      setSelectedCheque(selectCheque[0].cheque);
    }else{
      setSelectedCheque('');
    }


    bankTemplates.length=0;
    setBankTemplates(bankTemplates);
    setBankTemplates(temps);
  };
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code}>
          {item.bank_name}-{item.bank_code}
        </option>
      );
    });
  };
  const getTemplates = async () => {
    setLoading(true);
    try {
      const response = await getAllBankTemplates(email);
      setAllTemplates(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const [updateLoadingBtn, setUpdateLoadingBtn] = useState(false);

  const UpdateTemplateData = async () => {
    setUpdateLoadingBtn(true);
    try {
      const response = await UpdateBankTemplates(
        email,
        name,
        JSON.stringify(coordinates),
        'MCB'
      );
      toast.success(response.data.message);
      getTemplates();
      setUpdateLoadingBtn(false);
    } catch (err:any) {
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error("Request Failed!")
      }
      setUpdateLoadingBtn(false);
    }
  };

  const Prints = () => (
    <div className="a4-page" id={'dateElement'} ref={canvasRefs}>
      {coordinates &&
        coordinates.map((element, index) => {
          if (index < 4) {
            return (              
              <div
              tabIndex={0}
                key={element.id}
                className="position-absolute rotate-parent cheque-index"
                id={element.id}
                style={{ 
                  top: `${element.y}px`,
                  left: `${element.x}px`,
                  fontSize: '12px',
                  overflowWrap: 'break-word',
                  height:`${element.height}`,
                  width:`${element.width}`,
                  //transform-origin: "0 0",
                  transform: `rotateZ(${element.rotate}deg)`
                }}
                onDragEnd={(e) => {
                  handleMouseMoveOnPage(
                    e,
                    element.label,
                    element.id,
                    element.prefix,
                    element.postFix,
                    element.rotate,
                    element.isImg,
                    element.bgImg,
                    element.width,
                    element.height
                  );
                }}
                draggable
        
                onKeyDown={(e)=>{
                  e.preventDefault();
                  if(e.keyCode === 38){
                    element.y =element.y-1;
                    document.getElementById(element.id)!.style.top = element.y.toString()+"px";
                  }
                  if(e.keyCode === 37){
                    element.x=element.x-1;
                    document.getElementById(element.id)!.style.left = element.x.toString()+"px";
                  }
                  if(e.keyCode === 39){
                    element.x=element.x+1;
                    document.getElementById(element.id)!.style.left = element.x.toString()+"px";
                  } if(e.keyCode === 40){
                    element.y=element.y+1;
                    document.getElementById(element.id)!.style.top = element.y.toString()+"px";
        
                  }
                  
                }}
              >
                {element.prefix}
                {element.label}
                {element.postFix}
                <GrRotateLeft
                  style={{ color: 'green' }}
                  className="ml-1 rotate-icon"
                  onClick={() => {
                    coordinates.map((item, index) => {
                      if (item.label === element.label) {
                        let tempObj = coordinates[index];
                        if (element.rotate === 0) {
                          tempObj.rotate = 90;
                        } else {
                          tempObj.rotate = 0;
                        }
                        coordinates[index] = tempObj;
                        setCoordinates(coordinates);
                        setCheckChanges(`${element.label}-${tempObj.rotate}`);
                      }
                    });
                  }}
                />
              </div>
            );
          }
        })}

      {/* cross Cheque */}
      <div
      tabIndex={0}
        key={coordinates[4].id}
        className="position-absolute rotate-parent cross-cheque cheque-index"
        // id={coordinates[4].id}
        id="chequePayees"
        style={{ 
          top: `${coordinates[4].y}px`,
          left: `${coordinates[4].x}px`,
          fontSize: '12px',
          overflowWrap: 'break-word',
          height:`${coordinates[4].height}`,
          width:`${coordinates[4].width}`,
          transform: `rotateZ(${coordinates[4].rotate}deg)`,
        }}
        onDragEnd={(e) => {
          handleMouseMoveOnPage(
            e,
            coordinates[4].label,
            coordinates[4].id,
            coordinates[4].prefix,
            coordinates[4].postFix,
            coordinates[4].rotate,
            coordinates[4].isImg,
            coordinates[4].bgImg,
            coordinates[4].width,
            coordinates[4].height
          );
        }}        
        draggable

        onKeyDown={(e)=>{
          e.preventDefault();
          if(e.keyCode === 38){
            coordinates[4].y =coordinates[4].y-1;
            document.getElementById("chequePayees")!.style.top = coordinates[4].y.toString()+"px";
          }
          if(e.keyCode === 37){
            coordinates[4].x=coordinates[4].x-1;
            document.getElementById("chequePayees")!.style.left = coordinates[4].x.toString()+"px";
          }
          if(e.keyCode === 39){
            coordinates[4].x=coordinates[4].x+1;
            document.getElementById("chequePayees")!.style.left = coordinates[4].x.toString()+"px";
          } if(e.keyCode === 40){
            coordinates[4].y=coordinates[4].y+1;
            document.getElementById("chequePayees")!.style.top = coordinates[4].y.toString()+"px";

          }
          
        }}
      >
        {coordinates[4].prefix}
        {coordinates[4].label}
        {coordinates[4].postFix}
        <GrRotateLeft
          style={{ color: 'green' }}
          className="ml-1 rotate-icon"
          onClick={() => {
            coordinates.map((item, index) => {
              if (item.label === coordinates[4].label) {
                let tempObj = coordinates[index];
                if (coordinates[4].rotate === -30) {
                  tempObj.rotate = 60;
                } else {
                  tempObj.rotate = -30;
                }
                coordinates[index] = tempObj;
                setCoordinates(coordinates);
                setCheckChanges(`${coordinates[4].label}-${tempObj.rotate}`);
              }
            });
          }}
        />
      </div>

      {/* memo box */}
      <div
       tabIndex={0}
        key={coordinates[5].id}
        className="position-absolute rotate-parent cheque-index"
        // id={coordinates[5].id}
        id="memoSpace"
        style={{
          top: `${coordinates[5].y}px`,
          left: `${coordinates[5].x}px`,
          
          fontSize: '12px',
          overflowWrap: 'break-word',
          height:`${coordinates[5].height}`,
          width:`${coordinates[5].width}`,
          transform: `rotateZ(${coordinates[5].rotate}deg)`,
        }}
        onDragEnd={(e) => {
          handleMouseMoveOnPage(
            e,
            coordinates[5].label,
            coordinates[5].id,
            coordinates[5].prefix,
            coordinates[5].postFix,
            coordinates[5].rotate,
            coordinates[5].isImg,
            coordinates[5].bgImg,
            coordinates[5].width,
            coordinates[5].height
          );
        }}
        draggable

        onKeyDown={(e)=>{
          e.preventDefault();
          if(e.keyCode === 38){
            coordinates[5].y =coordinates[5].y-1;
            document.getElementById("memoSpace")!.style.top = coordinates[5].y.toString()+"px";
          }
          if(e.keyCode === 37){
            coordinates[5].x=coordinates[5].x-1;
            document.getElementById("memoSpace")!.style.left = coordinates[5].x.toString()+"px";
          }
          if(e.keyCode === 39){
            coordinates[5].x=coordinates[5].x+1;
            document.getElementById("memoSpace")!.style.left = coordinates[5].x.toString()+"px";
          } if(e.keyCode === 40){
            coordinates[5].y=coordinates[5].y+1;
            document.getElementById("memoSpace")!.style.top = coordinates[5].y.toString()+"px";

          }
          
        }}

      >
        {coordinates[5].prefix}
        {coordinates[5].label}
        {coordinates[5].postFix}
        <GrRotateLeft
          style={{ color: 'green' }}
          className="ml-1 rotate-icon"
          onClick={() => {
            coordinates.map((item, index) => {
              if (item.label === coordinates[5].label) {
                let tempObj = coordinates[index];
                if (coordinates[5].rotate === 0) {
                  tempObj.rotate = 90;
                } else {
                  tempObj.rotate = 0;
                }
                coordinates[index] = tempObj;
                setCoordinates(coordinates);
                setCheckChanges(`${coordinates[5].label}-${tempObj.rotate}`);
              }
            });
          }}
        />
      </div>
      
      <div
      tabIndex={0}
        key={coordinates[6].id}
        className="position-absolute rotate-parent cross-cheque"
        // id={coordinates[6].id}
        id="chequeImg"
        style={{
          // backgroundImage: `url('${coordinates[6].bgImg}')`,
          backgroundImage: `url('${selectedCheque}')`,
          // width: `40%`,
          // height: `300px`,
          // width: `17cm`,
          // height: `7.2cm`,
          width: `${coordinates[6].width}`,
          height: `${coordinates[6].height}`,
          backgroundSize: `contain`,
          backgroundRepeat: `no-repeat`,
          top: `${coordinates[6].y}px`,
          left: `${coordinates[6].x}px`,
          fontSize: '12px',
          overflowWrap: 'break-word',
          transform: `rotateZ(${coordinates[6].rotate}deg)`,
        }}
        onDragEnd={(e) => {
          handleMouseMoveOnPage(
            e,
            coordinates[6].label,
            coordinates[6].id,
            coordinates[6].prefix,
            coordinates[6].postFix,
            coordinates[6].rotate,
            coordinates[6].isImg,
            coordinates[6].bgImg,
            coordinates[6].width,
            coordinates[6].height
          );
        }}
        draggable

        onKeyDown={(e)=>{
          e.preventDefault();
          if(e.keyCode === 38){// Arrow Up
            coordinates[6].y =coordinates[6].y-1;
            document.getElementById("chequeImg")!.style.top = coordinates[6].y.toString()+"px";
          }
          if(e.keyCode === 37){//Arrow Left
            coordinates[6].x=coordinates[6].x-1;
            document.getElementById("chequeImg")!.style.left = coordinates[6].x.toString()+"px";
          }
          if(e.keyCode === 39){//Arrow Right
            coordinates[6].x=coordinates[6].x+1;
            document.getElementById("chequeImg")!.style.left = coordinates[6].x.toString()+"px";
          } if(e.keyCode === 40){//Arrow Down
            coordinates[6].y=coordinates[6].y+1;
            document.getElementById("chequeImg")!.style.top = coordinates[6].y.toString()+"px";

          }
        }}
        
      >
        {coordinates[6].prefix}
        {coordinates[6].label}
        {coordinates[6].postFix}
        <GrRotateLeft
          style={{ color: 'green' }}
          className="ml-1 rotate-icon"
          onClick={() => {
            coordinates.map((item, index) => {
              if (item.label === coordinates[6].label) {
                let tempObj = coordinates[index];
                if (coordinates[6].rotate === 0) {
                  tempObj.rotate = 90;
                } else {
                  tempObj.rotate = 0;
                }
                coordinates[index] = tempObj;
                setCoordinates(coordinates);
                setCheckChanges(`${coordinates[6].label}-${tempObj.rotate}`);
              }
            });
          }}
        />
      </div>
    
    </div>
  );

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(
    null
  );
  const canvasRefs = useRef(null);

  const printContent = () => {
    var node = document.getElementById('dateElement') as HTMLElement;
    html2canvas(node)
    .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'px', 'a4');
        pdf.addImage(imgData, 'PNG',0,0,0,0,"SRK","NONE");
        var data= JSON.parse(sessionStorage.getItem('cheque') || '');
        pdf.save(`Cheque ${data.txn_id} ${data.amc_code} ${data.execution_date}.pdf`); // Save the cheque
    })
    // var Options = {
    //   width: 680,
    //   height: 842,
    // };
    // htmlToImage
    //   .toPng(node)
    //   .then(function (dataUrl) {
    //     var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
    //     const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
    //     const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
    //     doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight); // add image to Pdf
    //     doc.save('Cheque.pdf'); // Save the cheque
    //   })
    //   .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    //   });
  };

  const saveChequeTemplate = async () => {
    if (bankname !== '' && name !== '') {
      setDisableAddBtn(true);
      try {
        const response = await addBankTemplate(
          email,
          name,
          JSON.stringify(coordinates),
          bankname
        );
        toast.success(response.data.message);
        setNameTab(false);
        getTemplates();
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setDisableAddBtn(false);
    } else {
      toast.error('Kindly Fill All Fields');
    }
  };

  const [nameTab, setNameTab] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [bankname, setBankName] = useState('');

  const renderModalForTempaletePopup = () => {
    switch (nameTab) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setNameTab(false);
              setDisableAddBtn(false);
            }}
          > 
            <div className="modal-header ">
              <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                      setNameTab(false);
                      setDisableAddBtn(false); 
                  }}
                  >
                  <i className="tim-icons icon-simple-remove" />
                  </button>
                  <h6 className="title title-up"> Add Template </h6>
              </div>  
            <div className="modal-body">
              <div className="row"> 
                <div className="col-md-12">
                                <div className="form-group">
                                    Select Bank
                                        <select className="form-control w-100" value={bankname} onChange={(e) => { setBankName(e.target.value) }}>
                                        <option value="" defaultChecked hidden> Select Bank</option>
                                        {renderBankNameDropdown()}
                                    </select>
                                </div>
                                </div> 
                <div className="col-md-12">
                                <div className="form-group">
                                    Name
                                    <input type="text" value={name} className="form-control w-100" onChange={(e) => {
                                        setName(e.target.value);
                                    }} />
                                </div>
                                </div>

                <div className="col-md-12">
                  <button
                    className="btn btn-primary mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      saveChequeTemplate();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };
  const [Loading, setLoading] = useState(false);

  return (
    <>
    {/* <div className="content">
        <Row>
          <Col md="12"> */}
      <Container fluid className="pl-1 pr-0 ">
        <ToastContainer limit={1} />
        <div className="row no-gutters">
          <div className=" pl-0 mt-1 hight-sx">
            <div className="d-flex">
              <Prints />
              <div className="flex glow-sp-x fields-section" style={{
                  backgroundColor: `#6c757d`
              }}>
                <div className="border h-100">
                  <h3 className="text-center p-3">Drag And Drop Fields</h3>
               
                </div>
              </div>
              <div className="Fieldslex formats-section ">
                <div className="border h-100 pointer">
                  <h3 className="text-center p-3">All Cheque Formats</h3>
                  <div className="col-md-12">
                                <div className="form-group">
                                    Select Bank
                                        <select className="form-control w-100" value={bankCode} onChange={(e) => { 
                                          setBankCode(e.target.value) 
                                          templateOfBank(e.target.value);
                                          }}>
                                        <option value="" defaultChecked hidden> Select Bank</option>
                                        {renderBankNameDropdown()}
                                    </select>
                                </div>
                                </div>
                  <div className="temp-scroll">
                    {/* {allTemplates &&
                      allTemplates.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="scroll-items border card-title box-report text-center mx-5 mb-3 p-3 box-items" 
                            onClick={() => {
                              let tempObj = JSON.parse(item.template_detail);
                               
                              tempObj.push(
                              {
                                id: '7',
                                x: 76,
                                y: 700,
                                label: '',
                                prefix: '',
                                postFix: '',
                                rotate: 0,
                                isImg: true,
                                bgImg: chequeRepo[item['template_name'].split(' ').join('_')]
                              });
                              setCoordinates(tempObj);
                              setName(item.template_name);
                              setBank_name(item.bank_name);
                              setEditFlag(true);
                            }}
                          >
                            {item.template_name}
                          </div>
                        );
                      })} */}
                      {bankTemplates &&
                      bankTemplates.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="scroll-items border card-title box-report text-center mx-5 mb-3 p-3 box-items" 
                            onClick={() => {
                              let tempObj = JSON.parse(item.template_detail);
                               
                              tempObj.push(
                              {
                                id: '7',
                                x: 76,
                                y: 700,
                                label: '',
                                prefix: '',
                                postFix: '',
                                rotate: 0,
                                isImg: true,
                                bgImg: chequeRepo[item['template_name'].split(' ').join('_')]
                              });
                              setCoordinates(tempObj);
                              setName(item.template_name);
                              setBank_name(item.bank_name);
                              setEditFlag(true);
                            }}
                          >
                            {item.template_name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center ml-1 mt-2 mb-2">
              <div className=" ">
                <button
                  className="btn btn-default "
                  onClick={() => {
                    count = count + 1;
                    setCount(count);
                    setCoordinates(resetCoordinates);
                    setCheckChanges(`${count}-${nameTab}`);
                    setEditFlag(false);
                  }}
                >
                  Clear
                </button>
              </div>
              <div className=" ">
                <button
                  className="btn btn-secondary  ml-2"
                  onClick={() => {
                    printContent();
                  }}
                >
                  Print
                </button>
              </div>

              <div className=" ">
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    setNameTab(true);
                  }}
                >
                  Save
                </button>
              </div>
              {editFlag && (
                <div className=" ">
                  <button
                    className="btn btn-info ml-2"
                    onClick={() => {
                      UpdateTemplateData();
                    }}
                    disabled={Boolean(updateLoadingBtn)}
                  >
                    {updateLoadingBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Update</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'none' }}>
            {/* <Header /> */}
          </div>
        </div>
        {renderModalForTempaletePopup()}
      </Container>
      {/* </Col>
        </Row>
      </div> */}
    </>
  );
});

export default CanvasScreen;