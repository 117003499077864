import React from 'react';
import { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadTransaction } from './../../stores/services/beta-transaction.service';
import {JsonToTable} from 'react-json-to-table';
import * as fs from 'fs';
import { Modal } from 'react-bootstrap';
import xls from "xlsx";

let csvToJson = require('convert-csv-to-json');
const csv = require('csvtojson/v2');

let xlsxToJson = require('convert-excel-to-json');
//const xlsx = require('xlsx-to-json');

const BulkUplaod = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [fileData, setfileData] = useState<any>([]);
  const[fileDataCount, setFileDataCount]=useState(0);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const email = sessionStorage.getItem('email') || '';

  const [errorData, setErrorData] = useState<any>([]);

  // here to upload the transation bulk upload
  const UploadTxn = async () => {
    if (file) {
      setLoading(true);
      try {
        const res = await uploadTransaction(email, fileData);
        if(res.data.message==="Transaction upload started"){
          setFile('');
          setFileName('');
          setfileData(null);
          setFileDataCount(0);
          toast.success('Transaction upload completed');
          errorData.length = 0;
          setErrorData(errorData);
        }else{
          toast.error('Error! Transaction is not uploaded');
        }
           
      } catch (error) {
        if(error.response.data.statusCode==500){
          toast.error(error.response.data.message);
        }else{
          setErrorBoolean(true);
        setErrorData(error.response.data.data);
        }
        
      }
      setLoading(false);
    } else {
      toast.error('Kindly Upload File');
    }
  };

  const upload = async (e: any) => {
    setFileError('');
    setfileData(null);
    let file = e?.target.files[0];
    let filesize=parseInt(file.size);
    if(filesize<=2097152)
    {
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    
    if (type === 'csv') {
      //code here
      setFile(file);
      setFileName(file.name);
      //start
      let jsonarray = [];
      csv()
        .fromFile(file.path)
        .then((jsonObj) => {
          jsonarray = jsonObj;
          jsonarray.forEach((element) => {});
          setfileData(jsonarray);
          setFileDataCount(jsonarray.length);
        });

      //end
    }else if(type==='xlsx'){
      setFile(file);
      setFileName(file.name);
      //start
    //   let jsonarray = [];
    //   xlsx(
    //     {
    //         input: file.path, 
    //         output: "",//file.path.replace(".xlsx",".txt"),
    //         // sheet: "Sheet1", // specific sheetname
    //         rowsToSkip: 0, // number of rows to skip at the top of the sheet; defaults to 0
    //         allowEmptyKey: false, // avoids empty keys in the output, example: {"": "something"}; default: true
    //     },
    //     function (err, result) {
    //     if (err) {
    //      console.error(err);
    //      } else {
    //       jsonarray = result;
    //       jsonarray.forEach((element) => {});
    //       setfileData(jsonarray);
    //       setFileDataCount(jsonarray.length) 
    //       }
    //     }
    // ); 
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = xls.utils.sheet_to_json(ws, { header: 2 });
      /* Update state */
     
    //  let jsonarray:any = [];
     if(data){
      // jsonarray = data;
      // jsonarray.forEach((element) => {});
      setfileData(data);
      setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  }else{
    toast.error("File size should be less than 2MB")
  }
  };

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            show={true}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBoolean(false);
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Kindly fill following fields data Missing Fields </h6>
             </div>   

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'}>Row Number</div>
                    <div className={'w-50'}>Field Name</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'}>{item.row_number}</div>
                        <div className={'w-50'}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              <Link to="/admin/transaction-types"> <i className="fa fa-angle-left"></i> </Link>
               Transaction Bulk Upload
            </h4>
            </div>
            <div className="card-body">
            <div className="">
              <div className="row">
              <div className="col-md-2">
                <label>Choose File</label>
              <div
                className="multi-input pointer"
                onClick={() => myRef1?.current?.click()}
              >
                <div className="form-group">
                  <div className="form-control">
                    {fileName === '' ? 'Upload File' : fileName}
                  </div>
                  <input
                    type="file"
                    ref={myRef1}
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={(e) => {
                      upload(e);
                    }}
                  />
                  {fileError ? (
                    <p className="error-labels error-message2">{fileError}</p>
                  ) : (
                    ''
                  )}

                </div>
              </div>
              {fileUploadingFlag && <img src="assets/loader.svg" width="50" />}
            </div>
            </div>
            </div>
            {fileDataCount<=0 ?(
              ''
            ):(
              <div className="table-responsive">
              <JsonToTable json={fileData} />
              </div>
            )}
            Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>
               
            <div className="mt-4">
              <button
                className="btn btn-primary "
                onClick={() => {
                  UploadTxn();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Upload</span>
                )}
              </button>
            </div>
          </div>
        </div>
        {renderErrorPopup()}
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default BulkUplaod;

