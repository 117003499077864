import React from 'react';
import { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { Container, Col, Row } from 'reactstrap';
import {
  getAllUnitHolders,
  updateUnitHolderStatus,
  searchUnitHolderAPI,
  uploadUnitHolder
} from '../../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { searchCheckListAPI } from '../../../stores/services/checklist.service';
const csv = require('csvtojson/v2');


const ClientList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const myRef1 = useRef<HTMLInputElement>(null);
  const [isSearchActive, setSearchActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');

  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getUnitHolders = async () => {
      setLoading(true);
      try {
        const response = await getAllUnitHolders(email, activePage, pageSize);
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10 &&
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } catch (err:any) {
        // toast.error(error.response.data.message[0]);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getUnitHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);
  const getUnitHoldersListUpdated = async () => {
    setLoading(true);
    try {
      const response = await getAllUnitHolders(email);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [clientStatus, setClientStatus] = useState('');
  const [code, setCode] = useState('');
  const [clientLoading, setClientLoading] = useState(false);
  const updateStatus = async () => {
    setClientLoading(true);
    let status = '';
    if (clientStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateUnitHolderStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      getUnitHoldersListUpdated();
    } catch (error) {}
    setClientLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].folio_no;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }
  const [errorData, setErrorData] = useState<any>([]);
    // here to upload the Unit Holder bulk upload
    const UploadTxn = async () => {
      if (file) {
        setLoading(true);
        try {
          const res = await uploadUnitHolder(email, csvData);
          setFile('');
          setFileName('');
          toast.success('Transaction upload started');
          errorData.length = 0;
          setErrorData(errorData);
        } catch (error) {
          setErrorBoolean(true);
          setErrorData(error.response.data.data);
          toast.error(error.response.data.message);
        }
        setLoading(false);
      } else {
        toast.error('Kindly Upload File');
      }
    };

  const upload = async (e: any) => {
    setFileError('');
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'csv') {
      //code here
      setFile(file);
      setFileName(file.name);
      //start
      let jsonarray = [];
      csv()
        .fromFile(file.path)
        .then((jsonObj) => {
          jsonarray = jsonObj;
          jsonarray.forEach((element) => {});
          setCsvData(jsonarray);
        });
      //const jsonArray = await csv().fromFile(csvFilePath);

      //end
    } else {
      toast.error('Please Upload Correct Format of File');
    }
  };

  const searchUnitHolderData = async () => {
    setLoading(true);
    try {
      const response = await searchUnitHolderAPI(email, searchValue);
      setData(response.data.data)
      setSearchActive(true);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      setSearchActive(false)
      getUnitHoldersListUpdated();
    } else {
      setSearchValue(e.target.value);
    }
  }
  const renderUpdateBankStatusPopup = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal60w"	
            show={true}	
          >	
        <div className="modal-header">	
          <button aria-hidden="true" className="close"  onClick={() => {	
                          setStatusSetPopup(false);	
                        }} data-dismiss="modal" type="button">	
            <i className="tim-icons icon-simple-remove"></i></button>	
            <h6 className="title title-up">Action</h6>	
            </div>	
            <div className="modal-body">	
              <div className="text-center">	
                <Row>	
                  <div className="mx-auto">	
                    {clientStatus === 'active' ? (	
                      <img src="assets/ban.svg" alt="" width="70" />	
                    ) : (	
                      <img src="assets/check.svg" alt="" width="70" />	
                    )}	
                    <h1 className="pb-3">Are You Sure?</h1>	
                    Are you sure you want to{' '}	
                    {clientStatus === 'active' ? 'Deactivate' : 'Activate'} this	
                    Client?	
                  </div>	
                </Row>	
                <Row className="mt-3">	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-primary "	
                        disabled={Boolean(clientLoading)}	
                        onClick={() => {	
                          updateStatus();	
                        }}	
                      >	
                        {clientLoading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Yes'	
                        )}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) { 
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
          > 

          <div className="modal-header">	
          <button aria-hidden="true" className="close"  
          onClick={() => {	
                          setErrorBoolean(false);
                        }} data-dismiss="modal" type="button">	
            <i className="tim-icons icon-simple-remove"></i></button>	 
            </div>

            <div className="modal-body">
              <div>
                <Row>
                  <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div>
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'}>Row Number</div>
                    <div className={'w-50'}>Field Name</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'}>{item.row_number}</div>
                        <div className={'w-50'}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const renderData = () => {	
    return res.map((items: any, index: any) => {	
      return (	
        <tr key={index}>		
          <td>{items.folio_no}</td>
          <td>{items.name}</td>		
          <td>{items.cnic}</td>		
          <td>{items.city}</td>
          <td>{items.email}</td>	
          <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>
          <td className="captilize">{items.status}</td>	
          <td className="pointer">	
            <div	
              className="mr-3"	
              onClick={() => {	
                setStatusSetPopup(true);	
                setClientStatus(items.status);	
                setCode(items.folio_no);	
              }}	
            >	
              {items.status !== 'active' ? (	
               <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                 <i className="tim-icons icon-simple-remove"> </i>
              </button>
              ) : (	
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                    <i className="fa fa-check"></i>
                </button>
              )} 

               <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"  onClick={() => {
                      sessionStorage.setItem('clientObj', JSON.stringify(items));
                      history.replace('/admin/view-client');
                    }}>
                    <i className="fa fa-eye"></i>
                </button>

                 <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                      sessionStorage.setItem('clientObj', JSON.stringify(items));
                      history.replace(`/admin/edit-client`);
                    }}>
                    <i className="tim-icons icon-pencil"></i>
                </button>
 
                </div>
            
           	
          </td>	
        </tr>	
      );	
    });	
  };	
  return (	
    <>
    
<div className="content">
        <Row>
          <Col md="12">
    <Container fluid>
      <ToastContainer limit={1} />
      <div className="card">
        <div className="d-flex align-items-center"></div>
        {!loading ? (
          <div className="">
            <div className="card-header">
              <h4 className="card-title">Client Listing</h4>
            </div>
            <div className="card-body"> 

            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="">
                      <ReactTooltip	
                        textColor="white"	
                        backgroundColor="black"	
                        effect="float"	
                       />	
                      <div className="form-group">
                      <input	
                        type="search"	
                        id="myInput"	
                        placeholder="Enter Folio Number"	
                        data-tip="Folio No"	
                        className="form-control"	
                        value={searchValue}	
                        onChange={(e) => {	
                          // setSearchValue(e.target.value);	
                          // myFunction(e);	
                          handleSearchValue(e);	
                        }}
                        onKeyPress={(e)=>{
                          let key_board_keycode = e.which || e.keyCode;
                          if(key_board_keycode == 13)
                          {
                              e.preventDefault();
                              searchUnitHolderData()
                          }
                        }}	
                      />	
                    </div>

                </div>

                <div className=" ">
                  <button className="btn btn-primary mt-a  ml-3 mr-3" onClick={searchUnitHolderData}>	
                          <i className="fa fa-search"></i> Search	
                  </button>	

                   {/* add */}
                   {/* <a className="icon " href="assets/templates/unitholder.csv"
                      data-tip="Print File">
                     <img src="assets/template.svg" alt="" width="20" />
                    </a> */}

                </div>
 

                {/* <div className="ml-3 mr-3">
                  
                 <div onClick={() => myRef1?.current?.click()}>
                      <div className="form-group">
                        <div className="form-control" data-tip="Upload File">
                        
                          {fileName === '' ? 'Upload File' : fileName}
                        </div> 
                        <input
                          type="file"
                          ref={myRef1}
                          style={{ display: 'none' }}
                          multiple={false}
                          data-tip="Upload File"	
                          onChange={(e) => {
                            upload(e);
                          }}
                        />
                        {fileError ? (
                          <p className="error-labels error-message2">{fileError}</p>
                        ) : (
                          ''
                        )}
                         
                      </div>
                    </div>
                    {fileUploadingFlag && <i className="fa fa-spinner fa-spin fa-2x" ></i> }  
                </div> */}

                <div className="">	 
                    {/* <button
                    className="btn btn-info btn-sm mt-a ml-4"
                    onClick={() => {
                      history.replace(`/view-file-data`)
                    }}
                    disabled={Boolean(loading)}
                  > 
                
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>  <i className="fa fa-upload mr-2"></i>Upload</span>
                    )}
                  </button> */}
                  </div>  

                  <div className="">	 
                    {/* <button
                    className="btn btn-primary btn-sm mt-a ml-4"
                    onClick={() => {
                      history.replace(`/upload-unit-holding`)
                    }}
                    disabled={Boolean(loading)}
                  > 
                
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>  <i className="fa fa-upload mr-2"></i>Upload Unit Holding</span>
                    )}
                  </button> */}
                  </div>

              </div>

              <div className="">
              <button
                    className="btn btn-info btn-sm mt-a ml-4"
                    onClick={() => {
                      history.replace(`/admin/view-file`)
                    }}
                    disabled={Boolean(loading)}
                  > 
                
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span> <i className="fa fa-upload mr-2"></i>Upload</span>
                    )}
                  </button>
              <button
                    className="btn btn-primary btn-sm mt-a ml-4"
                    onClick={() => {
                      history.replace(`/admin/upload-unitholding`)
                    }}
                    disabled={Boolean(loading)}
                  > 
                
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>  <i className="fa fa-upload mr-2"></i>Upload Unit Holding</span>
                    )}
                  </button>
                  <button	
                    className="btn btn-primary btn-sm ml-4 "	
                    onClick={() => {	
                      history.replace('/admin/setup-clients');	
                    }}	
                  >	
                    <i className="fa fa-plus mr-2"></i> Add New	
                  </button>	
                </div>
                </div>
               <div className="div-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Folio No</th>
                    <th>Name</th>
                    <th>CNIC</th>
                    <th>City</th>
                    <th>Email</th>
                    <th>Registration Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </table>
              </div>
              {!isSearchActive &&
              <div
                className="row p-3 pointer"
                style={{
                  float: 'right',
                }}
              >
                {/* PREVIOUS BUTTON */}
                {activePage !== paginationArr[0] ? (
                  <i
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={() => {
                      let pageArr = paginationArr;
                      let index = pageArr.findIndex((x) => x === activePage);
                      if (index !== -1) {
                        setActivePage(paginationArr[index - 1]);
                      }
                    }}
                  ></i>
                ) : (
                  ''
                )}
                {/* NEXT BUTTON */}
                {activePage !== paginationArr[paginationArr.length - 1] ? (
                  <i
                    className="fa ml-3 fa-arrow-right"
                    aria-hidden="true"
                    onClick={() => {
                      let pageArr = paginationArr;
                      let index = pageArr.findIndex((x) => x === activePage);
                      if (index !== -1) {
                        setActivePage(paginationArr[index + 1]);
                      }
                    }}
                  ></i>
                ) : (
                  ''
                )}
              </div>} 
          </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center my-5">
             <i className="fa fa-spinner fa-spin fa-3x"></i>
          </div>
        )}
        {renderUpdateBankStatusPopup()}
        {renderErrorPopup()}
      </div>
    </Container>
    </Col>
        </Row>
      </div>
  </>
  );	
};	
export default ClientList;