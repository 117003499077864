import React, { useState } from 'react';
import jsPDF from 'jspdf';
import { Container } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../components/Header';
import * as htmlToImage from 'html-to-image';

import { FiUploadCloud } from 'react-icons/fi';
import Dropzone from 'react-dropzone';
import { useHistory } from 'react-router-dom';

export type Coordinates = {
  id: string;
  x: number;
  y: number;
  classes: string;
  label: string;
  value: string;
};

const PrintLeter = React.forwardRef((props: any, ref) => {
  const history = useHistory();
  let templateData = JSON.parse(sessionStorage.getItem('letterTemplate') || '');
  const [headLabel, setHeadLabel] = React.useState(''); // label represent transaction name

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
  }, [props.match.params.type]);

  const [coordinates, setCoordinates] = useState(templateData);
  const email = sessionStorage.getItem('email') || '';
  const [name, setName] = useState('');
  let [count, setCount] = useState(1);
  const [allTemplates, setAllTemplates] = useState<any>([]);
  const [editFlag, setEditFlag] = useState(false);
  const [bank_name, setBank_name] = useState('');
  const [checkChanges, setCheckChanges] = useState('');

  const [bankNameData, setBankNameData] = useState<any>([]);

  const [file, setFile] = useState('');
  const handleDrop = (acceptedFiles: any) => {
    var url = URL.createObjectURL(acceptedFiles[0]);
    setFile(url);
  };

  const Prints = () => (
    <div className="a4-page" id={'dateElement'}>
      <div className="w-100">
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              {!file ? (
                <div className="drop-container">
                  <div className=" text-center">
                    <FiUploadCloud className="upload-icon" />
                    <h6 style={{ fontSize: '10px' }}>Upload</h6>
                  </div>
                </div>
              ) : (
                <img src={file} height={90} className="pt-2 pl-2" alt="" />
              )}
            </div>
          )}
        </Dropzone>
      </div>
      {coordinates &&
        coordinates.map((element, index) => {
          return (
            <div
              key={element.id}
              className={
                'position-absolute rotate-parent ' +
                element.classes +
                ' ' +
                (index === 23 ? 'width-half' : '')
              }
              id={element.id}
              style={{
                top: `${element.y}px`,
                left: `${element.x}px`,
                fontSize: '12px',
                overflowWrap: 'break-word',
              }}
            >
              <div className="d-flex">
                <div className={' ' + (element.value ? 'label-width' : '')}>
                  {element.label}
                </div>
                {element.value && <div>:{element.value}</div>}
              </div>
            </div>
          );
        })}
    </div>
  );

  const printContent = () => {
    var node = document.getElementById('dateElement') as HTMLElement;
    var Options = {
      width: 680,
      height: 842,
    };
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image(680, 842);
        img.src = dataUrl;
        var doc = new jsPDF('p', 'px', 'a4'); // make A4 size Pdf file
        const pdfWidth = doc.internal.pageSize.getWidth(); // set It width
        const pdfHeight = doc.internal.pageSize.getHeight(); // set its Height
        doc.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight); // add image to Pdf
        doc.save('Cheque.pdf'); // Save the cheque
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const [nameTab, setNameTab] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [bankname, setBankName] = useState('');

  const [Loading, setLoading] = useState(false);
  const [alignLeft, setAlignLeft] = useState('0');

  const alignLeftFunction = () => {
    if (+alignLeft < 880 && +alignLeft > 0) {
      coordinates &&
        coordinates.map((element, index) => {
          element.x = +alignLeft;
        });
      setCoordinates(coordinates);
      setCheckChanges(`${alignLeft}-${alignLeft}`);
    }
  };

  const AddFile = async (event: any) => {
    let file = event.target.files;
    handleDrop(file);
  };

  return (
    <>
      <Container fluid className="pl-1 pr-0">
        <ToastContainer limit={1} />
            {/* <Header /> */}

        <div className="row no-gutters">
          <div className="col-12 col-sm-6 pl-0 mt-1 col-md-11 hight-sx">
            <div className="d-flex">
              <Prints />
              <div className="d-flex  justify-content-center fields-section">
                <div className="h-100">
                  <h3 className="text-center p-3 card-title">Add Values</h3>
                  <div className="form-group">

                    <textarea
                      className="form-control"
                      placeholder="Type Note"
                      onChange={(e) => {
                        coordinates[23].label = e.target.value;
                        setCoordinates(coordinates);
                        setCheckChanges(
                          `${coordinates[23].label}-${e.target.value}`
                        );
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type Heading"
                      onChange={(e) => {
                        coordinates[7].label = e.target.value;
                        setCoordinates(coordinates);
                        setCheckChanges(
                          `${coordinates[7].label}-${e.target.value}`
                        );
                      }}
                    />
                  </div>
                  <div className="  mt-3">
                    <input
                      type="file"
                      className="form-control"
                      id="browser"
                      onChange={AddFile}
                      multiple={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* alignLeftFunction */}
            <div className="d-flex mt-2 mb-2">
              <div className="">
                <button
                  className="btn btn-default ml-2"
                  onClick={() => {
                    if (headLabel === 'list') {
                      history.replace('/transaction-listing');
                    } else {
                      history.replace('/pending-transactions');
                    }
                  }}
                >
                  Back
                </button>
              </div>
              <div className="">
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    printContent();
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
          <div style={{ display: 'none' }}>
            <Header />
          </div>
        </div>
      </Container>
    </>
  );
});

export default PrintLeter;
