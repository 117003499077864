// import React, { useState } from 'react';	
// import Header from '../../components/Header';	
// import { Container, Row, Col,FormGroup, Card, Label, Input } from 'reactstrap';	
// import { ToastContainer, toast } from 'react-toastify';	
// import { Modal } from 'react-bootstrap';	
// import 'react-toastify/dist/ReactToastify.css';	
// import { updateTransactionStatus } from '../../stores/services/transactions.service';	
// import { useHistory } from 'react-router-dom';	
// import moment from 'moment';	
// import { getTaxAmount } from '../../stores/services/tax.service';	
// import ReactTooltip from 'react-tooltip';	
// import { AiFillInfoCircle, AiFillEye } from 'react-icons/ai';	
// import { getChecklist } from '../../stores/services/checklist.service';	
// import CheckListItem from '../../components/checklist-item';	
// import ViewTransaction from './../transactions/view-beta-txn';	
// const SignatoryA = (props: any) => {	
//   const history = useHistory();	
//   const [values, setValues] = useState<any>([]);	
//   const [headLabel, setHeadLabel] = useState('');	
//   const [checklistData, setCheckListData] = useState<any>([]);	
//   const [note, setNote] = useState('');	
//   const [trx_id, setTrxId] = useState('');	
//   const [trx_status, setTrxStatus] = useState('');	
//   const [status, setStatus] = useState('');	
//   const [statusError, setStatusError] = useState('');	
//   const [trx_history, setTrxHistory] = useState([]);	
//   const [wht_tax, setWhtTax] = useState('');	
//   const [net_tax, setNetTax] = useState('');	
//   const [sst_tax, setSstTax] = useState('');	
//   const [Loading, setLoading] = useState(false);	
//   const [viewChecklist, setViewCheckList] = useState(false);	
//   const [trx_checklist, setTrxCheckList] = useState([]);	
//   const [data, setData] = useState<any>([]);	
//   const role_name = sessionStorage.getItem('role') || '';	
//   async function calculateAllTax(obj: any) {	
//     let SST_Tax,	
//       SST_Variable,	
//       WHT_Tax,	
//       Net__Tax = '';	
//     try {	
//       const response = await getTaxAmount(	
//         email,	
//         obj.type_of_transaction,	
//         obj.gross_amount,	
//         'SST'	
//       );	
//       if (response.data.data == 'empty') {	
//         setSstTax('0');	
//         setWhtTax('0');	
//         setNetTax('0');	
//         return;	
//       }	
//       SST_Tax = response.data.data;	
//       setSstTax(SST_Tax);	
//       let totalAmount: number = SST_Tax + parseInt(obj.gross_amount);	
//       const whtTaxRes = await getTaxAmount(	
//         email,	
//         obj.type_of_transaction,	
//         totalAmount.toString(),	
//         'W.H.T'	
//       );	
//       WHT_Tax = whtTaxRes.data.data;	
//       setWhtTax(WHT_Tax);	
//       let sst20 = +response.data.data * 0.2;	
//       SST_Variable = sst20;	
//       let x: any = totalAmount - whtTaxRes.data.data;	
//       x = x - sst20;	
//       Net__Tax = x;	
//       setNetTax(Net__Tax);	
//     } catch (error) { }	
//   }	
//   React.useEffect(() => {	
//     setHeadLabel(props.match.params.type);	
//     const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');	
//     setData(obj);	
//     setTrxId(obj.txn_id);	
//     // if (props.match.params.type !== 'compliance') {	
//     // if (props.match.params.type === 'compliance' || props.match.params.type === 'signatory-a' || props.match.params.type === 'signatory-b') {	
//     //   setTrxHistory(obj.txn_history);	
//     // }	
//     setTrxHistory(obj.txn_history);
//     setTrxStatus(obj.txn_status);	
//     calculateAllTax(obj);	
//     // setView(true);	
//     //console.log('obj', obj)	
//     let objArr = Object.entries(obj);	
//     setValues(objArr);	
//   }, [props.match.params.type]);	
//   React.useEffect(() => {	
//     const getAllChecklists = async () => {	
//       try {	
//         const response = await getChecklist(email, role_name);	
//         setCheckListData(response.data.data);	
//       } catch (error) {	
//         toast.error(error.response.data.message[0]);	
//       }	
//     };	
//     getAllChecklists();	
//     // eslint-disable-next-line react-hooks/exhaustive-deps	
//   }, []);	
//   const titleCase = (value: string) => {	
//     let sentence = value.toLowerCase().split('_');	
//     for (let i = 0; i < sentence.length; i++) {	
//       sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
//     }	
//     return sentence.join(' ');	
//   };	
//   const email = sessionStorage.getItem('email') || '';	
//   const acceptRejectTransaction = (status: string) => {	
//     setReasonText('');	
//     setLoading(true);	
//   updateTransactionStatus(email, status, trx_id, reasonText, checklistData)	
//       .then((response) => {	
//         setLoading(false);	
//         //console.log(response.data.message);	
//         toast.success(response.data.message);	
//         setTimeout(() => {	
//           history.replace(`/comp-sign-transactions/${props.match.params.type}`);	
//         }, 1000);	
//       })	
//       .catch((err) => {	
//         console.log(err);	
//         setLoading(false);	
//         toast.error(err.response.data.message);	
//       });	
//   };	
//   const [reasonText, setReasonText] = useState('');	
//   const [reasonTextError, setReasonTextError] = useState('');	
//   const [rejectLoading, setRejectLoading] = useState(false);	 
  
//   const updateTxStatus = async () => {
//     const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');
//     const isValid = validate();
//     if (isValid) {
//       if (status === 'accept') { 
//         setStatusError('');
//         if (headLabel.toUpperCase() === 'COMPLIANCE') {
//           if (trx_status === 'COMPLIANCE') {
//             acceptRejectTransaction('INREVIEW');
//           } else {
//             toast.error(
//               'You cannot Accept Transaction as Transaction is with Signatory A or B'
//             );
//           }
//         } else if (headLabel.toUpperCase() === 'SIGNATORY-A' || 
//         headLabel.toUpperCase() === 'INREVIEW' || 
//         headLabel.toUpperCase() === 'CONCERNED-OFFICER' || 
//         headLabel.toUpperCase() === 'SIGNATORY-B') {
//           if (trx_status === 'INREVIEW') {
//             if(role_name === 'SIGNATORY-A' || headLabel.toUpperCase() === 'SIGNATORY-A'){
//               acceptRejectTransaction('SIGNATORY-B');
//             }else{
//               acceptRejectTransaction('SIGNATORY-A');
//             }
//             // if (headLabel.toUpperCase() === 'SIGNATORY-A') {
//             //   acceptRejectTransaction('SIGNATORY-B');
//             // } else {
//             //   acceptRejectTransaction('SIGNATORY-A');
//             // }
//           } else if (trx_status === 'SIGNATORY-A' || trx_status === 'SIGNATORY-B') {
//             acceptRejectTransaction('COMPLETED');
//           } else if (trx_status === 'CONCERNED-OFFICER') {
//               acceptRejectTransaction('COMPLIANCE');
//            } else {
//             toast.error(
//               'You cannot Accept Transaction as Compliance hasnt verified yet'
//             );
//           }
//         }
//       } else {
//         // on Reject Transaction
//         //check if atleast one checklist is na
//         // let isSomeNA = checklistData.some((val) => {
//         //   return val.value === 'na';
//         // });
//         // if (!isSomeNA) {
//         //   setStatusError('Atleast one checklist must be Na');
//         //   return;
//         // }
//         setStatusError('');
//         if (reasonText.trim() === '') {
//           setReasonTextError('Required');
//         } else {
//           setReasonTextError('');
//           if (obj.made_by === 'AMC') {
//             acceptRejectTransaction('REJECTED-AMC');
//           } else acceptRejectTransaction('REJECTED-TRUSTEE');
//         }
//       }
//     }
//   };	
//   const validate = () => {	
//     let statusErr = '';	
//     status.trim() === '' ? (statusErr = 'Required') : (statusErr = '');	
//     if (statusErr) {	
//       setStatusError(statusErr);	
//       return false;	
//     } else {	
//       return true;	
//     }	
//   };	
//   const updateFormData = (obj, id) => {	
//     let temp = checklistData;	
//     temp[id] = obj;	
//     setCheckListData(temp);	
//   };	
//   const renderTooltip = (value: any) => {	
//     switch (titleCase(value[0])) {	
//       case 'Wht':	
//         return wht_tax;	
//       case 'Sst':	
//         return sst_tax;	
//       case 'Net Amount':	
//         return net_tax;	
//       default:	
//         return '';	
//     }	
//   };	
//   const renderIcon = (value: any) => {	
//     switch (titleCase(value[0])) {	
//       case 'Wht':	
//         return true;	
//       case 'Sst':	
//         return true;	
//       case 'Net Amount':	
//         return true;	
//       default:	
//         return false;	
//     }	
//   };	
//   const [statusSetPopup, setStatusSetPopup] = useState(false);	
//   const [checklistSetPopup, setCheckListSetPopup] = useState(false);	
//   const renderRejectPopup = () => {	
//     switch (statusSetPopup) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal60w"	
//             show={true}	
//           >	
//             <div className="modal-body">	
//               <div className="text-center">	
//                 <p >Rejection Reason</p>	
//                 <div className="input-holder">	
//                   <textarea className={"form-control w-100"} value={reasonText} onChange={(e) => { setReasonText(e.target.value); setReasonTextError('') }}></textarea>	
//                 </div>	
//                 <Row>	
//                   <Col >	
//                     <div className=" ">	
//                       <button className="btn btn-primary" disabled={Boolean(rejectLoading)} onClick={() => { updateTxStatus() }}>	
//                         {rejectLoading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
//                           <span className="login-txt"> Loading...</span></> : 'Confirm'}	
//                       </button>	
//                     </div>	
//                   </Col>	
//                   <Col>	
//                     <div className="">	
//                       <button className="btn btn-default" onClick={() => { setStatusSetPopup(false) }}>	
//                         Cancel	
//                     </button>	
//                     </div>	
//                   </Col>	
//                 </Row>	
//               </div>	
//             </div>	
//           </Modal >	
//         )	
//       default:	
//         return '';	
//     }	
//   };	
//   const viewCheckListPopup = () => {	
//     switch (viewChecklist) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="xl"	
//           > 	
//             <div className="modal-header ">	
//                 <button	
//                   aria-hidden={true}	
//                   className="close"	
//                   data-dismiss="modal"	
//                   type="button"	
//                   onClick={() => {	
//                     setViewCheckList(false);	
//                   }}	
//                 >	
//                   <i className="tim-icons icon-simple-remove" />	
//                 </button>	
//                 <h6 className="title title-up"> Approval Check List  </h6>	
//               </div>	
//             <div className="modal-body">	
//               <div className=" "> 	
//               <div className={'checlist-scroll'}>
//                 {trx_checklist.map((val: any, ind: any) => {	
//                   return (	
//                     <Row key={ind} >	
                      	
//                       <Col md="5">	
//                       <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>	
//                       </Col>	
//                       <Col md="7">	
//                         <Row>	
//                           <Col md="3">	
                         	
//                               <FormGroup check>	
//                                 <Label check>	
//                                   <Input 	
//                                   checked={val.value == 'compliant'}	
//                                   type="checkbox"	
//                                   value="compliant"	
//                                   readOnly />	
//                                   <span className="form-check-sign" />	
//                                   Compliant	
//                                 </Label>	
//                               </FormGroup>	
//                               </Col>	
//                               <Col md="2"> 	
//                            <FormGroup check>	
//                             <Label check>	
//                               <Input type="checkbox" 	
//                               checked={val.value == 'na'}	
//                               value="na"	
//                               readOnly />	
//                               <span className="form-check-sign" />	
//                               N /A	
//                             </Label>	
//                           </FormGroup>	
//                               </Col>	
//                               <Col md="7">	
//                               <FormGroup>	
//                                 <input	
//                                   type="text"	
//                                   placeholder="Comments"	
//                                   value={val.comment}	
//                                   className="form-control"	
//                                   readOnly	
//                                 />	
//                                 </FormGroup>	
                            	
//                           </Col>	
//                         </Row>	 
//                       </Col>	
//                     </Row>	
//                   );	
//                 })}	
//                 </div>
//                 <Row>	
//                   <Col>	
//                     <div className="mt-4">	
//                       <button	
//                         className="btn btn-default"	
//                         onClick={() => {	
//                           setViewCheckList(false);	
//                         }}	
//                       >	
//                         Cancel	
//                       </button>	
//                     </div>	
//                   </Col>	
//                 </Row>	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   const renderCheckListPopup = () => {	
//     switch (checklistSetPopup) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="lg"	
//           >	
//             <div className="modal-header">	
//               <button	
//                 aria-hidden={true}	
//                 className="close"	
//                 data-dismiss="modal"	
//                 type="button"	
//                 onClick={() => {	
//                   setCheckListSetPopup(false);	
//                 }}	
//               >	
//                 <i className="tim-icons icon-simple-remove" />	
//               </button>	
//               <h6 className="title title-up">  Approval Check List</h6>	
//             </div> 	
//             <div className="modal-body">	
      
//               <div className=" "> 	
//               <div className={'checlist-scroll'}>
//                 {checklistData.map((val: any, ind: any) => {	
//                   return (	
//                     <CheckListItem	
//                       updateFormData={updateFormData}	
//                       val={val}	
//                       ind={ind}	
//                     />	
//                   );	
//                 })}	
//                 </div>
//                 <Row  >	
//                 <Col md="12">	
//                   <div className="d-flex">	
                    	
//                     <FormGroup check className="mr-3">	
//                         <Label check>	
//                           <Input type="checkbox"	
//                            checked={status === 'accept'} 	
//                            value="accept"	
//                            onChange={(e) => {	
//                              setReasonText('')	
//                              setStatusError('');	
//                              setStatus(e.target.value);	
//                            }} />	
//                           <span className="form-check-sign" />	
//                           Accept	
//                         </Label>	
//                       </FormGroup>	
//                       <FormGroup check>	
//                         <Label check>	
//                           <Input type="checkbox" 	
//                           checked={status === 'reject'}	
//                           value="reject"	
//                           onChange={(e) => {	
//                             setStatusError('');	
//                             setStatus(e.target.value);	
//                           }}	
//                           />	
//                           <span className="form-check-sign" />	
//                           Reject	
//                         </Label>	
//                       </FormGroup>	
 	
//                   </div>	
//                   </Col>	
//                 </Row>	
//                 <Row>	
//                 <Col md="12">
//                   {statusError ? (	
//                     <text className="error-lab">{statusError}</text>	
//                   ) : (	
//                       ''	
//                     )}	
//                      </Col>
//                 </Row>	 
//                   {status === 'reject' && (	
//                   <Row>	
//                     <div className="col-md-12">	
//                     <div className="form-group">	
//                       <textarea	
//                         placeholder="Reason"	
//                         className={	
//                           'form-control w-100'	
//                         }	
//                         value={reasonText}	
//                         onChange={(e) => {	
//                           setReasonText(e.target.value);	
//                           setReasonTextError('');	
//                         }}	
//                       ></textarea>	
//                     </div>	
//                     </div>	
//                     {reasonTextError && (	
//                       <text className="error-lab">{reasonTextError}</text>	
//                     )}	
//                   </Row>	
//                 )}	
//                 <Row className="mt-4"> 	
//                     <div className=" ">	
//                       <button	
//                         className="btn btn-primary mr-3"	
//                         disabled={Boolean(Loading)}	
//                         onClick={() => {	
//                           updateTxStatus();	
//                         }}	
//                       >	
//                         {Loading ? (	
//                           <>	
//                             <span	
//                               className="spinner-border login-txt spinner-border-sm"	
//                               role="status"	
//                               aria-hidden="true"	
//                             ></span>	
//                             <span className="login-txt"> Loading...</span>	
//                           </>	
//                         ) : (	
//                             'Submit'	
//                           )}	
//                       </button>	
//                     </div> 	
//                     <div className="">	
//                       <button	
//                         className="btn btn-default"	
//                         onClick={() => {	
//                           setCheckListSetPopup(false);	
//                         }}	
//                       >	
//                         Cancel	
//                       </button>	
//                     </div> 	
//                 </Row>	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   const renderPsxData = () => {	
//     return securites.map((items: any, index: number) => {	
//       return (	
//         <tr key={index}>	
//           <td>{items.type}</td>	
//           <td>{items.market}</td>	
//           <td>{items.symbol}</td>	
//           <td>{items.quantity}</td>	
//           <td>{items.avg_rate}</td>	
//           <td>{items.gross_amount}</td>	
//           <td>{items.deductions}</td>	
//           <td>{items.additions}</td>	
//           <td>{items.net_amount}</td>	
//         </tr>	
//       );	
//     })	
//   }	
//   const [flagForSecutities, setFlagForSecutities] = useState(false);	
//   const [securites, setSecurites] = useState<any>([]);	
//   const renderSecuritiesModalPopup = () => {	
//     switch (flagForSecutities) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="lg"	
//             backdrop={true}	
//             onHide={() => { setFlagForSecutities(false) }}	
//           >	  
//           <div className="modal-header ">
//             <button
//               aria-hidden={true}
//               className="close"
//               data-dismiss="modal"
//               type="button"
//               onClick={() => {	
//                 setFlagForSecutities(false);	 
//               }}
//             >
//               <i className="tim-icons icon-simple-remove" />
//             </button>
//             <h6 className="title title-up">View Security </h6>
//           </div>

//             <div className="modal-body">	
//               <div className="">	
//                 {securites.length > 0 &&	
//                   <div className="input-size-pos">	
//                     <table className="table">	
//                       <thead>	
//                         <tr>	
//                           <th>Type</th>	
//                           <th>Market</th>	
//                           <th>Symbol</th>	
//                           <th>Quantity</th>	
//                           <th>Avg Rate</th>	
//                           <th>Gross Amount</th>	
//                           <th>Deduction</th>	
//                           <th>Addition</th>	
//                           <th>Net Amount</th>	
//                         </tr>	
//                       </thead>	
//                       <tbody >	
//                         {renderPsxData()}	
//                       </tbody>	
//                     </table>	
//                   </div>}	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   //render table data for tax	
//   //view tax table	
//   const [viewTaxModal, setTaxModal] = useState(false);	
//   const [totalTax, setTotalTax] = useState<any>([]);	
//   //render Tax table data 	
//   const renderTaxData = () => {	
//     return totalTax.map((items: any, index: number) => {	
//       return (	
//         <tr key={index}>	
//           <td>{items.name}</td>	
//           <td>{items.amount}</td>	
//           <td>{items.calculatedAmount}</td>	
//         </tr>	
//       );	
//     })	
//   }	
//   const renderTaxPopup = () => {	
//     switch (viewTaxModal) {	
//       case true:	
//         return (	
//           <Modal	
//             className=""	
//             dialogClassName="modal90w"	
//             show={true}	
//             size="lg"	
//             backdrop={true}	
//             onHide={() => { setTaxModal(false) }}	
//           >	
//             <Modal.Header closeButton onClick={() => {	
//               setTaxModal(false);	
//               // clearTaxobject();	
//             }}>	
//               <Modal.Title>View Txn Charges</Modal.Title>	
//             </Modal.Header>	
//             <div className="modal-body">	
//               <div className="">	
//                 {securites.length > 0 &&	
//                   <div className="input-size-pos">	
//                     <table className="table ">	
//                       <thead>	
//                         <tr>	
//                           <th>Tax Name</th>	
//                           <th>Tax Amount</th>	
//                           <th>Calculated Amount</th>	
//                         </tr>	
//                       </thead>	
//                       <tbody >	
//                         {renderTaxData()}	
//                       </tbody>	
//                     </table>	
//                   </div>}	
//               </div>	
//             </div>	
//           </Modal>	
//         );	
//       default:	
//         return '';	
//     }	
//   };	
//   return (	
//     <>	
//       <Container fluid>	
//         <Header />	
//         <ToastContainer limit={1} />	
//         <div className="  ">	
//           <div className="">	
//             {/* <Link to="/comp-sign-transactions/compliance"><img src="assets/arrow-left.svg" alt="" width="24" /></Link>	 */}
//             <h4 className="card-title">Accept/Reject Transaction - <span className="color-primary"> {headLabel.toUpperCase()} </span></h4>	
//           </div>	
//           <div className=""> 	
//             <div className="card-body">	
//             <ViewTransaction data={data} flag={true} />	
//             </div>	
//             <Row>	
//               <Col md="6">	
//                 <div className="row">	
//                    <div className="col-md-12">	
//                     <button className="btn btn-primary mr-4 ml-2 bg-positive" disabled={Boolean(Loading)}	
//                       onClick={() => {	
//                         setCheckListSetPopup(true)	
//                       }}	
//                     >	
//                       Action	
//                     </button>	
//                   </div>	
//                   {/* <div className="hov">	
//                     <button className="btn-3 bg-positive" onClick={() => { setCheckListSetPopup(true) }} >	
//                       <p>Reject</p>	
//                     </button>	
//                   </div> */}	
//                 </div>	
//               </Col>	
//             </Row>	
//             <div className="line"></div>	
//             <Row className="">	
//               {	
//                 trx_history.map((hist: any, index: number) => {	
//                   return (	
//                     <Col md="3"	
//                       onClick={() => {	
//                         if (hist.action === 'CREATED') {	
//                           return	
//                         }	 
//                         if (!hist.check_list)	
//                           return	
//                         setTrxCheckList(hist.check_list);	
//                         setViewCheckList(true);	
//                       }}>	
                      
//                       <div className="card process-card pointer ml-2 ">	
//                         <div className="card-header">	 
//                           <h4 className="card-title">	
//                           <span className="card-number">
//                           {index + 1}
//                           </span>
//                            <span className="mr-1">
//                                 </span> <i className="tim-icons icon-single-02 mr-1"></i> {hist.updated_by_role}
//                           </h4>	

//                        </div>	
//                       <div className="card-body text-center">	 
                          
//                         <p className="small mt-2">Status:</p>	
//                         <h3 className="accepted text-primary">  {hist.action}    </h3>	 
//                         {hist.updated_at !== '' ? (	
//                           <>	
//                           <p className="small mt-2">by</p>	
//                           <h4 className="comment text-primary">{(hist.updated_by)}</h4>	 
//                             <p className="small mt-2">at</p>	
//                             <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MMM-YYYY, h:mm a")}</h4>	 
//                           </>	
//                         ) : (	
//                             ''	
//                           )}	
//                         {hist.note !== '' ? (	
//                           <>	
//                             <p className="small mt-2">Comment</p>	
//                             <small className="comment dot-text" title={hist.note}>{hist.note}</small>	
//                           </>	
//                         ) : (	
//                             ''	
//                           )}	
//                         {	
//                           hist.created_by !== '' ? (	
//                             <>	
//                             <p className="small mt-2">by</p>	
//                             <h4 className="text-primary" >{hist.created_by}</h4>	
//                               <p className="small mt-2">at</p>	
//                               <h4 className="comment text-primary">  {moment(hist.created_at).format("DD-MMM-YYYY, h:mm a")}</h4>	
//                             </>	
//                           ) : (	
//                               ''	
//                             )}	
//                       </div>	
//                       </div>	
//                     </Col>	
//                   );	
//                 })	
//               }	
//             </Row>	
//             {viewCheckListPopup()}	
//             {renderRejectPopup()}	
//             {renderCheckListPopup()}	
//             {renderSecuritiesModalPopup()}	
//             {renderTaxPopup()}	
//           </div>	
//         </div>	
//       </Container>	
//     </>	
//   );	
// };	
// export default SignatoryA;

import React, { useState } from 'react';		
import { Container, Row, Col,FormGroup, Card, Label, Input } from 'reactstrap';	
import { ToastContainer, toast } from 'react-toastify';	
import { Modal } from 'react-bootstrap';	
import 'react-toastify/dist/ReactToastify.css';	
import { updateTransactionStatus } from '../../stores/services/transactions.service';	
import { useHistory } from 'react-router-dom';	
import moment from 'moment';	
import { getTaxAmount } from '../../stores/services/tax.service';	
import ReactTooltip from 'react-tooltip';	
import { AiFillInfoCircle, AiFillEye } from 'react-icons/ai';	
import { getChecklist } from '../../stores/services/checklist.service';	
import CheckListItem from '../../components/checklist-item';	
import ViewTransaction from './../transactions/view-beta-txn';	
import { Prev } from 'react-bootstrap/esm/PageItem';
import { Link } from 'react-router-dom';
const SignatoryA = (props: any) => {
  // let counter='';	
  const history = useHistory();	
  const [values, setValues] = useState<any>([]);	
  const [headLabel, setHeadLabel] = useState('');	
  const [checklistData, setCheckListData] = useState<any>([]);	
  const [note, setNote] = useState('');	
  const [trx_id, setTrxId] = useState('');	
  const [trx_status, setTrxStatus] = useState('');	
  let [counter, setCounter] = useState('');	
  const [status, setStatus] = useState('');	
  const [statusError, setStatusError] = useState('');	
  const [trx_history, setTrxHistory] = useState([]);	
  const [wht_tax, setWhtTax] = useState('');	
  const [net_tax, setNetTax] = useState('');	
  const [sst_tax, setSstTax] = useState('');	
  const [Loading, setLoading] = useState(false);	
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  const [data, setData] = useState<any>([]);	
  const role_name = sessionStorage.getItem('role') || '';	
  async function calculateAllTax(obj: any) {	
    let SST_Tax,	
      SST_Variable,	
      WHT_Tax,	
      Net__Tax = '';	
    try {	
      const response = await getTaxAmount(	
        email,	
        obj.type_of_transaction,	
        obj.gross_amount,	
        'SST'	
      );	
      if (response.data.data == 'empty') {	
        setSstTax('0');	
        setWhtTax('0');	
        setNetTax('0');	
        return;	
      }	
      SST_Tax = response.data.data;	
      setSstTax(SST_Tax);	
      let totalAmount: number = SST_Tax + parseInt(obj.gross_amount);	
      const whtTaxRes = await getTaxAmount(	
        email,	
        obj.type_of_transaction,	
        totalAmount.toString(),	
        'W.H.T'	
      );	
      WHT_Tax = whtTaxRes.data.data;	
      setWhtTax(WHT_Tax);	
      let sst20 = +response.data.data * 0.2;	
      SST_Variable = sst20;	
      let x: any = totalAmount - whtTaxRes.data.data;	
      x = x - sst20;	
      Net__Tax = x;	
      setNetTax(Net__Tax);	
    } catch (error) { }	
  }	
  React.useEffect(() => {	
    setHeadLabel(props.match.params.type);	
    const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');	
    setData(obj);	
    setTrxId(obj.txn_id);	
    // if (props.match.params.type !== 'compliance') {	
    // if (props.match.params.type === 'compliance' || props.match.params.type === 'signatory-a' || props.match.params.type === 'signatory-b') {	
    //   setTrxHistory(obj.txn_history);	
    // }	
    setTrxHistory(obj.txn_history);
    setTrxStatus(obj.txn_status);	
    setCounter(obj.counter);	
    calculateAllTax(obj);	
    // setView(true);	
    let objArr = Object.entries(obj);	
    setValues(objArr);	
  }, [props.match.params.type]);	
  React.useEffect(() => {	
    const getAllChecklists = async () => {	
      try {	
        const response = await getChecklist(email, role_name);	
        setCheckListData(response.data.data);	
      } catch (error) {	
        toast.error(error.response.data.message[0]);	
      }	
    };	
    getAllChecklists();	
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, []);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };	
  const email = sessionStorage.getItem('email') || '';	
  const acceptRejectTransaction = (status: string) => {	
    setReasonText('');	
    setLoading(true);	
  updateTransactionStatus(email, status, trx_id, reasonText, checklistData, counter)	
      .then((response) => {	
        setLoading(false);	
        if(response.data.status===409){
          toast.error(response.data.message);
        }else{
        toast.success(response.data.message);
        }	
        setTimeout(() => {	
          history.replace(`/admin/comp-sign-transactions/${props.match.params.type}`);	
        }, 1000);	
      })	
      .catch((err) => {	
        setLoading(false);	
        toast.error(err.response.data.message);	
      });	
  };	
  const [reasonText, setReasonText] = useState('');	
  const [reasonTextError, setReasonTextError] = useState('');	
  const [rejectLoading, setRejectLoading] = useState(false);	 
  
  const updateTxStatus = async () => {
    const obj = JSON.parse(sessionStorage.getItem('csab-txn') || '');
    const isValid = validate();
    if (isValid) {
      if (status === 'accept') { 
        setStatusError('');
        if (headLabel.toUpperCase() === 'COMPLIANCE') {
          if (trx_status === 'COMPLIANCE') {
            acceptRejectTransaction('INREVIEW');
          } else {
            toast.error(
              'You cannot Accept Transaction as Transaction is with Signatory A or B'
            );
          }
        } else if (headLabel.toUpperCase() === 'SIGNATORY-A' || 
        headLabel.toUpperCase() === 'INREVIEW' || 
        headLabel.toUpperCase() === 'CONCERNED-OFFICER' || 
        headLabel.toUpperCase() === 'SIGNATORY-B') {
          if (trx_status === 'INREVIEW') {
            if(role_name === 'SIGNATORY-A' || headLabel.toUpperCase() === 'SIGNATORY-A'){
              if(counter=='0' || counter == 'undefined'){
                counter='1';
                acceptRejectTransaction('INREVIEW');
              }else if(counter=='1') {
                counter='2';
                acceptRejectTransaction('COMPLETED');
              }
             
            }else if(role_name === 'SIGNATORY-B' || headLabel.toUpperCase() === 'SIGNATORY-B'){
              if(counter=='0'){
                // let c=parseInt(counter)+1;
                // counter=c.toString();
                counter='1';
                acceptRejectTransaction('SIGNATORY-A');
              }else{
                // let c=parseInt(counter)+1;
                // counter=c.toString();
                counter='2';
                acceptRejectTransaction('COMPLETED');
              }
              // acceptRejectTransaction('SIGNATORY-A');
            }
          } else if (trx_status === 'SIGNATORY-A' || trx_status === 'SIGNATORY-B') {
            counter='2';
            acceptRejectTransaction('COMPLETED');
          } else if (trx_status === 'CONCERNED-OFFICER') {
              acceptRejectTransaction('COMPLIANCE');
           } else {
            toast.error(
              'You cannot Accept Transaction as Compliance hasnt verified yet'
            );
          }
        }
      } else {
        // on Reject Transaction
        //check if atleast one checklist is na
        // let isSomeNA = checklistData.some((val) => {
        //   return val.value === 'na';
        // });
        // if (!isSomeNA) {
        //   setStatusError('Atleast one checklist must be Na');
        //   return;
        // }
        setStatusError('');
        if (reasonText.trim() === '') {
          setReasonTextError('Required');
        } else {
          setReasonTextError('');
          if (obj.made_by === 'AMC') {
            acceptRejectTransaction('REJECTED-AMC');
          } else acceptRejectTransaction('REJECTED-TRUSTEE');
        }
      }
    }
  };	
  const validate = () => {	
    let statusErr = '';	
    status.trim() === '' ? (statusErr = 'Required') : (statusErr = '');	
    if (statusErr) {	
      setStatusError(statusErr);	
      return false;	
    } else {	
      return true;	
    }	
  };	
  const updateFormData = (obj, id) => {	
    let temp = checklistData;	
    temp[id] = obj;	
    setCheckListData(temp);	
  };	
  const renderTooltip = (value: any) => {	
    switch (titleCase(value[0])) {	
      case 'Wht':	
        return wht_tax;	
      case 'Sst':	
        return sst_tax;	
      case 'Net Amount':	
        return net_tax;	
      default:	
        return '';	
    }	
  };	
  const renderIcon = (value: any) => {	
    switch (titleCase(value[0])) {	
      case 'Wht':	
        return true;	
      case 'Sst':	
        return true;	
      case 'Net Amount':	
        return true;	
      default:	
        return false;	
    }	
  };	
  const [statusSetPopup, setStatusSetPopup] = useState(false);	
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);	
  const renderRejectPopup = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal60w"	
            show={true}	
          >	
            <div className="modal-body">	
              <div className="text-center">	
                <p >Rejection Reason</p>	
                <div className="input-holder">	
                  <textarea className={"form-control w-100"} value={reasonText} onChange={(e) => { setReasonText(e.target.value); setReasonTextError('') }}></textarea>	
                </div>	
                <Row>	
                  <Col >	
                    <div className=" ">	
                      <button className="btn btn-primary" disabled={Boolean(rejectLoading)} onClick={() => { updateTxStatus() }}>	
                        {rejectLoading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                          <span className="login-txt"> Loading...</span></> : 'Confirm'}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className="">	
                      <button className="btn btn-default" onClick={() => { setStatusSetPopup(false) }}>	
                        Cancel	
                    </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal >	
        )	
      default:	
        return '';	
    }	
  };	
  const viewCheckListPopup = () => {	
    switch (viewChecklist) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="xl"	
          > 	
            <div className="modal-header ">	
                <button	
                  aria-hidden={true}	
                  className="close"	
                  data-dismiss="modal"	
                  type="button"	
                  onClick={() => {	
                    setViewCheckList(false);	
                  }}	
                >	
                  <i className="tim-icons icon-simple-remove" />	
                </button>	
                <h6 className="title title-up"> Approval Check List  </h6>	
              </div>	
            <div className="modal-body">	
              <div className=" "> 	
              <div className={'checlist-scroll'}>
                {trx_checklist.map((val: any, ind: any) => {	
                  return (	
                    <Row key={ind} >	
                      	
                      <Col md="5">	
                      <text className="text-primary">{val.serial_no}. </text> <text>{val.message}</text>	
                      </Col>	
                      <Col md="7">	
                        <Row>	
                          <Col md="3">	
                         	
                              <FormGroup check>	
                                <Label check>	
                                  <Input 	
                                  checked={val.value == 'compliant'}	
                                  type="checkbox"	
                                  value="compliant"	
                                  readOnly />	
                                  <span className="form-check-sign" />	
                                  Compliant	
                                </Label>	
                              </FormGroup>	
                              </Col>	
                              <Col md="2"> 	
                           <FormGroup check>	
                            <Label check>	
                              <Input type="checkbox" 	
                              checked={val.value == 'na'}	
                              value="na"	
                              readOnly />	
                              <span className="form-check-sign" />	
                              N /A	
                            </Label>	
                          </FormGroup>	
                              </Col>	
                              <Col md="7">	
                              <FormGroup>	
                                <input	
                                  type="text"	
                                  placeholder="Comments"	
                                  value={val.comment}	
                                  className="form-control"	
                                  readOnly	
                                />	
                                </FormGroup>	
                            	
                          </Col>	
                        </Row>	 
                      </Col>	
                    </Row>	
                  );	
                })}	
                </div>
                <Row>	
                  <Col>	
                    <div className="mt-4">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setViewCheckList(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderCheckListPopup = () => {	
    switch (checklistSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
          >	
            <div className="modal-header">	
              <button	
                aria-hidden={true}	
                className="close"	
                data-dismiss="modal"	
                type="button"	
                onClick={() => {	
                  setCheckListSetPopup(false);	
                }}	
              >	
                <i className="tim-icons icon-simple-remove" />	
              </button>	
              <h6 className="title title-up">  Approval Check List</h6>	
            </div> 	
            <div className="modal-body">	
      
              <div className=" "> 	
              <div className={'checlist-scroll'}>
                {checklistData.map((val: any, ind: any) => {	
                  return (	
                    <CheckListItem	
                      updateFormData={updateFormData}	
                      val={val}	
                      ind={ind}	
                    />	
                  );	
                })}	
                </div>
                <Row  >	
                <Col md="12">	
                  <div className="d-flex">	
                    	
                    <FormGroup check className="mr-3">	
                        <Label check>	
                          <Input type="checkbox"	
                           checked={status === 'accept'} 	
                           value="accept"	
                           onChange={(e) => {	
                             setReasonText('')	
                             setStatusError('');	
                             setStatus(e.target.value);	
                           }} />	
                          <span className="form-check-sign" />	
                          Accept	
                        </Label>	
                      </FormGroup>	
                      <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox" 	
                          checked={status === 'reject'}	
                          value="reject"	
                          onChange={(e) => {	
                            setStatusError('');	
                            setStatus(e.target.value);	
                          }}	
                          />	
                          <span className="form-check-sign" />	
                          Reject	
                        </Label>	
                      </FormGroup>	
 	
                  </div>	
                  </Col>	
                </Row>	
                <Row>	
                <Col md="12">
                  {statusError ? (	
                    <text className="error-lab">{statusError}</text>	
                  ) : (	
                      ''	
                    )}	
                     </Col>
                </Row>	 
                  {status === 'reject' && (	
                  <Row>	
                    <div className="col-md-12">	
                    <div className="form-group">	
                      <textarea	
                        placeholder="Reason"	
                        className={	
                          'form-control w-100'	
                        }	
                        value={reasonText}	
                        onChange={(e) => {	
                          setReasonText(e.target.value);	
                          setReasonTextError('');	
                        }}	
                      ></textarea>	
                    </div>	
                    </div>	
                    {reasonTextError && (	
                      <text className="error-lab">{reasonTextError}</text>	
                    )}	
                  </Row>	
                )}	
                <Row className="mt-4"> 	
                    <div className=" ">	
                      <button	
                        className="btn btn-primary mr-3"	
                        disabled={Boolean(Loading)}	
                        onClick={() => {	
                          updateTxStatus();	
                        }}	
                      >	
                        {Loading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                            'Submit'	
                          )}	
                      </button>	
                    </div> 	
                    <div className="">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setCheckListSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div> 	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderPsxData = () => {	
    return securites.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.type}</td>	
          <td>{items.market}</td>	
          <td>{items.symbol}</td>	
          <td>{items.quantity}</td>	
          <td>{items.avg_rate}</td>	
          <td>{items.gross_amount}</td>	
          <td>{items.deductions}</td>	
          <td>{items.additions}</td>	
          <td>{items.net_amount}</td>	
        </tr>	
      );	
    })	
  }	
  const [flagForSecutities, setFlagForSecutities] = useState(false);	
  const [securites, setSecurites] = useState<any>([]);	
  const renderSecuritiesModalPopup = () => {	
    switch (flagForSecutities) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => { setFlagForSecutities(false) }}	
          >	  
          <div className="modal-header ">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {	
                setFlagForSecutities(false);	 
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">View Security </h6>
          </div>

            <div className="modal-body">	
              <div className="">	
                {securites.length > 0 &&	
                  <div className="input-size-pos">	
                    <table className="table">	
                      <thead>	
                        <tr>	
                          <th>Type</th>	
                          <th>Market</th>	
                          <th>Symbol</th>	
                          <th>Quantity</th>	
                          <th>Avg Rate</th>	
                          <th>Gross Amount</th>	
                          <th>Deduction</th>	
                          <th>Addition</th>	
                          <th>Net Amount</th>	
                        </tr>	
                      </thead>	
                      <tbody >	
                        {renderPsxData()}	
                      </tbody>	
                    </table>	
                  </div>}	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  //render table data for tax	
  //view tax table	
  const [viewTaxModal, setTaxModal] = useState(false);	
  const [totalTax, setTotalTax] = useState<any>([]);	
  //render Tax table data 	
  const renderTaxData = () => {	
    return totalTax.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.name}</td>	
          <td>{items.amount}</td>	
          <td>{items.calculatedAmount}</td>	
        </tr>	
      );	
    })	
  }	
  const renderTaxPopup = () => {	
    switch (viewTaxModal) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => { setTaxModal(false) }}	
          >	
            <Modal.Header closeButton onClick={() => {	
              setTaxModal(false);	
              // clearTaxobject();	
            }}>	
              <Modal.Title>View Txn Charges</Modal.Title>	
            </Modal.Header>	
            <div className="modal-body">	
              <div className="">	
                {securites.length > 0 &&	
                  <div className="input-size-pos">	
                    <table className="table ">	
                      <thead>	
                        <tr>	
                          <th>Tax Name</th>	
                          <th>Tax Amount</th>	
                          <th>Calculated Amount</th>	
                        </tr>	
                      </thead>	
                      <tbody >	
                        {renderTaxData()}	
                      </tbody>	
                    </table>	
                  </div>}	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>		
        <ToastContainer limit={1} />	
        <div className="  ">	
          <div className="">	
            {/* <Link to="/comp-sign-transactions/compliance"><img src="assets/arrow-left.svg" alt="" width="24" /></Link>	 */}
         
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-2 mt-1 pointer" onClick={() => {
                      history.replace('/admin/'+headLabel.toLowerCase())
                  }}></i>
              Accept/Reject Transaction - <span className="color-primary"> {headLabel.toUpperCase()} </span></h4>	
          </div>	
          <div className=""> 	
            <div className="card-body">	
            <ViewTransaction data={data} flag={true} />	
            </div>	
            <Row>	
              <Col md="6">	
                <div className="row">	
                   <div className="col-md-12">	
                    <button className="btn btn-primary mr-4 ml-2 bg-positive" disabled={Boolean(Loading)}	
                      onClick={() => {	
                        setCheckListSetPopup(true)	
                      }}	
                    >	
                      Action	
                    </button>	
                  </div>	
                  {/* <div className="hov">	
                    <button className="btn-3 bg-positive" onClick={() => { setCheckListSetPopup(true) }} >	
                      <p>Reject</p>	
                    </button>	
                  </div> */}	
                </div>	
              </Col>	
            </Row>	
            <div className="line"></div>	
            <Row className="">	
              {	
                trx_history.map((hist: any, index: number) => {	
                  return (	
                    <Col md="3"	
                      onClick={() => {	
                        if (hist.action === 'CREATED') {	
                          return	
                        }	 
                        if (!hist.check_list)	
                          return	
                        setTrxCheckList(hist.check_list);	
                        setViewCheckList(true);	
                      }}>	
                      
                      <div className="card process-card pointer ml-2 ">	
                        <div className="card-header">	 
                          <h4 className="card-title">	
                          <span className="card-number">
                          {index + 1}
                          </span>
                           <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> {hist.updated_by_role}
                          </h4>	

                       </div>	
                      <div className="card-body text-center">	 
                          
                        <p className="small mt-2">Status:</p>	
                        <h3 className="accepted text-primary">  {hist.action}    </h3>	 
                        {hist.updated_at !== '' ? (	
                          <>	
                          <p className="small mt-2">by</p>	
                          <h4 className="comment text-primary">{(hist.updated_by)}</h4>	 
                            <p className="small mt-2">at</p>	
                            <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MMM-YYYY, h:mm a")}</h4>	 
                          </>	
                        ) : (	
                            ''	
                          )}	
                        {hist.note !== '' ? (	
                          <>	
                            <p className="small mt-2">Comment</p>	
                            <small className="comment dot-text" title={hist.note}>{hist.note}</small>	
                          </>	
                        ) : (	
                            ''	
                          )}	
                        {	
                          hist.created_by !== '' ? (	
                            <>	
                            <p className="small mt-2">by</p>	
                            <h4 className="text-primary" >{hist.created_by}</h4>	
                              <p className="small mt-2">at</p>	
                              <h4 className="comment text-primary">  {moment(hist.created_at).format("DD-MMM-YYYY, h:mm a")}</h4>	
                            </>	
                          ) : (	
                              ''	
                            )}	
                      </div>	
                      </div>	
                    </Col>	
                  );	
                })	
              }	
            </Row>	
            {viewCheckListPopup()}	
            {renderRejectPopup()}	
            {renderCheckListPopup()}	
            {renderSecuritiesModalPopup()}	
            {renderTaxPopup()}	
          </div>	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default SignatoryA;