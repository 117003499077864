import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import {
  getAmc,
  updateAmcStatus,
  searchAPI,
} from '../../../stores/services/amc.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CgImage } from 'react-icons/cg';
import Config from './../../../config/index';

const AmcList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');
  const [isSearchActive, setSearchActive] = useState(false);
  const [amcCode, setAmcCode] = useState('');
  const [amcName, setAmcName] = useState('');
  React.useEffect(() => {
    const getAmcListData = async () => {
      setLoading(true);
      try {
        const response = await getAmc(email, activePage, pageSize);
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10 &&
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } catch (err:any) {
        // toast.error(error.response.data.message[0]);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getAmcListData();
  }, [activePage]);
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }
  const getUpdatedList = async () => {
    setLoading(true);
    try {
      const response = await getAmc(email, activePage, pageSize);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
      // setRes(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const searchAMCData = async () => {
    setLoading(true);
    try {
      const response = await searchAPI(email, searchValue);
      setData(response.data.data);
      setSearchActive(true);
      setPageSize('10');
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      setSearchActive(false);
      setPageSize('10');
      getUpdatedList();
    } else {
      setSearchValue(e.target.value);
    }
  };

  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentAmcStatus, setCurrentAmcStatus] = useState('');
  const [code, setCode] = useState('');
  const [amcLoading, setAmcLoading] = useState(false);
  const updateStatus = async () => {
    setAmcLoading(true);
    let status = '';
    if (currentAmcStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateAmcStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      getUpdatedList();
    } catch (error) {}
    setAmcLoading(false);
  };

  const renderComponents = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
          className=" "	
          dialogClassName="modal60w"	
          show={true}	
        >	
              <div className="modal-header justify-content-center">	
                      <button	
                        aria-hidden={true}	
                        className="close"	
                        data-dismiss="modal"	
                        type="button"	
                        onClick={() => { setStatusSetPopup(false) }}	
                      >	
                        <i className="tim-icons icon-simple-remove" />	
                      </button>	
                      <h6 className="title title-up">Action</h6>	
                    </div>	
                    <div className="modal-body">	
                      <div className="text-center">	
                        <Row>	
                          <div className="mx-auto">	
                            {currentAmcStatus === 'active' ? <img src="assets/ban.svg" alt="" width="70" /> : <img src="assets/check.svg" alt="" width="70" />}	
                            <h1 className="pb-3">Are You Sure?</h1>	
                                Are you sure you want to {currentAmcStatus === 'active' ? 'Deactivate' : 'Activate'} this AMC?	
                          </div>	
                        </Row>	
                        <Row className="mt-3">	
                          <Col >	
                            <div className="">	
                              <button className="btn btn-primary" disabled={Boolean(amcLoading)} onClick={() => { updateStatus() }}>	
                                {amcLoading ? <><span  className="fa fa-spinner fa-spin p-3 " role="status" aria-hidden="true"></span>	
                                  {/* <span className="login-txt"> Loading...</span> */}
                                  </> : 'Yes'}	
                              </button>	
                            </div>	
                          </Col>	
                          <Col>	
                            <div className="">	
                              <button className="btn btn-default" onClick={() => { setStatusSetPopup(false) }}>	
                                Cancel	
                            </button>	
                            </div>	
                          </Col>	
                        </Row>	
                      </div>	
          </div>	
        </Modal >	
        )	
      default:	
        return '';	
    }	
  };	
  const [showEvidence, setShowEvidence] = useState(false);	
  const [imageURl, setImageUrl] = useState('');	
  const renderEvidenceImage = () => {	
    switch (showEvidence) {	
      case true:	
        return (	
          <Modal	
            className=" "	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => { setShowEvidence(false) }}	
          > 	
            <div className="modal-header ">	
                        <button	
                          aria-hidden={true}	
                          className="close"	
                          data-dismiss="modal"	
                          type="button"	
                          onClick={() => {	
                            setShowEvidence(false);	
                          }} // clearTaxobject();		
                         >	
                          <i className="tim-icons icon-simple-remove" />	
                        </button>	
                        <h6 className="title title-up">Evidence</h6>	
                      </div> 	
            	
            <div className="modal-body">	
              <div className="text-center">	
                <img className="url-image-sx" src={`${Config.imageBaseUrl}${imageURl}`} alt="" />	
              </div>	
            </div>	
          </Modal >	
        )	
      default:	
        return '';	
    }	
  };	

  // modal
  const [amcDetailSetPopup, setAmcDetailSetPopup] = useState(false);	

  const renderData = () => {	
    return res.map((items: any, index: any) => {	
      return (	
        <tr key={index}>	
          <td>{items.amc_code}</td>	
          <td>{items.name}</td>	
          <td>{items.concerned_officer}</td>	
          <td>{items.subtitute_concerned_officer}</td>	
          <td>{items.status}</td>	
          <td  className="pointer"	
            onClick={() => {	
              setStatusSetPopup(true);	
              setCurrentAmcStatus(items.status);	
              setCode(items.amc_code)	
            }}	
          >	
            {items.status !== 'active' ?	 
              <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                <i className="tim-icons icon-simple-remove">
              </i></button> 
              :   
              <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                 <i className="fas fa-check"></i> 
                 </button>}
          </td>	
          <td className="pointer">	 
            <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"onClick={() => {	
              sessionStorage.setItem('amcObj', JSON.stringify(items));	
              history.replace('/admin/view-amc')	
            }}>
              <i className="fa fa-eye">  </i>
            </button>

            <ReactTooltip textColor='white' backgroundColor='black' effect="float" />	
            {items.evidence && (
              <CgImage
                className="ml-2"
                data-tip={'View Evidence'}
                onClick={() => {
                  setShowEvidence(true);
                  setImageUrl(items.evidence);
                }}
              />
            )} 
            <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                sessionStorage.setItem('amcobj', JSON.stringify(items));
                history.replace(`/admin/edit-amc`);
              }}>
                <i className="tim-icons icon-pencil"> </i>
            </button>

          </td>	
        </tr>	
      );	
    })	
  }	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card "> 	
          <div className="card-header">	
            <h4 className="card-title">AMC Listing</h4>	
          </div> 	
          {!Loading ?	
            <div className="card-body"> 	
              <div className="input-holder">  	
                    <div className="form-group inline-box ">	
                    <ReactTooltip textColor='white' backgroundColor='black' effect="float" />	
                    <input type="search" id="myInput" placeholder="Enter AMC Name" className="form-control mr-2" data-tip="Amc Name" value={searchValue} onChange={(e: any) => {	
                      // setSearchValue(e.target.value);	
                      handleSearchValue(e)	
                      // myFunction(e);	
                    }}
                    onKeyPress={(e)=>{
                      let key_board_keycode = e.which || e.keyCode;
                      if(key_board_keycode == 13)
                      {
                          e.preventDefault();
                          searchAMCData()
                      }
                    }}
                    
                    />	
                      <button className="ml-3 btn btn-primary mt-a" onClick={searchAMCData}> <i className="fa fa-search"></i> Search </button>	
                  </div>	
              	
                  <div className="">	
                      <a className="btn btn-primary btn-sm mb-0" onClick={() => {	
                        history.replace('/admin/add-amc')	
                      }}>	
                        <i className="fa fa-plus mr-2"></i>Add New	
                      </a>	
                  </div>	
                	
               	
              </div>	
              <div className="div-table">	 
              <table className="table " id="myTable" >	
                <thead>	
                  <tr>	
                    <th>AMC Code</th>	
                    <th>Name for AMC</th>	
                    <th>Concerned Officer</th>	
                    <th>Substitute Concerned Officer</th>	
                    <th>Status</th>	
                    <th  >Action</th>	
                    <th>Details</th>	
                  </tr>	
                </thead>	
                <tbody id="myUL">	
                  {renderData()}	
                </tbody>	
              </table>	 
              <div	
                className="row pr-3 pointer"	
                style={{	
                  float: 'right',	
                }}	
              >	
                {/* PREVIOUS BUTTON */}	
                {activePage !== paginationArr[0] ? (	
                  <i className="fa fa-arrow-left" aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index - 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                    ''	
                  )}	
                {/* NEXT BUTTON */}	
                {activePage !== paginationArr[paginationArr.length - 1] ? (	
                  <i className="fa ml-3 fa-arrow-right" aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {
                        setActivePage(paginationArr[index + 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                    ''	
                  )}	
              </div>	
            </div>	
            </div>	
            :	
            <div className="d-flex justify-content-center my-5">	
               <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
            </div>}	
          {renderComponents()}	
          {renderEvidenceImage()}	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  )	
};	
export default AmcList;	
