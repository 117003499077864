import React from 'react';		
import {	
  addBroker,	
  getBrokerType,	
} from '../../../stores/services/broker.service';	
import { getAllBanks } from '../../../stores/services/bank.service';	
import { getBranchByBankName } from '../../../stores/services/branch.service';	
import { useState } from 'react';	
import { Link } from 'react-router-dom';	
import { Container, Row, Col, CardHeader, CardTitle, CardBody, Card } from 'reactstrap';	
import { ToastContainer, toast } from 'react-toastify';	
const BrokerComponent = () => {	
  //hooks for getting all inputs from user	
  const [bankname, setBankName] = useState('');	
  const [branchName, setBranchName] = useState('');	
  const [brokerName, setBrokerName] = useState('');	
  const [brokerType, setBrokerType] = useState('');	
  const [revenueAuthority, setRevenueAuthority] = useState('');	
  const [iban, setIban] = useState('');	
  const [acc_title, setAccTitle] = useState('');	
  const [code, setCode] = useState('');	
  const email = sessionStorage.getItem('email') || '';	
  const [companyName, setCompanyName] = useState('');	
  //error getting hooks	
  const [bankNameError, setBankNameError] = useState('');	
  const [branchNameError, setBranchNameError] = useState('');	
  const [brokerNameError, setBrokerError] = useState('');	
  const [brokerTypeError, setBrokerTypeError] = useState('');	
  const [revenueAuthorityError, setRevenueAuthorityError] = useState('');	
  const [ibanError, setIbanError] = useState('');	
  const [acc_titleError, setAccTitleError] = useState('');	
  const [codeError, setCodeError] = useState('');	
  const [Loading, setLoading] = useState(false);	
  const [companyNameError, setCompanyNameError] = useState('');	
  const [brokerTypeData, setBrokerTypeData] = useState<any>([]);	
  const [ branchCheckFlag , setBranchCheckFlag] = useState(false);	

  const [active, setActive] = useState('');
  //	
  const codeRegex = new RegExp('^[A-Z]{1,3}-[0-9]{1,3}$');	
  //for dropdown data	
  const [bankNameData, setBankNameData] = useState<any>([]);	
  const [allbranchesData, setAllBranchesData] = useState<any>([]);	
  const getBranchByName = async (name: string) => {	
    allbranchesData.length = 0;	
    setAllBranchesData(allbranchesData);	
    setBranchName('');	
    setBranchNameError('');	
    //get all branches for selected bank for dropdown	
    try {	
      const response = await getBranchByBankName(email, name);	
      setAllBranchesData(response.data.data);	
      if(response.data.data.length > 0){	
        setBranchCheckFlag(false)	
      }else{	
        setBranchCheckFlag(true)	
      }	
    } catch (error) {}	
  };	
  React.useEffect(() => {	
    const fetchBank = async () => {	
      bankNameData.length = 0;	
      setBankNameData(bankNameData);	
      //get all banks list for dropdown	
      try {	
        const response = await getAllBanks(email);	
        setBankNameData(response.data.data);	
      } catch (error) {}	
      try {	
        const res = await getBrokerType(email);	
        setBrokerTypeData(res.data.company_names);	
      } catch (error) {}	
      //get tx nature data for dropdown	
    };	
    fetchBank();	
  }, []);	
  const changeCodeValue = (value: any) => {	
    setCode(value);	
    if (value.trim() === '') {	
      setCodeError('Required');	
    } else {	
      codeRegex.test(value) !== true	
        ? setCodeError('Invalid')	
        : setCodeError('');	
    }	
  };	
  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {	
    return bankNameData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.bank_code}>	
          {item.bank_name} ({item.bank_code})	
        </option>	
      );	
    });	
  };	
  //render dropdown for bank name data	
  const renderBrokerTypeDropdown = () => {	
    return brokerTypeData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item}>	
          {item}	
        </option>	
      );	
    });	
  };	
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {	
    return allbranchesData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.branch_code}>	
          {item.branch_name} ({item.branch_code})	
        </option>	
      );	
    });	
  };	
  //render dropdown for nature of transaction data	
  const validate = () => {	
    let bankErr,	
      branchErr,	
      brokerErr,	
      brokerTypeErr,	
      revenueAuthorityErr,
      ibanErr,	
      titleErr,	
      codeErr = '';	
    bankname.trim() === '' ? (bankErr = 'Required') : (bankErr = '');	
    branchName.trim() === '' ? (branchErr = 'Required') : (branchErr = '');	
    brokerType.trim() === ''	
      ? (brokerTypeErr = 'Required')	
      : (brokerTypeErr = '');	
      revenueAuthority.trim() === ''	
      ? (revenueAuthorityErr = 'Required')	
      : (revenueAuthorityErr = '');	
    iban.trim() === '' ? (ibanErr = 'Required') : (ibanErr = '');	
    acc_title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');	
    code.trim() === '' ? (codeErr = 'Required') : (codeErr = '');	
    companyName.trim() === '' ? (brokerErr = 'Required') : (brokerErr = '');	
    if (	
      bankErr ||	
      branchErr ||	
      brokerErr ||	
      brokerTypeErr ||	
      revenueAuthorityErr ||
      ibanErr ||	
      titleErr ||	
      codeErr	
    ) {	
      setBankNameError(bankErr);	
      setAccTitleError(titleErr);	
      setCodeError(codeErr);	
      setCompanyNameError(brokerErr);	
      setBranchNameError(branchErr);	
      setBrokerTypeError(brokerTypeErr);	
      setRevenueAuthorityError(revenueAuthorityErr);	
      setIbanError(ibanErr);	
      return false;	
    } else {	
      return true;	
    }	
  };	
  const addBrokerProcess = async () => {	
    const isValid = validate();	
    if (isValid) {	
      setLoading(true);	
      try {	
        const response = await addBroker(	
          email,	
          brokerType,	
          bankname,	
          branchName,	
          iban,	
          code,	
          acc_title,	
          companyName,
          revenueAuthority,
          active
        );	
        setBrokerType('');	
        setRevenueAuthority('');	
        setCompanyName('');	
        setCode('');	
        setAccTitle('');	
        setBankName('');	
        setBranchName('');	
        setIban('');	
        toast.success(response.data.message);	
      } catch (error) {	
        if (error.response.data.statusCode) {	
          toast.error(error.response.data.message[0]);	
        } else {	
          toast.error(error.response.data.message);	
        }	
      }	
      setLoading(false);	
    }	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		

        <div className="input-holder">	
              <h4 className="card-title">Associated Beneficiary</h4>	
              <Link to="/admin/associated-beneficiaries" className="t-3 ml-auto btn btn-primary btn-sm" replace>	
                <i className="fa fa-eye mr-2"></i> View All	
              </Link>	
        </div>

        <Row>
          <Col md="6">
                <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Associated Beneficiary
                  </CardTitle>
                </CardHeader>
                <CardBody>

                <div className="form-group">	
                    <label>Code: <span className="color-amber">*</span></label>	
                  <div>	
                  <div className="">	
                      <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          codeError ? 'required-border' : ''	
                        }`}	
                        value={code}	
                        onChange={(e) => {	
                     	
                          var cleaned = e.target.value;	
                          if (cleaned.length == 3 && !cleaned.includes("-")){	
                            cleaned = cleaned + "-";	
                          }	
                          else if(cleaned.length > 7){	
                            cleaned = cleaned.substring(0,7);	
                          }	
                          changeCodeValue(cleaned.toUpperCase())	
                        }} />	
                        	
                        	
                    </div>	
                    <div className="d-flex">	
                            <p style={{ fontSize: '11px' }}>	
                              Code Pattern (AAA-999)	
                            </p>	
                            {codeError ? (	
                              <span	
                                className="ml-auto"	
                                style={{ fontSize: '11px'}}	
                              >	
                                {codeError}	
                              </span>	
                            ) : (	
                              ''	
                            )}	
                      </div>	
                  	
                  </div>	
                  </div>

                  <div className="form-group">	
                    <label>Company Name: <span className="color-amber">*</span></label>	
                    <div className="">	
                      <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          companyNameError ? 'required-border' : ''	
                        }`}	
                        value={companyName}	
                        onChange={(e) => {	
                          // eslint-disable-next-line 
                          setCompanyName(e.target.value),	
                            setCompanyNameError('');	
                        }}	
                      />	
                      {/* {companyNameError ? <p className="error-labels error-message">{companyNameError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group">	
                    <label>Company Type: <span className="color-amber">*</span></label>	
                    <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          brokerTypeError ? 'required-border' : ''	
                        }`}	
                        value={brokerType}	
                        onChange={(e) => {	
                          // eslint-disable-next-line 
                          setBrokerType(e.target.value), setBrokerTypeError('');	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                          Select Broker Type	
                        </option>	
                        {renderBrokerTypeDropdown()}	
                      </select>	
                      {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}	
                    </div>	
                  </div>
 
                  <div className="form-group">	
                    <label>Revenue Authority: <span className="color-amber">*</span></label>	
                    <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          revenueAuthorityError ? 'required-border' : ''	
                        }`}	
                        value={revenueAuthority}	
                        onChange={(e) => {	
                          // eslint-disable-next-line 
                          setRevenueAuthority(e.target.value);
                          setRevenueAuthorityError('');	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                          Select Revenue Authority
                        </option>	
                        <option value="FBR">FBR - Federal Board Of Revenue</option>	
                        <option value="SRB">SRB - Sindh Revenue Board</option>	
                        <option value="PRA">PRA - Punjab Revenue Authority</option>	
                        <option value="BRA">BRA - Balochistan Revenue Authority</option>	
                        <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>	
                      </select>	
                      {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}	
                    </div>	
                  </div>

                </CardBody>
              </Card>
          </Col>

          <Col md="6">

          <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Bank Account Details
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group">	
                    <label>Account Title: <span className="color-amber">*</span></label>	
                    <div className="">	
                      <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          acc_titleError ? 'required-border' : ''	
                        }`}	
                        value={acc_title}	
                        onChange={(e) => {	
                          setAccTitle(e.target.value);	
                          setAccTitleError('');	
                        }}	
                      />	
                      {/* {acc_titleError ? <p className="error-labels error-message2">{acc_titleError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group">	
                    <label>IBAN / Account No: <span className="color-amber">*</span></label>	
                    <div className="">	
                      <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          ibanError ? 'required-border' : ''	
                        }`}	
                        value={iban}	
                        onChange={(e) => {	
                          setIban(e.target.value);	
                          setIbanError('');	
                        }}	
                      />	
                      {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group">	
                    <label>Bank Name: <span className="color-amber">*</span></label>	
                    <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          bankNameError ? 'required-border' : ''	
                        }`}	
                        value={bankname}	
                        onChange={(e) => {	
                          setBankNameError('');	
                          setBankName(e.target.value);	
                          getBranchByName(e.target.value);	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                          {' '}	
                          Select Bank	
                        </option>	
                        {renderBankNameDropdown()}	
                      </select>	
                      {/* {bankNameError ? <p className="error-labels error-message">{bankNameError}</p> : ''} */}	
                    </div>	
                  </div>	

                  <div className="form-group">	
                    <label>Branch Name: <span className="color-amber">*</span></label>	
                        
                        <input type="text" className="form-control"
                          value={branchName}	
                          onChange={(e) => {	
                            setBankNameError('');	
                            setBranchName(e.target.value);	
                          }}	
                         />
                    {/* <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          bankNameError ? 'required-border' : ''	
                        }`}	
                        value={branchName}	
                        onChange={(e) => {	
                          setBankNameError('');	
                          setBranchName(e.target.value);	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                        {branchCheckFlag? 'No Branch Found' :'Select Branch Name'}	
                        </option>	
                        {renderBranchsDropdown()}	
                      </select>	
                     </div>	 */}
                  </div>

                  <div className="form-group">	
                    <label>Active: <span className="color-amber">*</span></label>	
                        <select 
                        className="form-control"
                        value={active}
                        onChange={(e)=>{
                          setActive(e.target.value);
                        }}>
                        <option value="" hidden>Active / Deactive</option>
                        <option value="active">Active</option>
                        <option value="deactive">Deactive</option>
                        </select>	
                         
                  </div>
 
                </CardBody>
              </Card>


          </Col>
        </Row>

             <button	
                  className="btn btn-primary"	
                  onClick={addBrokerProcess}	
                  disabled={Boolean(Loading)}	
                >	
                  {Loading ? (	
                    <>	
                      <span	
                        className="spinner-border login-txt spinner-border-sm"	
                        role="status"	
                        aria-hidden="true"	
                      ></span>	
                      <span className="login-txt"> Loading...</span>	
                    </>	
                  ) : (	
                    <span>Create</span>	
                  )}	
                </button>	
 	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default BrokerComponent;