import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

export default function CheckListItem({
  updateFormData,
  val,
  ind,
}): JSX.Element {
  const [checked, setChecked] = useState('');

  const handleCheckbox = (status) => {
    setChecked(status);
    val.value = status;
    updateFormData(val, ind);
  };

  const handleCommentChange = (text) => {
    val.comment = text;
    updateFormData(val, ind);
  };

  return (
    <div>
      <Row key={ind} className="pt-3">
         
        <Col md="5">
        <text className="text-primary">{val.serial_no}. </text>  <text>{val.message}</text>
        </Col>
        <Col md="7">
          <Row>
            <Col md="4">
            {/* <label className="">Compliant</label>
              <input
                className="checklist-checkbox"
                checked={checked == 'compliant'}
                type="checkbox"
                value="compliant"
                onChange={(e) => handleCheckbox(e.target.value)}
              /> */}
 

                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                          checked={checked == 'compliant'} 
                          value="compliant"
                          onChange={(e) => handleCheckbox(e.target.value)}
                           />
                          <span className="form-check-sign" />
                          Compliant
                        </Label>
                      </FormGroup>
              </Col>
              <Col md="3">
              {/* <label>N /A</label>
                <input
                  className="checklist-checkbox"
                  type="checkbox"
                  id="unlisted"
                  checked={checked == 'na'}
                  value="na"
                  onChange={(e) => {
                    handleCheckbox(e.target.value);
                  }}
                /> */}
                  <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                          id="unlisted"
                          checked={checked == 'na'}
                          value="na"
                          onChange={(e) => {
                            handleCheckbox(e.target.value);
                          }}
                           />
                          <span className="form-check-sign" />
                          N /A
                        </Label>
                      </FormGroup>
 
            </Col>
             
            <Col md="5">
            <div className="form-group">
              <input
                type="text"
                placeholder="Comments"
                className="form-control"
                onChange={(e) => {
                  handleCommentChange(e.target.value);
                }}
              />
              </div>
            </Col>
          </Row>
          
        </Col>
      </Row>
    </div>
  );
}
