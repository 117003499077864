import React from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Container, Col, Row } from 'reactstrap';
import {
  getFunds,
  deleteFund,
  updateFundStatus,
  searchFundAPI,
} from '../../../stores/services/funds.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const FundList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  let [activePage, setActivePage] = useState('');
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');
  const [isSearchActive, setSearchActive] = useState(false);

  React.useEffect(() => {
    sessionStorage.removeItem('fundobj');
    const getFundList = async () => {
      setLoading(true);
      try {
        const response = await getFunds(email, activePage, pageSize);
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10 &&
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } catch (err:any) {
        // toast.error(error.response.data.message[0]);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getFundList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [fundLoading, setFundLoading] = useState(false);
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentAmcStatus, setCurrentAmcStatus] = useState('');
  const [code, setCode] = useState('');
  const updateStatus = async () => {
    setFundLoading(true);
    let status = '';
    if (currentAmcStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateFundStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      getUpdatedFundList();
    } catch (error) {}
    setFundLoading(false);
  };
  const deleteFundSelected = async () => {
    setFundLoading(true);
    try {
      const response = await deleteFund(email, code);
      toast.success(response.data.message);
      setDeletePopup(false);
      getUpdatedFundList();
    } catch (error) {}
    setFundLoading(false);
  };
  const getUpdatedFundList = async () => {
    setLoading(true);
    try {
      const response = await getFunds(email, activePage, pageSize);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].fund_name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const searchFundData = async () => {
    setLoading(true);
    try {
      const response = await searchFundAPI(email, searchValue);
      setData(response.data.data);
      setSearchActive(true);
      setPageSize('100');
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      getUpdatedFundList();
      setPageSize('10');
      setSearchActive(false);
    } else {
      setSearchValue(e.target.value);
    }
  };

  const renderData = () => {	
    return res.map((items: any, index: any) => {	
      return (	
        <tr key={index}>	
        <td>{items.fund_name}</td>	
        <td>{items.symbol_code}</td>	
        <td>{items.amc_code}</td>	
        {/* <td>1 Million</td> */}	
        <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>	
        <td>{items.nav && parseFloat(items.nav).toFixed(2)}</td>	
        <td>{items.nature}</td>
        <td>{items.status}</td>	
           <td	
            className="d-flex pointer"	
            onClick={() => {	
              setStatusSetPopup(true);	
              setCurrentAmcStatus(items.status);	
              setCode(items.symbol_code);	
            }}	
          >	
            {items.status !== 'active' ? (	 
              <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                <i className="tim-icons icon-simple-remove">
                  </i></button>
            ) : (	 
              <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                <i className="fa fa-check">
                  </i></button>
            )}	 
            <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"  onClick={() => {	
                  sessionStorage.setItem('fundObj', JSON.stringify(items));	
                  history.replace('/admin/view-fund');	
                }}	>
                <i className="fa fa-eye">
                  </i></button>
 

              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                sessionStorage.setItem('fundobj', JSON.stringify(items));	
                history.replace(`/admin/edit-fund`);	
              }}>
                <i className="tim-icons icon-pencil">
               </i></button>

          </td>	
         	
       	
      </tr>	
      );	
    });	
  };	
  const renderComponents = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal60w"	
            show={true}	
          >	
             <div className="modal-header justify-content-center">	
                <button aria-hidden="true" className="close"  onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	 
                        data-dismiss="modal" type="button">	
                    <i className="tim-icons icon-simple-remove"></i>	
                </button>	
            <h6 className="title title-up">Action</h6>	
        </div>	
            <div className="modal-body">	
              <div className="text-center">	
                <Row>	
                  <div className="mx-auto">	
                    {currentAmcStatus === 'active' ? (	
                      <img src="assets/ban.svg" alt="" width="70" />	
                    ) : (	
                      <img src="assets/check.svg" alt="" width="70" />	
                    )}	
                    <h1 className="pb-3">Are You Sure?</h1>	
                    Are you sure you want to{' '}	
                    {currentAmcStatus === 'active'	
                      ? 'Deactivate'	
                      : 'Activate'}{' '}	
                    this Fund?	
                  </div>	
                </Row>	
                <Row className="mt-4">	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-primary"	
                        disabled={Boolean(fundLoading)}	
                        onClick={() => {	
                          updateStatus();	
                        }}	
                      >	
                        {fundLoading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Yes'	
                        )}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderDeletePopup = () => {	
    switch (deletePopup) {	
      case true:	
        return (	
          <Modal	
          className=""	
          dialogClassName="modal60w"	
          show={true}	
        >	
          <div className="modal-body">	
            <div className="text-center">	
              <Row>	
                <div className="mx-auto">	
                  <img src="assets/ban.svg" alt="" width="70" />	
                  <h1 className="pb-3">Are You Sure?</h1>	
                  Are You sure, you want to delete selected Fund?	
                </div>	
              </Row>	
              <Row>	
                <Col>	
                  <div className="">	
                    <button	
                      className="btn btn-primary"	
                      disabled={Boolean(fundLoading)}	
                      onClick={() => {	
                        deleteFundSelected();	
                      }}	
                    >	
                      {fundLoading ? (	
                        <>	
                          <span	
                            className="spinner-border login-txt spinner-border-sm"	
                            role="status"	
                            aria-hidden="true"	
                          ></span>	
                          <span className="login-txt"> Loading...</span>	
                        </>	
                      ) : (	
                        'Yes'	
                      )}	
                    </button>	
                  </div>	
                </Col>	
                <Col>	
                  <div className="">	
                    <button	
                      className="btn btn-default"	
                      onClick={() => {	
                        setDeletePopup(false);	
                      }}	
                    >	
                      Cancel	
                    </button>	
                  </div>	
                </Col>	
              </Row>	
            </div>	
          </div>	
        </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  return (	
    <>	
    
<div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        <div className="card">	
          <div className="d-flex align-items-center"></div>	
          {!Loading ? (	
            <div className="">	
              <div className="card-header">	
              <h4 className="card-title">Fund Listing</h4>	
              </div>	
              <div className="card-body">	
              <div className="input-holder">	
                <div className="form-group inline-box">	
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  />	
                  <input	
                    type="search"	
                    id="myInput"	
                    data-tip="Fund Name"	
                    placeholder="Enter Fund Name"	
                    className="form-control"	
                    value={searchValue}	
                    onChange={(e) => {	
                      setSearchValue(e.target.value);	
                      // myFunction(e);	
                      // handleSearchValue(e);	
                    }}
                    // onKeyPress={(e)=>{
                    //   let key_board_keycode = e.which || e.keyCode;
                    //   if (key_board_keycode == 13) {
                    //     e.preventDefault();
                    //     searchFundData();
                    //   }
                    // }}	
                  />	
                  <button className="btn btn-primary ml-3 mt-a" onClick={searchFundData}>	
                    <i className="fa fa-search"></i> Search	
                  </button>	
                </div>	
                <div	
                  className="btn btn-primary btn-sm "	
                  onClick={() => {	
                    history.replace('/admin/add-fund');	
                  }}	
                >	
                  <i className="fa fa-plus mr-2"></i> Add New	
                </div>	
                </div>	
            	
              <div className="">	
                <table className="table">	
                  <thead>	
                    <th>Fund Name</th>	
                    <th>Fund Code</th>	
                    <th>AMC Code</th>	
                    {/* <th>Amount</th> */}	
                    <th>Created At</th>	
                    <th>NAV</th>	
                    <th>Nature</th>	
                    <th>Status</th>	
                    <th>Action</th>	
                  </thead>	
                  <tbody>{renderData()}</tbody>	
                </table>	
                {!isSearchActive && (
                <div	
                  className="row p-3 pointer"	
                  style={{	
                    float: 'right',	
                  }}	
                >	
                  {/* PREVIOUS BUTTON */}	
                  {activePage !== paginationArr[0] ? (	
                    <i	
                      className="fa fa-arrow-left"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {	
                          setActivePage(paginationArr[index - 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                  {/* NEXT BUTTON */}	
                  {activePage !== paginationArr[paginationArr.length - 1] ? (	
                    <i	
                      className="fa ml-3 fa-arrow-right"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {
                          setActivePage(paginationArr[index + 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                </div>
                )}	
              </div>	
            </div>	
            </div>	
          ) : (	
            <div className="d-flex justify-content-center my-5">	
            <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
         </div>
          )}	
          {renderDeletePopup()}	
          {renderComponents()}	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default FundList;	
