import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
} from 'reactstrap';
import { PieChart } from 'react-minimal-pie-chart';
import { getDashboardData, ConvertToExponential, getDashboardTransactions, getDashboardTransactionsByAmc } from './../stores/services/dashboard.service';
import { toast, ToastContainer } from 'react-toastify';
import { AiOutlineSearch, AiOutlineArrowRight, AiOutlineCloseCircle } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import ViewTxnFields from '../containers/transactions/view-beta-txn';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { getAmc } from '../stores/services/amc.service';
import { getFundByAmc } from '../stores/services/funds.service';
// import { constants } from 'original-fs';

const Dash = () => {
  const [data, setData] = useState<any>([]);
  const email = sessionStorage.getItem('email') || ''
  const features = sessionStorage.getItem('features') || ''
  const [loading, setLoading] = useState(false); //Loading hook to deal Api call response	
  let date = new Date();
  let disbaleDates =new Date();
  disbaleDates.setMonth(disbaleDates.getMonth() - 3);

  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD')); // Starting Date Hook for Filter	

  // const [toDate, setToDate] = useState(''); // Ending Date Hook for Filter	
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD')); // Ending Date Hook for Filter	

  const [transaction, setTransaction] = useState<any>([]);
  const [saleOfUnitData, setSaleOfUnitData] = useState({ count: 0, amount: 0 });
  const [inflowData, setInflowData] = useState({ count: 0, amount: 0 });
  const [loadingTxn, setLoadingTxn] = useState(false); //Loading hook to deal Api call response	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const [viewFlag, setViewFlag] = useState(false);
  const [txnHeading, setTxnHeading] = useState('');
  const [allAmcData, setAllAmcData] = useState<any>([]); // all Amc Data	
  const [fields, setFields] = useState({
    amc_code: '',
    fund_code: '',
  });
  const [allFunds, setAllFunds] = useState<any>([]); // all Funds Data related to an Amc
  //Function  to Get Total Counts Values From All Sections Of DashBoard	
  React.useEffect(() => {
    const getDashData = async () => {
      setLoading(true);
      try {
        const response = await getDashboardData(email);
        setData(response.data);
        setInflowData({
          count: response.data.recent_txns.inflow_count,
          amount: 0,
        });
        setSaleOfUnitData({
          count: response.data.recent_txns.saleofunit_count,
          amount: response.data.recent_txns.saleofunit_amount,
        });
      } catch (error) {
        // console.log('error ', error);
      }
      setLoading(false);
    };
    // amc dropdown
    const getUnitHolders = async () => {
      setLoading(true);
      try {
        const amcResponse = await getAmc(email);

        let tempAmc = amcResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.amc_code}-${val.name}`,
            value: val.amc_code,
          };
        });
        setAllAmcData(tempAmc);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    // end amc dropdown
    getUnitHolders();
    getDashData();
    getAllTransaction();
  }, []);
	
  // Get all Funds and accounts of a specific selected Amc
  const getFundByAMcCode = async (code: string) => {
    allFunds.length = 0;
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.symbol_code}-(${val.fund_name})`,
          value: val.symbol_code,
        };
      });
      setAllFunds(temp);
    
    } catch (error) { }
    
  };

  //Function to Get All Type Of filtered Transactions For Dashboard 	
  const getAllTransaction = async () => {
    setLoadingTxn(true);
    try {
      const response = await getDashboardTransactions(email, fromDate, toDate);
      const tempObj = response.data;
      tempObj.registered_txn.length = 8;
      tempObj.compliance_txn.length = 8;
      tempObj.operation_txn.length = 8;
      tempObj.completed_txn.length = 8;
      setTransaction(tempObj);
    } catch (error) { }
    setLoadingTxn(false);
  };
//Function to Get All Type Of filtered Transactions For Dashboard by amc and fund
const getAllTransactionByAmc = async () => {
  if(fields.fund_code==''){
    let amc = fields.amc_code;
    let fund ='';
    setLoadingTxn(true);
    try {
      const response = await getDashboardTransactionsByAmc(email, fromDate, toDate, amc, fund);
      const tempObj = response.data;
      tempObj.registered_txn.length = 8;
      tempObj.compliance_txn.length = 8;
      tempObj.operation_txn.length = 8;
      tempObj.completed_txn.length = 8;
      setTransaction(tempObj);
    } catch (error) { }
    setLoadingTxn(false);
  }else if(fields.amc_code && fields.fund_code){
    let amc = fields.amc_code;
    let fund =fields.fund_code;
    setLoadingTxn(true);
  try {
    const response = await getDashboardTransactionsByAmc(email, fromDate, toDate, amc, fund);
    const tempObj = response.data;
    tempObj.registered_txn.length = 8;
    tempObj.compliance_txn.length = 8;
    tempObj.operation_txn.length = 8;
    tempObj.completed_txn.length = 8;
    setTransaction(tempObj);
  } catch (error) { }
  setLoadingTxn(false);
  }
  
};

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };
  const [dataTxn, setDataTxn] = useState<any>([]);
  //title case Convert Function For View fields	
  const titleCase = (value: string) => {
    let sentence = value.toLowerCase().split('_');
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(' ');
  };
  //render data in view model	
  const renderData = () => {
    return dataTxn.map((value: any, index: number) => {
      return (
        <>
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (
            <Col md="6" key={index}>
              <div className="input-holder left">
                <p className="label">{titleCase(value[0])}</p>
                <div className="input-1 hide-overflow">
                  <p
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {typeof value[1] === 'string'
                      ? value[0] === 'file'
                        ? 'N/A'
                        : value[1]
                      : value[0] === 'file'
                        ? 'N/A'
                        : ''}
                  </p>
                </div>
              </div>
            </Col>
          ) : (
            ''
          )}
        </>
      );
    });
  };
  const [selectTransaction, setSelectTransaction] = useState<any>([]);
  //Model to View Transaction	
  const viewTransationPopup = () => {
    switch (viewFlag) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => { setViewFlag(false) }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewFlag(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">{txnHeading} </h6>
            </div>
            <div className="modal-body">
              <div >
                {dataTxn ? (
                  <ViewTxnFields data={selectTransaction} />
                ) : (
                  // <Row>{renderData()}</Row>

                  ''
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <>
     <div className="content">
          <Row>
            <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        {/* <Header /> */}

        <div className="mx-3">
          {/* Start Dashboard */}
          <Row>
            <Col md="12">
              <div>
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                              <i className="tim-icons icon-single-copy-04 " />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total AMC</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3">{data.total_amc}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-single-copy-04" />Total AMC's
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-primary">
                              <i className="tim-icons icon-chart-bar-32" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Funds</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3">{data.total_funds}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-chart-bar-32" /> Total Funds
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-success">
                              <i className="tim-icons icon-credit-card" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Funds Accounts</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3">{data.total_fund_accounts}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-credit-card" />Total Funds Accounts
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-info">
                              <i className="tim-icons icon-shape-star" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total SMA Accounts</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3">{data.total_sma_accounts}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-shape-star" /> Total SMA Accounts
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
                {/* 2nd row */}
                <Row>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                              <i className="tim-icons icon-tv-2" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total AMC Users</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3">{data.total_amc_users}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-tv-2" /> Total AMC Users
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>


                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-primary">
                              <i className="tim-icons icon-single-02" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Unit Holders</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3">{data.total_unitholders}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-single-02" /> Total Unit Holders
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-success">
                              <i className="tim-icons icon-money-coins" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total Fund Amount</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
                              ) : (
                                <CardTitle tag="h3" title={data.total_fund_amount}>{data.total_fund_amount && ConvertToExponential(data.total_fund_amount, 2)}</CardTitle>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-money-coins" /> Total Fund Amount
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col lg="3" md="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col xs="5">
                            <div className="info-icon text-center icon-info">
                              <i className="tim-icons icon-shape-star" />
                            </div>
                          </Col>
                          <Col xs="7">
                            <div className="numbers">
                              <p className="card-category">Total SMA Amount</p>
                              {loading ? (
                                <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>) : (
                                <CardTitle tag="h3" title={data.total_sma_amount}>{data.total_sma_amount && ConvertToExponential(data.total_sma_amount, 2)}</CardTitle>)}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="tim-icons icon-shape-star" /> Total SMA Amount
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
          {/* End Dashboard */}

          {/* Activities Row */}
          <div >
            <div className="row">
              <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
              <div className="col-sm-2 ">
                <div className="form-group">
                  AMC
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    label={'Select'}
                    isDisabled={false}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        amc_code: e.value,
                        fund_code: ''
                      });
                      getFundByAMcCode(e.value);
                    }}
                    value={allAmcData.filter(
                      (option) => option.value === fields['amc_code']
                    )}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={allAmcData}
                  />
                </div>
              </div>
              {/* end */}
              {/* fund dropdown*/}
              <div className="col-sm-2 ">
                <div className="form-group">
                  Fund
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    label={'Select'}
                    isDisabled={false}
                    onChange={(e) => {
                      // eslint-disable-next-line 
                      fields['reconcile_balance'];
                      setFields({
                        ...fields,
                        fund_code: e.value,
                       
                      });
                    }}
                    value={allFunds.filter(
                      (option) => option.value === fields['fund_code']
                    )}
                    isClearable={false}
                    isSearchable={true}
                    name="color"
                    options={allFunds}
                  />
                </div>
              </div>
              {/* end */}
              <div className="col-sm-2 ">
                <div className="form-group ">
                  From
                  <input type="date" data-tip="From" min={moment(disbaleDates).format('YYYY-MM-DD')} value={fromDate} className="form-control" onChange={(e) => {
                    setFromDate(e.target.value);
                  }} />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group ">
                  To
                  <input type="date" data-tip="To" value={toDate} className="form-control" onChange={(e) => {
                    setToDate(e.target.value)
                  }} />
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="form-group pt-3">
                  {/* <span className="d-none">Button</span><br /> */}
                  <button className="btn btn-primary mt-a"
                    onClick={() => {
                      getAllTransactionByAmc();

                      // getAllTransaction();
                    }} >

                    <i className="fa fa-search mr-2"></i> Search
                  </button>
                </div>


              </div>
              {/* <div className="col-sm-2 pt-3">
                amc code : {fields.amc_code}<br/>
                fund code : {fields.fund_code}
              </div> */}
            </div>

            <Row className="mt-3">
              <Col lg="3" md="6">
                <div className="card h-450">
                  <div className="card-header">
                    <CardTitle>
                      <Row>
                        <Col xs="9">
                          <h4> <i className="text-primary tim-icons icon-pencil mr-1"></i> Registered</h4>
                        </Col>
                        <Col xs="3">
                          <div className="numbers">
                            {loadingTxn ? (
                              <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>) : (
                              <CardTitle className="flex-end text-primary" tag="h3">{transaction.registered_txn_count ? transaction.registered_txn_count : '0'}</CardTitle>)}
                          </div>
                        </Col>
                      </Row>
                    </CardTitle>
                  </div>
                  <div className="card-body">
                    {transaction.registered_txn_count > 0 ?
                      transaction.registered_txn.map((txnItem, index) => {
                        return (
                          <div className="" key={index} onClick={() => {
                            setViewFlag(true);
                            setSelectTransaction(txnItem)
                            let objArr = Object.entries(txnItem);
                            setDataTxn(objArr);
                            setTxnHeading(CapTxnType(txnItem.txnr_type))
                          }}>
                            <h5 className="text-border mb-2">
                              <i className="tim-icons icon-minimal-right mr-2 "></i>
                              <span className="float-right text-primary">  {numberWithCommas(txnItem.net_amount)}  </span>
                              {/* <span className="text-primary"> {txnItem.txn_id}  </span> 	 */}
                              <span> {CapTxnType(txnItem.txnr_type)} 	</span>	<br /> <small><span className="text-muted ml-4"> {txnItem.txn_id}  </span></small>

                            </h5>
                          </div>
                        )
                      })
                      : 'No Data'
                    }
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card h-450">
                  <div className="card-header">
                    <CardTitle>
                      <Row>
                        <Col xs="9">
                          <h4>  <i className="text-primary tim-icons icon-zoom-split mr-1"></i> Compliance</h4>
                        </Col>
                        <Col xs="3">
                          <div className="numbers">
                            {loadingTxn ? (
                              <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>) : (
                              <CardTitle className="flex-end text-primary" tag="h3">{transaction.compliance_txn_count}</CardTitle>)}
                          </div>
                        </Col>
                      </Row>
                    </CardTitle>
                  </div>
                  <div className="card-body">
                    {transaction.compliance_txn_count > 0 ?
                      transaction.compliance_txn.map((txnItem, index) => {
                        return (
                          <div className=" " key={index} onClick={() => {
                            setViewFlag(true);
                            setSelectTransaction(txnItem)
                            let objArr = Object.entries(txnItem);
                            setDataTxn(objArr);
                            setTxnHeading(CapTxnType(txnItem.txnr_type))
                          }}>
                            <h5 className="text-border mb-2">
                              <i className="tim-icons icon-minimal-right mr-2 "></i>
                              <span className="float-right text-primary">  {numberWithCommas(txnItem.net_amount)}  </span>

                              {/* <span className="text-primary"> {txnItem.txn_id}  </span>	 	 */}
                              <span> {CapTxnType(txnItem.txnr_type)} 	</span> 	<br /> <small><span className="text-muted ml-4"> {txnItem.txn_id}  </span></small>

                            </h5>
                          </div>
                        )
                      })
                      : 'No Data'
                    }
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card h-450">
                  <CardHeader>
                    <CardTitle  >
                      <Row>
                        <Col xs="9">
                          <h4> <i className="text-primary tim-icons icon-settings-gear-63 mr-1"> </i>Operations</h4>
                        </Col>
                        <Col xs="3">
                          <div className="numbers">
                            {loadingTxn ? (
                              <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>) : (
                              <CardTitle className="flex-end text-primary" tag="h3">{transaction.operation_txn_count}</CardTitle>)}
                          </div>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {transaction.operation_txn_count > 0 ?
                      transaction.operation_txn.map((txnItem, index) => {
                        return (
                          <div className="" key={index} onClick={() => {
                            setViewFlag(true);
                            setSelectTransaction(txnItem)
                            let objArr = Object.entries(txnItem);
                            setDataTxn(objArr);
                            setTxnHeading(CapTxnType(txnItem.txnr_type))
                          }}>
                            <h5 className="text-border mb-2">
                              <i className="tim-icons icon-minimal-right mr-2 "></i>
                              <span className="float-right text-primary">  {numberWithCommas(txnItem.net_amount)}  </span>
                              {/* <span className="text-primary"> {txnItem.txn_id}  </span>	 	 */}
                              <span> {CapTxnType(txnItem.txnr_type)} 	</span>  	<br /> <small><span className="text-muted ml-4"> {txnItem.txn_id}  </span></small>
                            </h5>
                          </div>
                        )
                      })
                      : 'No Data'
                    }
                  </CardBody>
                </div>
              </Col>
              <Col>
                <div className="card h-450">
                  <CardHeader>
                    <CardTitle  >
                      <Row>
                        <Col xs="9">
                          <h4> <i className=" text-primary fas fa-check"></i> Complete</h4>
                        </Col>
                        <Col xs="3">
                          <div className="numbers">
                            {loadingTxn ? (
                              <i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>) : (
                              <CardTitle className="flex-end text-primary" tag="h3">{transaction.completed_txn_count}</CardTitle>)}
                          </div>
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {transaction.completed_txn_count > 0 ?
                      transaction.completed_txn.map((txnItem, index) => {
                        return (
                          <div className=" txn-size" key={index} onClick={() => {
                            setViewFlag(true);
                            setSelectTransaction(txnItem)
                            let objArr = Object.entries(txnItem);
                            setDataTxn(objArr);
                            setTxnHeading(CapTxnType(txnItem.txnr_type))
                          }}>
                            <h5 className="text-border mb-2">
                              <i className="tim-icons icon-minimal-right mr-2 "></i>
                              <span className="float-right text-primary">  {numberWithCommas(txnItem.net_amount)}  </span>
                              {/* <span className="text-primary"> {txnItem.txn_id}  </span>	  	 */}
                              <span> {CapTxnType(txnItem.txnr_type)} 	</span> 	<br /> <small><span className="text-muted ml-4"> {txnItem.txn_id}  </span></small>
                            </h5>
                          </div>
                        )
                      })
                      : 'No Data'
                    }
                  </CardBody>
                </div>
              </Col>
            </Row>
          </div>



          {/* <div>	
          <Row className="mb-5">	
            <Col sm="7">	
              <div className="card">	
              <div className="card-body">	
                <table className="table " id="myTable" >	
                  <thead>	
                    <tr>	
                      <th>Transaction Type</th>	
                      <th>Transactions Count</th>	
                      <th>Transactions Amount</th>	
                    </tr>	
                  </thead>	
                  <tbody id="myUL">	
                    <tr>	
                      <td>Sale Of Unit</td>	
                      <td>{saleOfUnitData.count}</td>	
                      <td>{saleOfUnitData.amount}</td>	
                    </tr>	
                    <tr>	
                      <td>Fee Payment</td>	
                      <td>{data.recent_txns.feepayment_count}</td>	
                      <td>{data.recent_txns.feepayment_amount}</td>	
                    </tr>	
                    <tr>	
                      <td>Inflow</td>	
                      <td>{inflowData.count}</td>	
                      <td>{inflowData.amount}</td>	
                    </tr>	
                  </tbody>	
                </table>	
              </div>	
              </div>	
            </Col>	
            <Col sm="2">	
              {loading ?	
               <i className="fa fa-spinner fa-spin fa-1x" ></i>  :	
                <div className="bar-chart">	
                  <PieChart	
                    data={[	
                      { title: 'Accept', value: +data.processed_transactions, color: '#056b6b' },	
                      { title: 'Pending', value: +data.pending_transactions, color: '#374e4e' },	
                      { title: 'Reject', value: +data.rejected_transactions, color: '#b0fdfd' },	
                    ]}	
                    label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}	
                    labelStyle={{	
                      fontSize: "6px",	
                    }}	
                  />	
                  <h5 className={'text-center'}>Transactions</h5>		
                </div>}	
            </Col>	
            <Col sm="2">	
              <div className="bar-chart">	
                <PieChart	
                  data={[	
                    { title: 'Accept', value:90, color: '#056b6b' },	
                    { title: 'Pending', value: 15, color: '#374e4e' },	
                    { title: 'Reject', value: 20, color: '#b0fdfd' },	
                  ]}	
                  label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}	
                  labelStyle={{	
                    fontSize: "6px",	
                  }}	
                />	
                <h5 className={'text-center'} >Amount</h5>		
              </div>	
            </Col>	
          </Row>	
         	
        </div> */}
          {viewTransationPopup()}
        </div>


      </Container>

</Col>
</Row>
      </div>
    </>
  )
};
export default Dash;
