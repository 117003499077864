import React, {useRef,  useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import Menu from '@material-ui/core/Menu';

const BetaTxnType = () => {
  const history = useHistory();
  const clearTransactiionHistory = () => {
    sessionStorage.removeItem('rejectedTxObj');
    sessionStorage.removeItem('rejectedTxName');
    sessionStorage.removeItem('rejectedFlag');
    sessionStorage.removeItem('rejectedAmc');
  };
  let txnTypeCatagory = JSON.parse(
    sessionStorage.getItem('txnTypeCatagory') || ''
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let last_id = sessionStorage.getItem('last_id') || '';
 
  const renderMenuPopup = () => {
    return (
      <Menu
        className=" shadow"
        id="simple-menu"
        anchorEl={anchorEl}
        // PaperProps={{
        //   style: {
        //     width: '70',
        //     outline: 'none',
        //     background: '#5ad2d2',
        //   },
        // }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      > 
        <div className="  p-2 "  >{last_id}</div> 
        </Menu>
    );
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <div>
          <div>
            <div className="title-row">
              <h4 className=" ">Transaction Types</h4>
              {/* <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm" replace>
                <div className="fa fa-upload mr-2"></div> Bulk Upload
              </Link> */}
            </div>
            <Row >
              <Col>
                <div className="card">
                  <div className="card-header">
                  <h4 className="card-title"> <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i> Unit Management</h4>
                  </div>
                  <div className="card-body">
                    {txnTypeCatagory[0].unitManagementTxn.map(
                      (item: any, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            className="box-element"
                            onClick={() => {
                              history.replace(
                                `/admin/beta-transaction/${item.api_name}`
                              );
                              if(item.api_name==='unitconversion'){
                                sessionStorage.setItem('payment_type', 'Payment');
                              }else{
                              sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              }
                              clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={item.api_name + ' ico'}></span>
                              <i className="fa fa-angle-right"></i> {item.dis_name}
                            </span>
                          </MenuItem>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card">
                  <div className="card-header">
                  <h4 className="card-title"> <i className="tim-icons icon-credit-card mr-2 text-primary"></i> Fund Management</h4>
                  </div>
                  <div className="card-body">
                    {txnTypeCatagory[1].fundManagementTxn.map(
                      (item: any, index: number) => {
                        return (
                          <MenuItem
                            className="box-element"
                            key={index}
                            onClick={() => {
                              history.replace(
                                `/admin/beta-transaction/${item.api_name}`
                              );
                              sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={item.api_name + ' ico'}></span>
                              <i className="fa fa-angle-right"></i>  {item.dis_name}
                            </span>

                          </MenuItem>
                        );
                      }
                    )}
                     <MenuItem
                            className="box-element"
                            key='4'
                            onClick={() => {
                              history.replace(
                                `/admin/fund-dividend-payout`
                              );
                              // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              // clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={' ico'}></span>
                              <i className="fa fa-angle-right"></i> {'Fund Dividend Payout'}
                            </span>

                          </MenuItem>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card">
                  <div className="card-header">
                  <h4 className="card-title"> <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>  Investments</h4>
                  </div>
                  <div className="card-body">
                    {txnTypeCatagory[2].investmentTxn.map(
                      (item: any, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            className="box-element"
                            onClick={() => {
                              history.replace(
                                `/admin/beta-transaction/${item.api_name}`
                              );
                              sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={item.api_name +  'ico'}></span>
                              <i className="fa fa-angle-right"></i>  {item.dis_name}
                            </span>
                          </MenuItem>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
              <Col>
                <div className="card">
                  <div className="card-header">
                  <h4 className="card-title"> <i className="tim-icons icon-money-coins mr-2 text-primary"></i> Fee Payments</h4>
                  </div>
                  <div className="card-body">
                    {txnTypeCatagory[3].feePaymentTxn.map(
                      (item: any, index: number) => {
                        return (
                          <MenuItem
                            className="box-element"
                            key={index}
                            onClick={() => {
                              history.replace(
                                `/admin/beta-transaction/${item.api_name}`
                              );
                              sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={item.api_name + ' ico'}></span>
                              <i className="fa fa-angle-right"></i>   {item.dis_name}
                            </span>
                          </MenuItem>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {last_id !== '' && (
              <>
                <ReactTooltip
                  textColor="white"
                  backgroundColor="black"
                  effect="float"
                />
                <AiOutlineInfoCircle
                  data-tip="Last Transaction"
                  className={'float-right mt-3'}
                  onClick={(e) => {
                    handleClick(e);
                  }}
                />
              </>
            )}
          </div>
          {renderMenuPopup()}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};


export default BetaTxnType;
