import moment from 'moment';
import DCClogo from '../../assets/img/DCC-Logo.png';

export const mmMaturityOfCouponLetter  = (transaction: any, securities: any, amtWords: any,   transNetAmount: any, transGrossAmount: any) => {

    let date = new Date();
    let dateFormat = moment(date).format('DD-MM-YYYY');
    let q="qrcodemmMaturityofCoupon";
    let id="www.digitalcustodian.co/v/"+transaction.txn_id;  
    setTimeout(() => {
        if(id!=undefined){  
            var element =document.getElementById(q)!;
            if(element!=null){
             element.innerHTML="";
            }
             var qrcode = new QRCode(document.getElementById(q), {
             width : 80,
             height : 80
         });
           qrcode.makeCode(id);
     }
    
    } , 500)

    let securityType = securities.map(u => u.security_type).join('|');
    const dueCouponDate = securities.map(u => u.last_coupon_date);
    const faceValue = securities.map(u => u.money_market_face_value);
 

    // String.prototype.removeDuplicate = function () {
    //     return Array.from(new Set(this.split('|'))).toString().replace(',', '');
    // }

    // securityType = securityType.removeDuplicate();

    function removeDuplicate(secType) {
        return Array.from(new Set(secType.split('|'))).toString().replace(',', '');
    }

    securityType = removeDuplicate(securityType);
 
    const numberWithCommas = (amount:any) => {
        amount= amount || '0';
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } 

      let netAmountSum = 0;

      const getData = (items) => {
        if(transaction.txnr_type==='debtmarketinvestment'){
        let rows = '';
        
        for (let i = 0;i < items.length; i++ ) { 
            rows += ` 
                <tr>
                <td style="border: 1px solid #333">${items[i].security_type}</td>
                <td style="border: 1px solid #333">${moment(items[i].issue_date).format('DD-MM-YYYY')}</td>
                <td style="border: 1px solid #333">${moment(items[i].maturity_date).format('DD-MM-YYYY')}</td>
                <td style="border: 1px solid #333"> ${items[i].next_coupon_date}  </td>
                <td style="border: 1px solid #333">${items[i].coupon_rate}</td>
                <td style="border: 1px solid #333">${numberWithCommas(items[i].money_market_face_value)}</td>
                <td style="border: 1px solid #333"> ${numberWithCommas(transaction.net_amount)} </td>   
                </tr>
            `
            netAmountSum += parseFloat(transaction.net_amount);
        } 
    return rows;
        }
    }

    return `<html> 
    <head>
        <title>MACS Trustee</title>
    </head>
    <body style="font-family:Arial">
        <header>
            <table width="100%">
                <tr>
                    <td>
                        <img src="${DCClogo}" alt="Trustee" height="100" />
                    </td>
                    <td valign="bottom">
                    </td>
                    <td valign="bottom">
                    </td>
                    <td align="right" valign="bottom">
                    <div style='text-align: right; height:100%; float: right;' id="${q}">
                     
                    </div>
                    </td>
                </tr>
            </table>
        </header>
        <hr />
        <section>
            <table style="width: 100%;"><tr><td>${dateFormat}</td> </tr></table>
            <br/>
            <div>The Chief Manager <br /> ${transaction.bank} <br />  ${transaction.branch},</div>
            <br />
            <div> <b>Sub: Coupon Maturity of (${securityType}) ${transaction.fund_account} .</b></div>
            <br /> 
            <div>Please note that <b> ${transaction.fund_account} </b> has Maturity of <b>${securityType}</b> as per details mention below.</div>

            <div>Dear Sir/Madam,</div>
            <div>
                This is with reference to the subject, Please note that ${transaction.fund_account} 
                has coupon Maturity of <b>${securityType}</b> - due on ${transaction.next_coupon_date} as per following
            </div>
            <table style="width: 100%;">

            <tr>
                <th style="border: 1px solid #333; width: 10%"> Security  </th>
                <th style="border: 1px solid #333; width: 15%"> Issue Date    </th>
                <th style="border: 1px solid #333; width: 15%"> Maturity Date    </th>
                <th style="border: 1px solid #333; width: 15%"> Next Coupon Date    </th>
                <th style="border: 1px solid #333; width: 10%"> Coupon Rate  </th>
                <th style="border: 1px solid #333; width: 15%"> Face Value   </th>
                <th style="border: 1px solid #333; width: 20%"> Coupon Maturity Amount   </th>
            </tr>

         

            ${getData(securities)}
               
            </table>
            <br /> 

            <table>
                <tr> 
                    <td>
                    You are requested to kindly transfer coupon maturity amount ${numberWithCommas(netAmountSum)} into account number <b> ${transaction.account_number} </b>. </td>
                    </tr>
                    
                    <tr> <td> Title <b>${transaction.fund_account}</b> maintained with <b> ${transaction.bank} ${transaction.branch} </b>  </td> </tr>
            </table>

            <div>
                This is to inform you that deduction of withholding tax is not applied on the profit of above transaction, as mutual funds are exempted from withholding tax as per Clause (47B)
                of Part IV of Second Schedule of Income Tax Ordinance 2001
            </div> 
            <br/>
            <div>Regards,</div>
            <br />
            <br />
            <br />
            <br />
            <table style="width: 100%;">
            <tr>
                <td></td>
                <td >
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td style="width: 40%;"> </td>
                <td>
                    <div style="border-top:1px solid #000; text-align: center;">Authorized Signatory</div> 
                </td>
                <td></td>
            </tr>
        </table>
    </body>
    </html>
    `
}
