import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { BsTable } from 'react-icons/bs';
import html2pdf from 'html2pdf.js';

import { getAmc } from '../../stores/services/amc.service';
import { getAllUnitHoldersByAmc } from '../../stores/services/unit-holder.service';
import { getUnitHolderStatement } from '../../stores/services/report.service';
import {searchUnitHolderAPI} from '../../stores/services/unit-holder.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import DCClogo from '../../assets/img/DCC-Logo.png';

const UnitHolderStatement = () => {
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [unitHolderStatement, setUnitHolderStatement] = useState<any>([]);
  const [unitHolderCount, setUnitHolderCount] = useState(0);
  const [unitHolderData, setUnitHolderData] = useState<any>([]);
  const [allAmc, setAllAmc] = useState<any>([]);
  const [allUnitHolders, setAllUnitHolders] = useState<any>([]);
  const [folioNo, setFolioNo] = useState('');
  const [Amc, setAmc] = useState('');
  const [totalUnits, setTotalUnits] = useState('');
  const [totalGrossAmount, setTotalGrossAmount] = useState('');
  const [totalNetAmount, setTotalNetAmount] = useState('');
  const [totalCGT, setTotalCGT] = useState('');
  const [totalSaleLoad, setTotalSaleLoad] = useState('');
  const [totalRedemptionLoad, setTotalRedemptionLoad] = useState('');
  const [openingBalance, setOpeningBalance] = useState('');
  const [closingBalance, setClosingBalance] = useState('');
  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [resFolioNo, setResFolioNo] = useState('');
  const [resAmc, setResAmc] = useState('');
  const [resFromDate, setResFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [resToDate, setResToDate] = useState(moment(date).format('YYYY-MM-DD'));

  const [folioNoErr, setFolioNoErr] = useState('');
  const [AmcErr, setAmcErr] = useState('');

        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           setAllAmc(amcResponse.data.data);
            }catch(err:any){
              if(err.response !== undefined ) {
                toast.error(err.response.data.message);	
              }else {
                toast.error("Request Failed!")
              }
            }
          }
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code}>
                {item.name}
                </option>
            );
            });
        };

         const getUnitHolders=async(code:any)=>{
          allUnitHolders.length=0;
          setAllUnitHolders(allUnitHolders);
            const unitHolderResponse = await getAllUnitHoldersByAmc(email, code);
            setAllUnitHolders(unitHolderResponse.data.data)
         }

        const renderUnitHoldersDropDown=()=>{
            return allUnitHolders.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.folio_no}>
                    {item.name} - {item.folio_no}
                    </option>
                );
          });
        }

  //code for search data for Reports

  const searchData = async () => {  
    try {
      unitHolderStatement.length=0;
      setUnitHolderStatement(unitHolderStatement);
      setOpeningBalance('');
      setClosingBalance('');
      setTotalUnits('');
      setTotalGrossAmount('');
      setTotalNetAmount('');
      setTotalCGT('');
      setTotalSaleLoad('');
      setTotalRedemptionLoad('');
      setLoading(true);
      if(Amc==='' && folioNo==='')
      {
        setAmcErr('required-border');
        setFolioNoErr('required-border');
      }else if(folioNo===''){
        setFolioNoErr('required-border');
      }else if(Amc===''){
        setAmcErr('required-border');
      }else{
      const searchResponse = await getUnitHolderStatement(email, folioNo, fromDate,toDate);
      const response = await searchUnitHolderAPI(email, folioNo);
      setUnitHolderData(response.data.data)
      let array = Object.entries(searchResponse.data.data);
      setResAmc(Amc);
      setResFolioNo(folioNo);
      setResToDate(toDate);
      setResFromDate(fromDate);
      let data:any=[];
      // let balance=0.0;
      let temp=[] as any;
      temp=array[0][1];
      setOpeningBalance(temp.Record.current_holding  || '0');
      let balance=parseFloat(temp.Record.current_holding || '0'); 
      let totalunits=0, totalgrossamount=0, totalnetamount=0, totalcgt=0, totalsaleload=0, totalredemptionload=0;
      array.forEach(([key, value]) => {       
        data=value;
        let record=[];
        record['Record']= data.Record; 
        
        // if(key=='0'){
        //   setOpeningBalance(data.Record.current_holding);
        //   balance=parseFloat(data.Record.current_holding);
        //  }
         
        totalunits+=parseFloat(data.Record.units || 0);
        totalgrossamount+=parseFloat(data.Record.gross_amount || 0);
        totalnetamount+=parseFloat(data.Record.net_amount || 0);
        totalcgt+=parseFloat(data.Record.tax_cgt === undefined ? '0' : data.Record.tax_cgt || 0);
        totalredemptionload+=parseFloat(data.Record.redemption_load === undefined ? '0' : data.Record.redemption_load || 0);
        totalsaleload+=parseFloat(data.Record.sale_load === undefined ? '0' : data.Record.sale_load || 0);
        if(data.Record.txnr_type=='saleofunit' || data.Record.txnr_type=='conversionin' || data.Record.txnr_type==='funddividendpayout'){
            balance += parseFloat(data.Record.units || '0');
        }else{
            balance -= parseFloat(data.Record.units || '0');
        } 
          record['Record']['balance']=balance.toFixed(4);
          unitHolderStatement.push(record);
      })
      setClosingBalance(balance.toFixed(4));
      setTotalUnits(totalunits.toFixed(4));
      setTotalGrossAmount(totalgrossamount.toFixed(2));
      setTotalNetAmount(totalnetamount.toFixed(2));
      setTotalCGT(totalcgt.toFixed(2));
      setTotalRedemptionLoad(totalredemptionload.toFixed(2));
      setTotalSaleLoad(totalsaleload.toFixed(2));

      setUnitHolderCount(unitHolderStatement.length);
    }
      setLoading(false);
    } catch (error) {
        setLoading(false);
    }
  }

  const  numberWithCommas = (amount:any) => {
      return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  }

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Unit Holder Statement</h4>
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className={"form-control "+ AmcErr}
                    onChange={(e) => {
                        setAmc(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                        getUnitHolders(e.target.value);
                        setAmcErr('');
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Unit Holder</label>
                  <select
                    className={"form-control "+ folioNoErr}
                    onChange={(e) => {
                        setFolioNo(e.target.value);
                        setFolioNoErr('');
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Unit Holder
                    </option>
                      {renderUnitHoldersDropDown()}
                  </select>
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Date From</label>
                  <input
                    type="date"
                    defaultValue={fromDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setFromDate(date);
                    }}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="form-group  ">
                  <label>Date To</label>
                  <input
                    type="date"
                    defaultValue={toDate}
                    className="form-control"
                    onChange={(e) => {
                        let date = moment(e.target.value).format('YYYY-MM-DD');
                        setToDate(date);
                    }}
                  />
                </div>
              </Col>
            
              <Col md="2">
            <div className=" ">
              {/* here Search Button */}
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  searchData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Search</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
            <div
              id="printDiv"
              className="p-4"
              style={{ background: 'white', color: 'black' }}
            >
               <PDFExport paperSize="A4" margin="1.5cm" scale={0.6} repeatHeaders={true} fileName={"Unit-Holder-Statement-"+date+".pdf"} ref={pdfExportComponent}>
              {unitHolderStatement.length > 0 ? (
                <>
                <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Unit Holder Statement</h3>
                  </div>

                  <Row>

                  {unitHolderData.length>0 && unitHolderData[0].name!==undefined && (
                  <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            Name:
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{unitHolderData[0].name}</span>
                        </Col>
                      </Row>
                    </Col>
                  )}

                    <Col md="4" style={{ marginLeft: '70px' }}>
                      <Row>
                        <Col md="5">
                          <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                            AMC:
                          </span>
                        </Col>
                        <Col md="6">
                          <span className="text-nowrap">{resAmc}</span>
                        </Col>
                      </Row>
                    </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              Unit Holder:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{resFolioNo}</span>
                          </Col>
                        </Row>
                      </Col>


                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                              From Date:
                            </span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(resFromDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>To Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(resToDate).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Report Date:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{moment(date).format('DD-MM-YYYY')}</span>
                          </Col>
                        </Row>
                      </Col>

                  </Row>

                  {unitHolderData.length>0 && (
                  <>                  
                  <hr/>
                  <Row>

                  {unitHolderData[0].account_title!==undefined && (
                    <Col md="4" style={{ marginLeft: '70px' }}>
                   
                   <Row>
                     <Col md="5">
                       <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Account Title:</span>
                     </Col>
                     <Col md="6">
                       <span className="text-nowrap">{unitHolderData[0].account_title}</span>
                     </Col>
                   </Row>
                 </Col>
                  )}
                  
                  {unitHolderData[0].account_no!==undefined && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Account No.:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{unitHolderData[0].account_no}</span>
                          </Col>
                        </Row>
                      </Col>
                  )}

                    {unitHolderData[0].bank_name!==undefined && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Bank:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{unitHolderData[0].bank_name}</span>
                          </Col>
                        </Row>
                      </Col>
                      )}

                    {unitHolderData[0].branch_name!==undefined && (
                      <Col md="4" style={{ marginLeft: '70px' }}>
                        <Row>
                          <Col md="5">
                            <span className="text-nowrap" style={{ fontWeight: 'bold' }}>Branch:</span>
                          </Col>
                          <Col md="6">
                            <span className="text-nowrap">{unitHolderData[0].branch_name}</span>
                          </Col>
                        </Row>
                      </Col>
                      )}
                     
                    </Row>
                    </>
                    )}
                  
                  <div
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                      <div className="float-right"><b>Opening Balance</b> : {numberWithCommas(openingBalance)}</div>
                    <table className="report" style={{ color: 'black' }}>
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Fund</th>
                            <th className="text-right">Fund Units</th>
                            <th className="text-right">Gross Amount</th>
                            <th className="text-right">Net Amount</th>
                            <th className="text-right">Sale Load</th>
                            <th className="text-right">Redemption Load</th>
                            <th className="text-right">CGT</th>
                            <th className="text-right">Balance</th>
                        </tr>
                      </thead>
                      <tbody className="report-table-body">
                        {unitHolderStatement.map((item: any, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                               <td>{moment(item.Record.execution_date).format('DD-MM-YYYY')}</td>
                               <td>{CapTxnType(item.Record.txnr_type)}</td>
                               <td>{item.Record.fund_code}</td>
                               <td className="text-right">{numberWithCommas(item.Record.units)}</td>
                               <td className="text-right">{numberWithCommas(item.Record.gross_amount)}</td>
                               <td className="text-right">{numberWithCommas(item.Record.net_amount)}</td>
                               <td className="text-right">{numberWithCommas(item.Record.sale_load===undefined ? '0' : item.Record.sale_load)}</td>
                               <td className="text-right">{numberWithCommas(item.Record.redemption_load ===undefined ? '0' : item.Record.redemption_load)}</td>
                               <td className="text-right">{numberWithCommas(item.Record.tax_cgt ===undefined ? '0' : item.Record.tax_cgt )}</td>
                               <td className="text-right">{numberWithCommas(item.Record.balance ===undefined ? '0' : item.Record.balance)}</td>
                              </tr>
                            </>
                          );
                        })}
                        <tr>
                          <th>Total</th>
                          <th></th>
                          <th></th>
                          <th className="text-right">{numberWithCommas(totalUnits)}</th>
                          <th className="text-right">{numberWithCommas(totalGrossAmount)}</th>
                          <th className="text-right">{numberWithCommas(totalNetAmount)}</th>
                          <th className="text-right">{numberWithCommas(totalSaleLoad)}</th>
                          <th className="text-right">{numberWithCommas(totalRedemptionLoad)}</th>
                          <th className="text-right">{numberWithCommas(totalCGT)}</th>
                          <th></th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="float-right"><b>Closing Balance</b> : {numberWithCommas(closingBalance)}</div>
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}
               </PDFExport>
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                // printReport();
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
              Print
            </button>
          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default UnitHolderStatement;
