import React from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Container, Col, Row } from 'reactstrap';
import {
  getAllBanks,
  updateBankStatus,
  searchBankAPI,
} from '../../../stores/services/bank.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';

const BankList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  let [activePage, setActivePage] = useState('');
  const [isSearchActive, setSearchActive] = useState(false);
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');

  React.useEffect(() => {
    const getBankList = async () => {
      setLoading(true);
      try {
        const response = await getAllBanks(email, activePage, pageSize);
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10 &&
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);
  const getBankListUpdated = async () => {
    setLoading(true);
    try {
      const response = await getAllBanks(email, activePage, pageSize);
      let pageArr = paginationArr;
      let existingPageIndex = paginationArr.findIndex(
        (x) => x === response.data.page_info.next_page_number
      );
      if (
        existingPageIndex === -1 &&
        response.data.data.length === 10 &&
        10 * pageArr.length !== parseInt(response.data.page_info.total_count)
      ) {
        pageArr.push(response.data.page_info.next_page_number);
      }
      setPaginationArr(pageArr);
      setData(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentBankStatus, setCurrentBankStatus] = useState('');
  const [code, setCode] = useState('');
  const [bankLoading, setbankLoading] = useState(false);
  const updateStatus = async () => {
    setbankLoading(true);
    let status = '';
    if (currentBankStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateBankStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      getBankListUpdated();
    } catch (error) {}
    setbankLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].bank_name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const searchBankData = async () => {
    setLoading(true);
    try {
      const response = await searchBankAPI(email, searchValue);
      setData(response.data.data);
      setSearchActive(true);
      setPageSize('100');
    } catch (error) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      getBankListUpdated();
      setPageSize('10');
      setSearchActive(false);
    } else {
      setSearchValue(e.target.value);
    }
  }



  const renderUpdateBankStatusPopup = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal60w"	
            show={true}	
          >	
            <div className="modal-header justify-content-center">	
              <button aria-hidden="true" className="close" onClick={() => {	
                          setStatusSetPopup(false);	
                        }} data-dismiss="modal" type="button">	
                <i className="tim-icons icon-simple-remove"></i>	
                </button>	
                <h6 className="title title-up">Action</h6></div>	
            <div className="modal-body">	
              <div className="text-center">	
                <Row>	
                  <div className="mx-auto">	
                    {currentBankStatus === 'active' ? (	
                      <img src="assets/ban.svg" alt="" width="70" />	
                    ) : (	
                      <img src="assets/check.svg" alt="" width="70" />	
                    )}	
                    <h1 className="pb-3">Are You Sure?</h1>	
                    Are you sure you want to{' '}	
                    {currentBankStatus === 'active'	
                      ? 'Deactivate'	
                      : 'Activate'}{' '}	
                    this Bank?	
                  </div>	
                </Row>	
                <Row>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-primary "	
                        disabled={Boolean(bankLoading)}	
                        onClick={() => {	
                          updateStatus();	
                        }}	
                      >	
                        {bankLoading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Yes'	
                        )}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className=" ">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderData = () => {	
    return res.map((items: any, index: any) => {	
      return (	
        <tr key={index}>	
          <td>{items.bank_name}</td>	
          <td>{items.bank_code}</td>	
          <td>{moment(items.creation_date).format('DD-MM-YYYY')}</td>	
          <td className="captilize">{items.status}</td>	
          <td className=" ">	
            <div	
              className="mr-3 pointer"	
              onClick={() => {	
                setStatusSetPopup(true);	
                setCurrentBankStatus(items.status);	
                setCode(items.bank_code);	
              }}	
            >	
              {items.status !== 'active' ? (	
               <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
               <i className="tim-icons icon-simple-remove">
                 </i></button>
              ) : (	
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                <i className="fa fa-check">
                  </i></button>
              )}	 

              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                sessionStorage.setItem('bankObj', JSON.stringify(items));	
                history.replace(`/admin/edit-bank`);	
              }}>
                <i className="tim-icons icon-pencil">
                  </i></button>
            </div>	
           	
          </td>	
        </tr>	
      );	
    });	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		
        <div className="card">	
            <div className="card-header">	
            <h4 className="card-title">Bank Listing</h4>	
            </div>	
            <div className="d-flex align-items-center"></div>
          {!Loading ? (	
            <div className="card-body">	
              <div className="input-holder">	
                <div className="">	
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  />	
                  <div className="form-group inline-box">
                    <input	
                      type="search"	
                      id="myInput"	
                      data-tip="Bank Name"	
                      className="form-control"
                      placeholder="Enter Bank Name"	
                      value={searchValue}	
                      onChange={(e) => {	
                        // setSearchValue(e.target.value);	
                        handleSearchValue(e);	
                        // myFunction(e);	
                      }}	
                      onKeyPress={(e)=>{
                        let key_board_keycode = e.which || e.keyCode;
                        if(key_board_keycode == 13)
                        {
                            e.preventDefault();
                            searchBankData()
                        }
                      }}
                    />	
                    <button className="btn btn-primary ml-3 mt-a" onClick={searchBankData}>	
                      <i className="fa fa-search"></i> Search
                    </button>	
                  </div>
                </div>	
                <button	
                  className="btn btn-primary btn-sm ml-4"	
                  onClick={() => {	
                    history.replace('/admin/setup-banks');	
                  }}	
                >	
                  <span>	
                    <i className="fa fa-plus mr-2"></i>Add New	
                  </span>	
                </button>	
              </div>	
              <div className="div-table">
                <table	
                  className="table"	
                  data-pagination={true}	
                  data-page-size={5}	
                >	
                  <thead>	
                    <tr>	
                      <th>Bank Name</th>	
                      <th>Bank Code</th>	
                      <th>Date Created</th>	
                      <th>Status</th>	
                      <th>Action</th>	
                    </tr>	
                  </thead>	
                  <tbody>{renderData()}</tbody>	
                </table>	
              
                {!isSearchActive &&(
                <div	
                  className="row pr-3 pointer"	
                  style={{	
                    float: 'right',	
                  }}	
                >	
                  {/* PREVIOUS BUTTON */}	
                  {activePage !== paginationArr[0] ? (	
                    <i	
                      className="fa fa-arrow-left"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {	
                          setActivePage(paginationArr[index - 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                  {/* NEXT BUTTON */}	
                  {activePage !== paginationArr[paginationArr.length - 1] ? (	
                    <i	
                      className="fa ml-3 fa-arrow-right"	
                      aria-hidden="true"	
                      onClick={() => {	
                        let pageArr = paginationArr;	
                        let index = pageArr.findIndex((x) => x === activePage);	
                        if (index !== -1) {
                          setActivePage(paginationArr[index + 1]);	
                        }	
                      }}	
                    ></i>	
                  ) : (	
                    ''	
                  )}	
                </div>
                )}
              </div>	
              </div>
          ) : (	
            <div className="d-flex justify-content-around my-4">	
                <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 	
            </div>	
          )}	
          {renderUpdateBankStatusPopup()}	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default BankList;