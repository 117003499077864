import React, { useState } from 'react';		
import {	
  Container,	
  Row,	
  Col,	
} from 'reactstrap';	
import { editSecurity } from '../../../stores/services/security.service';	
import { useHistory, Link } from 'react-router-dom';	
import { toast, ToastContainer } from 'react-toastify';	
const codeRegex = new RegExp('^[A-Z]{1,5}$');	
const EditSecurity = () => {
    const history = useHistory();	
    const [securityType, setSecurityType] = useState('');	
    const [code, setCode] = useState('');	
    const [name, setName] = useState('');	
    const [issueDate, setIssueDate] = useState('');
    const [maturityDate, setMaturityDate] = useState('');	
    const [faceValue, setFaceValue] = useState('');	
    const [price, setPrice] = useState('');	
    
    const [securityTypeError, setSecurityTypeError] = useState('');	
    const [codeError, setCodeError] = useState('');	
    const [nameError, setNameError] = useState('');	
    const [issueDateError, setIssueDateError] = useState('');
    const [maturityDateError, setMaturityDateError] = useState('');
    const [faceValueError, setFaceValueError] = useState('');
    const [priceError, setPriceError] = useState('');
  
    const [Loading, setLoading] = useState(false);
    const updateSecurity = async () => {	
        if (!securityType) {	
          setSecurityTypeError('Required')	
          return;	
        }	
        if (!name) {	
          setNameError('Required')	
          return;	
        }	
        setLoading(true)	
        await editSecurity(sessionStorage.getItem('email') || '', name, code, securityType, issueDate, maturityDate,faceValue, price )	
          .then((response) => {	
            // toast.success(response.data.message);	
            // setTimeout(function () {	
            //   history.replace('/admin/securities');	
            // }, 3000);	
            if(response.data.status==200){
              toast.success(response.data.message);	
              setTimeout(function () {	
                history.replace('/admin/securities');	
              }, 3000);	
            }else if(response.data.status==500){
              toast.error(response.data.message);
            }else{
              toast.error(response.data.message);	
            }
          })	
          .catch((err) => {	
            // console.log(err);	
          })	
        setLoading(false)
    }	
          
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('securityObj') || '');
    setSecurityType(obj.security_type);
    setCode(obj.code);
    setName(obj.name);
    setIssueDate(obj.issue_date);
    setMaturityDate(obj.maturity_date);
    setFaceValue(obj.face_value);
    setPrice(obj.price);
  }, []);

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        <div className="card ">	
          <div className="">	
            <div className="card-header title-row">	
            <h4 className="card-title">Edit - Investment Securities</h4>	
              <Link to="/admin/securities" className="t-3 ml-auto btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>	
            </div>	
            <div className="card-body">	
               <Row>	
            <Col md="6">
                  <div className="form-group">
                    <label>Security Type</label>
                  <div>
                  <div className="">
                      <select 
                       className={`form-control  w-100 ${
                        securityTypeError ? ' required-border' : ''
                      }`}
                      value={securityType}
                      onChange={(e)=>{
                      setSecurityType(e.target.value);
                      setSecurityTypeError('');
                      }}>
                      <option value='' hidden>Select Security</option>
                      <optgroup>Money Market</optgroup>
                      <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasuary bill</option>

                      <optgroup>Debt Market</optgroup>
                      <option value='SUKUK'>Sukuk Certificates</option>
                      <option value='TFC'>Term Finance Certificates</option>
                      <option value='CP'>Commercial Papers</option>

                      <optgroup>Term Deposits</optgroup>
                      <option value='TDR'>Term Deposit Receipts</option>
                      <option value='CDR'>Call Deposit Receipts</option>
                      <option value='LOP'>Letter of Placement</option>
                      </select>
                        
                        
                    </div>
                  
                  </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Symbol: <span className="color-amber">*</span></label>
                  <div>
                  <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          codeError ? ' required-border' : ''
                        }`}
                        value={code}
                        readOnly
                        onChange={(e) => {
                        // setCode(e.target.value.toUpperCase());
                        // setCodeError('');
                        }} />
                         
                    </div>
                  
                  </div>
                  </div>
                </Col>	
            </Row>
            <Row>	
          
              <Col md="6">	
                <div className="form-group">	
                  <label>Name: <span className="color-amber">*</span></label>	
                  <div className="">	
                    <input className={`form-control  w-100 ${nameError ? ' required-border' : ''}`} value={name} onChange={(e) => {	
                      setName(e.target.value);	
                      setNameError('');	
                    }} />		
                  </div>	
                </div>	
              </Col>
              <Col md="6">
                  <div className="form-group">
                    <label>Issue Date</label>
                  <div>
                  <div className="">
                      <input
                        type="date"
                        className='form-control'
                        value={issueDate}
                        onChange={(e) => {
                          setIssueDate(e.target.value);
                        }} />
                        
                        
                    </div>
                  
                  </div>
                  </div>
                </Col>	
            </Row>
            <Row>	
         
              <Col md="6">	
                <div className="form-group">	
                  <label>Maturity Date</label>	
                  <div className="">	
                    <input type='date' value={maturityDate} className='form-control' onChange={(e) => {	
                    setMaturityDate(e.target.value);
                    }} />	
                  </div>	
                </div>	
              </Col>	
              <Col md="6">
                  <div className="form-group">
                    <label>Face Value</label>
                  <div>
                  <div className="">
                      <input
                        type="number"
                        className='form-control'
                        value={faceValue}
                        onChange={(e) => {
                          let value= decimalValue(e.target.value, 2);
                          setFaceValue(value);
                        }} />
                        
                        
                    </div>
                  
                  </div>
                  </div>
                </Col>
            </Row>
            <Row>	
            
                <Col md="6">
                  <div className="form-group">
                    <label>Price</label>
                  <div>
                  <div className="">
                      <input
                        type="number"
                        className='form-control'
                        value={price}
                        onChange={(e) => {
                          let value= decimalValue(e.target.value, 4);
                          setPrice(value);
                        }} />
                        
                        
                    </div>
                  
                  </div>
                  </div>
                </Col>
            </Row>
            <div className="">	
              <button className="btn btn-primary" onClick={updateSecurity} disabled={Boolean(Loading)}>	
                {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                  <span className="login-txt"> Loading...</span></> : <span>Update</span>}	
              </button>	
            </div>	
            </div>	
            	
          </div>	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EditSecurity;
