import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
// import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
import BetaTxnType from "../../components/beta-txn-type";
import FixedPlugin from "../../components/FixedPlugin";
import Header from "../../components/Header";

// import routes from "../../routes.js";
// import routes2 from "../../routes2.js";
import logo from "../../assets/macs-logo.png";
import Dashboard from "../../containers/components/DashboardPage";
import TransactionTxnFields from "../../containers/transactions/single-beta-txn";
import FundDividendPayout from "../../containers/transactions/fund-dividend-payout-txn";
import BulkUplaod from "../../containers/transactions/bulk-upload.tsx";
import UserManage from "../../containers/auth/user-setting";
// import PendingTransactions from "../../containers/pending-transactions";
import RejectedTransaction from "../../containers/txnListing/rejected-transactions";
import NavComponent from "../../containers/setup/nav/nav";
import AccountPortfolio from "../../containers/setup/portfolio/accountPortfolio";
import FundPortfolio from "../../containers/setup/portfolio/fundPortfolio";
import ClientPortfolio from "../../containers/setup/portfolio/unitPortfolio";
import ReconciliationScreen from "../../containers/reconciliation/index";
import PendingTransactions from "../../containers/txnListing/pending-transactions";
import ComplianceSignatoryTransactions from "../../containers/txnListing/comp-sign-listing";
import SignatoryA from "../../containers/txnListing/signatory-a";
import NotificattionScreen from "../../containers/components/notification-screen";
import AmcTransactionListing from "../../containers/txnListing/amc-transaction-listing";
import TransactionListing from "../../containers/txnListing/transaction-listing";
import BankAccountReport from "../../containers/report/bank-account-report";
import DailyNavReport from "../../containers/report/daily-nav-report";
 
import CollectiveTaxPayment  from "../../containers/tax-payment/collective-tax-payment-ledgers";
import CollectivePayment  from "../../containers/tax-payment/collective-payment-list";

// import ReconciliationReport from "../../containers/report/reonciliation-report";
import ReportScreen from "../../containers/report/report";
import TransactionReportScreen from "../../containers/report/transaction-report";
import UnitHolderReportScreen from "../../containers/report/unitHolder-report";
import UnitHolderStatement from "../../containers/report/unit-holder-statement";
import BankStatement from "../../containers/report/bank-statement";
import AccountSummaryReport from "../../containers/report/account-summary-report";
import RecieptPaymentRegisterReport from "../../containers/report/reciept-payment-register-report";
import EquityHoldingReport from "../../containers/report/equity-holding-report";
import EquityHoldingReconciliationReport from "../../containers/reconciliation/equity-holding-reconciliation-report";
import MoneyMarketTransactionReport from "../../containers/report/money-market-transaction-report";
import EquityInvestmentTransactionReport from "../../containers/report/equity-investment-transaction-report";
import EquitySettlementReport from "../../containers/report/equity-settlement-report";
import LiabilityReceivableReport from "../../containers/report/liability-and-receivable-report";
import IPSHoldingStatement from "../../containers/report/ips-holding-statement";

import AmcList from "../../containers/setup/amc/amc-listing";
import Setup from "../../containers/setup/amc/setup";
import EditAmc from "../../containers/setup/amc/edit-amc";
import ViewAmc from "../../containers/setup/amc/view-amc";

import FundList from "../../containers/setup/fund/fund-listing";
import SetupFunds from "../../containers/setup/fund/setup-funds";
import EditFunds from "../../containers/setup/fund/edit-fund";
import ViewFund from "../../containers/setup/fund/view-fund";

import AccountList from "../../containers/setup/account/account-listing";
import SetupAccount from "../../containers/setup/account/setup-account";
import EditAccountComponent from "../../containers/setup/account/edit-account";

import ClientList from "../../containers/setup/unit-holder/client-listing";
import SetupClients from "../../containers/setup/unit-holder/setup-clients";
import EditClient from "../../containers/setup/unit-holder/edit-client";
import ViewClient from "../../containers/setup/unit-holder/view-client";
// import ClientPortfolio from "../../containers/setup/unit-holder/unitPortfolio";
import UploadUnitHolding from "../../containers/setup/unit-holder/upload-unit-holding";
import ViewFile from "../../containers/setup/unit-holder/view-file-data";

import BrokerList from "../../containers/setup/broker/broker-listing";
import BrokerComponent from "../../containers/setup/broker/broker";
import EditBroker from "../../containers/setup/broker/edit-broker";

import SecurityList from "../../containers/setup/security/security-listing";
import SetupSecurity from "../../containers/setup/security/setup-security";
import EditSecurity from "../../containers/setup/security/edit-security";
import ViewSecurity from "../../containers/setup/security/view-security";
import SecurityTransactionFilter from "../../containers/setup/security/security-filter";

import BankList from "../../containers/setup/bank/bank-listing";
import SetupBank from "../../containers/setup/bank/setup-bank";
import EditBank from "../../containers/setup/bank/edit-bank";

import BranchList from "../../containers/setup/branch/branch-listing";
import SetupBranch from "../../containers/setup/branch/setup-branches";
import EditBranch from "../../containers/setup/branch/edit-branch";
import ViewBranch from "../../containers/setup/branch/view-branch";

import CheckList from "../../containers/setup/checklist/checklist-listing";
import SetupCheckList from "../../containers/setup/checklist/setup-checklist";
import EditCheckList from "../../containers/setup/checklist/edit-checklist";

import UsersList from "../../containers/userManagement/user-manage";
import AddUser from "../../containers/userManagement/add-user";
import EditUser from "../../containers/userManagement/edit-user";

import RoleManage from "../../containers/userRoles/role-manage";
import AddRole from "../../containers/userRoles/add-role";
import EditRole from "../../containers/userRoles/edit-role";

import CanvasScreen from "../../containers/transactions/template";
import PrintCheque from "../../containers/transactions/printTemplate";
import LetterFormater from "../../containers/transactions/leterFormter";
import PrintLeter from "../../containers/transactions/printLeter";

var ps;

const Admin = (props) => {
  const history = useHistory();
  const [activeColor, setActiveColor] = React.useState("blue");
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const [features, setFeatures] = React.useState([]);
  let [nodes, setNodes] = React.useState([]);
  let [routes, setRoutes] = React.useState([]);

  const data = JSON.parse(sessionStorage.getItem("features")) || "";

  document.addEventListener(
    "DOMContentLoaded",
    function () {
      onLoad();
    },
    false
  );
  async function onLoad() {
    try {
      let isvalid = true;
      const path =
        window.location.pathname == "/admin/details/compliance"
          ? "/admin/compliance"
          : window.location.pathname == "/admin/details/signatory-a"
          ? "/admin/signatory-a"
          : window.location.pathname == "/admin/details/signatory-b"
          ? "/admin/signatory-b"
          : window.location.pathname == "/admin/beta-transaction/saleofunit"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/transaction-types"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/unitconversion"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/conversionin"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/conversionout"
          ? "/admin/post-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/redemptionofunits"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/bulk-upload"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/fundtransfer"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/outflow"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/inflow"
          ? "/admin/post-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/debtmarketinvestment"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/termdeposit"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/bankprofit"
          ? "/admin/post-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/equityinvestment"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/cashdividend"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/cashmargin"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/marginreturn"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/advisoryfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/auditorsfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/bankcharges"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/managementfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/trusteefee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/custodyfee"
          ? "/admin/post-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/shariahadvisoryfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/cdcfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/listingfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/printingfee"
          ? "/admin/post-transaction"
          : window.location.pathname ==
            "/admin/beta-transaction/professionalfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/ratingfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/taxpayment"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/secpfee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/brokagefee"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/beta-transaction/otherpayment"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/fund-dividend-payout"
          ? "/admin/post-transaction"
          : window.location.pathname == "/admin/add-amc"
          ? "/admin/amc"
          : window.location.pathname == "/admin/view-amc"
          ? "/admin/amc"
          : window.location.pathname == "/admin/edit-amc"
          ? "/admin/amc"
          : window.location.pathname == "/admin/add-fund"
          ? "/admin/funds"
          : window.location.pathname == "/admin/view-fund"
          ? "/admin/funds"
          : window.location.pathname == "/admin/edit-fund"
          ? "/admin/funds"
          : window.location.pathname == "/admin/setup-accounts"
          ? "/admin/bank-accounts"
          : window.location.pathname == "/admin/edit-account"
          ? "/admin/bank-accounts"
          : window.location.pathname == "/admin/view-file"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/upload-unitholding"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/setup-clients"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/view-client"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/edit-client"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/setup-brokers"
          ? "/admin/associated-beneficiaries"
          : window.location.pathname == "/admin/edit-broker"
          ? "/admin/associated-beneficiaries"
          : window.location.pathname == "/admin/setup-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/view-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/edit-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/security-transactions"
          ? "/admin/securities"
          : window.location.pathname == "/admin/setup-banks"
          ? "/admin/banks"
          : window.location.pathname == "/admin/edit-bank"
          ? "/admin/banks"
          : window.location.pathname == "/admin/setup-branches"
          ? "/admin/bank-branches"
          : window.location.pathname == "/admin/edit-branch"
          ? "/admin/bank-branches"
          : window.location.pathname == "/admin/view-branch"
          ? "/admin/bank-branches"
          : window.location.pathname == "/admin/setup-checklist"
          ? "/admin/check-list"
          : window.location.pathname == "/admin/edit-checklist"
          ? "/admin/check-list"
          : window.location.pathname == "/admin/add-user"
          ? "/admin/user-management"
          : window.location.pathname == "/admin/edit-user"
          ? "/admin/user-management"
          : window.location.pathname == "/admin/add-role"
          ? "/admin/role-management"
          : window.location.pathname == "/admin/edit-role"
          ? "/admin/role-management"
          : window.location.pathname;

      const arr = routes;

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].views !== undefined) {
          const views = arr[i].views;
          for (var a = 0; a < views.length; a++) {
            if (path == "/admin" + views[a].path) {
              //
              isvalid = true;
              return;
            } else {
              isvalid = false;
            }
          }
        } else if (path == "/admin" + arr[i].path) {
          isvalid = true;
          return;
        }
      }
      if (isvalid === false) {
        if (sessionStorage.getItem("isAuthenticated") === "true") {
          history.replace("/admin/view");
          alert("You are not authorized!");
        } else {
          history.replace("/login");
        }
        // toast.error("You are not authorized!");
      }
    } catch (e) {
      alert(e.message);
    }
  }

  // let routes = [];
  // data.map((item, i) => {
  //   let obj = {
  //     path: `/${item.feature.replace(/\s+/g, "-").toLowerCase()}`,
  //     name: item.feature,
  //     rtlName: "لوحة القيادة",
  //     icon: "tim-icons icon-chart-pie-36",
  //     component: Dashboard,
  //     layout: "/admin",
  //   };
  //   routes.push(obj);
  // });

  const handleSetAllRoutes = (data) => {
    let newRoute = [];
    data.map((item, i) => {
      if (item.submenu) {
        if (item.enabled) {
          let obj = {
            collapse: true,
            name: item.label,
            rtlName: `${i}خرائط`,
            icon: "tim-icons icon-single-02 " + item.label.replace(" ", ""),
            state: item.label,
          };
          let subMenuArray = [];
          item.submenu.map((submenu, j) => {
            if (submenu.enabled) {
              let comp =
                submenu.label == "Post Transaction"
                  ? BetaTxnType
                  : submenu.label == "Transaction Status"
                  ? PendingTransactions
                  : submenu.label == "Rejected Transaction"
                  ? RejectedTransaction
                  : submenu.label == "Concerned Transactions"
                  ? TransactionListing
                  // : submenu.label == "Collective Payment"
                  // ? CollectiveTaxPayment
                  : submenu.label == "Collective Payment"
                  ? CollectivePayment
                  : submenu.label == "Compliance"
                  ? ComplianceSignatoryTransactions
                  : submenu.label == "Signatory-A"
                  ? ComplianceSignatoryTransactions
                  : submenu.label == "Signatory-B"
                  ? ComplianceSignatoryTransactions
                  : submenu.label == "Concerned Officer"
                  ? AmcTransactionListing
                  : submenu.label == "NAV Update"
                  ? NavComponent
                  : submenu.label == "Upload Scripts"
                  ? ViewFile
                  : submenu.label== "Bank Account Reconciliation"
                  ? ReconciliationScreen
                  : submenu.label == "Equity Holding Reconciliation"
                  ? EquityHoldingReconciliationReport
                  : submenu.label == "Unit Holder Portfolio"
                  ? ClientPortfolio
                  : submenu.label == "Fund Account Summary"
                  ? AccountPortfolio
                  : submenu.label == "Fund Investment Portfolio"
                  ? FundPortfolio
                  // : submenu.label == "Bank Accounts Report"
                  // ? BankAccountReport
                  : submenu.label == "Transaction Report"
                  ? TransactionReportScreen
                  : submenu.label== "Money Market Transaction Report"
                  ? MoneyMarketTransactionReport
                  : submenu.label == "Equity Investment Transaction Report"
                  ? EquityInvestmentTransactionReport
                  : submenu.label==="Unit Holder Statement"
                  ? UnitHolderStatement
                  : submenu.label==="Bank Statement"
                  ? BankStatement
                  : submenu.label==="Daily Account Summary"
                  ? AccountSummaryReport
                  : submenu.label==="Reciept Payment Register Report"
                  ? RecieptPaymentRegisterReport
                  : submenu.label == "Nav Report"
                  ? DailyNavReport
                  : submenu.label == "Fund Report"
                  ? ReportScreen
                  // : submenu.label == "Unit Management Report"
                  // ? UnitHolderReportScreen
                  : submenu.label=="Equity Holding Report"
                  ? EquityHoldingReport
                  : submenu.label == "Equity Settlement Report"
                  ? EquitySettlementReport
                  : submenu.label == "Liability & Receivable Report"
                  ? LiabilityReceivableReport
                  : submenu.label == "IPS Holding Statement"
                  ? IPSHoldingStatement
                  // : submenu.label == "Equity Holding Reconciliation Report"
                  // ? EquityHoldingReconciliationReport
                  : // :submenu.label==""
                  // ? ReconciliationReport

                  submenu.label == "AMC"
                  ? AmcList
                  : submenu.label == "Funds"
                  ? FundList
                  : submenu.label == "Bank Accounts"
                  ? AccountList
                  : submenu.label == "Unit Holders"
                  ? ClientList
                  : submenu.label == "Associated Beneficiaries"
                  ? BrokerList
                  : submenu.label == "Securities"
                  ? SecurityList
                  : submenu.label == "Banks"
                  ? BankList
                  : submenu.label == "Bank Branches"
                  ? BranchList
                  : submenu.label == "Check List"
                  ? CheckList
                  : submenu.label == "User Management"
                  ? UsersList
                  : submenu.label == "Role Management"
                  ? RoleManage
                  : submenu.label == "Cheque Template Designer"
                  ? CanvasScreen
                  : submenu.label == "Letter Template Designer"
                  ? LetterFormater
                  : Dashboard;
              var matches = submenu.label.match(/\b(\w)/g); // ['J','S','O','N']
              var acronym = matches.join("");
              let subObj = {
                path: `/${submenu.label.replace(/\s+/g, "-").toLowerCase()}`,
                name: submenu.label,
                rtlName: `${j}خرائط`,
                mini: acronym,
                rtlMini: "زم",
                component: comp,
                layout: "/admin",
                isAuthenticated: "true",
              };
              subMenuArray.push(subObj);
            }
          });
          if (subMenuArray.length !== 0) {
            Object.assign(obj, { views: subMenuArray });

            newRoute.push(obj);
          }
        }
      } else {
        // if (item.enabled) {
        //   // let obj = {
        //   //   path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
        //   //   name: item.label,
        //   //   rtlName: "لوحة القيادة",
        //   //   icon: "tim-icons icon-chart-pie-36",
        //   //   component: Dashboard,
        //   //   layout: "/admin",
        //   // };


        //   let obj = {
        //     path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
        //     name: item.label,
        //     rtlName: "لوحة القيادة",
        //     icon: "tim-icons " + item.label,
        //     component: ReconciliationScreen,
        //     layout: "/admin",
        //     isAuthenticated: "true",
        //   };
        //   newRoute.push(obj);
        // }
      }
    });

    setRoutes(newRoute);
  };

  // route
  React.useEffect(() => {
    // const response = JSON.parse(sessionStorage.getItem("features")) || "";
    //   setFeatures(response);
    //   response.unshift({
    //     id: -1,
    //     feature: "ESOS",
    //     parent_id: null,
    //   });
    //   for (let index = 0; index < response.length; index++) {
    //     if (response[index].parent_id === 0) {
    //       response[index].parent_id = -1;
    //     }
    //     response[index].value = response[index].id;
    //     response[index].label = response[index].feature;
    //   }
    //   var data = response;
    //   var root;
    //   const idMapping = data.reduce((acc, el, i) => {
    //     acc[el.id] = i;
    //     return acc;
    //   }, {});
    //   data.forEach((el) => {
    //     // Handle the root element
    //     if (el.parent_id === null) {
    //       root = el;
    //       return;
    //     }
    //     // Use our mapping to locate the parent element in our data array
    //     const parentEl = data[idMapping[el.parent_id]];
    //     // Add our current el to its parent's `children` array
    //     // parentEl.children = [...(parentEl.children || []), el];
    //   });
    //   setNodes([root]);

    let template = [];

    const buildDefaultTemplate = () => {
      template = [
        {
          label: "Dashboard",
          enabled: true,
          submenu: [
            {
              enabled: false,
              label: "View",
            },
          ],
        },

        {
          label: "Transactions",
          enabled: true,
          submenu: [
            {
              label: "Post Transaction",
              enabled: false,
            },
            {
              enabled: false,
              label: "Transaction Status",
            },
            {
              enabled: false,
              label: "Rejected Transaction",
            },
            {
              enabled: false,
              label: "Concerned Transactions",
            },
            {
              enabled: true,
              label: "Collective Payment",
            },
          ],
        },

        {
          label: "Processing",
          enabled: true,
          submenu: [
            {
              enabled: false,
              label: "Compliance",
            },
            {
              enabled: false,
              label: "Signatory-A",
            },
            {
              enabled: false,
              label: "Signatory-B",
            },

            {
              enabled: false,
              label: "Concerned Officer",
            },
          ],
        },

        {
          label: "Reconciliation",
          enabled: true,
          submenu: [
            {
              enabled: true,
              label: "Bank Account Reconciliation",
            },
            {
              enabled: true,
              label: "Equity Holding Reconciliation",
            },
          ],
        },
          

        {
          label: "Daily Tasks",
          enabled: true,
          submenu: [
            {
              enabled: false,
              label: "NAV Update",
            },
            {
              enabled: false,
              label: "Upload Scripts",
            },
          ],
        },

        {
          label: "Portfolio",
          enabled: true,
          submenu: [
            {
              enabled: false,
              label: "Unit Holder Portfolio",
            },
            {
              enabled: false,
              label: "Fund Account Summary",
            },
            {
              enabled: false,
              label: "Fund Investment Portfolio",
            },
          ],
        },

        {
          label: "Reports",
          enabled: true,
          submenu: [
            {
              enabled: false,
              label: "Fund Report",
            },
            // {
            //   enabled: false,
            //   label: "Unit Management Report",
            // },
            {
              enabled: true,
              label: "Transaction Report",
            },
            {
              enabled: true,
              label: "Money Market Transaction Report",
            },
            {
              enabled: true,
              label: "Equity Investment Transaction Report",
            },
            {
              enabled: true,
              label: "Nav Report",
            },
            {
              enabled: true,
              label: "Unit Holder Statement",
            },
            {
              enabled: true,
              label: "Bank Statement",
            },
            {
              enabled: true,
              label: "Daily Account Summary",
            },
            {
              enabled: true,
              label: "Liability & Receivable Report",
            },
            {
              enabled: true,
              label: "Reciept Payment Register Report",
            },
            {
              enabled: true,
              label: "Equity Settlement Report",
            },
            {
              enabled: true,
              label: "Equity Holding Report",
            },
            {
              enabled: true,
              label: "IPS Holding Statement",
            },
          ],
        },

        {
          label: "Management",
          enabled: true,
          submenu: [
            {
              label: "AMC",
              enabled: false,
            },
            {
              label: "Funds",
              enabled: false,
            },
            {
              label: "Bank Accounts",
              enabled: false,
            },

            {
              label: "Unit Holders",
              enabled: false,
            },

            {
              label: "Associated Beneficiaries",
              enabled: false,
            },

            {
              label: "Securities",
              enabled: false,
            },

            {
              label: "Banks",
              enabled: false,
            },
            {
              label: "Bank Branches",
              enabled: false,
            },

            {
              label: "Check List",
              enabled: false,
            },
          ],
        },

        {
          label: "Settings",
          enabled: true,
          submenu: [
            {
              label: "User Management",
              enabled: false,
            },
            {
              label: "Role Management",
              enabled: false,
            },

            {
              enabled: false,
              label: "Cheque Template Designer",
            },

            {
              enabled: false,
              label: "Letter Template Designer",
            },
          ],
        },

      ];
      if (
        sessionStorage.getItem("features") !== "undefined" &&
        sessionStorage.getItem("features") !== null
      ) {
        const featuesList = JSON.parse(sessionStorage.getItem("features"));
        let labelArr = [];
        for (let index = 0; index < featuesList.length; index++) {
          for (let j_index = 0; j_index < template.length; j_index++) {
            const result = searchTree(
              template[j_index],
              featuesList[index].feature
            );
            if (result !== null) {
              labelArr.push(result.label);
              break;
            }
          }
        }

        template.forEach(function iter(a) {
          if (labelArr.includes(a.label)) {
            a.enabled = true;
          }
          Array.isArray(a.children) && a.children.forEach(iter);
        });
      }
      // const menu = Menu.buildFromTemplate(template);
      // Menu.setApplicationMenu(menu);
      handleSetAllRoutes(template);
    };

    const searchTree = (element, matchingTitle) => {
      if (element.label === matchingTitle) {
        element.enabled = true;
        return element;
      } else if (element.submenu != null) {
        var result = null;
        for (let i = 0; result == null && i < element.submenu.length; i++) {
          result = searchTree(element.submenu[i], matchingTitle);
        }
        return result;
      }
      return null;
    };
    buildDefaultTemplate();
  }, []);

  // end route
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            isAuthenticated={prop.isAuthenticated}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleMiniClick = () => {
    // let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      // notifyMessage += "deactivated...";
    } else {
      setSidebarMini(true);
      // notifyMessage += "activated...";
    }
    // let options = {};
    // options = {
    //   place: "tr",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={activeColor}
        logo={{
          outterLink: "https://digitalcustodian.co/",
          text: "MACS-Core",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanelRef} data={activeColor}>
        {/* <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        /> */}
        <Header />
        <Switch>
          {getRoutes(routes)}

          {/* <Route path="/admin/create-transaction" component={BetaTxnType} /> */}
          <PrivateRoute
            path="/admin/transaction-types"
            component={BetaTxnType}
          />
          <PrivateRoute
            path="/admin/comp-sign-transactions/:type"
            component={ComplianceSignatoryTransactions}
          />
          {/* <Route path="/admin/signatory-a/:type" component={SignatoryA} /> */}
          <PrivateRoute path="/admin/details/:type" component={SignatoryA} />
          <PrivateRoute
            path="/admin/notification-view"
            component={NotificattionScreen}
          />
          <PrivateRoute
            path="/admin/beta-transaction/:type"
            component={TransactionTxnFields}
          />
          <PrivateRoute
            path="/admin/fund-dividend-payout"
            component={FundDividendPayout}
          />

          <PrivateRoute path="/admin/collective-tax-payment" component={CollectiveTaxPayment}/>
          {/* <PrivateRoute path="/admin/collective-payment" component={CollectiveTaxPayment}/> */}

          {/* <Route path="/admin/fund-report/:type" component={ReportScreen} /> */}
          <PrivateRoute
            path="/admin/report-beta/:type"
            component={ReportScreen}
          />
          <PrivateRoute
            path="/admin/reconciliation"
            component={ReconciliationScreen}
          />

          <PrivateRoute
            path="/admin/equity-holding-reconciliation"
            component={EquityHoldingReconciliationReport}
          />

          <PrivateRoute path="/admin/bulk-upload" component={BulkUplaod} />
          <PrivateRoute path="/admin/dashboard" component={Dashboard} />
          <PrivateRoute path="/admin/user-setting" component={UserManage} />

          {/* <PrivateRoute path="/admin/amc" componet={AmcList} /> */}
          <PrivateRoute path="/admin/add-amc" component={Setup} />
          <PrivateRoute path="/admin/edit-amc" component={EditAmc} />
          <PrivateRoute path="/admin/view-amc" component={ViewAmc} />

          <PrivateRoute path="/admin/funds" component={FundList} />
          <PrivateRoute path="/admin/add-fund" component={SetupFunds} />
          <PrivateRoute path="/admin/edit-fund" component={EditFunds} />
          <PrivateRoute path="/admin/view-fund" component={ViewFund} />

          <PrivateRoute path="/admin/bank-accounts" component={AccountList} />
          <PrivateRoute path="/admin/setup-accounts" component={SetupAccount} />
          <PrivateRoute
            path="/admin/edit-account"
            component={EditAccountComponent}
          />

          <PrivateRoute path="/admin/unit-holders" component={ClientList} />
          <PrivateRoute path="/admin/setup-clients" component={SetupClients} />
          <PrivateRoute path="/admin/edit-client" component={EditClient} />
          <PrivateRoute path="/admin/view-client" component={ViewClient} />
          <PrivateRoute
            path="/admin/upload-unitholding"
            component={UploadUnitHolding}
          />
          <PrivateRoute path="/admin/view-file" component={ViewFile} />

          <PrivateRoute
            path="/admin/associated-beneficiaries"
            component={BrokerList}
          />
          <PrivateRoute
            path="/admin/setup-brokers"
            component={BrokerComponent}
          />
          <PrivateRoute path="/admin/edit-broker" component={EditBroker} />

          <PrivateRoute path="/admin/securities" component={SecurityList} />
          <PrivateRoute
            path="/admin/setup-security"
            component={SetupSecurity}
          />
          <PrivateRoute path="/admin/edit-security" component={EditSecurity} />
          <PrivateRoute path="/admin/view-security" component={ViewSecurity} />
          <PrivateRoute
            path="/admin/security-transactions"
            component={SecurityTransactionFilter}
          />

          <PrivateRoute path="/admin/banks" component={BankList} />
          <PrivateRoute path="/admin/setup-banks" component={SetupBank} />
          <PrivateRoute path="/admin/edit-bank" component={EditBank} />

          <PrivateRoute path="/admin/bank-branches" componet={BranchList} />
          <PrivateRoute path="/admin/setup-branches" component={SetupBranch} />
          <PrivateRoute path="/admin/edit-branch" component={EditBranch} />
          <PrivateRoute path="/admin/view-branch" component={ViewBranch} />

          <PrivateRoute path="/admin/check-list" componet={CheckList} />
          <PrivateRoute
            path="/admin/setup-checklist"
            component={SetupCheckList}
          />
          <PrivateRoute
            path="/admin/edit-checklist"
            component={EditCheckList}
          />

          <PrivateRoute path="/admin/user-management" componet={UsersList} />
          <PrivateRoute path="/admin/add-user" component={AddUser} />
          <PrivateRoute path="/admin/edit-user" component={EditUser} />

          <PrivateRoute path="/admin/role-management" componet={RoleManage} />
          <PrivateRoute path="/admin/add-role" component={AddRole} />
          <PrivateRoute path="/admin/edit-role" component={EditRole} />

          <PrivateRoute
            path="/admin/print-screen/:type"
            component={PrintCheque}
          />
          <PrivateRoute
            exact
            path="/print-letter-screen/:type"
            component={PrintLeter}
          />

          {/* <Redirect from="*" to="/admin/dashboard" /> */}
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
      {/* <FixedPlugin
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleMiniClick={handleMiniClick}
      /> */}
      <FixedPlugin />
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("isAuthenticated") === "true" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export default Admin;
