import React from 'react';
import {
  updateBroker,
  getBroker,
  getBrokerType,
} from '../../../stores/services/broker.service';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { useState } from 'react';
import { Container, Row, Col, CardHeader, CardTitle, CardBody, Card } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';

const EditBroker = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [brokerType, setBrokerType] = useState('');
  const [revenueAuthority, setRevenueAuthority] = useState('');
  const [iban, setIban] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const obj = JSON.parse(sessionStorage.getItem('brokerObj') || '');
  const [acc_title, setAccTitle] = useState('');
  const [code, setCode] = useState('');
  const [acc_titleError, setAccTitleError] = useState('');
  const [codeError, setCodeError] = useState('');
  //error getting hooks
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);

  const [active, setActive] = useState('');

  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };
  const [brokerTypeData, setBrokerTypeData] = useState<any>([]);

  React.useEffect(() => {
    const getAccountList = async () => {
      setLoading(true);
      try {
        const response = await getBroker(email, obj.company_code);
        setCompanyName(response.data.data.company_name);
        setCode(response.data.data.company_code);
        setAccTitle(response.data.data.account_title);
        setBankName(response.data.data.bank_name);
        setBranchName(response.data.data.branch);
        setBrokerType(response.data.data.company_type);
        setRevenueAuthority(response.data.data.tax_collected);
        setIban(response.data.data.iban);
        setActive(response.data.data.active)

        getBranchByName(response.data.data.bank_name);
      } catch (err:any) {
        // toast.error(error.response.data.message[0]);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getAccountList();
  }, []);

  React.useEffect(() => {
    const fetchBank = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all banks list for dropdown
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
      } catch (error) {}
      try {
        const res = await getBrokerType(email);
        setBrokerTypeData(res.data.company_names);
      } catch (error) {}
      //get tx nature data for dropdown
    };
    fetchBank();
  }, []);

  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code}>
          {item.bank_code}-{item.bank_name}
        </option>
      );
    });
  };
  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };

  //render dropdown for bank name data
  const renderBrokerTypeDropdown = () => {
    return brokerTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data
  const validate = () => {
    let ntnNumErr,
      brokerErr = '';
    companyName.trim() === '' ? (brokerErr = 'Required') : (brokerErr = '');
    if (brokerErr) {
      setCompanyNameError(brokerErr);
      return false;
    } else {
      return true;
    }
  };

  const updateBrokerProcess = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await updateBroker(
          email,
          brokerType,
          bankName,
          branchName,
          iban,
          code,
          acc_title,
          companyName,
          revenueAuthority,
          active
        );
        toast.success(response.data.message);
        setTimeout(() => {
          history.replace(`/admin/associated-beneficiaries`);
        }, 3000);
      } catch (error) {
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message[0]);
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />

        <div className="input-holder">
              <h4 className="card-title">Edit - Associated Beneficiary</h4>
              <Link to="/admin/associated-beneficiaries" className="t-3 ml-auto btn btn-primary btn-sm" replace>
               <i className="fa fa-eye mr-2"></i> View All
              </Link>    
        </div> 

        <Row>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Associated Beneficiary
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group">
                    <label>Code:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          codeError ? 'required-border' : ''
                        }`}
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);
                          setCodeError('');
                        }}
                      />
                      {/* {codeError ? <p className="error-labels error-message2">{codeError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Company Name:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          companyNameError ? 'required-border' : ''
                        }`}
                        value={companyName}
                        onChange={(e) => {
                          // eslint-disable-next-line 
                          setCompanyName(e.target.value),
                            setCompanyNameError('');
                        }}
                      />
                      {/* {companyNameError ? <p className="error-labels error-message">{companyNameError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Company Type:</label>
                    <div className="">
                      <select
                        className="form-control"
                        value={brokerType}
                        onChange={(e) => {
                          setBrokerType(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Broker Type
                        </option>
                        {renderBrokerTypeDropdown()}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Revenue Authority:</label>
                    <div className="">
                      <select
                        className="form-control"
                        value={revenueAuthority}
                        onChange={(e) => {
                          setRevenueAuthority(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Revenue Authority
                        </option>
                        <option value="FBR">FBR - Federal Board Of Revenue</option>	
                        <option value="SRB">SRB - Sindh Revenue Board</option>	
                        <option value="PRA">PRA - Punjab Revenue Authority</option>	
                        <option value="BRA">BRA - Balochistan Revenue Authority</option>	
                        <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>
                      </select>
                    </div>
                  </div>


                </CardBody>
              </Card>
          </Col>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Bank Account Details
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group">
                    <label>Account Title:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          acc_titleError ? 'required-border' : ''
                        }`}
                        value={acc_title}
                        onChange={(e) => {
                          setAccTitle(e.target.value);
                          setAccTitleError('');
                        }}
                      />
                      {/* {acc_titleError ? <p className="error-labels error-message2">{acc_titleError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>IBAN / Account No:</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        value={iban}
                        onChange={(e) => {
                          setIban(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Bank Name:</label>
                    <div className="">
                      <select
                        className="form-control"
                        value={bankName}
                        onChange={(e) => {
                          setBankName(e.target.value);
                          getBranchByName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Bank
                        </option>
                        {renderBankNameDropdown()}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Branch Name:</label>
                        <input type="text" className="form-control"
                         value={branchName}
                         onChange={(e) => {
                           setBranchName(e.target.value);
                         }}
                         />
                    {/* <div className="">
                      <select
                        className="form-control"
                        value={branchName}
                        onChange={(e) => {
                          setBranchName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {branchCheckFlag
                            ? 'No Branch Found'
                            : 'Select Branch Name'}
                        </option>
                        {renderBranchsDropdown()}
                      </select>
                    </div> */}
                  </div>

                  <div className="form-group">	
                    <label>Active: <span className="color-amber">*</span></label>	
                        <select 
                        className="form-control"
                        value={active}
                        onChange={(e)=>{
                          setActive(e.target.value);
                        }}>
                        <option value="" hidden>Active / Deactive</option>
                        <option value="active">Active</option>
                        <option value="deactive">Deactive</option>
                        </select>	
                         
                  </div>

                </CardBody>
              </Card>
          </Col>
        </Row> 
        
        <button
          className="btn btn-primary mr-3"
          onClick={updateBrokerProcess}
          disabled={Boolean(Loading)}
        >
          {Loading ? (
            <>
              <span
                className=" "
                role="status"
                aria-hidden="true"
              ></span>
              <span className="login-txt"> <i className="fa fa-spin fa-spinner mr-1"></i> Loading...</span>
            </>
          ) : (
            <span>Update</span>
          )}
        </button> 

        <button
          className="btn btn-default"
          onClick={() => {
            history.replace('/admin/associated-beneficiaries');
          }}
        >
          Cancel
        </button> 

 
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EditBroker;
