import React from 'react';
import { useState } from 'react'; 
import { Container, Row, Col,FormGroup,Label,Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import moment from 'moment';
import { numberWithCommas } from '../../../utils/customFunction';

const ViewFund = () => {
  //hooks for getting all inputs from user
  const [fundName, setFundName] = useState('');
  const [code, setCode] = useState('');
  const [amcName, setAmcName] = useState('');
  const [fundType, setFundType] = useState('');
  const [incorporationDate, setIncorporationDate] = useState('');
  const [revocationDate, setRevocationDate] = useState('');
  const [psxListing, setPsxListing] = useState('');
  const [moneyMarket, setMoneyMarket] = useState<any>([]);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [nav, setNav] = useState('');
  const [faceValue, setFaceValue] = useState('');
  const [cdc_participant_id, setCDCParticipantID] = useState('');
  const [cdc_account_number, setCDCAccountNumber] = useState('');

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('fundObj') || '');
    setFundName(obj.fund_name);
    setCode(obj.symbol_code);
    setNav(obj.nav);
    setFaceValue(obj.face_value);
    setAmcName(obj.amc_code);
    setFundType(obj.nature);
    setIncorporationDate(obj.date_of_incorporation);
    setRevocationDate(obj.date_of_revocation);
    setPsxListing(obj.psx_listing);
    if (obj.money_market !== '' && obj.money_market !== '[]') {
      setMoneyMarket(JSON.parse(obj.money_market));
    }
    if (obj.stock_market !== '' && obj.stock_market !== '[]') {
      setStockMarket(JSON.parse(obj.stock_market));
    }
    if (obj.term_deposit !== '' && obj.term_deposit !== '[]') {
      setTermDeposit(JSON.parse(obj.term_deposit));
    }

    setCDCParticipantID(obj.cdc_participant_id);
    setCDCAccountNumber(obj.cdc_account_number);

  }, []);
  const [stockMarket, setStockMarket] = useState<any>([]);
  const renderFundDataInTable = () => {
    return moneyMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{moment(items.issue_date).format('DD-MM-YYYY')}</td> 
          <td>{moment(items.maturity_date).format('DD-MM-YYYY')}</td> 
          <td>{numberWithCommas(items.coupon_rate || '')}</td> 
          <td>{numberWithCommas(items.yield || '')}</td> 
          <td>{numberWithCommas(items.face_value || '')}</td>
          <td>{numberWithCommas(items.price || '')}</td> 
          <td>{numberWithCommas(items.units || '')}</td> 
          <td>{items.total_amount && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td> 
          <td>{items.tenor}</td> 
        </tr>
      );
    });
  };

  const renderEquityInTable = () => {
    return stockMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(2))}</td>
          <td>{numberWithCommas(items.unit || '') && numberWithCommas(parseFloat(items.unit).toFixed(4))}</td>
          <td>{numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}</td>
          <td>{items.market}</td>
        </tr>
      );
    });
  };

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount || '')}</td>
          <td>{numberWithCommas(items.interest_rate || '')}</td>
          <td>{moment(items.from_date).format('DD-MM-YYYY')}</td>
          <td>{moment(items.to_date).format('DD-MM-YYYY')}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
        </tr>
      );
    });
  };

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>

        <div className="input-holder">
            <h4 className="card-title">View - Funds</h4> 
              <Link to="/admin/funds" className="t-3 ml-auto btn btn-primary btn-sm" replace>
                <i className="fa fa-eye mr-2"></i> View All
              </Link> 
         </div>

         <Row>
           <Col md="6">
              <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Fund Details
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="form-group  ">
                <label>Fund Code</label>
                <div className="">
                  <input type="text" className="form-control" value={code} />
                </div>
              </div>

              <div className="form-group  ">
                <label>Fund Name</label>
                <div className="">
                  <input type="text" className="form-control" value={fundName} />
                </div>
              </div>

              <div className="form-group  ">
                <label>AMC Name</label>
                <div className="">
                  <select className="form-control" value={amcName}>
                    <option value={amcName} defaultChecked hidden>
                      {amcName}
                    </option>
                  </select>
                </div>
              </div>

              <div className="form-group  ">
                <label>Nature / Type of Fund</label>
                <div className="">
                  <input type="text" className="form-control" value={fundType} />
                </div>
              </div>
 
              </CardBody>
            </Card>
          </Col>

           <Col md="6">
              <Card>
              <CardHeader>
                <CardTitle tag="h4">
                Incorporation
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="form-group  ">
                <label>Date of Incorporation</label>
                <div className="">
                  <input
                    type="text"
                    className="form-control"
                    value={moment(incorporationDate).format('DD-MM-YYYY')}
                  />
                </div>
              </div>

              <div className="form-group  ">
                    <label>Date of Revocation</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        value={moment(revocationDate).format('DD-MM-YYYY')}
                      />
                    </div>
                  </div>
                  
                  <Row>
                    <Col md="6">
                      <div className="form-group  ">
                        <label>NAV</label>
                        <div className="">
                          <input type="text" className="form-control" value={nav} />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group  ">
                      <label>Face Value</label>
                      <div className="">
                        <input type="text" className="form-control" value={faceValue} />
                      </div>
                    </div>
                    </Col>
                  </Row> 

                  <Row>
                   <Col md="6">
                    <div className="form-group">
                      <label>
                        CDC Participant ID 
                      </label>
                      <div  >
                        <input
                          type="text"
                          className={`form-control  w-100 `}
                          value={cdc_participant_id}
                        /> 
                      </div>
                    </div>  
                   </Col>
                   <Col md="6">
                     <div className="form-group">
                       <label htmlFor="">CDC Account Number </label>
                       <input type="text" className="form-control"  
                       value={cdc_account_number}
                        />
                      </div>
                   </Col>
                 </Row>

                  <div className="">
                    <label>PSX Listing</label>
                    <div className=" ">
                      <div>  
                        <FormGroup check className="form-check-radio">
                          <Label check>
                            <Input
                              defaultChecked
                              id="listed"
                              name="listed"
                              value="true"
                              checked={true}
                              type="radio"
                            />
                            <span className="form-check-sign" />
                            {psxListing === 'true' ? 'Listed' : 'UnListed'}
                          </Label>
                      </FormGroup> 
                      </div>
                    </div>
                  </div>
              </CardBody>
            </Card>
          </Col>
         </Row>

         <Row>
         {moneyMarket.length > 0 && (
           <Col md="12">
              <Card>
              <CardHeader>
                <CardTitle tag="h4">
                   Money Market Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody> 
              <div className="input-size-pos mb-3 ">
                <h3></h3>
                <div className=" ">
                  <table className="table ">
                    <thead>
                      <tr>
                      <th>Symbol / Code</th>
                        <th>Security Type</th>
                        <th>Name</th>
                        <th>Issue Date</th>
                        <th>Maturity Date</th>
                        <th>Coupon Rate</th>
                        <th>Yield</th>
                        <th>Face Value</th>
                        <th>Price</th>
                        <th>Units</th>
                        <th>Settlement Amount</th>
                        <th>Tenor</th>
                      </tr>
                    </thead>
                    <tbody>{renderFundDataInTable()}</tbody>
                  </table>
                </div>
              </div>
          

              </CardBody>
            </Card>
          </Col>
          )}

          {stockMarket.length > 0 && (
           <Col md="12">
              <Card>
              <CardHeader>
                <CardTitle tag="h4">
                 Stock Market Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody> 
             
              <div className="input-size-pos mb-3 ">
                <h3></h3>
                <div className=" ">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Symbol / Code</th>
                        <th>Average Rate</th> 
                        <th>Quantity </th>
                        <th>Amount </th>
                        <th>Market </th>
                      </tr>
                    </thead>
                    <tbody>{renderEquityInTable()}</tbody>
                  </table>
                </div>
              </div>
           
              </CardBody>
            </Card>
          </Col>
           )} 

         </Row>

         {termDeposit.length>0 && (
         <Row>
           
         <Col md="12">
              <Card>
              <CardHeader>
                <CardTitle tag="h4">
                   Term Deposit Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody> 
              <div className="input-size-pos mb-3 ">
                <h3></h3>
                <div className=" ">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Principal Amount</th>
                        <th>Interest Rate</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Bank</th>
                        <th>Branch</th>
                      </tr>
                    </thead>
                    <tbody>{renderTermDepositInTable()}</tbody>
                  </table>
                </div>
              </div>
          
              </CardBody>
            </Card>
          </Col>
         </Row>
          )}
 
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewFund;
