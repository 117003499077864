import axios from 'axios';
import Config from './../../config/index';


const RefreshTokenHandler = async (resp)=> {
// console.log("[Refresh token handler] " , resp)

if(resp.message == "jwt expired")
{
    try {
        let refreshToken=sessionStorage.getItem('refreshToken') || '';
        let email=sessionStorage.getItem('email') || '';  
        let url = `${Config.baseUrl}/user/token`

        let response: any = await axios.post(
            url,
            { email, refresh_token : refreshToken  })
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('token');
            sessionStorage.setItem('refreshToken', response.data.refreshToken)
            sessionStorage.setItem('token', response.data.accessToken)
             return {status : true}
    }catch(err) {
        if(err.response.data.message == '[AuthGuard] Reuse of the refreshToken  detected!') {
            sessionStorage.clear();	
            window.location.href ="/login"
        }else {
            // console.log("caugth unpredictable response")
            throw err
        }
    }
    
}else if(resp.message == "[AuthGuard] Login again to restore success") {
    sessionStorage.clear();	
    window.location.href ="/login"
}else {
    return resp;
}

}

export default RefreshTokenHandler;