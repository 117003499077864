import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';


const getUnRealizedLedgers = async (
    email: string,
    amc_code: string,
    fund_code: string,
    ledger_type: string,
    date_from : string,
    date_to: string,
  ) => {
    const url = `${Config.baseUrl}/transaction/unrealizedledgers?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&ledger_type=${ledger_type}&date_from=${date_from}&date_to=${date_to}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await getUnRealizedLedgers(email,amc_code,fund_code,ledger_type,date_from,date_to)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
  };
  
  const saveCollectivePayment = async (
    email: string,
    data : any,
  ) => {
    const url = `${Config.baseUrl}/transaction/collectivepayment`;
    
    try{
    let  result = await axios.post(
        url,
        { email, data },
        {
          headers: {
            Authorization: sessionStorage.getItem('token') || '',
          },
        }
      );
      return result;
    }catch(err:any) {
      if(err.response.data.status == 401) { 
        let responseToHandle = err.response.data
        let result = await RefreshTokenHandler(responseToHandle)
        if(result.status) {
         return await saveCollectivePayment(email,data)
        }else {
          throw err;
          // return result
        }
      }else {
            throw err
      }
   }
    
  };

const getCollectivePayment = async (
    email: string,
    amc_code : string,
    fund_code : string,
    date_from : string,
    date_to : string
  ) => {
    const token = sessionStorage.getItem('token') || '';
    // const url = `${Config.baseUrl}/account?email=${email}&page_number=${page_number}&page_size=${page_size}`;
    const url = `${Config.baseUrl}/collectivepayment?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}&date_from=${date_from}&date_to=${date_to}`;
    try{
    const result: any = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await getCollectivePayment(email,amc_code, fund_code,date_from,date_to)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
        }
  }
  };

  export {
    getCollectivePayment,
    getUnRealizedLedgers,
    saveCollectivePayment
};