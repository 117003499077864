import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { getAllUnitHolders } from '../../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../../stores/services/amc.service';
import { getFundByAmc } from '../../../stores/services/funds.service';
import { use } from 'chai';
import { getMultiAccountByCodes } from './../../../stores/services/account.service';
const csv = require('csvtojson/v2');

const AccountPortfolio = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [multiFunds, setMultiFunds] = useState<any>([]);

  const [selected, setSelected] = useState<any>([]);
  const [selectedMoneyMarket, setSelectedMoneyMarket] = useState<any>([]);
  const [folio_no, setFolio_no] = useState('');
  const [totalAmount, setTotalAmount] = useState('0');
  const [totalUnits, setUnits] = useState('');
  const [amcName, setAmcName] = useState('');
  const [symbolCode, setSymbolCode] = useState('');
  const [totalMoneyMarAmount, setTotalMoneyMarAmount] = useState('0');
  const [allFundCodes, setAllFundCodes] = useState('');
  const [accountloading, setAccountLoading] = useState<boolean>(false);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const [ fundObject , setFundObject ] = useState([]);
  
  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getUnitHolders = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      setApiLoading(true);
      //get all Amc for dropdown
      try {
        const amcResponse = await getAmc(email);
        let temp = amcResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.name}-${val.amc_code}`,
            value: val.amc_code,
          };
        });
        setAmcdata(temp);
      } catch (error) {
        setApiLoading(false);
      }
      setApiLoading(false);
    };
    getUnitHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getfundByAmcCode = async (code: string) => {
    // setLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    setApiLoading(true);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      let fundCode = '';
      let fundObj = [] as any ;
      let temp = response.data.data.map((val) => {
        if (fundCode === '') {
          fundCode = val.symbol_code;
        } else {
          fundCode = fundCode + ',' + val.symbol_code;
        }
        let tempObj = {
          fund_name : val.fund_name,
          fundCode : val.symbol_code
        }
        fundObj.push(tempObj)
        return {
          ...val,
          label: `${val.fund_name}-${val.symbol_code}`,
          value: val.symbol_code,
        };
      });
      if (fundCode !== '') {
        setAccountLoading(true);
        try {
          const res = await getMultiAccountByCodes(email, fundCode);
          var codes = fundCode.split(',');
          setMultiFunds(res.data);
          let temp = Object.keys(res.data).map((val) => {
            // return { label: val, value: res.data[val] };
          });
        } catch (error) {}
      } else {
        setAccountLoading(false);
        toast.error('No Fund Found Against This AMC');
      }
      setFundObject(fundObj);
      setAllFundCodes(fundCode);
      setAllFunds(temp);
    } catch (error) {
      setApiLoading(false);
    }
    setApiLoading(false);
    setAccFundLoading(false);
    // setLoading(false);
  };
   
  const getFundName = (value) =>{
    let cd;
    fundObject.map((item:any, index:number) => {
        if (item.fundCode === value) {
          cd = item.fund_name
        }
    })
    return cd;
  }

  const renderStockMarketData = () => {
    return Object.keys(multiFunds).map((val, index) => {
      return (  
        <div className=" card" key={index}>
          <div className="card-header">
            <h4 className="card-title">
             {getFundName(val)}- <span className="text-primary"> ({val}) </span>  
            </h4>
          </div>
          <div className="card-body">

         
          {multiFunds[val].length > 0 ? (
            <table className="table ">
              <thead>
                <tr  >
                  <th className="w-75">Detail</th>
                  <th >Amount</th>
                </tr>
              </thead>
              <tbody>{renderMultiTableData(multiFunds[val])}</tbody>
            </table>
          ) : (
            <div className="record-empty">
              <BsTable className="icon" />
              <p className="text-center empty-text">Empty</p>
            </div>
          )}
        </div> 
        </div> 
      );
    });
  };

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const renderMultiTableData = (value) => {
    return value.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td >
            {items.bank_name}-{items.account_title}-{items.account_no}
          </td>
          <td>{parseFloat(items.balance_amount).toLocaleString()}</td>
        </tr>
      );
    });
  };



  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="d-flex align-items-center"></div>
          {!loading ? (
            <div className=" ">
              <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Fund Account Summary
                </h4>
              </div> 
 
          <div className="card-body">
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>AMC Name</label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      label={'Select'}
                      isDisabled={false}
                      onChange={(e) => {
                        setAmcName(e.value);
                        getfundByAmcCode(e.value);
                        selected.length = 0;
                        setSelected(selected);
                        setSymbolCode('');
                        setSelectedMoneyMarket(selected);
                        setTotalMoneyMarAmount('0');
                        setTotalAmount('0');
                      }}
                      value={amcdata.filter(
                        (option) => option.value === amcName
                      )}
                      isClearable={false}
                      isSearchable={true}
                      name="color"
                      options={amcdata}
                    />
                  </div>
                </Col>
              </Row>
              </div>
              </div>
 

               

              {apiLoading ? (
                <div className="d-flex justify-content-center my-5">
                  <i className="fa fa-spinner fa-spin fa-3x" ></i> 
                </div>
              ) : (
                accountloading && <div  > 
                  {renderStockMarketData()}
                  </div> 
              )}
            </div> 
          ) : (
            <div className="d-flex justify-content-center my-5">
                  <i className="fa fa-spinner fa-spin fa-3x" ></i> 
                </div>
          )}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default AccountPortfolio;
