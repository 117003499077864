import React from 'react';
import { useState, useRef } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { getAllUnitHolders } from '../../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { BsTable } from 'react-icons/bs';
import { getAmc } from '../../../stores/services/amc.service';
import { getFundByAmc } from '../../../stores/services/funds.service';
import { use } from 'chai';
import moment from 'moment';

const csv = require('csvtojson/v2');

const FundPortfolio = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [unitLoading, setUnitLoading] = useState<boolean>(false);
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);

  const [selected, setSelected] = useState<any>([]);
  const [selectedMoneyMarket, setSelectedMoneyMarket] = useState<any>([]);
  const [selectedTermDeposit, setSelectedTermDeposit] = useState<any>([]);
  const [totalTermDepositAmount, setTotalTermDepositAmount] = useState('0');
  const [folio_no, setFolio_no] = useState('');
  const [totalAmount, setTotalAmount] = useState('0');
  const [totalUnits, setUnits] = useState('');
  const [amcName, setAmcName] = useState('');
  const [symbolCode, setSymbolCode] = useState('');
  const [totalMoneyMarAmount, setTotalMoneyMarAmount] = useState('0');

  React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    const getUnitHolders = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      //get all Amc for dropdown
      try {
        const amcResponse = await getAmc(email);
        let temp = amcResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.name}-${val.amc_code}`,
            value: val.amc_code,
          };
        });
        setAmcdata(temp);
      } catch (error) {}
    };
    getUnitHolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getfundByAmcCode = async (code: string) => {
    // setLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.fund_name}-${val.symbol_code}`,
          value: val.symbol_code,
        };
      });
      setAllFunds(temp);
    } catch (error) {}
    setAccFundLoading(false);
    // setLoading(false);
  };

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const decimalValue = (value, len) => {
     // eslint-disable-next-line 
    value===undefined ? value='0' : value;
    let startingValue = value.toString().split('.')[0];
    let decimalVal = value.toString().split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  const renderStockMarketData = () => {
    return selected.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.market}</td>
          <td>{items.code ? items.code : 'N/A'}</td> 
          <td>{numberWithCommas(decimalValue(items.unit, 4))}</td>
          <td>
            {numberWithCommas(decimalValue(items.close_rate, 4))} 
          </td>
          <td className="text-right"> 
            {numberWithCommas(decimalValue(items.amount, 2))}
          </td>
        </tr>
      );
    });
  };

  const renderMoneyMarketData = () => {
    return selectedMoneyMarket.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.code ? items.code : 'N/A'}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.units || '')}</td>
          <td>{moment(items.issue_date).format('DD-MM-YYYY')}</td>
          <td>{moment(items.maturity_date).format('DD-MM-YYYY')}</td>
          <td className="text-right">{numberWithCommas(decimalValue(items.total_amount=="" ? "0" : items.total_amount,2))}</td>
        </tr>
      );
    });
  };

  const renderTermDepositData = () => {
    return selectedTermDeposit.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td className="text-right">{numberWithCommas(items.principal_amount || '')}</td>
          <td>{numberWithCommas(items.interest_rate || '')}</td>
          <td>{moment(items.from_date).format('DD-MM-YYYY')}</td>
          <td>{moment(items.to_date).format('DD-MM-YYYY')}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
        </tr>
      );
    });
  };

  const getCalculatredData = (marketData) => {
    //for stock Market
    if (
      marketData.stock_market !== '' &&
      marketData.stock_market !== '[]' &&
      marketData.stock_market !== undefined
    ) {
      let stock_market = JSON.parse(marketData.stock_market);
      let unit = 0;
      let amount = 0;
      stock_market.map((item, index) => {
        unit = unit + parseFloat(item.unit);
        amount = amount + parseFloat(item.amount);
      });
      setTotalAmount(amount.toFixed(2));
      setUnits(unit.toString());
      setSelected(stock_market);
    } else {
      selected.length = 0;
      setTotalAmount('0');
      setSelected(selected);
    }
    //for money market data
    if (
      marketData.money_market !== '' &&
      marketData.money_market !== '[]' &&
      marketData.money_market !== undefined
    ) {
      let money_market = JSON.parse(marketData.money_market);
      let amount = 0;
      money_market.map((item, index) => {
        amount = amount + parseFloat(item.total_amount==='' ? item.total_amount='0' : item.total_amount);
      });
      setTotalMoneyMarAmount(amount.toFixed(2));
      setSelectedMoneyMarket(money_market);
    } else {
      selectedMoneyMarket.length = 0;
      setTotalMoneyMarAmount('0');
      setSelectedMoneyMarket(selected);
    }

    // for term deposit
    if (
      marketData.term_deposit !== '' &&
      marketData.term_deposit !== '[]' &&
      marketData.term_deposit !== undefined
    ) {
      let term_deposit = JSON.parse(marketData.term_deposit);
      let amount = 0;
      term_deposit.map((item, index) => {
        amount = amount + parseFloat(item.principal_amount);
      });
      setTotalTermDepositAmount(amount.toFixed(2));
      setSelectedTermDeposit(term_deposit);
    } else {
      selectedTermDeposit.length = 0;
      setTotalTermDepositAmount('0');
      setSelectedTermDeposit(selectedTermDeposit);
    }
  };
  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className=" ">
          <div className="d-flex align-items-center"></div>
          {!loading ? (
            <div className="">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Fund Investment Portfolio
                </h4>
              </div> 
              <div className="card-body">
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>AMC Name</label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      label={'Select'}
                      isDisabled={false}
                      onChange={(e) => {
                        setAmcName(e.value);
                        getfundByAmcCode(e.value);
                        selected.length = 0;
                        setSelected(selected);
                        setSymbolCode('');
                        setSelectedMoneyMarket(selected);
                        setTotalMoneyMarAmount('0');
                        setTotalAmount('0');
                      }}
                      value={amcdata.filter(
                        (option) => option.value === amcName
                      )}
                      isClearable={false}
                      isSearchable={true}
                      name="color"
                      options={amcdata}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group  ">
                    <label>Select Fund</label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      label={'Select'}
                      isDisabled={false}
                      onChange={(e) => {
                        setUnitLoading(true);
                        let value = allFunds.filter((item: any) => {
                          return item.symbol_code === e.value;
                        });
                        setSymbolCode(value[0].symbol_code);
                        getCalculatredData(value[0]);
                        setUnitLoading(false);
                        // selected()
                      }}
                      value={allFunds.filter(
                        (option) => option.value === symbolCode
                      )}
                      isClearable={false}
                      isSearchable={true}
                      name="color"
                      options={allFunds}
                    />
                  </div>
                </Col>
              </Row>
              </div>
              </div>
             
             
             <div className="row"> 
              <div className="col-md-6">
                {selected.length > 0 ? (
                  <div className=" card">
                    <div className="card-header">
                      <h4 className="card-title">
                      Stock Market
                      </h4>
                    </div>
                    <div className="card-body">
                    
                  <table className="table ">
                    <thead>
                      <tr  >
                        <th> Market </th>
                        <th>Equity Portfolio</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderStockMarketData()}
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td>{numberWithCommas(totalUnits)}</td>
                        <td></td>
                        <td className="text-right">{numberWithCommas(totalAmount)}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  </div>
                ) : unitLoading ? (
                  ''
                ) : (
                  <div className="table-empty">
                    <BsTable className="icon" />
                    {folio_no === '' ? (
                      <p className="text-center empty-text">
                        Select Fund
                      </p>
                    ) : (
                      <p className="text-center empty-text">Empty</p>
                    )}
                  </div>
                )}
              </div> 

              <div className="col-md-6">
              {selectedMoneyMarket.length > 0 ? (
                <div className="  card">
                  <div className="card-header">
                    <h4 className="card-title">
                    Money Market
                    </h4>
                  </div> 
                  <div className="card-body">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Security Name</th>
                        <th>Units</th>
                        <th>Issue Date</th>
                        <th>Maturity Date</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderMoneyMarketData()}
                      <tr>
                        <td>Total</td>
                        <td colSpan={5} className="text-right mr-2">{numberWithCommas(totalMoneyMarAmount)=="NaN" ? "0" : numberWithCommas(totalMoneyMarAmount)}</td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
              ) : (
                ''
              )}
</div>

          </div>


          <div className="row">
            <div className="col-md-6">
                {selectedTermDeposit.length > 0 ? (
                  <div className=" card">
                    <div className="card-header">
                      <h4 className="card-title">
                      Term Deposit
                      </h4>
                    </div>
                    <div className="card-body">
                    
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th className="text-right">Principal Amount</th>
                        <th>Interest Rate </th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Bank</th>
                        <th>Branch</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderTermDepositData()}
                      <tr>
                        <td>Total</td>
                        <td colSpan={7} className="text-right">{totalTermDepositAmount==='NaN' ? '0' : numberWithCommas(totalTermDepositAmount)}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  </div>
                ) : (
                  ''
                ) }
              </div>
              </div>

              {parseFloat(totalMoneyMarAmount + totalAmount + totalTermDepositAmount) > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h4>
                  Grand Total :{' '}
                  {(parseFloat(totalMoneyMarAmount) +  +totalAmount + +totalTermDepositAmount).toLocaleString()}
                  </h4>
                </div>
                </div>
              )}
            
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <i className="fa fa-spinner fa-spin fa-3x" ></i> 
            </div>
          )}
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default FundPortfolio;
