import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';
/**
 *
 * @param email
 * @param bank_name
 * @param branch_name
 * @param city
 * @param area
 * @param branch_code
 * @param branch_address
 *
 */
const addBranch = async (
  email: string,
  bank_name: string,
  branch_name: string,
  city: string,
  area: string,
  branch_code: string,
  branch_address: string
) => {
  const url = `${Config.baseUrl}/branch/`;
  
  try{
  let  result = await axios.post(
      url,
      { email, bank_name, branch_name, city, area, branch_code, branch_address },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await addBranch(email,bank_name,branch_name,city,area, branch_code, branch_address)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
 }
  
};

/**
 *
 * @param email
 * @param bankName
 *
 */
const getOfficer = async (email: string, rolname: string) => {
  const url = `${Config.baseUrl}/user/get-all?email=${email}&role_name=${rolname}`;
  try{
  let result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler(responseToHandle)
    if(result.status) {
     return await getOfficer(email,rolname)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param name
 * @param role
 * @param description
 * @param user_email
 */
const addUser = async (
  email: string,
  name: string,
  role: string,
  description: string,
  user_email: string,
  signature_specimen:string
) => {
  const url = `${Config.baseUrl}/user/add`;
  try{
  let result: any = await axios.post(
    url,
    { email, name, role, description, user_email, signature_specimen },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await addUser(email,name,role, description, user_email,signature_specimen)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
 }
};

/**
 *
 * @param email
 * @param name
 * @param role
 */
const editUser = async (email: string, name: string, role: string, signature_specimen:string) => {
  const url = `${Config.baseUrl}/user/update-info`;
  try{
  let result: any = await axios.post(
    url,
    { email, name, role, signature_specimen },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await editUser(email,name,role,signature_specimen)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
 }
};

/**
 *
 * @param email
 */
const getAllUsers = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-all?email=${email}`;
 try{
  let result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || ''
    },
  });
   return result;
 }catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler(responseToHandle)
    if(result.status) {
     return await getAllUsers(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
 }
};

/**
 *
 * @param email
 * @param user_email
 * @param two_fa_enabled
 */
const enableDisableTwoFaAdmin = async (
  email: string,
  user_email: string,
  two_fa_enabled: Boolean
) => {
  const url = `${Config.baseUrl}/user/enable-disable-2fa/admin`;
  try{
  let result: any = await axios.post(
    url,
    { email, user_email, two_fa_enabled },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await enableDisableTwoFaAdmin(email,user_email,two_fa_enabled)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
   }
};

/**
 *
 * @param email
 * @param old_password
 * @param new_password
 */
const changePassword = async (
  email: string,
  old_password: string,
  new_password: string
) => {
  const url = `${Config.baseUrl}/user/change-password`;
  try{
  let result: any = await axios.post(
    url,
    { email, old_password, new_password },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await changePassword(email,old_password,new_password)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
 }
};

/**
 *
 * @param email
 */
const updateUserStatus = async (
  email: string,
  user_email: string,
  status: string
) => {
  const url = `${Config.baseUrl}/user/update-status`;
  try{
  let result: any = await axios.post(
    url,
    { email, user_email, status },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await updateUserStatus(email,user_email,status)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
 }
};
export {
  addBranch,
  updateUserStatus,
  getOfficer,
  addUser,
  getAllUsers,
  changePassword,
  editUser,
  enableDisableTwoFaAdmin,
};
