import React, { useState, useRef } from 'react';	
import {	
  Container,	
  Row,	
  Col,	
} from 'reactstrap';	
import { Link, useHistory } from "react-router-dom";	
import { getAllRoles } from "../../stores/services/role.service";	
import { addUser } from "../../stores/services/user.service";	
import { toast, ToastContainer } from 'react-toastify';	
const AddUser = () => {	
  const history = useHistory();	
  const [Loading, setLoading] = useState(false);	
  const [user_name, setUserName] = useState('');	
  const [user_email, setUserEmail] = useState('');	
  const [role, setRole] = useState('-1');	
  const [roles, setRoles] = useState([]);	
  let [nameError, setNameError] = useState(false);	
  let [emailError, setEmailError] = useState(false);	
  let [invalidEmailError, setInvalidEmailError] = useState(false);	
  let [roleError, setRoleError] = useState(false);	

  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');
  const [base64SpicemenImg, setBase64SpicemenImg] = useState<any | null>(null);
  const myRef1 = useRef<HTMLInputElement>(null);

  React.useEffect(() => {	
    getAllRoles(sessionStorage.getItem('email') || '')	
      .then((response) => {	
        // Filter Roles for Trustee user	
        let filterRoles = response.data.data.filter((user) => {	
          if (
            (user.role_name !== 'TXN_CREATOR' && user.role_name !== 'TXN-CREATOR' && user.role_name !== 'TXN CREATOR ' && user.role_name !== 'TXN CREATOR' ) &&
            (user.role_name !== 'AUTHORIZER-B' && user.role_name !== 'AUTHORIZER_B' && user.role_name !== 'AUTHORIZER B' && user.role_name !== 'AUTHORIZOR B')  &&
            (user.role_name !== 'AUTHORIZER-A' && user.role_name !== 'AUTHORIZER_A' && user.role_name !== 'AUTHORIZER A' && user.role_name !== 'AUTHORIZOR A')
          )  {	
            return user;	
          }	
          	
        });
        setRoles(filterRoles);	
      })	
      .catch((err) => {	
       // console.log(err);	
      });	
  }, []);	
   const ValidateEmail = (email: string) => {	
    if (	
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(	
        email	
      )	
    ) {	
      return true;	
    }	
    return false;	
  };	

  const upload=(e)=>{
    let file = e?.target.files[0];
    let filesize=parseInt(file.size);
    if(filesize<=500000)
    {
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    
    if (type === 'png' || type === 'jpg' || type ==='jpeg') {
      //code here
      setFile(file);
      setFileName(file.name)
      const reader = new FileReader();
      reader.readAsDataURL(file);
     
      reader.onload = function () {
        setBase64SpicemenImg(reader.result);
      };
    }else{
      toast.error('Invalid Format')
    }
  }else{
    toast.error("File size should be less than 500kb");
  }
  
  };

  const addUserBtn = () => {	
    setNameError(false);	
    setEmailError(false);	
    setRoleError(false);	
    setInvalidEmailError(false);	
    setLoading(true);	
    if (!user_name || user_name.trim() === '') {	
      toast.error('Name is Required');	
      setLoading(false);	
      return;	
    }	
    if (!user_email || user_email.trim() === '') {	
      toast.error('Email is Required');	
      setLoading(false);	
      return;	
    }	
    if (!ValidateEmail(user_email)) {	
      toast.error('Email is Invalid');	
      setLoading(false);	
      return;	
    }	
    if (role === '-1') {	
      toast.error('Role Selection is Required');	
      setLoading(false);	
      return;	
    }	
    addUser(	
      sessionStorage.getItem('email') || '',	
      user_name,	
      role,	
      'description',	
      user_email,
      base64SpicemenImg == null ? '' : base64SpicemenImg.toString()	
    )	
      .then((response) => {		
        toast.success(response.data.message);	
        setTimeout(function () {	
          history.replace('/admin/user-management');	
        }, 3000);	
        setLoading(false);	
      })	
      .catch((err) => {	
        setLoading(false);	
        toast.error(err.response.data.message);	
      });	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer />	
        <div className="card mt-4">	
          <div className="card-header">	
          <h4 className="card-title">Add User</h4>	
          </div>	
          <div className="card-body">	
            <div className="title-row">	
              <h3 className="mb-1">User Info</h3>	
              <Link to="/admin/user-management" className="t-3 btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>	
            </div>	
            <p className="t-3 mb-2">Please enter the following information to add a new admin or user for trustee system</p>	
            <div className="">	
            <Row>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Name</label>	
                  <input className="form-control" value={user_name} onChange={(e) => {	
                    setUserName(e.target.value);	
                  }} />	
                </div>	
                {	
                  nameError === true ?	
                    <p className="error-labels">Name is Required.</p>	
                    : ''	
                }	
              </Col>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Email</label>	
                  <input className="form-control" value={user_email} onChange={(e) => {
                    let value = e.target.value;	
                    setUserEmail(value.toLowerCase());	
                  }} />	
                </div>	
                {	
                  emailError === true ?	
                    <p className="error-labels">Email is Required.</p>	
                    : ''	
                }	
                {	
                  invalidEmailError === true ?	
                    <p className="error-labels">Email is Invalid.</p>	
                    : ''	
                }	
              </Col>	
            </Row>	
            <Row>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Select Role</label>	
                  <select className="form-control" value={role} onChange={(e) => {	
                    setRole(e.target.value);	
                  }}>	
                    <option value="-1">Select Role</option>	
                    {	
                      roles.map((role:any) => {	
                        return (	
                          <option value={role.role_name}>{role.role_name}</option>	
                        )	
                      })	
                    }	
                  </select>	
                </div>	
                {	
                  roleError === true ?	
                    <p className="error-labels">Role is Required.</p>	
                    : ''	
                }	
              </Col>	
            </Row>	

            <Row>	
              <Col md="6">	
                <div className="form-group">	
                  <label>Signature Specimen <small className="color-amber">(PNG or JPG of upto 500KB)</small></label>	
                  <div
                className="multi-input pointer"
                onClick={() => myRef1?.current?.click()}
              >
                <div className="form-group">
                  <div className="form-control">
                    {fileName === '' ? 'Upload File' : fileName}
                  </div>
                  <input
                    type="file"
                    ref={myRef1}
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={(e) => {
                      upload(e);
                    }}
                  />
                  {/* {base64SpicemenImg ? (
                    <img src={base64SpicemenImg} className="rounded" alt="spicemen" width="100px" height="100px"/>
                  ):(
                    ''
                  )} */}

                </div>
              </div>
                </div>	
                {base64SpicemenImg ? (
                    <img src={base64SpicemenImg} className="rounded" alt="spicemen" width="100px" height="100px"/>
                  ):(
                    ''
                  )}
              </Col>	
            </Row>

            </div>	
            <div className="mt-3">	
              <button className="btn btn-primary" onClick={addUserBtn} disabled={Boolean(Loading)}>	
                {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                  <span className="login-txt"> Loading...</span></> : <span>Add User</span>}	
              </button>	
            </div>	
          </div>	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  )	
};	
export default AddUser;