import React, { useEffect } from 'react';
import { useState , useRef} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsTable } from 'react-icons/bs';

import { getAmc } from '../../stores/services/amc.service';
import { getFundByAmc } from '../../stores/services/funds.service';
import { red } from '@material-ui/core/colors';

const EquityHoldingReconciliationReport = () => {
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem('email') || '';
  let date = new Date();
  const [allAmc, setAllAmc] = useState<any>([]);
  const [cdcData, setCDCData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundStockMarketData, setFundStockMarketData] = useState<any>([]);
  let [participantID, setParticipantID] = useState('');

  const myRef1 = useRef<HTMLInputElement>(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState('');

        useEffect(()=>{
          const getAllAMC=async()=>{
            try{
           const amcResponse = await getAmc(email, '', '', '');
           setAllAmc(amcResponse.data.data);
            }catch(err:any){
              if(err.response !== undefined ) {
                toast.error(err.response.data.message);	
              }else {
                toast.error("Request Failed!")
              }
            }
          }
            getAllAMC();
        },[])

        let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

        const CapTxnType = (type: any) => {
            let i, txType;
            for (i = 0; i < txnTypes.length; i++) {
            if (txnTypes[i].api_name === type) {
                txType = txnTypes[i].dis_name;
                break;
            }
            }
            return txType;
        };

        const renderAmcDropdown = () => {
            return allAmc.map((item: any, index: number) => {
            return (
                <option key={index} value={item.amc_code}>
                {item.name}
                </option>
            );
            });
        };

        const getFunds=async(code:any)=>{
            try{
                const response = await getFundByAmc(email, code);
                setAllFunds(response.data.data);
            }catch(error){

            }
        }

        const renderFundsDropDown=()=>{
            return allFunds.map((item: any, index: number) => {
                return (
                    <option key={index} value={item.symbol_code}>
                    {item.fund_name} - {item.symbol_code}
                    </option>
                );
          });
        }


  const  numberWithCommas = (amount:any) => {
      return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  }


  async function processLineByLine(fileObj) {
    var reader = new FileReader();
    reader.readAsText(fileObj);
    let content:any=[];
    reader.onload = function(fileObj) {
     content = reader.result;
     content = content.split('\n');
     var row1 = content[0].split(',');
     var code = row1[1].replace(/"/g,'');
     if(code===participantID){
     var data = [] as any;
     var i = 1;
     let TotalLine= content.length-1;
     for (i=1;i<TotalLine; i++){
     content[i] = content[i].replaceAll('"','').trim();
     let str = content[i].split(',');
    if(i>=1){
    let obj = {
        ['symbol']: str[0],
        ['name']: str[1],
        ['quantity']: str[4],
      };
      data.push(obj);
  }
}
setCDCData(data);
}else{
  setFileName('');
  setFile('');
  toast.error("Invalid file for the selected fund.")
}
setLoading(false);
}
}

  const uploadCDCFile  = (e: any) => {
    cdcData.length=0;
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'txt') {
      setLoading(true);
    setFileName(file.name);
    setFile(file);
    processLineByLine(file);
    }else{
      setLoading(false);
      toast.error('Please Upload Correct Format of File');
    }
  };

  const loadData= () =>{
    setLoading(true);
      if(fundStockMarketData.length==0){
        toast.error('Select fund.')
      }else if(cdcData.length==0){
          toast.error('Upload file.')
      }else{
     let cdcArray = cdcData.map((cdcitem , index)  => {
         let matchedObject =  fundStockMarketData.find(fundItem => {
              return fundItem.code === cdcitem.symbol
          })

        if(matchedObject) {
            return {
                symbol:cdcitem.symbol,
                  name:cdcitem.name,
                  macs_holding:matchedObject.unit,
                  cdc_holding:cdcitem.quantity,
                  difference: (+matchedObject.unit - +cdcitem.quantity).toFixed(0),
            }
        }else {
            return {
              symbol:cdcitem.symbol,
              name:cdcitem.name,
              macs_holding:'0',
              cdc_holding:cdcitem.quantity,
              difference: '-'+cdcitem.quantity,
            }
        }
      })
    
      let cdcArrayCodes = cdcArray.map(item => item.symbol);
      let fundUnmatched = fundStockMarketData.filter(item => !cdcArrayCodes.includes(item.code))
      fundUnmatched = fundUnmatched.map((item, index) =>{
                return {
                      symbol:item.code,
                      name:'',
                      macs_holding:item.unit,
                      cdc_holding:'0',
                      difference: item.unit,
                }
             })
      let finalArray = [...cdcArray , ...fundUnmatched]
      setData(finalArray)
    }
    setLoading(false);
  }

  return (
    <>
    <div className="content">
      <Row>
        <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Equity Holding Reconciliation</h4>
            </div>
            <div className="card-body">
            <Row>
              <Col md="2">
                <div className="form-group  ">
                  <label>Select AMC</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        getFunds(e.target.value);
                    }}
                  >
                    <option value="" defaultChecked hidden>
                      Select AMC
                    </option>
                    {renderAmcDropdown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        let filterFund = allFunds.filter((item, any)=>{
                           return (item.symbol_code===e.target.value);
                        });
                        setFundStockMarketData(JSON.parse(filterFund[0].stock_market))
                        participantID=filterFund[0].cdc_participant_id;
                        setParticipantID(participantID)
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>
              <Col md="2">
              <div>Upload Securities</div>
                             <div
                             onClick={() => myRef1?.current?.click()} >
                            <div className="form-group">
                          <div className="form-control" data-tip="Upload File">
                          
                            {filename === '' ? 'Upload File' : filename}
                          </div> 
                                  <input
                                  className="form-control mt-1"
                                    type="file"
                                    ref={myRef1}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      uploadCDCFile(e);
                                    }}
                                    onClick={(e)=>{
                                      e.currentTarget.value='';
                                    }}
                                  />
                                </div>
                              </div>
              </Col>

            
              <Col md="2">
            <div className=" ">
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                loadData();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Reconcile</span>
                )}
              </button>
            </div>
            </Col>
            </Row>
            {/* End */}
          </div>
          </div>

          {/* here Search results for report Data */}
          <div className="card mt-3"> 
            <div className="card-body">
              {data.length > 0 ? (
                <>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3>Equity Holding Reconciliation</h3>
                  </div>
                  
                  <div className='table-reponsive'
                    style={{
                      overflowX: 'auto',
                      overflowY: 'hidden',
                    }}
                  >
                    
                    <table className="table">
                      <thead style={{ alignSelf: 'center' }}>
                        <tr>
                           <th>Symbol</th>
                            <th>Name</th>  
                            <th className="text-right">Holding as per MACS</th>
                            <th className="text-right">CDC</th>
                            <th className="text-right">Difference</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item: any, index: number) => {
                          return (
                            <>
                            {parseFloat(item.difference)>0 && (
                              <tr key={index} className="highlight-row-success">
                              <td>{item.symbol}</td>
                               <td>{item.name}</td>                             
                               <td className="text-right">{item.macs_holding}</td>
                               <td className="text-right">{item.cdc_holding}</td>
                               <td className="text-right">{item.difference}</td>
                              </tr>
                            )}
                            {parseFloat(item.difference)<0 && (
                              <tr key={index} className="highlight-row-error">
                              <td>{item.symbol}</td>
                               <td>{item.name}</td>                             
                               <td className="text-right">{item.macs_holding}</td>
                               <td className="text-right">{item.cdc_holding}</td>
                               <td className="text-right">{item.difference}</td>
                              </tr>
                            )}
                            {parseFloat(item.difference)==0 && (
                              <tr key={index}>
                              <td>{item.symbol}</td>
                               <td>{item.name}</td>                             
                               <td className="text-right">{item.macs_holding}</td>
                               <td className="text-right">{item.cdc_holding}</td>
                               <td className="text-right">{item.difference}</td>
                              </tr>
                            )}
                            </>
                          );
                        })}

                       
                      </tbody>
                    </table>
                  </div>
                 
                </>
              ) : (
                <div className="table-empty">
                  <BsTable className="icon" />
                  <p className="text-center empty-text">No record found</p>
                </div>
              )}

          </div>
        </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EquityHoldingReconciliationReport;
