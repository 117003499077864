import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 */
const getFeatures = async (email: string) => {
  const url = `${Config.baseUrl}/bank/features?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler(responseToHandle)
    if(result.status) {
     return await getFeatures(email)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

/**
 *
 * @param email
 * @param role_name
 * @param description
 * @param features
 */
const addRole = async (
  email: string,
  role_name: string,
  description: string,
  features: string
) => {
  const url = `${Config.baseUrl}/role/`;
  try{
  const result: any = await axios.post(
    url,
    { role_name, description, email, features },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await addRole(email,role_name,description,features)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

const editRole = async (
  email: string,
  role_name: string,
  description: string,
  features: string
) => {
  const url = `${Config.baseUrl}/role/`;
  try{
  const result: any = await axios.put(
    url,
    { role_name, description, email, features },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await editRole(email,role_name,description,features)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};

/**
 *
 * @param email
 */
const getAllRoles = async (email: string) => {
  const url = `${Config.baseUrl}/role?email=${email}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: sessionStorage.getItem('token') || '',
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler(responseToHandle)
    if(result.status) {
     return await getAllRoles(email)
    }else {
      throw err;
      // return result
    }
}else {
      throw err
}
}
};

const updateRoleStatus = async (
  email: string,
  role_name: string,
  description: string,
  features: string,
  status: string
) => {
  const url = `${Config.baseUrl}/role/`;
  try{
  const result: any = await axios.put(
    url,
    { role_name, description, email, features, status },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler(responseToHandle)
      if(result.status) {
       return await updateRoleStatus(email,role_name,description,features,status)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  
  }
};

export { getFeatures, updateRoleStatus, addRole, getAllRoles, editRole };
