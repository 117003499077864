import React from 'react';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

import {
    Row,
    Col,
} from 'reactstrap';
interface IProps {
    setShowModal: Function;
    setTxCreator: Function;
    emailArray: any[];
    setEmailArray: Function;
}

const TxnCreatorPopup: React.FC<IProps> = ({
    setShowModal,
    setTxCreator,
    emailArray,
    setEmailArray,
  }: IProps) => {
    const [Loading, setLoading] = useState(false);
    const [emailAdd, setEmailAdd] = useState('');
    const emailRegex = new RegExp(
      '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
    );
    const [emailError, setEmailError] = useState('');
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState('');
    const validate = async () => {
      let nameErr,
        emailErr = '';
      emailAdd.trim() === ''
        ? (emailErr = 'Email Required')
        : emailRegex.test(emailAdd) !== true
        ? (emailErr = 'Invalid Email')
        : (emailErr = '');
      name.trim() === '' ? (nameErr = 'Name Required') : (nameErr = '');
      if (nameErr || emailErr) {
        setNameError(nameErr);
        setEmailError(emailErr);
        return false;
      } else {
        return true;
      }
    };
    const addEmailInList = async () => {
      const isValid = await validate();
      if (isValid) {
        setEmailError('');
        let obj = {
          name: name,
          email: emailAdd,
          role: 'TXN_CREATOR',
        };
        emailArray.push(obj);
        setEmailArray(emailArray);
        setShowModal(false);
      }
    };


    return (
        <Modal
        className=""
        dialogClassName="modal60w"
        show={true}
        >

        <div className="modal-header ">
            <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
                setEmailAdd('');
                setShowModal(false);
              }}
            >
                <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up"> Transaction Creator   </h6>
            </div>  

      <div className="modal-body">
        <Row> 
          <Col md="12">
            <div className="form-group">
                <label>Name</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError('');
                }}
              />
              {nameError ? (
                <p className="error-labels error-mes">{nameError}</p>
              ) : (
                ''
              )}
            </div>
          </Col>  

          <Col md="12">
            <div className="form-group">
            <label>Email</label>
              <input
                type="text"
                className="form-control"
                value={emailAdd}
                onChange={(e) => {
                  setEmailAdd(e.target.value);
                  setEmailError('');
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    addEmailInList();
                  }
                }}
              />
              {emailError ? (
                <p className="error-labels error-mes">{emailError}</p>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
        <div className="float-right">
          <Row>
            <Col>
              <div className="">
                <button
                  className="btn btn-primary"
                  disabled={Boolean(Loading)}
                  onClick={() => {
                    addEmailInList();
                  }}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Confirm</span>
                  )}
                </button>
              </div>
            </Col>
            <Col>
              <div className="">
                <button
                  className="btn btn-default"
                  onClick={() => {
                    setEmailAdd('');
                    setShowModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
    );
};
export default TxnCreatorPopup;